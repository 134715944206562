import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle` 
    * {
        box-sizing: border-box;
    }
     body {
        padding: 0;
    }
`;
export default GlobalStyles;
