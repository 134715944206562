import React from 'react';
import { Text, Button } from '@components';
import styled from 'styled-components';

const StyledCtn = styled.div`
    border-bottom: 2px solid ${(p) => p.theme.colors.extraLightGray};
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SavedAddressDetails = ({ streetAddress, city, zipcode, building, state, handleOnClick }) => {
    return (
        <StyledCtn>
            <div className="flex flex-col">
                <Text type="md" className="text-lg">
                    {streetAddress}
                </Text>
                {building && <Text type="smLT">{building}</Text>}
                <Text type="smLT">
                    {city}, {state}, {zipcode}
                </Text>
                <Button type="quaternary" className="flex">
                    edit
                </Button>
            </div>
            <div className="flex flex-col">
                <Button onClick={() => handleOnClick(streetAddress, building, city, state, zipcode)} type="tertiary">
                    Use this Address
                </Button>
            </div>
        </StyledCtn>
    );
};

export default SavedAddressDetails;
