import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Label, Button } from '@components';
import { useFormik, ErrorMessage, getIn } from 'formik';
import * as Yup from 'yup';
import getStyles from '@global/helpers/getStyles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logOut } from '@store/actions';

export const ChangePassword = ({ action, close }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required('Required'),
            newPassword: Yup.string().min(7).required('Required'),
            confirmNewPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
                .required('Password confirm is required'),
        }),
        onSubmit: (values) => {
            action(
                {
                    ...values,
                },
                (res) => {
                    //handleResponse with error
                    setLoading(false);
                    close(false);
                    dispatch(logOut(history));
                }
            );
        },
    });

    return (
        <form onSubmit={formik.handleSubmit} className="w-full flex flex-col content-center justify-center">
            <Label label="Old Password" type="primary">
                <TextInput
                    className="mt-1"
                    type="password"
                    name="oldPassword"
                    withoutValidation
                    placeholder=""
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.oldPassword}
                    style={getStyles(formik, 'oldPassword')}
                />
                {formik.errors.oldPassword && formik.touched.oldPassword ? (
                    <div className="mt-1 text-status-error">{formik.errors.oldPassword}</div>
                ) : null}
            </Label>
            <Label label="New Password" type="primary">
                <TextInput
                    className="mt-1"
                    type="password"
                    name="newPassword"
                    withoutValidation
                    placeholder=""
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.newPassword}
                    style={getStyles(formik, 'newPassword')}
                />
                {formik.errors.newPassword && formik.touched.newPassword ? (
                    <div className="mt-1 text-status-error">{formik.errors.newPassword}</div>
                ) : null}
            </Label>
            <Label label="Confirm New Password" type="primary">
                <TextInput
                    className="mt-1"
                    type="password"
                    name="confirmNewPassword"
                    withoutValidation
                    placeholder=""
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmNewPassword}
                    style={getStyles(formik, 'confirmNewPassword')}
                />
                {formik.errors.confirmNewPassword && formik.touched.confirmNewPassword ? (
                    <div className="mt-1 text-status-error">{formik.errors.confirmNewPassword}</div>
                ) : null}
            </Label>
            <Button
                className="w-full flex justify-center content-center items-center m-auto my-12 lg:mb-10 rounded-sm"
                type="primary"
                loading={loading}
            >
                Save Changes
            </Button>
        </form>
    );
};
