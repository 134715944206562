import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingPage } from 'components/GlobalComponents/Loading';
import { GenericContainer } from '@components';
import HeroContainer from './components/heroContainer';
import { MenuHero } from '@components/GlobalComponents/Heros/MenuHero/index';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch,
    Redirect,
    useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Menu from './pages/menu';
import Item from './pages/item';
import Favorites from './pages/favorites';
import Recents from './pages/recents';
import Category from './pages/category';
import { selectLocation } from '@store/actions';

import MainContainer from '../components/MainContainer';
import FilterContext from './components/filterContext';

const MenuContainer = () => {
    let { location } = useParams();
    let { path } = useRouteMatch();
    const { menu, error } = useSelector((state) => state.menuData);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const locationData = useSelector((state) => state.menuData?.location);

    const dispatch = useDispatch();
    let history = useHistory();

    return (
        <FilterContext.Provider
            value={{
                filters: [appliedFilters, setAppliedFilters],
            }}
        >
            <MainContainer>
                <div>
                    {error ? (
                        <>
                            <HeroContainer displayName="Menu" />
                            <MenuHero></MenuHero>
                            <GenericContainer className="max-w-xl">
                                <div className="mt-4">
                                    <span className="text-xl font-bold">
                                        There's been an error retreiving the menu. Please try a different location.
                                    </span>
                                </div>
                            </GenericContainer>
                        </>
                    ) : (
                        <div>
                            <Switch>
                                <Route exact path={path} component={Menu} />
                                <Route path={`${path}/edit/:index`} component={Item} />
                                <Route path={`${path}/:categoryId/:itemId`} component={Item} />
                                <Route path={`${path}/:categoryId`} component={Category} />
                                <Route path="*">
                                    <Redirect to="/menu" />
                                </Route>
                            </Switch>
                        </div>
                    )}
                </div>
            </MainContainer>
        </FilterContext.Provider>
    );
};

const MenuParentRouter = (props) => {
    let { location } = useParams();
    let { path } = useRouteMatch();
    const { menu, error } = useSelector((state) => state.menuData);

    const loading = useSelector((state) => state.rootData.loading);
    const [initialized, setinitialized] = useState(false);

    const locationData = useSelector((state) => state.menuData?.location);

    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        if (locationData?.slug != location) {
            dispatch(selectLocation({ location: location }));
        }
        setinitialized(true);
    }, [locationData?.id]);

    return (
        <>
            {(loading && !initialized) || (!menu && error === false) ? (
                <LoadingPage />
            ) : (
                <Switch>
                    <Route path={`${path}/favorites`} component={Favorites} />
                    <Route path={`${path}/recents`} component={Recents} />
                    <Route component={MenuContainer} />
                </Switch>
            )}
        </>
    );
};

export default MenuParentRouter;
