import styled from 'styled-components';
import tw from 'tailwind.macro';
import {
    DownArrowWhiteIcon,
    RedQuestionIcon,
    EmptyCircleIcon,
    BlackCircleCheckIcon,
    RedClockIcon,
    AvatarIcon,
    CreditCardIcon,
} from '@assets';

//////////---Checkout Header---//////////

export const CheckoutHeader = styled.div`
    ${tw`
        w-full
        flex
        flex-row
        justify-between
        content-center
        items-center
        border-b-2
        border-beige
    `}
`;

export const CheckoutHeaderLabel = styled.h2`
    ${tw`
		font-veneer
        text-3xl
        pb-5
    `}
`;

//////////---Checkout General---//////////

export const CheckoutDetailsContainer = styled.div`
    ${tw`w-full py-6 border-b-2 border-beige`}
`;
export const CheckoutHeadlineIconRow = styled.div`
    ${tw`w-full flex flex-row justify-between items-center`}
`;
export const CheckoutSimpleHeadline = styled.div`
    ${tw`flex flex-row items-center content-center`}
`;
export const CheckoutRedHeading = styled.p`
    ${tw`text-lg lg:text-2xl uppercase self-end font-futuraConMD text-primaryColor font-medium leading-8`}
`;

//////////---Icons---//////////

export const StyledRedClockIcon = styled(RedClockIcon)`
    ${tw`mr-4 self-center`}
`;
export const StyledBlackCircleCheckedIcon = styled(BlackCircleCheckIcon)`
    height: 30px;
    width: 30px;
`;
export const StyledEmptyCircleIcon = styled(EmptyCircleIcon)`
    height: 30px;
    width: 30px;
`;
export const StyledRedQuestionIcon = styled(RedQuestionIcon)`
    height: 30px;
    width: 30px;
    ${tw`mr-4`}
`;
export const StyledDownArrowWhiteIcon = styled(DownArrowWhiteIcon)`
    height: 30px;
    width: 30px;
    ${tw`mr-4`}
`;
export const StyledProfileIcon = styled(AvatarIcon)`
    height: 27px;
    width: 27px;
    ${tw`mr-4`}
`;
export const StyledCreditCardIcon = styled(CreditCardIcon)`
    height: 30px;
    width: 30px;
    ${tw`mr-4`}
`;

//////////---Order Details---//////////

export const CheckoutOrderDetailsCtn = styled.div`
    ${tw`w-full flex flex-col content-center`}
`;
export const CheckoutOrderDetailsRow = styled.div`
    ${tw`flex flex-row justify-between content-center items-center`}
`;
export const CheckoutOrderDetailsHeading = styled.p`
    ${tw`flex text-lg lg:text-2xl uppercase self-start font-futuraConMD text-black font-medium leading-6 lg:leading-8 tracking-wide`}
`;
export const CheckoutOrderDetailsTax = styled.p`
    ${tw`font-futura text-xs lg:text-sm leading-4 lg:leading-5 opacity-75`}
`;
export const CheckoutOrderDetailsSubTotal = styled.p`
    ${tw`font-futura text-xs lg:text-sm leading-4 lg:leading-5 opacity-75`}
`;
export const CheckoutOrderTotalsCtn = styled.div`
    ${tw`flex flex-row justify-between items-center py-2`}
`;
export const CheckoutOrderTotal = styled.p`
    ${tw`font-futuraHY text-lg lg:text-xl`}
`;

//////////---ASAP/Later Toggle---//////////
export const StyledAsap = styled.div`
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    ${tw`flex flex-row border-transparent rounded-sm justify-between py-3 pl-5 pr-2`}

    &:last-child {
        ${tw`mr-0 lg:mr-0`}
    }

    @media (min-width: 1024px) {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
    }
`;
export const StyledText = styled.p`
    ${tw`font-futura font-semibold text-base text-blackleading-5`}
`;
export const StyledDeliveryTimeSelectBar = styled.select`
    ${tw`w-full bg-primaryColor cursor rounded-sm text-white font-futura text-base font-normal tracking-normal py-4 px-4 lg:px-5 lg:py-3`}
`;

//////////---User Credit Cards---//////////
export const StyledCardBox = styled.div`
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    ${tw`flex flex-row border border-transparent content-center items-center rounded-sm justify-between py-3 pl-5 pr-2`}

    @media (min-width: 1024px) {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
    }
`;
export const StyledCardType = styled.p`
    ${tw`font-futura text-lg text-black leading-5 pr-1`}
`;
export const StyledEndingIn = styled.p`
    ${tw`font-futura text-lg text-black leading-5 pr-0.5`}
`;

//////////---Tip---//////////

export const StyledTipBox = styled.div`
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    ${tw`flex flex-row border border-transparent rounded-sm justify-between py-3 pl-5 pr-2`}

    @media (min-width: 1024px) {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
    }
`;

export const StyledTip = styled.p`
    ${tw`font-futuraHY text-lg text-black leading-5`}
`;

//////////---Confirmation---//////////

export const StyledConfirmationHeader = styled.div`
    ${tw`flex flex-col h-auto mb-6 md:mb-0 md:h-49 justify-start md:justify-center md:items-center md:content-center md:m-auto`}
`;
export const StyledHeroCtn = styled.div`
    ${tw`w-full bg-black h-48 md:h-49 -mt-1 flex md:justify-center md:items-center`}
`;
export const StyledSubCtn = styled.ul`
    ${tw`w-full py-5 px-5 md:px-8 text-white md:text-3xl flex flex-col md:flex-row content-center md:max-w-5xl md:items-start justify-around md:justify-between`}
`;
export const StyledOrderStatus = styled.li`
    ${tw`flex font-veneer uppercase m-0 md:m-auto items-start md:items-center justify-start md:justify-around`}
`;
export const StyledOrderStatusDetail = styled.div`
    text-transform: none;
    ${tw`flex m-0 md:m-auto items-start md:items-center justify-start md:justify-around w-inherit whitespace-no-wrap font-futuraLT text-white text-sm`}
`;

export const StyledCreateAccountSection = styled.div`
    ${tw`flex flex-col w-full px-4 md:px-0 pt-6 pb-9 md:pt-10 md:pb-13 border-b-2 border-beige`}
`;

//////////---OC Delivery Pickup Details---//////////

export const StyledDeliveryPickupSection = styled.div`
    ${tw`flex flex-col w-full px-4 md:px-0 pt-6 pb-9 md:pt-10 md:pb-13 border-b-2 border-beige`}
`;
export const StyledHeader = styled.div`
    ${tw`font-futuraConMD uppercase text-lg md:text-2xl leading-5 md:leading-7 mb-4`}
`;
export const StyledDeliveryPickupTime = styled.div`
    ${tw`font-futura font-semibold text-base md:text-lg leading-5 mb-0.5`}
`;
export const StyledStreetAddress = styled.div`
    ${tw`flex font-futuraLT text-sm md:text-lg leading-6 mt-1 text-opacity-70`}
`;
export const StyledStreetCityStateZip = styled.div`
    ${tw`flex font-futuraLT text-sm md:text-lg leading-6 mb-5 text-opacity-70`}
`;
export const StyledPhoneNumber = styled.div`
    ${tw`flex font-futuraLT text-sm md:text-lg leading-6 text-opacity-70`}
`;
export const StyledStoreInfo = styled.div`
    ${tw`w-full flex justify-between items-start`}
`;
export const StyledStoreDetails = styled.div`
    ${tw`flex flex-col`}
`;

//////////---Order Summary---//////////

export const StyledOrderSummarySection = styled.div`
    ${tw`flex flex-col w-full px-4 md:px-0 pt-6 pb-9 md:pt-10 md:pb-13`}
`;
