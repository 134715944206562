import MainContainer from 'containers/components/MainContainer';
import React from 'react';
import GenericContainerNarrow from '../../GenericContainerNarrow';
import {
    StyledHeroCtn,
    StyledHeroHeading,
    StyledSubHeading,
    StyledDateCtn,
    StyledDate,
    StyledMessageCtn,
    StyledSmallText,
    StyledBoldWords,
    StyledOrderedListCtn,
    StyledNumberedListItem,
    StyledNoDiscListItem,
    StyledLetteredListItem,
} from './styles/index';

export const UserAgreement = () => {
    return (
        <MainContainer>
            <StyledHeroCtn>
                <StyledHeroHeading>User Agreement</StyledHeroHeading>
            </StyledHeroCtn>
            <GenericContainerNarrow className={'px-4 py-7 lg:py-12'}>
                <StyledMessageCtn>
                    <StyledSubHeading>TERMS AND CONDITIONS</StyledSubHeading>
                    <StyledDateCtn>
                        <StyledDate>Last updated: 10 December 2020</StyledDate>
                    </StyledDateCtn>
                    <StyledSmallText>
                        Thank you for visiting our terms of use agreement (the "
                        <StyledBoldWords>Terms of Use</StyledBoldWords>"). These Terms of Use govern your use of this
                        website and any branded websites that link to these terms (each, a "
                        <StyledBoldWords>Website</StyledBoldWords>"), the services and resources enabled therein (each a
                        "<StyledBoldWords>Service</StyledBoldWords>" and collectively, the "
                        <StyledBoldWords>Services</StyledBoldWords>"), and each branded application that includes links
                        to these terms (the "<StyledBoldWords>Application</StyledBoldWords>" together with the Website
                        and Services, the "<StyledBoldWords>Properties</StyledBoldWords>"), which are provided to you by
                        Olo, Inc. ("
                        <StyledBoldWords>Olo</StyledBoldWords>") on behalf of the company whose branding is displayed on
                        the Properties (Taco Mac). This Terms of Use agreement is a legal agreement between you and Olo,
                        and not Taco Mac.
                        <div className="py-2">
                            PLEASE READ THIS TERMS OF USE CAREFULLY. BY ACCESSING OR USING THE WEBSITE OR SERVICES,
                            CLICKING ON THE "I ACCEPT" BUTTON, COMPLETING THE REGISTRATION PROCESS, AND/OR BROWSING THE
                            WEBSITE OR DOWNLOADING THE APPLICATION, YOU REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND,
                            AND AGREE TO BE BOUND BY THESE TERMS OF USE, (2) YOU ARE OF LEGAL AGE TO FORM A BINDING
                            CONTRACT WITH OLO, AND (3) YOU HAVE THE AUTHORITY TO ENTER INTO THE TERMS OF USE PERSONALLY
                            OR ON BEHALF OF THE COMPANY YOU HAVE NAMED AS THE USER, AND TO BIND THAT COMPANY TO THE
                            TERMS OF USE. THE TERM "YOU" REFERS TO THE INDIVIDUAL OR LEGAL ENTITY, AS APPLICABLE,
                            IDENTIFIED AS THE USER WHEN YOU REGISTERED ON THE SERVICES.{' '}
                            <StyledBoldWords>
                                IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF USE, YOU MAY NOT ACCESS OR USE THIS
                                WEBSITE, APPLICATION OR SERVICES
                            </StyledBoldWords>
                            .
                        </div>
                    </StyledSmallText>
                    <StyledBoldWords>
                        THE TERMS OF USE INCLUDE A CLASS ACTION WAIVER AND WAIVER OF JURY TRIALS, AND REQUIRE BINDING
                        ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES.
                    </StyledBoldWords>
                    <StyledBoldWords>
                        THE TERMS OF USE LIMIT THE REMEDIES THAT MAY BE AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.
                    </StyledBoldWords>
                    <StyledSmallText>
                        PLEASE NOTE THAT THE TERMS OF USE ARE SUBJECT TO CHANGE BY OLO IN ITS SOLE DISCRETION AT ANY
                        TIME. When changes are made, Olo will make a new copy of the Terms of Use available at the
                        Website and within the Application. We will also update the "Last Updated" date at the top of
                        the Terms of Use. If we make any material changes, and you have registered with us to create an
                        Account (as defined below) we will also send an e-mail to you at the last e-mail address you
                        provided to us pursuant to the Terms. Any changes to the Terms of Use will be effective
                        immediately for new users of the Website, the Application and/ or Services and will be effective
                        thirty (30) days after posting notice of such changes on the Website for existing users,
                        provided that any material changes shall be effective for users who have an Account with us upon
                        the earlier of thirty (30) days after posting notice of such changes on the Website or thirty
                        (30) days after dispatch of an e-mail notice of such changes to Registered Users (defined
                        below). Olo may require you to provide consent to the updated Terms of Use in a specified manner
                        before further use of the Website, the Application and/ or the Services is permitted. If you do
                        not agree to any change(s) after receiving a notice of such change(s), you shall stop using the
                        Website, the Application and/or the Services. Otherwise, your continued use of the Website, the
                        Application and/or Services constitutes your acceptance of such change(s). PLEASE REGULARLY
                        CHECK THE WEBSITE TO VIEW THE THEN-CURRENT TERMS OF USE.
                    </StyledSmallText>
                    <StyledOrderedListCtn>
                        <StyledNumberedListItem>
                            <StyledBoldWords>Use of the Services</StyledBoldWords>
                            <StyledOrderedListCtn>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Application License</StyledBoldWords>. The Properties are protected
                                    by copyright laws throughout the world. Subject to your compliance with these Terms
                                    of Use, Olo grants you a limited non-exclusive, non-transferable, revocable license
                                    to download, install and use a copy of the Application on a mobile device or
                                    computer that you own or control and to run such copy of the Application solely for
                                    your own personal purposes. Furthermore, with respect to any Application accessed
                                    through or downloaded from the Apple iTunes App Store (an "App Store Sourced
                                    Application"), you will only use the App Store Sourced Application (i) on an
                                    Apple-branded product that runs the iOS (Apple’s proprietary operating system) and
                                    (ii) as permitted by the "Usage Rules" set forth in the Apple App Store Terms of
                                    Service.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Updates</StyledBoldWords>. You understand that the Properties are
                                    evolving. As a result, Olo may require you to accept updates to the Properties that
                                    you have installed on your computer or mobile device. You acknowledge and agree that
                                    Olo reserves the right, in its sole discretion, to modify the Properties from time
                                    to time, with or without notice. You may need to update third-party software from
                                    time to time in order to use the Properties.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Branded Company Products</StyledBoldWords>. The Properties enable
                                    Taco Mac to offer various food, beverage and other related products ("Products") to
                                    you and other users. The Products advertised or otherwise made available for
                                    purchase on the Properties are determined solely by Taco Mac, and Olo shall have no
                                    liability to you for any modification, unavailability or discontinuation of any
                                    Products.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Delivery Services</StyledBoldWords>. Any delivery service you use,
                                    as provided at the option of Taco Mac, is at your sole discretion and Olo shall have
                                    no liability to you for any delivery-related issues.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Limitations on Your Use of Services</StyledBoldWords>. The rights
                                    granted to you in these Terms of Use are subject to the following restrictions: (a)
                                    you may not attempt, or authorize, encourage, or support others' attempts, to
                                    circumvent, reverse engineer, decrypt, break or otherwise alter or interfere with
                                    the Properties; (b) you may not copy, distribute, sell, resell, or exploit for any
                                    commercial purposes any portion of the Properties, or any Products accessible
                                    through the Services; (c) you shall not use any manual or automated software,
                                    devices or other processes, including, without limitation, spiders, robots,
                                    scrapers, data mining tools, and the like, to "scape" or download data from any web
                                    pages contained in the Website; (d) you shall not access the Properties to build a
                                    competing or similar website, application or service; and (e) except as expressly
                                    stated herein, no part of the Properties may be copied, reproduced, distributed,
                                    republished, downloaded, displayed, posted or transmitted in any form or by any
                                    means. Any future release, update or other addition to the Properties shall be
                                    subject to these Terms of Use. Olo, its suppliers and services providers, and Taco
                                    Mac reserve all rights not granted in these Terms of Use. Any authorized use of the
                                    Properties terminates the licenses granted by Olo hereunder.
                                </StyledLetteredListItem>
                            </StyledOrderedListCtn>
                        </StyledNumberedListItem>
                        <StyledNumberedListItem>
                            <StyledBoldWords>Eligibility and Registration</StyledBoldWords>
                            <StyledOrderedListCtn>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Registering Your Account</StyledBoldWords>. In order to access
                                    certain features of the Properties you may be required to become a Registered User.
                                    For purposes of the Terms of Use, a "
                                    <StyledBoldWords>Registered User</StyledBoldWords>" is a user who has registered an
                                    account on the Website or Application ("
                                    <StyledBoldWords>Account</StyledBoldWords>
                                    "), has a valid account with a third party service or social networking service ("
                                    <StyledBoldWords>SNS</StyledBoldWords>") through which the User has connected to the
                                    Services (each such account, a "
                                    <StyledBoldWords>Third-Party Account</StyledBoldWords>
                                    ").
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Access Through a SNS</StyledBoldWords>. If you access the Services
                                    through a SNS as part of the functionality of the Website, the Application and/or
                                    the Services, you may link your Account with Third-Party Accounts, by allowing Olo
                                    to access your Third-Party Account, as is permitted under the applicable terms and
                                    conditions that govern your use of each Third-Party Account. You represent that you
                                    are entitled to disclose your Third-Party Account login information to Olo and/or
                                    grant Olo access to your Third-Party Account (including, but not limited to, for use
                                    for the purposes described herein) without breach by you of any of the terms and
                                    conditions that govern your use of the applicable Third-Party Account and without
                                    obligating Olo to pay any fees or making Olo subject to any usage limitations
                                    imposed by such third-party service providers. By granting Olo access to any
                                    Third-Party Accounts, you understand that Olo may access, make available and store
                                    (if applicable) any information, data, text, software, music, sound, photographs,
                                    graphics, video, messages, tags and/or other materials accessible through the
                                    Properties ("
                                    <StyledBoldWords>Content</StyledBoldWords>
                                    ") that you have provided to and stored in your Third-Party Account ("
                                    <StyledBoldWords>SNS Content</StyledBoldWords>
                                    ") so that it is available on and through the Properties via your Account. Unless
                                    otherwise specified in the Terms of Use, all SNS Content is, as between you and Olo,
                                    your content. Depending on the Third-Party Accounts you choose and subject to the
                                    privacy settings that you have set in such Third-Party Accounts, personally
                                    identifiable information that you post to your Third-Party Accounts may be available
                                    on and through your Account on the Properties. Please note that if a Third-Party
                                    Account or associated service becomes unavailable or the Olo’s access to such
                                    Third-Party Account is terminated by the third-party service provider, then SNS
                                    Content will no longer be available on and through the Properties. PLEASE NOTE THAT
                                    YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR
                                    THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY
                                    SERVICE PROVIDERS, AND OLO DISCLAIMS ANY LIABILITY FOR PERSONALLY IDENTIFIABLE
                                    INFORMATION THAT MAY BE PROVIDED TO IT BY SUCH THIRD-PARTY SERVICE PROVIDERS IN
                                    VIOLATION OF THE PRIVACY SETTINGS THAT YOU HAVE SET IN SUCH THIRD-PARTY ACCOUNTS.
                                    Olo makes no effort to review any SNS Content for any purpose, including but not
                                    limited to, for accuracy, legality or noninfringement, and Olo is not responsible
                                    for any SNS Content.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Registration Data</StyledBoldWords>. In registering for the
                                    Services, you agree to (1) provide true, accurate, current and complete information
                                    about yourself as prompted by the Services’ registration form (the "
                                    <StyledBoldWords>Registration Data</StyledBoldWords>
                                    "); and (2) maintain and promptly update the Registration Data to keep it true,
                                    accurate, current and complete. You represent that you are (1) at least eighteen
                                    (18) years old; (2) of legal age to form a binding contract; and (3) not a person
                                    barred from using the Properties under the laws of the United States, your place of
                                    residence or any other applicable jurisdiction. You are responsible for all
                                    activities that occur under your Account. You agree that you shall monitor your
                                    Account to restrict use by minors, and you will accept full responsibility for any
                                    unauthorized use of the Properties by minors. You may not share your Account or
                                    password with anyone, and you agree to (1) notify Olo immediately of any
                                    unauthorized use of your password or any other breach of security; and (2) exit from
                                    your Account at the end of each session. If you provide any information that is
                                    untrue, inaccurate, not current or incomplete, or Olo has reasonable grounds to
                                    suspect that such information is untrue, inaccurate, not current or incomplete, Olo
                                    has the right to suspend or terminate your Account and refuse any and all current or
                                    future use of the Properties (or any portion thereof). You agree not to create an
                                    Account using a false identity or information, or on behalf of someone other than
                                    yourself. You agree not to create an Account or use the Properties if you have been
                                    previously removed by Olo, or if you have been previously banned from any of the
                                    Properties.
                                </StyledLetteredListItem>
                            </StyledOrderedListCtn>
                        </StyledNumberedListItem>
                        <StyledNumberedListItem>
                            <StyledBoldWords>Termination</StyledBoldWords>
                            <StyledOrderedListCtn>
                                <StyledNoDiscListItem>
                                    If you materially breach the terms of this Terms of Use, Olo may suspend your
                                    ability to use the Properties or may terminate this Term of Use effective
                                    immediately, with or without notice to you. If you want to terminate this Terms of
                                    Use, you may do so by (a) notifying Olo at any time, and (b) closing your Account
                                    for all Services that you use. Your notice should be sent, in writing, to Olo’s
                                    address set forth below. Upon termination of these Terms of Use, your right to use
                                    the Services will automatically terminate immediately. Olo will not have any
                                    liability whatsoever to you for any suspension or termination. All provisions of
                                    these Terms of Use, which by their nature should survive, shall survive termination
                                    of the Terms of Use, including without limitation, ownership provisions, warranty
                                    disclaimers and limitation of liability.
                                </StyledNoDiscListItem>
                            </StyledOrderedListCtn>
                        </StyledNumberedListItem>
                        <StyledNumberedListItem value={4}>
                            <StyledBoldWords>Payment and Billing</StyledBoldWords>
                            <StyledOrderedListCtn>
                                <StyledNoDiscListItem>
                                    You agree to pay all fees or charges to your Account in accordance with the fees,
                                    charges and billing terms from Taco Mac or any third party acting on their behalf
                                    (e.g., a delivery service company) and in effect at the time a fee or charge is due
                                    and payable. You must provide Olo with a valid credit card (Visa, MasterCard,
                                    American Express, Discover, or any other issuers accepted by us), charge card, Taco
                                    Mac-specific gift card or Taco Mac-specific cash card ("
                                    <StyledBoldWords>Payment Provider</StyledBoldWords>
                                    "). You acknowledge and agree that for each order you place through the Service, the
                                    full cost of Products you order will be assessed against your Payment Provider. You
                                    acknowledge and agree that a command originating from your Account constitutes an
                                    authorization for the Service to charge the designated amount and you assume all
                                    liability for, and shall promptly pay, any and all such charges. Without limiting
                                    the foregoing, you acknowledge and agree that you will pay assessed charges for any
                                    and all orders placed from your Account. The Service will provide you with
                                    reasonable notice of any material modification in the fees charged for the services,
                                    which notice may be provided by posting the new fee schedule on the Website. You
                                    agree to immediately notify Olo of any change in your billing address or the credit
                                    card used for payment hereunder.
                                </StyledNoDiscListItem>
                            </StyledOrderedListCtn>
                        </StyledNumberedListItem>
                        <StyledNumberedListItem value={5}>
                            <StyledBoldWords>Refund Policy</StyledBoldWords>
                            <StyledOrderedListCtn>
                                <StyledNoDiscListItem>
                                    You acknowledge that Olo is not responsible for full or partial refunds on purchases
                                    of Products or related order fulfillment charges including any associated
                                    delivery-related charges. Addressing customer requests for refunds on purchases of
                                    Products will be the sole responsibility of the Taco Mac location at which the order
                                    was placed in accordance with such location’s refund policies. Contact the location
                                    directly with the phone number provided as soon as possible to request a full or
                                    partial refund.
                                </StyledNoDiscListItem>
                            </StyledOrderedListCtn>
                        </StyledNumberedListItem>
                        <StyledNumberedListItem value={6}>
                            <StyledBoldWords>Disclaimer of Warranties</StyledBoldWords>
                            <StyledOrderedListCtn>
                                <StyledNoDiscListItem>
                                    YOU EXPRESSLY AGREE THAT USE OF THE PROPERTIES IS AT YOUR OWN RISK. THE PROPERTIES
                                    ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. OLO EXPRESSLY DISCLAIMS ALL
                                    WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT
                                    LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE
                                    OR PURPOSE, AND NON-INFRINGEMENT. OLO MAKES NO WARRANTY THAT THE SERVICES WILL MEET
                                    YOUR REQUIREMENTS, OR THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
                                    ERROR FREE. YOU UNDERSTAND AND AGREE THAT ANY PRODUCTS YOU OBTAIN THROUGH USE OF THE
                                    SERVICES IS DONE AT YOUR OWN RISK, AND OLO MAKES NO WARRANTY REGARDING ANY DEALINGS
                                    WITH OR TRANSACTIONS ENTERED INTO WITH ANY OTHER PARTIES THROUGH THE SERVICES. NO
                                    ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM OLO OR THROUGH
                                    THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
                                </StyledNoDiscListItem>
                            </StyledOrderedListCtn>
                        </StyledNumberedListItem>
                        <StyledNumberedListItem value={7}>
                            <StyledBoldWords>Limitation of Liability</StyledBoldWords>
                            <StyledOrderedListCtn>
                                <StyledNoDiscListItem>
                                    YOU UNDERSTAND THAT TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL
                                    OLO, TACO MAC, ANY THIRD PARTY PROVIDERS OR ANY OF THEIR OFFICERS, DIRECTORS,
                                    EMPLOYEES, AFFILIATES, AGENTS OR LICENSORS BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                                    SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES (EVEN IF SUCH PARTIES WERE ADVISED OF
                                    THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF OR RELATED TO YOUR USE OF THE
                                    PROPERTIES OR PRODUCTS, REGARDLESS OF WHETHER SUCH DAMAGES ARE BASED ON CONTRACT,
                                    TORT OR OTHERWISE. THE PARTIES ACKNOWLEDGE THAT THE TERMS OF THIS PARAGRAPH REFLECT
                                    THE ALLOCATION OF RISK SET FORTH IN THIS TERMS OF USE AND THAT THE PARTIES WOULD NOT
                                    ENTER INTO THIS AGREEMENT WITHOUT THESE LIMITATIONS OF LIABILITY. UNDER NO
                                    CIRCUMSTANCES WILL THE AGGREGATE LIABILITY OF OLO TO YOU FOR ALL CLAIMS ARISING FROM
                                    OR RELATED TO YOUR USE OF THE PROPERTIES OR PRODUCTS, EXCEED TO ONE HUNDRED DOLLARS
                                    ($100). Some jurisdictions do not allow the exclusion of certain warranties or the
                                    limitation or exclusion of liability for incidental or consequential damages.
                                    Accordingly, some of the above limitations and disclaimers may not apply to you. To
                                    the extent that we may not, as a matter of applicable law, disclaim any implied
                                    warranty or limit its liabilities, the scope and duration of such warranty and the
                                    extent of our liability shall be the minimum permitted under such applicable law.
                                </StyledNoDiscListItem>
                            </StyledOrderedListCtn>
                        </StyledNumberedListItem>
                        <StyledNumberedListItem value={8}>
                            <StyledBoldWords>Indemnification</StyledBoldWords>
                            <StyledOrderedListCtn>
                                <StyledNoDiscListItem>
                                    You agree to indemnify, defend and hold harmless the Olo, Taco Mac, and each of
                                    their agents, employees, representatives, licensors, affiliates, officers and
                                    directors, from and against any and all claims, liabilities, damages, losses, costs,
                                    expenses, fees (including reasonable attorneys' fees and court costs) resulting or
                                    arising from any third-party claim in connection with (a) any information you (or
                                    anyone accessing the services using your password) submit or transmit through the
                                    Services, (b) your use of or access to the Properties, (c) your violation of this
                                    Terms of Use, (d) your violation of any rights of any third party, or (e) any
                                    viruses, trojan horses, worms, time bombs, cancelbots, spyware, or other similar
                                    harmful or deleterious programming routines input by you into the services.
                                </StyledNoDiscListItem>
                            </StyledOrderedListCtn>
                        </StyledNumberedListItem>
                        <StyledNumberedListItem value={9}>
                            <StyledBoldWords>Intellectual Property Ownership</StyledBoldWords>
                            <StyledOrderedListCtn>
                                <StyledNoDiscListItem>
                                    Except for the content and information you upload to the Services, you agree that
                                    Olo and its suppliers (including Taco Mac) own all rights, title and interest in the
                                    Properties, including all trademarks, brand names, and logos therein. All such
                                    material is protected by relevant intellectual property laws, including copyright,
                                    trademark, patent and/or trade secret laws. Such material may not be modified,
                                    reproduced, transmitted, sold, offered for sale, publicly displayed, or
                                    redistributed in any way without our prior written permission and the prior written
                                    permission of any other applicable rights licensor. All trademarks, brands, slogans
                                    and other indicia of origin ("
                                    <StyledBoldWords>Marks</StyledBoldWords>") that appear on or in connection with the
                                    Services are the property of Olo and/or its affiliates, licensors (including Taco
                                    Mac) and/or licensees. You are not authorized to use any such Marks.
                                </StyledNoDiscListItem>
                            </StyledOrderedListCtn>
                        </StyledNumberedListItem>
                        <StyledNumberedListItem value={10}>
                            <StyledBoldWords>App Stores</StyledBoldWords>
                            <StyledOrderedListCtn>
                                <StyledNoDiscListItem>
                                    App Stores You acknowledge and agree that the availability of the Application and
                                    the Services is dependent on the third party from whom you received the Application
                                    license, e.g., the Apple iTunes or Google Play app stores ("
                                    <StyledBoldWords>App Store</StyledBoldWords>
                                    "). You acknowledge that the Terms of Use are between you and Olo and not with the
                                    App Store. Olo, not the App Store, is solely responsible for the Properties,
                                    including the Application, the content thereof, maintenance, support services, and
                                    warranty therefor, and addressing any claims relating thereto (e.g., product
                                    liability, legal compliance or intellectual property infringement). In order to use
                                    the Application, you must have access to a wireless network, and you agree to pay
                                    all fees associated with such access. You also agree to pay all fees (if any)
                                    charged by the App Store in connection with the Properties, including the
                                    Application. You agree to comply with, and your license to use the Application is
                                    conditioned upon your compliance with, all applicable third-party terms of agreement
                                    (e.g., the App Store’s terms and policies) when using the Properties, including the
                                    Application. You acknowledge that the App Store (and its subsidiaries) are
                                    third-party beneficiaries of the Terms of Use and will have the right to enforce
                                    them.
                                </StyledNoDiscListItem>
                            </StyledOrderedListCtn>
                        </StyledNumberedListItem>
                        <StyledNumberedListItem value={11}>
                            <StyledBoldWords>
                                Arbitration Agreement; Class Waiver; Waiver of Trial by Jury{' '}
                            </StyledBoldWords>
                            <StyledOrderedListCtn>
                                <StyledNoDiscListItem>
                                    <i>
                                        Please read this Arbitration Agreement carefully. It is part of your contract
                                        with Olo and affects your rights. It contains procedures for MANDATORY BINDING
                                        ARBITRATION AND A CLASS ACTION WAIVER
                                    </i>
                                    .
                                </StyledNoDiscListItem>
                            </StyledOrderedListCtn>
                            <StyledOrderedListCtn>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Applicability of Arbitration Agreement</StyledBoldWords>. All
                                    claims and disputes (excluding claims for injunctive or other equitable relief as
                                    set forth below) in connection with the Terms of Use or the use of any product or
                                    service provided by Olo that cannot be resolved informally or in small claims court
                                    shall be resolved by binding arbitration on an individual basis under the terms of
                                    this Arbitration Agreement. This Arbitration Agreement applies to you and Olo, and
                                    to any subsidiaries, affiliates, agents, employees, predecessors in interest,
                                    successors, and assigns, as well as all authorized or unauthorized users or
                                    beneficiaries of services or goods provided under the Terms of Use.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>
                                        Notice Requirement and Informal Dispute Resolution
                                    </StyledBoldWords>
                                    . Before either party may seek arbitration, the party must first send to the other
                                    party a written Notice of Dispute ("Notice") describing the nature and basis of the
                                    claim or dispute, and the requested relief. A Notice to Olo should be sent to: 26
                                    Broadway, 24th Floor, New York, NY 10004. After the Notice is received, you and Olo
                                    may attempt to resolve the claim or dispute informally. If you and Olo do not
                                    resolve the claim or dispute within 30 days after the Notice is received, either
                                    party may begin an arbitration proceeding. The amount of any settlement offer made
                                    by any party may not be disclosed to the arbitrator until after the arbitrator has
                                    determined the amount of the award, if any, to which either party is entitled.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Arbitration Rules</StyledBoldWords>. Arbitration shall be initiated
                                    through the American Arbitration Association ("AAA"), an established alternative
                                    dispute resolution provider ("ADR Provider") that offers arbitration as set forth in
                                    this section. If AAA is not available to arbitrate, the parties shall agree to
                                    select an alternative ADR Provider. The rules of the ADR Provider shall govern all
                                    aspects of this arbitration, including but not limited to the method of initiating
                                    and/or demanding arbitration, except to the extent such rules are in conflict with
                                    the Terms of Use. The AAA Consumer Arbitration Rules governing the arbitration are
                                    available online at www.adr.org or by calling the AAA at 1-800-778-7879. The
                                    arbitration shall be conducted by a single, neutral arbitrator. Any claims or
                                    disputes where the total amount of the award sought is less than Ten Thousand U.S.
                                    Dollars (US $10,000.00) may be resolved through binding non-appearance-based
                                    arbitration, at the option of the party seeking relief. For claims or disputes where
                                    the total amount of the award sought is Ten Thousand U.S. Dollars (US $10,000.00) or
                                    more, the right to a hearing will be determined by the Arbitration Rules. Any
                                    hearing will be held in a location within 100 miles of your residence, unless you
                                    reside outside of the United States, and unless the parties agree otherwise. Any
                                    judgment on the award rendered by the arbitrator may be entered in any court of
                                    competent jurisdiction. Each party shall bear its own costs (including attorney’s
                                    fees) and disbursements arising out of the arbitration, and shall pay an equal share
                                    of the fees and costs of the ADR Provider.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>
                                        Additional Rules for Non-appearance Based Arbitration
                                    </StyledBoldWords>
                                    . If non-appearance arbitration is elected, the arbitration shall be conducted by
                                    telephone, online and/or based solely on written submissions; the specific manner
                                    shall be chosen by the party initiating the arbitration. The arbitration shall not
                                    involve any personal appearance by the parties or witnesses unless otherwise
                                    mutually agreed by the parties.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Time Limits</StyledBoldWords>. If you or Olo pursue arbitration,
                                    the arbitration action must be initiated and/or demanded within the statute of
                                    limitations (i.e., the legal deadline for filing a claim) and within any deadline
                                    imposed under the AAA Rules for the pertinent claim.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Authority of Arbitrator</StyledBoldWords>. If arbitration is
                                    initiated, the arbitrator will decide the rights and liabilities, if any, of you and
                                    Olo, and the dispute will not be consolidated with any other matters or joined with
                                    any other cases or parties. The arbitrator shall have the authority to grant motions
                                    dispositive of all or part of any claim. The arbitrator shall have the authority to
                                    award monetary damages and to grant any non-monetary remedy or relief available to
                                    an individual under applicable law, the AAA Rules, and the Terms of Use. The
                                    arbitrator shall issue a written award and statement of decision describing the
                                    essential findings and conclusions on which the award is based, including the
                                    calculation of any damages awarded. The arbitrator has the same authority to award
                                    relief on an individual basis that a judge in a court of law would have. The award
                                    of the arbitrator is final and binding upon you and Olo.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Waiver of Jury Trial</StyledBoldWords>. THE PARTIES HEREBY WAIVE
                                    THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT
                                    OF A JUDGE OR A JURY, instead electing that all claims and disputes shall be
                                    resolved by arbitration under this Arbitration Agreement. Arbitration procedures are
                                    typically more limited, more efficient and less costly than rules applicable in
                                    court and are subject to very limited review by a court. In the event any litigation
                                    should arise between you and Olo in any state or federal court in a suit to vacate
                                    or enforce an arbitration award or otherwise, YOU AND OLO WAIVE ALL RIGHTS TO A JURY
                                    TRIAL, instead electing that the dispute be resolved by a judge.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Waiver of Class or Consolidated Actions</StyledBoldWords>. ALL
                                    CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE
                                    ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS
                                    OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR
                                    CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Confidentiality</StyledBoldWords>. All aspects of the arbitration
                                    proceeding, including but not limited to the award of the arbitrator and compliance
                                    therewith, shall be strictly confidential. The parties agree to maintain
                                    confidentiality unless otherwise required by law. This Paragraph shall not prevent a
                                    party from submitting to a court of law any information necessary to enforce this
                                    Agreement, to enforce an arbitration award, or to seek injunctive or equitable
                                    relief.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Severability</StyledBoldWords>. If any part or parts of this
                                    Arbitration Agreement are found under the law to be invalid or unenforceable by a
                                    court of competent jurisdiction, then such specific part or parts shall be of no
                                    force and effect and shall be severed and the remainder of the Agreement shall
                                    continue in full force and effect.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Right to Waive</StyledBoldWords>. Any or all of the rights and
                                    limitations set forth in this Agreement may be waived by the party against whom the
                                    claim is asserted. Such waiver shall not waive or effect any other portion of this
                                    Agreement.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Survival of Agreement</StyledBoldWords>. This Arbitration Agreement
                                    will survive the termination of your relationship with Olo.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Small Claims Court</StyledBoldWords>. Notwithstanding the
                                    foregoing, either you or Olo may bring an individual action in small claims court.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Emergency Equitable Relief</StyledBoldWords>. Notwithstanding the
                                    foregoing, either party may seek emergency equitable relief before a state or
                                    federal court in order to maintain the status quo pending arbitration. A request for
                                    interim measures shall not be deemed a waiver of any other rights or obligations
                                    under this Arbitration Agreement.
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Claims Not Subject To Arbitration</StyledBoldWords>.
                                    Notwithstanding the foregoing, claims of defamation, violation of the Computer Fraud
                                    and Abuse Act, and infringement or misappropriation of the other party’s patent,
                                    copyright, trademark, or trade secret shall not be subject to this arbitration
                                    agreement
                                </StyledLetteredListItem>
                                <StyledLetteredListItem>
                                    <StyledBoldWords>Courts</StyledBoldWords>. In any circumstances where the foregoing
                                    Agreement permits the parties to litigate in court, the parties hereby agree to
                                    submit to the personal jurisdiction of the courts located within New York County,
                                    New York, for such purpose.
                                </StyledLetteredListItem>
                            </StyledOrderedListCtn>
                        </StyledNumberedListItem>
                        <StyledNumberedListItem value={12}>
                            <StyledBoldWords>Choice of Law</StyledBoldWords>
                        </StyledNumberedListItem>
                        <StyledNoDiscListItem>
                            These Terms of Use are governed by U.S. federal law and/or laws of the state of New York,
                            consistent with the Federal Arbitration Act, without resort to conflict of law provisions.
                        </StyledNoDiscListItem>
                        <StyledNumberedListItem value={13}>
                            <StyledBoldWords>Notice</StyledBoldWords>
                        </StyledNumberedListItem>
                        <StyledNoDiscListItem>
                            Where Olo requires that you provide an e-mail address, you are responsible for providing Olo
                            with your most current e-mail address. In the event that the last e-mail address you
                            provided to Olo is not valid, or for any reason is not capable of delivering to you any
                            notices required/ permitted by the Terms of Use, Olo’s dispatch of the e-mail containing
                            such notice will nonetheless constitute effective notice. You may give notice to Olo only at
                            the following address: Olo, Inc. d/b/a Olo, 26 Broadway, 24th Floor, New York, NY 10004.
                            Such notice shall be deemed given when received by Olo by letter delivered by nationally
                            recognized overnight delivery service or first class postage prepaid mail at the above
                            address.
                        </StyledNoDiscListItem>
                        <StyledNumberedListItem value={14}>
                            <StyledBoldWords>Waiver</StyledBoldWords>
                        </StyledNumberedListItem>
                        <StyledNoDiscListItem>
                            Any waiver or failure to enforce any provision of the Terms of Use on one occasion will not
                            be deemed a waiver of any other provision or of such provision on any other occasion.
                        </StyledNoDiscListItem>
                        <StyledNumberedListItem value={15}>
                            <StyledBoldWords>Severability</StyledBoldWords>
                        </StyledNumberedListItem>
                        <StyledNoDiscListItem>
                            If any provision of the Terms of Use is, for any reason, held to be invalid or
                            unenforceable, the other provisions of the Terms of Use will remain enforceable, and the
                            invalid or unenforceable provision will be deemed modified so that it is valid and
                            enforceable to the maximum extent permitted by law.
                        </StyledNoDiscListItem>
                        <StyledNumberedListItem value={16}>
                            <StyledBoldWords>Export Control</StyledBoldWords>
                        </StyledNumberedListItem>
                        <StyledNoDiscListItem>
                            You may not use, export, import, or transfer the Properties except as authorized by U.S.
                            law, the laws of the jurisdiction in which you obtained the Properties, and any other
                            applicable laws. In particular, but without limitation, Olo Properties may not be exported
                            or re-exported (a) into any United States embargoed countries, or (b) to anyone on the U.S.
                            Treasury Department’s list of Specially Designated Nationals or the U.S. Department of
                            Commerce’s Denied Person’s List or Entity List. By using the Properties, you represent and
                            warrant that (i) you are not located in a country that is subject to a U.S. Government
                            embargo, or that has been designated by the U.S. Government as a "terrorist supporting"
                            country and (ii) you are not listed on any U.S. Government list of prohibited or restricted
                            parties. You also will not use the Olo Properties for any purpose prohibited by U.S. law,
                            including the development, design, manufacture or production of missiles, nuclear, chemical
                            or biological weapons. You acknowledge and agree that products, services or technology
                            provided by Company are subject to the export control laws and regulations of the United
                            States. You shall comply with these laws and regulations and shall not, without prior U.S.
                            government authorization, export, re-export, or transfer Olo products, services or
                            technology, either directly or indirectly, to any country in violation of such laws and
                            regulations.
                        </StyledNoDiscListItem>
                        <StyledNumberedListItem value={17}>
                            <StyledBoldWords>Electronic Communications</StyledBoldWords>
                        </StyledNumberedListItem>
                        <StyledNoDiscListItem>
                            The communications between you and Olo use electronic means, whether you visit the
                            Properties or send Olo e-mails, or whether Olo posts notices on the Olo Properties or
                            communicates with you via e-mail. For contractual purposes, you (1) consent to receive
                            communications from Olo in an electronic form; and (2) agree that all terms and conditions,
                            agreements, notices, disclosures, and other communications that Olo provides to you
                            electronically satisfy any legal requirement that such communications would satisfy if it
                            were to be in writing. The foregoing does not affect your statutory rights.
                        </StyledNoDiscListItem>
                        <StyledNumberedListItem value={18}>
                            <StyledBoldWords>Release</StyledBoldWords>
                        </StyledNumberedListItem>
                        <StyledNoDiscListItem>
                            You hereby release Olo, its affiliates and each of their officers, directors, employees, and
                            agents and their successors from claims, demands, any and all losses, damages, rights, and
                            actions of any kind, including personal injuries, death, and property damage, that is either
                            directly or indirectly related to or arises from your use of the Properties, including but
                            not limited to, any interactions with or conduct of other Users or third-party websites of
                            any kind arising in connection with or as a result of the Terms of Use or your use of the
                            Properties. If you are a California resident, you hereby waive California Civil Code Section
                            1542, which states, "A general release does not extend to claims which the creditor does not
                            know or suspect to exist in his favor at the time of executing the release, which, if known
                            by him must have materially affected his settlement with the debtor.
                        </StyledNoDiscListItem>
                        <StyledNumberedListItem value={19}>
                            <StyledBoldWords>Assignment</StyledBoldWords>
                        </StyledNumberedListItem>
                        <StyledNoDiscListItem>
                            The Terms of Use, and your rights and obligations hereunder, may not be assigned,
                            subcontracted, delegated or otherwise transferred by you without Olo’s prior written
                            consent, and any attempted assignment, subcontract, delegation, or transfer in violation of
                            the foregoing will be null and void.
                        </StyledNoDiscListItem>
                        <StyledNumberedListItem value={20}>
                            <StyledBoldWords>Force Majeure</StyledBoldWords>
                        </StyledNumberedListItem>
                        <StyledNoDiscListItem>
                            Olo shall not be liable for any delay or failure to perform resulting from causes outside
                            its reasonable control, including, but not limited to, acts of God, war, terrorism, riots,
                            embargos, acts of civil or military authorities, fire, floods, accidents, strikes or
                            shortages of transportation facilities, fuel, energy, labor or materials.
                        </StyledNoDiscListItem>
                        <StyledNumberedListItem value={21}>
                            <StyledBoldWords>Questions, Complaints, Claims</StyledBoldWords>
                        </StyledNumberedListItem>
                        <StyledNoDiscListItem>
                            If you have any questions, complaints or claims with respect to the Properties, please
                            contact us at: Olo, Inc. d/b/a Olo, 26 Broadway, 24th Floor, New York, New York 10004. We
                            will do our best to address your concerns. If you feel that your concerns have been
                            addressed incompletely, we invite you to let us know for further investigation.
                        </StyledNoDiscListItem>
                        <StyledNumberedListItem value={22}>
                            <StyledBoldWords>Consumer Complaints</StyledBoldWords>
                        </StyledNumberedListItem>
                        <StyledNoDiscListItem>
                            In accordance with California Civil Code §1789.3, you may report complaints to the Complaint
                            Assistance Unit of the Division of Consumer Services of the California Department of
                            Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by
                            telephone at (800) 952-5210.
                        </StyledNoDiscListItem>
                        <StyledNumberedListItem value={23}>
                            <StyledBoldWords>Entire Agreement</StyledBoldWords>
                        </StyledNumberedListItem>
                        <StyledNoDiscListItem>
                            The Terms of Use are the final, complete and exclusive agreement of the parties with respect
                            to the subject matter hereof and supersedes and merges all prior discussions between the
                            parties with respect to such subject matter.
                        </StyledNoDiscListItem>
                    </StyledOrderedListCtn>
                </StyledMessageCtn>
            </GenericContainerNarrow>
        </MainContainer>
    );
};
