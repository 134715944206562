import React, { useEffect, useState } from 'react';
import MainContainer from '../components/MainContainer';
import { useDispatch, useSelector } from 'react-redux';
import {
    getUserSavedDeliveryAddress,
    retrieveUserRecentRestaurant,
    setUserAddressQuery,
    getRestaurants,
} from '@store/actions';

import { RestaurantContainer, SavedAddresses, Map } from '@components';
import { useWindowSize } from '@hooks';

import LandingPageContext from './landingPageContext';

const LandingPage = () => {
    const dispatch = useDispatch();
    const { userAddressQuery } = useSelector((state) => state.rootData);
    const { isPickup } = useSelector((state) => state.restaurantData);
    const { loggedIn } = useSelector((state) => state.authData);
    const [inputValue, setInputValue] = useState(userAddressQuery);
    const [searchValue, setSearchValue] = useState(null);

    const setInputValueDispatch = (data) => {
        dispatch(setUserAddressQuery(data));
        setInputValue(data);
    };

    const viewPort = useWindowSize().width;
    const breakpoint = 1024;
    const isMobile = viewPort < breakpoint;

    useEffect(() => {
        if (loggedIn) {
            dispatch(getUserSavedDeliveryAddress());
            dispatch(retrieveUserRecentRestaurant());
        }
        dispatch(getRestaurants());
    }, []);

    return (
        <LandingPageContext.Provider
            value={{
                searchValue: [searchValue, setSearchValue],
            }}
        >
            <MainContainer>
                <div className="lg:flex">
                    <RestaurantContainer
                        inputValue={inputValue}
                        setInputValue={setInputValueDispatch}
                        isPickup={isPickup}
                        isMobile={isMobile}
                    />

                    {!isPickup && !inputValue && isMobile && <SavedAddresses setInputValue={setInputValueDispatch} />}
                </div>
            </MainContainer>
        </LandingPageContext.Provider>
    );
};

export default LandingPage;
