import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Text } from '@components';
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from '@assets';

const ToggleCheckboxes = ({ terms, setTerms, optin, setOptin, error }) => {
    let history = useHistory();

    const toggleTerms = () => {
        setTerms(!terms);
    };

    const toggleEmails = () => {
        setOptin(!optin);
    };

    return (
        <>
            <div className="w-full flex flex-col">
                <div className="flex flex-row items-center py-0.5 cursor-pointer" onClick={toggleTerms}>
                    {terms ? (
                        <span>
                            <CheckboxCheckedIcon></CheckboxCheckedIcon>
                        </span>
                    ) : (
                        <span>
                            <CheckboxUncheckedIcon></CheckboxUncheckedIcon>
                        </span>
                    )}
                    <Text className="font-futura flex flex-row flex-wrap leading-4 text-xs pl-2">
                        I have read and accepted
                        <Button
                            type="quinternaryLowerCase"
                            size="xsmall"
                            className="px-1"
                            onClick={() => history.push('/user-agreement')}
                        >
                            the terms and conditions
                        </Button>
                    </Text>
                </div>
                {error && (
                    <div className="mt-2 leading-4 text-xs text-status-error">
                        Please check you have read the terms and conditions.
                    </div>
                )}
                <div className="flex flex-row items-center py-2 cursor-pointer" onClick={toggleEmails}>
                    {optin ? (
                        <span>
                            <CheckboxCheckedIcon></CheckboxCheckedIcon>
                        </span>
                    ) : (
                        <span>
                            <CheckboxUncheckedIcon></CheckboxUncheckedIcon>
                        </span>
                    )}
                    <Text className="font-futura leading-4 text-xs px-2 text-black text-opacity-75">
                        Send me emails about future deals and features
                    </Text>
                </div>
            </div>
        </>
    );
};

ToggleCheckboxes.propTypes = {
    terms: PropTypes.bool,
    setTerms: PropTypes.func,
    optin: PropTypes.bool,
    setOptin: PropTypes.func,
    error: PropTypes.bool,
};

export default ToggleCheckboxes;
