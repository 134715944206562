import Button from 'components/GlobalComponents/Buttons';
import React from 'react';
import { AddItem } from './AddItem';
import { StyledMainContainer, StyledHeadingRow, StyledHeading, StyledOptional } from './style';
import { useWindowSize } from '@hooks';
import { Carousel } from './Carousel';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

export const CompleteYourMeal = ({ items, setUpsellItem, handleToggle }) => {
    const viewPort = useWindowSize().width;
    const breakpoint = 1024;
    const isMobile = viewPort < breakpoint;
    const flatMenu = useSelector((state) => state.menuData.menu.flatMenu);
    items.sort(() => Math.random() - 0.5);
    items = items.map((item) => {
        const additionalData = find(flatMenu, (o) => {
            return item.id == o.id;
        });
        const mergedItem = { ...additionalData, ...item };
        return mergedItem;
    });

    return (
        <StyledMainContainer>
            <StyledHeadingRow>
                <StyledHeading>Complete Your Meal</StyledHeading>
                <StyledOptional>Optional</StyledOptional>
            </StyledHeadingRow>

            <div className="w-full m-auto flex flex-col my-5 gap-1 lg:my-0 lg:gap-0">
                {!isMobile ? (
                    <Carousel items={items} setUpsellItem={setUpsellItem}></Carousel>
                ) : (
                    // </div>
                    <div className="w-full flex flex-col">
                        {items?.map((item, i) => {
                            return (
                                <div key={i} onClick={() => setUpsellItem(item)}>
                                    <AddItem
                                        key={item.id}
                                        itemPhoto={item.imagefilename}
                                        itemName={item.name}
                                        price={item.cost}
                                        calories={item.basecalories !== null ? `/ ${item.basecalories} cal` : ''}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <Button
                type="invertedPrimary"
                size="small"
                onClick={handleToggle}
                className="w-full border border-primaryColor text-sm rounded-sm p-0"
            >
                + Add something else{' '}
            </Button>
        </StyledMainContainer>
    );
};
