import React from 'react';

import { GenericContainerNarrow, TextHero, Text } from '@components';
import MainContainer from 'containers/components/MainContainer';
import { ForgotPasswordForm } from '../components/ForgotPasswordForm';

const ForgotPassword = (props) => {
    return (
        <>
            <TextHero heading={'Forgot Password'} type={'tertiaryWhite'} />
            <GenericContainerNarrow className={'px-4 py-7 lg:py-10'}>
                <ForgotPasswordForm />
            </GenericContainerNarrow>
        </>
    );
};

export default ForgotPassword;
