import React from 'react';
import { Text, TextInput, Label, Button } from '@components';
import { ResetPasswordCtn, ResetPasswordHeading } from '../pages/styles/index';

export const ResetPasswordForm = (props) => {
    return (
        <>
            <ResetPasswordCtn>
                <ResetPasswordHeading>
                    <h1>Reset your password</h1>
                </ResetPasswordHeading>
                <Text type="xs" className="pt-2">
                    Please enter your password to continue.
                </Text>
                <Text type="xs">
                    Please note that any credit card details stored on your account will be cleared for your security.
                </Text>
                <form className="w-full flex flex-col content-center justify-center pt-2">
                    <Label label="New Password" type="primary">
                        <TextInput className="mt-1 h-4" type="password" name="password" required autoFocus={true} />
                    </Label>
                    <Label label="Confirm Password" type="primary">
                        <TextInput className="mt-1 h-4" type="password" name="password" required autoFocus={true} />
                    </Label>
                    <Button className="mt-6 rounded-sm" type="primary">
                        Continue
                    </Button>
                </form>
            </ResetPasswordCtn>
        </>
    );
};
