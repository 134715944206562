import styled from 'styled-components';
import tw from 'tailwind.macro';
import { CloseWhiteIcon } from '@assets';

export const StyledBackground = styled.div`
    background: rgba(0, 0, 0, 0.75);
    ${tw`w-full h-full flex z-40 fixed top-0 left-0 justify-center items-center`}
`;

export const StyledModalWrapper = styled.div`
    ${tw`w-screen h-screen md:h-full m-auto top-0 lg:h-full lg:max-w-sm lg:items-center flex flex-col overflow-y-scroll`}
`;

export const StyledModalSubCtn = styled.div`
    ${tw`w-full bg-black p-5 flex flex-col justify-between lg:max-w-sm overflow-y-scroll`}
`;

export const StyleCloseModalCtn = styled.div`
    ${tw`w-full flex flex-row-reverse top-5 right-5`}
`;

export const StyledModalHeadline = styled.div`
    width: 219px;
    ${tw`flex flex-wrap z-10 content-start text-white text-4xl w-14 leading-9 font-veneer uppercase`}
`;

export const StyledCloseWhiteIcon = styled(CloseWhiteIcon)`
    height: 27px;
    width: 27px;
    ${tw`z-10 cursor-pointer`}
`;

export const StyledModalContent = styled.div`
    ${tw`w-full bg-white px-5 pt-5 overflow-y-scroll`}
`;
