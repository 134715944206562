import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'json',
    headers: {
        // 'Content-Type': 'multipart/form-data'
        'Api-Current-URL': window.location.href,
    }
});

api.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    error.message = error.response?.data?.data?.message ?? error.message;

    return Promise.reject(error);
});

export default api;
