import styled from 'styled-components';
import tw from 'tailwind.macro';

export const ForgotPasswordLink = styled.a`
    ${tw`
      self-center
      cursor-pointer
      underline
      mt-4
      lg:mt-5
      font-futuraConMD
      font-meduim
      text-primaryColor
      text-sm
      lg:text-base
      z-10
      `}
`;

export const ResetPasswordHeading = styled.div`
    ${tw`
      font-futuraConMD
      text-2xl
      m-auto
      lg:text-center
      max-w-49 
      leading-10
      lg:border-b-2
      lg:border-lightGray
      `}
`;

export const ResetPasswordCtn = styled.div`
    ${tw`
      w-full
      lg:w-3/5
      lg:m-auto
      lg:px-10
      pb-5
      pt-3
      lg:border-2
      lg:border-solid
      lg:border-primaryColor
      `}
`;
