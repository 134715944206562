import React from 'react';
import { AvatarIcon } from '@assets';
import { Text } from '@components';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

const StyledIcon = styled(AvatarIcon)`
    height: 27px;
    width: 27px;
`;

const Avatar = ({ isMobile }) => {
    const history = useHistory();
    let { path } = useRouteMatch();
    const authData = useSelector((state) => state.authData);
    return (
        <div className="mr-4 cursor flex flex-wrap items-center">
            <StyledIcon
                className="cursor"
                onClick={() => {
                    history.push(`/account`);
                }}
            />
            <>
                {!isMobile && (
                    <>
                        {!authData.loggedIn ? (
                            <>
                                <Text className="text-primaryColor text-lg font-veneer font-normal leading-8 lg:ml-2">
                                    <Link to="/auth/login">Login / </Link>
                                </Text>
                                <Text className="text-primaryColor text-lg font-veneer font-normal leading-8 lg:ml-1">
                                    <Link to="/auth/create-account">Sign up</Link>
                                </Text>
                            </>
                        ) : (
                            <Text className="flex text-primaryColor text-sm lg:text-lg font-veneer font-normal leading-4 lg:leading-8 m-auto px-0.5 lg:px-0 lg:ml-2 tracking-tight flex-wrap">
                                <Link to="/account">Hello, {authData.firstname}</Link>
                            </Text>
                        )}
                    </>
                )}
            </>
        </div>
    );
};

export default Avatar;
