import {
    CREATE_USER_ACCOUNT,
    LOGIN_USER,
    UPDATE_COMMUNICATION_PREFERENCES,
    USER_FORGOT_PASSWORD,
    USER_FORGOT_PASSWORD_RESET,
    GET_LOG_OUT,
    SET_USER_DATA,
    SET_USER_SAVED_ADDRESSES,
} from '../types';

export const createUserAccount = (data) => {
    return { type: CREATE_USER_ACCOUNT, ...data };
};

export const loginUser = (data) => {
    return { type: LOGIN_USER, ...data };
};

export const userForgotPassword = (data) => {
    return { type: USER_FORGOT_PASSWORD, ...data };
};

export const userForgotPasswordReset = (data) => {
    return { type: USER_FORGOT_PASSWORD_RESET };
};

export const logOut = (data) => {
    return { type: GET_LOG_OUT, ...data };
};
export const setUserData = (data) => {
    return { type: SET_USER_DATA, ...data };
};
export const updateCommunicationPreferences = (authtoken, commPrefs) => {
    const payload = { authtoken, commPrefs };
    return { type: UPDATE_COMMUNICATION_PREFERENCES, payload };
};
export const setSavedDeliveryAdresses = (data) => {
    const payload = { data };
    return { type: SET_USER_SAVED_ADDRESSES, payload };
};
export const updateSavedDeliveryAdresses = (data) => {
    const payload = { data };
    // return { type: SET_USER_SAVED_ADDRESSES, payload };
};

