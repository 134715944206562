import * as types from '../types';

let initialState = {
    showMap: true,
};

const showMap = (state) => {
    return Object.assign({}, state, { showMap: !state.showMap });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.TOGGLE_MAP:
            return showMap(state, action);
        default:
            return state;
    }
    return state;
};

export default reducer;
