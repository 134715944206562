import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import styled from 'styled-components';

export const Generic = styled.div`
    ${(props) => tw`
        flex
        w-full
        py-6
        lg:py-12
    `}
`;

const BodyContainer = (props) => {
    return <Generic className={props.className}>{props.children}</Generic>;
};

export default BodyContainer;
