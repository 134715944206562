import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Button } from '@components';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';

const StyledHeroCtn = styled.div`
    ${tw`w-full bg-black h-49 lg:h-56 -mt-1 flex md:justify-center md:items-center md:py-4`}
`;

const StyledSubCtn = styled.div`
    ${tw`w-full py-5 px-5 md:px-8 text-white text-5xl font-veneer uppercase flex flex-col md:flex-row justify-end content-center md:justify-between md:max-w-2xl md:items-center md:m-auto`}
`;

const StyledIconCtn = styled.span`
    ${tw`flex justify-end`}
    svg {
        ${tw`h-6 w-6`}
    }
`;

const StyledSubHeadingCtn = styled.div`
    ${tw`font-futuraCondensed flex items-center text-sm`}
`;

const StyledHeroHeading = styled.div`
    ${tw`max-w-56 leading-9`}
`;

const TextHero = ({ className, heading, subHeading, cta, to, type }) => {
    return (
        <StyledHeroCtn>
            <StyledSubCtn className={className}>
                <div className="mb-5 md:m-0">
                    <StyledHeroHeading>{heading}</StyledHeroHeading>
                </div>
                <div>
                    <StyledSubHeadingCtn>
                        {subHeading && <div className="mr-1 md:mr-4">{subHeading}</div>}
                        {cta && (
                            <Link
                                to={to}
                                className="font-futuraConMD text-white uppercase text-sm border-white rounded-sm tracking-wider px-2 border-solid border"
                            >
                                {cta}
                            </Link>
                        )}
                    </StyledSubHeadingCtn>
                </div>
            </StyledSubCtn>
        </StyledHeroCtn>
    );
};

TextHero.propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    cta: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
};

export default TextHero;
