import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const StyledTextArea = styled.textarea`
    ${tw`text-black text-base font-futuraLT w-full`}
    border: 1px solid ${(p) => p.theme.colors.lightGray};
    border-radius: 5px;
    text-indent: 10px;
    padding-top: 15px;
    height: 115px;
`;

const TextArea = ({ children, placeholder, name, className }) => {
    return (
        <StyledTextArea placeholder={placeholder} name={name} className={className}>
            {children}
        </StyledTextArea>
    );
};

export default TextArea;
