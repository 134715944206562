import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Label, Button } from '@components';
import { useFormik, ErrorMessage, getIn } from 'formik';
import getStyles from '@global/helpers/getStyles';
import * as Yup from 'yup';

const Favorite = ({ fetchSetFavorite, close }) => {
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            description: '',
        },
        validationSchema: Yup.object({
            description: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            fetchSetFavorite(values, (res) => {
                close();
            });
        },
    });
    return (
        <>
            <form
                onSubmit={formik.handleSubmit}
                className="w-full flex flex-col content-center justify-center"
                noValidate
            >
                <Label label="Name" type="primary" className="mt-0 mb-0">
                    <TextInput
                        className="mt-1"
                        type="text"
                        name="description"
                        withoutValidation
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                        style={getStyles(formik, 'description')}
                        maxLength={36}
                    />
                    {formik.errors.description && formik.touched.description ? (
                        <div className="mt-1 text-status-error">{formik.errors.description}</div>
                    ) : null}
                </Label>

                <Button
                    className="w-full flex justify-center content-center items-center m-auto my-12 lg:mb-10 rounded-sm"
                    type="primary"
                    disabled={!(formik.isValid && formik.dirty)}
                    loading={loading}
                >
                    Save Favorite
                </Button>
            </form>
        </>
    );
};

export default Favorite;
