import React, { useEffect, useState, useReducer, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import find from 'lodash/find';
import xor from 'lodash/xor';
import remove from 'lodash/remove';
import filter from 'lodash/filter';
import sumBy from 'lodash/sumBy';
import pickBy from 'lodash/pickBy';
import forOwn from 'lodash/forOwn';
import cloneDeep from 'lodash/cloneDeep';
import debounce from '@utils/debounce';
import { useHistory, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import classnames from 'classnames';

import { useWindowSize } from '@hooks';
import API from '@utils/API';
import { AddToCartBar, PhotoCard, GenericContainer, Button, SubHero } from '@components';
import MenuItemCard from '../components/menuItemCard';
import { Asterisk, RadioCheckedIcon, RadioCheckedGreenIcon } from '@assets';
import HeroContainer from '../components/heroContainer';
import { addItem } from '@store/actions';

import ItemContext from './context';

const Item = (props) => {
    let { itemId, categoryId, location } = useParams();

    const menu = useSelector((state) => state.menuData.menu);
    let category = find(menu.categories, (c) => c.id === Number(categoryId));
    let item = find(category.products, (p) => p.id === Number(itemId));

    const dispatch = useDispatch();
    const [itemData, setItemData] = useState(null);
    const [invalid, setInvalid] = useState([]);
    const [dirty, setDirty] = useState(false);
    const [cost, setCost] = useState([]);
    const [modified, setModified] = useState(false);
    const [modifiedDebounced] = useDebounce(modified, 500);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [navShrink, setNavShrink] = useState(false);

    const cartLoading = useSelector((state) => state.cartData.loading);

    const viewPort = useWindowSize().width;
    const breakpoint = 1024;
    const isMobile = viewPort < breakpoint;

    const basePriceObject = { id: null, amount: item.cost };

    const getChildModifierIds = (group) => {
        let modifiers = [];
        function mapGroup(subGroup, push = true) {
            if (push) {
                modifiers.push(subGroup.id);
            }
            if (subGroup?.options) {
                subGroup.options.forEach((item, i) => {
                    if (item?.modifiers) {
                        item.modifiers.forEach((modifier, i) => {
                            mapGroup(modifier);
                        });
                    }
                });
            }
        }
        mapGroup(group, false);

        return modifiers;
    };
    const setCostAction = (amount, id, type) => {
        if (type === 'add') {
            setCost((prev) => {
                return [...prev, basePriceObject, { id, amount }];
            });
        } else {
            setCost((prev) => {
                const filteredArray = filter(prev, function (n) {
                    return n.id !== id;
                });
                return filteredArray;
            });
        }
    };
    const bulkRemoveCostAction = (ids) => {
        setCost((prev) => {
            const filteredArray = filter(prev, function (o, i) {
                return ids.includes(Number(o.id));
            });
            return [...filteredArray, { id: null, amount: item.cost }];
        });
    };
    const searchMandatoryModifieres = (result) => {
        // TODO: This may need a deeper check, all the way down the trees. need to test
        const mandatory = result.optiongroups.filter((modifier) => {
            return modifier.mandatory === true;
        });
        if (!mandatory.length) {
            setModified(true);
        }
    };
    const optionsReducer = (state, action) => {
        switch (action.type) {
            case 'makeSelection':
                setModified(() => true);
                let { maxselects, minselects } = action.group;
                let { mandatory } = action.group;
                let groupMultipleSelections = !mandatory;
                let groups = cloneDeep(action.group);
                if (groupMultipleSelections) {
                    groups.options = remove(groups.options, function (n) {
                        return n.id === action.id;
                    });
                }
                const removableIds = getChildModifierIds(groups);
                let filtered = pickBy(state, function (o, i) {
                    return !removableIds.includes(Number(i));
                });
                const setPriceFiltered = (filtered) => {
                    let filteredValues = [];
                    forOwn(filtered, function (value, key) {
                        filteredValues.push(value);
                    });
                    bulkRemoveCostAction(filteredValues.flat());
                };

                if (action.selected) {
                    setCostAction(action.group.options[action.groupIndex].cost, action.id, 'subtract');
                    filtered[action.groupId] = xor(state[action.groupId], [action.id]);
                    setPriceFiltered(filtered);
                    return {
                        ...filtered,
                    };
                } else {
                    setCostAction(action.group.options[action.groupIndex].cost, action.id, 'add');
                    if (!groupMultipleSelections) {
                        const adjFiltered = {
                            ...filtered,
                            [action.groupId]: [action.id],
                        };
                        setPriceFiltered(adjFiltered);
                        return adjFiltered;
                    } else {
                        const adjFiltered = {
                            ...state,
                            [action.groupId]: xor(state[action.groupId], [action.id]),
                        };
                        setPriceFiltered(adjFiltered);
                        return adjFiltered;
                    }
                }

            default:
                throw new Error();
        }
    };
    const [selectedOptions, dispatchSelectedOptions] = useReducer(optionsReducer, {});

    useEffect(() => {
        if (itemData?.optiongroups) {
            validatedOptions();
        }
    }, [selectedOptions]);

    let history = useHistory();

    // TODO: Clear options below unselected option. For instance; if you pick 1 sauce but then change to two sauce, need to clear the options selected below 1 sauce.

    const validatedOptions = () => {
        setInvalid((oldArray) => []);
        let modifiers = [];
        function mapGroup(subGroup, push = true) {
            if (push) {
                modifiers.push(subGroup);
            }
            if (subGroup?.options && selectedOptions?.[subGroup.id]) {
                subGroup.options.forEach((item, i) => {
                    if (item?.modifiers && selectedOptions[subGroup.id].includes(item.id)) {
                        item.modifiers.forEach((modifier, i) => {
                            mapGroup(modifier);
                        });
                    }
                });
            }
        }

        itemData.optiongroups.forEach((item, i) => {
            mapGroup(item);
        });

        modifiers.forEach((modifier, i) => {
            if (modifier.mandatory || (modifier.maxselects && modifier.minselects > 0)) {
                if (selectedOptions?.[modifier.id] && selectedOptions[modifier.id].length > 0) {
                    let multiselect = modifier.maxselects || modifier.minselects;
                    if (multiselect) {
                        if (
                            selectedOptions[modifier.id].length >= modifier.minselects &&
                            selectedOptions[modifier.id].length <= modifier.maxselects
                        ) {
                            // correct amount of options selected
                            return;
                        } else {
                            setInvalid((oldArray) => [...oldArray, modifier.id]);
                        }
                    }
                    return;
                } else {
                    setInvalid((oldArray) => [...oldArray, modifier.id]);
                }
            }
        });
        return modifiers;
    };
    const flattenSelectedItems = () => {
        const flattened = [];
        for (const [key, value] of Object.entries(selectedOptions)) {
            flattened.push(value);
        }
        return flattened.flat();
    };
    const addToCart = () => {
        setDirty(true);
        validatedOptions();

        if (invalid.length === 0 && modified) {
            let customdata = JSON.stringify({
                itemId,
                categoryId,
                options: selectedOptions,
            });
            const flattenedOptions = flattenSelectedItems();
            let productData = {
                productid: itemId,
                quantity: 1,
                options: flattenedOptions.join(),
                // customdata
            };

            dispatch(addItem(productData, true, history, location));
        } else {
            // TODO: Need error states/handling
            alert("There's been an error");
        }
    };

    const calculatePrice = () => {
        return sumBy(cost, function (o) {
            return o.amount;
        });
    };

    const fetchSelectedOptions = (groups) => {
        return groups
            .map((group) => group.options)
            .flat()
            .filter((option) => option.selected)
            .map((group) => group.id)
            .toString();
    };

    useEffect(() => {
        let didCancel = false;
        const fetchData = async () => {
            try {
                const result = await API.get(`products/${itemId}/modifiers`, {});
                if (!didCancel) {
                    setItemData(result.data.data);
                    searchMandatoryModifieres(result.data.data);
                }
            } catch (error) {
                if (!didCancel) {
                }
            }
        };
        fetchData();
        return () => {
            didCancel = true;
        };
    }, [itemId]);

    const handleScroll = () => {
        // Quick offset (75) for the nav bar
        const currentScrollPos = window.pageYOffset - 75;
        const shrink = currentScrollPos > prevScrollPos;

        setNavShrink(shrink);
        setPrevScrollPos(currentScrollPos);
    };

    useEffect(() => {
        const debounceScroll = () => debounce(handleScroll, 250);
        window.addEventListener('scroll', debounceScroll);

        return () => window.removeEventListener('scroll', debounceScroll);
    }, []);

    let allergens = find(item.metadata, { key: 'allergens' });

    return (
        <ItemContext.Provider
            value={{
                item: [itemData, setItemData],
                selected: [selectedOptions, dispatchSelectedOptions],
                invalid: [invalid],
                dirty: [dirty],
            }}
        >
            <SubHero
                className={classnames('navbar', {
                    'navbar--shrink': navShrink && !isMobile,
                })}
                category={item.name}
                calories={!item.basecalories ? '0' : item.basecalories}
                price={item.cost}
                description={item.description}
                isMobile={isMobile}
                allergens={allergens}
            />
            <HeroContainer showLarge={!!(itemData?.optiongroups && !itemData.optiongroups.length)} />
            <GenericContainer className="p-4 lg:p-8 z-0">
                <div className="w-full">
                    {itemData?.optiongroups && (
                        <ModifierGroup depth={0} modifiers={itemData.optiongroups}></ModifierGroup>
                    )}
                </div>
            </GenericContainer>

            <AddToCartBar
                isMobile={isMobile}
                name={item.name}
                calories={item.basecalories}
                price={calculatePrice() == 0 ? item.cost : calculatePrice()}
                // price={0}
                onClick={addToCart}
                loading={cartLoading}
                isDisabled={!modifiedDebounced || invalid.length > 0}
            />
        </ItemContext.Provider>
    );
};

const ModifierGroup = ({ modifiers, depth, option }) => {
    return (
        <div>
            {modifiers.map((group, i) => (
                <OptionGroup group={group} key={i} option={option} depth={depth} index={i}></OptionGroup>
            ))}
        </div>
    );
};

const OptionGroup = ({ group, index, depth, option }) => {
    const childItemContext = useContext(ItemContext);
    const [invalid] = childItemContext.invalid;
    const [dirty] = childItemContext.dirty;
    const [selectedOptions] = childItemContext.selected;
    const required = group.mandatory || (group.maxselects && group.minselects > 0);
    const valid = Array.isArray(selectedOptions[group.id]) && !invalid.includes(group.id);
    const error = invalid.includes(group.id) && dirty;
    return (
        <div className="">
            <h2 className="font-bold text-primaryColor mb-4 uppercase text-xl">
                {(group.description === 'Customize' && option) ?
                    <>{group.description}: {option.name}</>
                :
                    <>{group.description}</>
                }

                {required && !valid && !error && (
                    <Asterisk
                        style={{
                            display: 'inline-block',
                            marginTop: '-4px',
                            marginLeft: '5px',
                        }}
                        className={''}
                    ></Asterisk>
                )}
                {valid && (
                    <span>
                        <RadioCheckedGreenIcon
                            style={{
                                display: 'inline-block',
                                marginTop: '-4px',
                                marginLeft: '5px',
                            }}
                        ></RadioCheckedGreenIcon>
                    </span>
                )}
                {error && <span> - Error</span>}
            </h2>
            <OptionList
                options={group.options}
                group={group}
                groupId={group.id}
                depth={depth}
                index={index}
            ></OptionList>
        </div>
    );
};
const OptionList = ({ options, index, depth, groupId, group }) => {
    const childItemContext = useContext(ItemContext);
    const [selectedOptions, dispatchSelectedOptions] = childItemContext.selected;
    let subOptions = [];
    let hasImages = false;
    if (options[0].metadata) {
        if (find(options[0].metadata, { key: 'img' }) || find(options[0].metadata, { key: 'IMG' })) {
            hasImages = true;
        }
    }

    let mainOption = options.map((option, i) => {
        let selected = Array.isArray(selectedOptions[groupId]) && selectedOptions[groupId].includes(option.id);
        if (option.modifiers && option.modifiers.length && selected) {
            subOptions.push(
                <div className="" key={i}>
                    <ModifierGroup modifiers={option.modifiers} option={option} depth={depth + 1} key={i}></ModifierGroup>
                </div>
            );
        }
        return (
            <MenuItemCard
                key={i}
                selected={selected}
                isDefault={option.isdefault}
                hasImages={hasImages}
                className={'col-span-2'}
                onClick={() =>
                    dispatchSelectedOptions({
                        type: 'makeSelection',
                        group,
                        id: option.id,
                        groupIndex: i,
                        groupId,
                        optionIndex: index,
                        depth,
                        selected: selected,
                    })
                }
                preSelectOption={()=>{
                    dispatchSelectedOptions({
                        type: 'makeSelection',
                        group,
                        id: option.id,
                        groupIndex: i,
                        groupId,
                        optionIndex: index,
                        depth,
                        selected: true,
                    })
                }}
                option={option}
            ></MenuItemCard>
        );
    });

    return (
        <div>
            <div className="grid grid-cols-6 gap-4 mb-10 pb-10 border-b-2 border-beige">{mainOption}</div>
            <div>{subOptions}</div>
        </div>
    );
};

export default Item;
