import React from 'react';
import { TmWordLogo, TmBuffaoLogo } from '@assets';
import styled from 'styled-components';

const LogoCtn = styled.div`
    height: ${(p) => (p.selectedLocation ? '55px' : '65px')};
    width: ${(p) => (p.selectedLocation ? '80px' : '189px')};
    display: flex;
    align-items: center;

    @media (min-width: ${(p) => p.theme.screens.lg}) {
        height: 105px;
        width: 269px;
    }
    @media (max-width: ${(p) => p.theme.screens.xs}) {
        height: 105px;
    }
`; //the media query above prevents the bag icon from overflowing outside the screen width

const StyledTextIcon = styled(TmWordLogo)`
    height: 90px;
    width: 189px;
    position: absolute;
    padding-top: 12px;
    @media (min-width: ${(p) => p.theme.screens.lg}) {
        left: 80px;
    }
`;

const RotatedImgLogo = styled(TmBuffaoLogo)`
    height: inherit;
    transform: rotate(345deg);
    position: absolute;
    top: -17px;
    left: -25px;
`;

const StyledLine = styled.div`
    height: 50px;
    width: 2px;
    background-color: ${(p) => p.theme.colors.beige};
`;

const Logo = ({ isMobile, onClick, isRoot, selectedLocation }) => {
    return (
        <>
            <LogoCtn onClick={onClick} selectedLocation={selectedLocation}>
                {!isMobile && (
                    <>
                        <RotatedImgLogo />
                        <StyledTextIcon />
                    </>
                )}

                {isMobile && (!isRoot ? <TmBuffaoLogo className="h-12 w-20" /> : <StyledTextIcon />)}
            </LogoCtn>
            {!isMobile && <StyledLine />}
        </>
    );
};

export default Logo;
