import { combineReducers } from 'redux';
import rootReducers from './rootReducers';
import menuReducers from './menuReducers';
import cartReducers from './cartReducers';
import restaurantReducers from './restaurantReducers';
import mapReducers from './mapReducers';
import authReducer from './authReducer';

const rootReducer = (asyncReducers) =>
    combineReducers({
        rootData: rootReducers,
        menuData: menuReducers,
        cartData: cartReducers,
        restaurantData: restaurantReducers,
        mapData: mapReducers,
        authData: authReducer,
        ...asyncReducers,
    });

export default rootReducer;
