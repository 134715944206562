import styled from 'styled-components';
import tw from 'tailwind.macro';
import { HeartFavoritesIcon } from '@assets';

//////////---General Favorites---//////////

export const StyledFavoritesMainCtn = styled.div`
    ${tw`w-full flex flex-col lg:pt-12`}
`;
export const StyledFavorite = styled.div`
    ${tw`py-6 px-4 border-b-2 border-gray lg:border-beige`}
`;
export const StyledHeadlineIconRow = styled.div`
    ${tw`flex flex-row items-center justify-between items-center`}
`;
export const StyledSimpleHeadline = styled.div`
    ${tw`font-veneer text-2xl text-primaryColor`}
`;

//////////---Icons---//////////

export const StyledHeartFavoritesIcon = styled(HeartFavoritesIcon)`
    ${tw`mr-2`}
`;

//////////---Favorite Order Details---//////////

export const StyledOrderQuantity = styled.div`
    ${tw`font-futuraHY text-base lg:text-lg leading-5 lg:leading-6`}
`;
export const StyledOrderDetailsCtn = styled.div`
    ${tw`flex flex-row justify-between items-center content-center pt-3 mb-4`}
`;
export const StyledOrderDetails = styled.div`
    ${tw`font-futura text-xs lg:text-sm leading-4 lg:leading-5 opacity-75`}
`;
export const StyledOrderDetailsExtras = styled.div`
    ${tw`font-futura text-xs lg:text-sm leading-4 lg:leading-5 opacity-75`}
`;
