import { getIn } from 'formik';

export default function getStyles(formik, fieldName) {
    if (!formik.touched[fieldName]) {
        return;
    }
    if (getIn(formik.errors, fieldName)) {
        return {
            border: '1px solid #C62032',
        };
    } else {
        return {
            border: '1px solid black',
        };
    }
}
