import React from 'react';
import PropTypes from 'prop-types';
import MainContainer from 'containers/components/MainContainer';
import { PhotoCard, GenericContainer } from '@components';
import HeroContainer from '../components/heroContainer';
import { MenuHero } from '@components/GlobalComponents/Heros/MenuHero/index';
import FavoritesAccount from '../../Account/components/Favorites';

const Favorites = (props) => {
    return (
        <MainContainer>
            <HeroContainer />
            <MenuHero></MenuHero>
            <div>
                <FavoritesAccount />
            </div>
        </MainContainer>
    );
};

export default Favorites;
