import React from 'react';
import { Text, RestaurantContainerDetails, SubHeading } from '@components';

const RestaurantList = ({ restaurants }) => {
    return (
        <>
            {restaurants.length ? (
                restaurants.map((restaurants, index) => {
                    return <RestaurantContainerDetails restaurants={restaurants} key={index} />;
                })
            ) : (
                <div className="p-5 z-10">
                    <SubHeading type="primary"> Whoops!</SubHeading>
                    <Text type="smLT" className="text-xl">
                        Looks like Taco Mac isn't available as your Local Wingman just yet. Please try another location.
                    </Text>
                </div>
            )}
        </>
    );
};

export default RestaurantList;
