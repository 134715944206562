import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    tel: yup
        .string()
        // eslint-disable-next-line
        .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)
        .max(24),
    email: yup.string().email().max(128),
    password: yup.string().min(8).max(1024),
    text: yup.string().max(36),
});
