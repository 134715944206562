import * as types from '../types';

let initialState = {
    loading: false,
    error: false,
    id: null,
    data: {
        products: [],
        subtotal: 0,
        total: 0,
        salestax: 0,
    },
};

const setCart = (state, action) => {
    return Object.assign({}, state, { ...state, ...action.data });
    return state;
};
const clearCart = (state) => {
    return initialState;
};
const setLoading = (state, action) => {
    return Object.assign({}, state, { loading: action.status });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_CART_LOADING:
            return setLoading(state, action);
        case types.SET_CART:
            return setCart(state, action);
        case types.CLEAR_CART:
            return clearCart(state);
        default:
            return state;
    }
    return state;
};

export default reducer;
