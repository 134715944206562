import React, { useEffect, useState } from 'react';
import { StyledPastOrdersCtn } from '../styles/pastOrders-index';
import { SingleOrder } from './subComponents/SingleOrder';
import NotLoggedIn from './subComponents/NotLoggedIn';
import { Text, Button, GenericContainerNarrow } from '@components';
import API from '@utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { addPrevious } from '@store/actions';
import timeout from '@global/helpers/timeout';
import { PageLoading } from '@components/GlobalComponents/Loading/PageLoading';
import { useHistory, useLocation } from 'react-router-dom';

const PastOrders = ({ props }) => {
    const selectedLocation = useSelector((state) => state.menuData.location?.slug);
    const { authtoken } = useSelector((state) => state.authData);
    const [pastOrders, setPastOrders] = useState([]);
    const [loading, setLoading] = useState([]);
    const [singleItems, setSingleItems] = useState([]);
    const [itemChoices, setItemChoices] = useState([]);
    const [prodName, setProdName] = useState('');
    const [prodQuantity, setProdQuantity] = useState(0);
    const [prodPrice, setProdPrice] = useState(0);
    const cartLoading = useSelector((state) => state.cartData.loading);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        let isCancelled = false;
        async function getPastOrders() {
            setLoading(true);
            if (authtoken) {
                try {
                    const result = await API.get(`/users/${authtoken}/recentOrders/`);
                    if (!isCancelled) {
                        setPastOrders(result.data.data.orders);
                        setSingleItems(result.data.data.orders[0].products);
                        setItemChoices(result.data.data.orders[0].products[0].choices);
                    }
                } catch (e) {
                    console.log(e);
                } finally {
                    await timeout(1000);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }
        getPastOrders();
        return () => {
            isCancelled = true;
        };
    }, [setPastOrders]);

    const addToBag = (favorite) => {
        if (!cartLoading) {
            handleAddPreviousToBag(favorite);
        }
        return;
    };

    const handleStartAnOrder = (e) => {
        e.preventDefault();
        if (selectedLocation) {
            history.push(`/order/${selectedLocation}/menu`);
        } else {
            history.push('/');
        }
    };

    const handleAddPreviousToBag = async (favorite) => {
        const loc = location.pathname.includes('menu') ? 'menu' : 'account';
        dispatch(addPrevious({ data: { ...favorite, from: loc }, history }));
    };

    return (
        <>
            <GenericContainerNarrow>
                {loading ? (
                    <PageLoading inner={true}></PageLoading>
                ) : (
                    <>
                        {authtoken ? (
                            <>
                                {pastOrders.length > 0 ? (
                                    <StyledPastOrdersCtn>
                                        {pastOrders.map((pastOrder, key) => (
                                            <SingleOrder
                                                order={pastOrder}
                                                cartOrderID={pastOrder.id}
                                                key={pastOrder.id}
                                                addToBag={() => {
                                                    addToBag(pastOrder);
                                                }}
                                            />
                                        ))}
                                    </StyledPastOrdersCtn>
                                ) : (
                                    <div className="w-full flex flex-col m-auto mb-25 mt-5 lg:mb-90 px-4">
                                        <div className="flex m-auto ">
                                            <Text type="smLT" className="lg:text-lg">
                                                You don’t have any past orders yet.
                                            </Text>
                                        </div>
                                        <Button
                                            onClick={handleStartAnOrder}
                                            className="w-full m-auto mt-7 rounded-sm"
                                            type="primary"
                                        >
                                            Start An Order
                                        </Button>
                                    </div>
                                )}
                            </>
                        ) : (
                            <NotLoggedIn></NotLoggedIn>
                        )}
                    </>
                )}
            </GenericContainerNarrow>
        </>
    );
};

export default PastOrders;
