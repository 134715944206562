import React from 'react';
import { DateTime } from 'luxon';

/**
 * Get restaurant hours
 */
export const getHoursOptions = (hours, deliveryDay, isPickup) => {
    const oloDateFormat = 'yyyyMMdd HH:mm';
    const hoursType = isPickup ? 'business' : 'dispatch';
    const { month, day } = deliveryDay;
    const today = DateTime.local().setZone('America/New_York');
    let date = DateTime.local().set({ month, day }).setZone('America/New_York');
    const isAfterToday = date.toSeconds() > today.toSeconds();

    // Reset time if it's a later date
    date = date.set({
        hour: isAfterToday ? 0 : today.hour,
        minute: isAfterToday ? 0 : today.minute,
    });

    const dayName = date.weekdayShort;
    const selectedDayHours = hours
        .find(arr => arr.type === hoursType)
        .ranges.find((r) => r.weekday === dayName);
    const storeStartTime = DateTime.fromFormat(selectedDayHours.start, oloDateFormat);
    const storeEndTime = DateTime.fromFormat(selectedDayHours.end, oloDateFormat);

    // Add 30m offset from current time
    const earliestTime = storeStartTime.plus({ minutes: 30 });

    // Split time difference into 15m interval
    const timeInterval = earliestTime.until(storeEndTime).splitBy({minute: 15});
    // Duplicate last item so we can use the `end` time matching the actual delivery end time
    timeInterval.push(timeInterval[timeInterval.length -1]);

    return timeInterval.map((timeInt, i) => {
        const { start, end } = timeInt;
        // Use `end` time for last item
        const timeString = i === (timeInterval.length - 1) ? end.toFormat('t') : start.toFormat('t');
        return (
            <option value={timeString} key={i}>
                {isAfterToday ? date.toFormat('cccc') : 'Today'} at {timeString}
            </option>
        )
    });
}

/**
 * Get hours based on user's current time
 */
export const _getHoursOptions = (hours, deliveryDay, isPickup) => {
    const oloDateFormat = 'yyyyMMdd HH:mm';
    const hoursType = isPickup ? 'business' : 'dispatch';
    const { month, day } = deliveryDay;
    const today = DateTime.local().setZone('America/New_York');
    let date = DateTime.local().set({ month, day }).setZone('America/New_York');
    const isAfterToday = date.toSeconds() > today.toSeconds();
    // Reset time if it's a later date
    date = date.set({
        hour: isAfterToday ? 0 : today.hour,
        minute: isAfterToday ? 0 : today.minute,
    });

    const dayName = date.weekdayShort;
    const selectedDayHours = hours
        .find(arr => arr.type === hoursType)
        .ranges.find((r) => r.weekday === dayName);
    const storeStartTime = DateTime.fromFormat(selectedDayHours.start, oloDateFormat);
    const storeEndTime = DateTime.fromFormat(selectedDayHours.end, oloDateFormat);
    const storeStartHour = storeStartTime.hour;

    // Make sure the earliest time is store opening time
    if (date.hour < storeStartHour) {
        date = date.set({hour: storeStartHour, minute: 0});
    }

    // Add 30m offset from current time
    let earliestTime = date.plus({ minutes: 30 });
    let { minute } = earliestTime;
    let timeIntervals = ['00', 15, 30, 45];
    const initialMinutes = +(timeIntervals.find((i) => i >= minute) || '00');
    if (initialMinutes) {
        earliestTime = earliestTime.set({minutes: initialMinutes});
    } else {
        earliestTime = earliestTime
            .set({minutes: 0})
            .plus({hour: 1});
    }

    // Split time difference into 15m interval
    let timeInterval = earliestTime.until(storeEndTime).splitBy({minute: 15});
    // Duplicate last item so we can use the `end` time matching the actual delivery end time
    timeInterval.push(timeInterval[timeInterval.length -1])

    return timeInterval.map((timeInt, i) => {
        const { start, end } = timeInt;
        // Use `end` time for last item
        const timeString = i === (timeInterval.length - 1) ? end.toFormat('t') : start.toFormat('t');
        return (
            <option value={timeString} key={i}>
                {isAfterToday ? date.toFormat('cccc') : 'Today'} at {timeString}
            </option>
        )
    });
};

export const getDayOptions = (selectedLocation) => {
    const date = DateTime.local().setZone('America/New_York');
    const options = [];

    for (let i = 0; i < selectedLocation.advanceorderdays; i++) {
        const upcomingDate = date.plus({day: i});
        const { day } = upcomingDate;

        options.push({
            date: upcomingDate.toObject(),
            label: day === date.day ? 'Today' : upcomingDate.toFormat('cccc LL/dd')
        })
    }

    return options.map((opt, i) => (
        <option value={JSON.stringify(opt.date)} key={i}>
            {opt.label}
        </option>
    ));
}

export const getWantedTime = (selectedDeliveryDay, timeStr) => {
    const date = DateTime.local().set(selectedDeliveryDay).setZone('America/New_York');
    const dObj = date.toObject();
    const timeOfDay = timeStr.substr(-2).toLowerCase();
    const minute = +timeStr.split(':')[1].substr(0, 2);
    let hour = +timeStr.split(':')[0];
    hour = timeOfDay === 'pm' ? (hour != 12 ? hour + 12 : hour) : hour;

    return {
        ismanualfire: 'false',
        year: dObj.year.toString(),
        month: dObj.month,
        day: dObj.day,
        hour,
        minute,
        tz: date.zoneName,
    };
};

export const checkoutFormValid = (props) => {
    const errors = [];

    // Loop through all props and validate them
    for (let prop in props) {
        const val = props[prop];

        if (val === '') {
            errors.push(prop);
        }
    }

    // Valid if there are no errors
    return {
        valid: errors.length === 0,
        errors,
    };
};

export const getTipAmount = (tip, tipType, total) => {
    if (tipType === 'amount') {
        return tip;
    }

    return +((tip / 100) * total).toFixed(2);
};

export const baseIframeInputStyles = `
    border-bottom: 1px solid #a1aec0;
    border-left-color: #a1aec0;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: #a1aec0;
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: #a1aec0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-style: solid;
    border-top-width: 1px;
    height: 48px;
    color: #000;
    font-size: 14px;
    line-height: 22px;
    width: calc(99% - 10px);
    padding-left: 10px;
`;

export const errorIframeInputStyles = `
    border-bottom: 1px solid #C62032;
    border-left-color: #C62032;
    border-right-color: #C62032;
    border-top-color: #C62032;
`;
