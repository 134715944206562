import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Link, useParams } from 'react-router-dom';

const StyledFooterLinkCtn = styled.div`
    ${tw`
        flex
        flex-col
        items-center

        lg:order-2
        lg:m-5
        lg:flex-row
        lg:justify-between
`}
`;

const StyledSingleLine = styled.span`
    border: 2px solid ${(p) => p.theme.colors.primaryColor};
    width: 15%;
    margin: 4% 0;

    ${tw`lg:hidden`}
`;

const StyledFooter = styled.footer`
    ${tw`
        bg-beige
        flex
        flex-col
        items-center

        lg:flex-row
    `}
    justify-content: space-evenly;
`;

const StyledFooterLinks = styled(Link)`
    ${tw`text-lg mb-4 cursor font-futuraConMD lg:mr-6 lg:my-0 cursor-pointer`}
    &:first-child {
        ${tw`lg:mt-0`}
        margin-top: 16%;
    }
`;

const StyledOnlineOrdering = styled.a`
    margin-bottom: 10%;
    ${tw`text-base uppercase font-bold font-veneer cursor mt-4
        lg:mx-8
        lg:mb-4
    `}
    span {
        ${tw`underline`}
    }
`;

const Footer = () => {
    const footerLinks = [
        { link: 'Contact Us', url: '/contactus' },
        { link: 'User Agreement', url: '/user-agreement' },
        { link: 'Privacy Policy', url: '/privacy-policy' },
        {
            link: 'Online Tracking Opt Out Guide',
            url: '/online-tracking-opt-out-guide',
        },
    ];

    return (
        <StyledFooter id="footer">
            <StyledFooterLinkCtn>
                {/* {footerLinks.map((link) => (
                    <StyledFooterLinks key={link}>{link}</StyledFooterLinks>
                ))} */}
                {footerLinks.map((link, i) => (
                    <StyledFooterLinks to={`${link.url}`} key={i}>
                        {link.link}
                    </StyledFooterLinks>
                ))}
            </StyledFooterLinkCtn>
            <StyledSingleLine />
            <StyledOnlineOrdering>
                Online Ordering By <span>olo.com</span>
            </StyledOnlineOrdering>
        </StyledFooter>
    );
};

export default Footer;
