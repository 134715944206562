import React from 'react';
import { RestaurantList, SubHeading } from '@components';

const RecentRestaurant = ({ restaurants, isPickup, nearbyRestaurants }) => {
    return (
        <>
            {nearbyRestaurants.length > 0 && (
                <SubHeading type="primary" className="px-4 pt-8 lg:pt-0">
                    Nearby
                </SubHeading>
            )}
            <RestaurantList restaurants={restaurants} isPickup={isPickup} />
        </>
    );
};

export default RecentRestaurant;
