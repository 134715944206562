import React from 'react';
import { PageHeaderNavBeige, Text } from '@components';
import { StyledFilterCtn, StyledTextCtn, StyledHeaderText, StyledToggleItemsCtn } from './styles/index';
import { MenuFilterToggle } from '@components/GlobalComponents/Toggles/MenuFilterItems/index';

export const MenuFilter = (props) => {
    const filters = [
        {
            key: 'dairy',
            title: 'Dairy',
        },
        // {
        //     key: 'vegetarian',
        //     title:'Vegetarian'
        // },
        {
            key: 'gluten',
            title: 'Gluten',
        },
        {
            key: 'nuts',
            title: 'Nuts',
        },
        {
            key: 'fish',
            title: 'Fish',
        },
        {
            key: 'soy',
            title: 'Soy',
        },
    ];
    const [appliedFilters, setAppliedFilters] = props.filters;
    return (
        <>
            <PageHeaderNavBeige>
                <StyledFilterCtn>
                    <StyledTextCtn>
                        <StyledHeaderText className={'tracking-wide'}>Any Dietary Restrictions?</StyledHeaderText>
                        <Text type={'smBlk'} className={'lg:text-lg'}>
                            Special Diet? Allergies? Select all that apply to your dietary restrictions:
                        </Text>
                    </StyledTextCtn>
                    <div className={'w-full mt-5 lg:mt-8 lg:mb-7'}>
                        <StyledToggleItemsCtn
                            className={'w-full grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 lg:gap-3'}
                        >
                            {filters.map((filter) => (
                                <MenuFilterToggle
                                    key={filter.key}
                                    value={filter.key}
                                    appliedFilters={appliedFilters}
                                    action={setAppliedFilters}
                                    content={filter.title}
                                />
                            ))}
                        </StyledToggleItemsCtn>
                    </div>
                    <StyledTextCtn className={'py-9 lg:pt-0 lg:pb-8'}>
                        <Text type={'smBlk'} className={'lg:text-base'}>
                            ** Please be advised: While Taco Mac makes every effort to ensure items are gluten-free,
                            there is a risk of cross contamination due to preparing food in our kitchens. We do not
                            assume any liability for adverse reactions while eating at Taco Mac.{' '}
                        </Text>
                    </StyledTextCtn>
                </StyledFilterCtn>
            </PageHeaderNavBeige>
        </>
    );
};
