import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { GenericContainerNarrow, TextHero } from '@components';
import MainContainer from 'containers/components/MainContainer';
import { LoginForm } from '../components/LoginForm';

const Login = () => {
    let { path } = useRouteMatch();
    let history = useHistory();

    const userLoggedIn = useSelector((state) => state.authData.loggedIn);

    const handleHeroOnClick = (e) => {
        e.preventDefault();
        // debugger
        history.push(`${path}/create-account`);
    };

    useEffect(() => {
        if (userLoggedIn) {
            // history.push('/account');
        }
    }, [userLoggedIn]);

    return (
        <>
            <TextHero
                heading={'Log In'}
                subHeading={'New Around Here?'}
                to={'/auth/create-account'}
                type={'tertiaryWhite'}
                cta={'Create Account'}
            />
            <GenericContainerNarrow className={'px-4 py-7 lg:py-16'}>
                <LoginForm />
            </GenericContainerNarrow>
        </>
    );
};

export default Login;
