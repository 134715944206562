import styled from 'styled-components';
import tw from 'tailwind.macro';
import { LocationTrueBlackIcon, MobileSwipeIcon, EditIcon, RemoveIcon } from '@assets';
import { Link, useParams } from 'react-router-dom';

export const CartContainer = styled.div`
    ${tw`

        w-full
        lg:w-2/5
        fixed
        right-0
        top-0
        flex
        flex-col
        bottom-0
        justify-between
        z-50
        h-screen
        bg-white

    `}
`;
export const CartContentContainer = styled.div`
    ${tw`
        h-screen
        flex
        flex-col

    `}
    ${({ isLoading }) =>
        isLoading &&
        `
        opacity:0.25;
    `}
`;
export const CartLoading = styled.div`
    ${tw`
        inset-0
        hidden
        absolute
        items-center
        justify-center
    `}
    ${({ isLoading }) =>
        isLoading &&
        `
        display:flex;
        z-index:99;
    `}
`;
export const CartOverlay = styled.div`
    ${tw`
        w-full
        fixed
        right-0
        top-0
        bottom-0
        left-0
        z-40
    `}
    background-color: rgba(0,0,0,0.8)
`;
export const CartHeader = styled.div`
    ${tw`
        w-full
        bg-beige
        px-5
        lg:px-8
    `}
`;
export const CloseContainer = styled.div`
    ${tw`
        w-full
        flex
        justify-end
        text-right
        pt-5
    `}
`;
export const PickupContainer = styled.div`
    ${tw`
    flex
    flex-row
    items-center
    justify-between
        w-full
        bg-beige
        pb-8
        pt-2
    `}
`;
export const BagContainer = styled.div`
    ${tw`
        w-full
        flex-1
    `}
`;
export const BagInfo = styled.div`
    ${tw`
        w-full
        py-6
        px-4
        lg:px-8
        flex
        justify-between
        items-start
        lg:items-center
        lg:content-center
        border-b
        border-extraLightGray
    `}
`;
export const BagHeader = styled.div`
    ${tw`font-veneer text-lg lg:text-2xl pb-1 lg:pb-3 leading-3`}
`;
export const BagAddMore = styled.div`
    ${tw`flex`}
`;
export const StyledAddMoreLink = styled(Link)`
    ${tw`border rounded-sm font-futuraConMD text-primaryColor uppercase text-sm border-current`}
`;

export const BagItems = styled.div`
    ${tw`
        w-full
        relative
        border-b
        border-extraLightGray
        overflow-y-scroll
        flex-1

    `}
`;

export const BagItem = styled.div`
    ${tw`
        w-full
        py-3
        flex
        flex-row
        justify-between
    `}
`;

export const ProductChoices = styled.div`
    ${tw`font-futura text-xs text-darkGray leading-3 my-0.5`}
`;
export const AddedExtras = styled.span`
    ${tw`font-futura text-xs text-black opacity-75 py-2`}
`;
export const ProductName = styled.div`
    ${tw`font-futura text-base lg:text-lg font-semibold leading-5 pb-2`}
`;
export const ProductPrice = styled.div`
    ${tw`max-h-6 font-futura text-base lg:text-lg font-medium leading-5 px-2`}
`;
export const EditRemoveBtn = styled.span`
    ${tw`text-primaryColor uppercase text-sm mt-1 mr-2 font-futuraConMD underline cursor-pointer`}
`;
export const CartItemCount = styled.span`
    ${tw`text-black font-veneer text-lg lg:text-2xl pb-1 leading-3 px-1`}
`;
export const PriceContainer = styled.div`
    ${tw`
        w-full
    `}
`;
export const PriceRow = styled.div`
    ${tw`
        w-full
        flex
        flex-row
        justify-between
    `}
    ${({ bold }) =>
        bold &&
        `
        font-weight: bold;
    `}
`;
export const PriceRowText = styled.span`
    ${tw`font-futura text-sm lg:text-lg opacity-75 lg:opacity-50 leading-5 lg:leading-6`}
`;
export const PriceRowTotal = styled.span`
    ${tw`font-futuraHY font-semibold text-lg lg:text-xl leading-5 lg:leading-6 my-0.5`}
`;
export const CheckoutContainer = styled.div`
    ${tw`
        w-full
        py-4
        px-3
        border-t-2
        border-extraLightGray
    `}
    @media screen and (max-width: 767px) {
        @supports (-webkit-touch-callout: none) {
           padding-bottom: 130px;
        }
    }
`;
export const PickUpDeliveryHeadline = styled.span`
    ${tw`text-sm lg:text-base text-darkGray leading-none`}
`;
export const PickUpDeliveryAddressCtn = styled.div`
    ${tw`w-full flex flex-col`}
`;
export const PickUpDeliveryAddressRow = styled.div`
    ${tw`w-full flex flex-row content-center items-center justify-between`}
`;
export const PickUpDeliveryAddressBold = styled.span`
    ${tw`text-lg lg:text-2xl font-veneer font-normal leading-none`}
`;
export const PickUpDeliveryStreet = styled.span`
    ${tw`flex flex-row font-futura text-sm lg:text-lg`}
`;
export const StyledLocationIcon = styled(LocationTrueBlackIcon)`
    ${tw`flex mr-1 z-10 cursor-pointer`}
`;

//////////--Mobile Edit/Remove Swipe--//////////

export const StyledMobileSwipeIcon = styled(MobileSwipeIcon)`
    ${tw`flex z-10 cursor-pointer my-4`}
`;
export const StyledMobileEditIcon = styled(EditIcon)`
    ${tw`flex z-10 cursor-pointer`}
`;
export const StyledMobileRemoveIcon = styled(RemoveIcon)`
    ${tw`flex z-10 cursor-pointer`}
`;
