import React, { useEffect, useState } from 'react';
import {
    StyledPastOrdersCtn,
    StyledSingleOrderContainer,
    StyledOrderInfoRow,
    StyledDateCtn,
    StyledOrderedFrom,
    StyledOrderQuantityPriceCtn,
    StyledOrderQuantity,
    StyledOrderPrice,
    StyledOrderDetailsCtn,
    StyledOrderDetails,
    StyledOrderCalories,
    StyledOrderDetailsExtras,
    StyledOrderTotalsCtn,
    StyledOrderTotalCost,
    StyledOrderTotals,
    StyledOrderStatus,
} from '../../../Account/styles/pastOrders-index';
import { StyledStreetAddress, StyledStreetCityStateZip, StyledStoreDetails, StyledPhoneNumber } from '../styles/index';
import NotLoggedIn from '../../../Account/components/subComponents/NotLoggedIn';
import { GenericContainer, GenericContainerNarrow, Text } from '@components';
import API from '@utils/API';
import { useDispatch, useSelector } from 'react-redux';
import timeout from '@global/helpers/timeout';
import { PageLoading } from '@components/GlobalComponents/Loading/PageLoading';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { TextHero } from 'components';
import find from 'lodash/find';

const OrderDetails = ({ props }) => {
    const { authtoken } = useSelector((state) => state.authData);
    const [order, setOrder] = useState([]);
    const [loading, setLoading] = useState([]);
    const [singleItems, setSingleItems] = useState([]);
    const [itemChoices, setItemChoices] = useState([]);
    const [prodName, setProdName] = useState('');
    const [prodQuantity, setProdQuantity] = useState(0);
    const [prodPrice, setProdPrice] = useState(0);
    const cartLoading = useSelector((state) => state.cartData.loading);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { orderId } = useParams();

    const [restaurantId, setRestaurantId] = useState('');
    const [storeStreetAddress, setStoreStreetAddress] = useState('');
    const [storeCity, setStoreCity] = useState('');
    const [storeState, setStoreState] = useState('');
    const [storeZip, setStoreZip] = useState('');
    const [storePhone, setStorePhone] = useState('');

    useEffect(() => {
        let isCancelled = false;

        async function getOrderInformation() {
            setLoading(true);
            if (orderId) {
                const statusResult = await API.get(`/orders/${orderId}/status/`);
                if (!isCancelled) {
                    setOrder(statusResult.data.data);
                    setSingleItems(statusResult.data.data.products);
                    setRestaurantId(statusResult.data.data.vendorid);

                    await timeout(1000);

                    const restaurantResult = await API.get(`restaurants/`);
                    const restaurantData = find(restaurantResult.data.data.restaurants, function(r) { return r.id === statusResult.data.data.vendorid; })
                    setStoreStreetAddress(restaurantData.streetaddress);
                    setStoreCity(restaurantData.city);
                    setStoreState(restaurantData.state);
                    setStoreZip(restaurantData.zip);
                    setStorePhone(restaurantData.telephone);


                    setLoading(false);
                }

            } else {
                setLoading(false);
            }

        };

        getOrderInformation();

        return () => {
            isCancelled = true;
        };


    }, [setOrder]);

    const formatDate = (str) => {
        if (str) {
            let year = str.slice(0, 4);
            let month = str.slice(4, 6);
            let day = str.slice(6, 8);
            let time = str.slice(9);
            time = time.split(':'); // convert to array

            // fetch
            var hours = Number(time[0]);
            var minutes = Number(time[1]);

            // calculate
            var timeValue;

            if (hours > 0 && hours <= 12) {
                timeValue = '' + hours;
            } else if (hours > 12) {
                timeValue = '' + (hours - 12);
            } else if (hours == 0) {
                timeValue = '12';
            }

            timeValue += minutes < 10 ? ':0' + minutes : ':' + minutes; // get minutes
            timeValue += hours >= 12 ? ' PM' : ' AM'; // get AM/PM

            let newDate = timeValue.concat(', ', month).concat('/', day).concat('/', year);
            return newDate;
        }
    };

    return (
        <>
            {loading ? (
                <PageLoading inner={true}></PageLoading>
            ) : (
                <>
                    <TextHero heading={`View Order #${order.oloid}`} />
                    {authtoken ? (
                        <GenericContainerNarrow>
                            <StyledPastOrdersCtn>
                                <StyledSingleOrderContainer>
                                    <StyledOrderStatus className="font-futura font-semibold text-base md:text-lg leading-5 mb-1">
                                        Order Status: {order.status}
                                    </StyledOrderStatus>
                                    <StyledOrderStatus className="font-futura font-semibold text-base md:text-lg leading-5 mb-6">
                                        Order Placed:{' '}
                                        <Text className="inline font-futuraLT"> {formatDate(order.timeplaced)}</Text>
                                    </StyledOrderStatus>

                                    <StyledOrderInfoRow>
                                        <div className="flex flex-row">
                                            {order.deliverymode === 'pickup' ? (
                                                <StyledDateCtn>Ready at {formatDate(order.readytime)}</StyledDateCtn>
                                            ) : (
                                                <StyledDateCtn>
                                                    {' '}
                                                    Delivery by {formatDate(order.readytime)}
                                                </StyledDateCtn>
                                            )}
                                            <StyledOrderedFrom>
                                                <span className={'capitalize'}>{order.deliverymode}</span>
                                                {` from ${order.vendorname}`}
                                            </StyledOrderedFrom>
                                        </div>
                                    </StyledOrderInfoRow>
                                    <StyledStoreDetails className="mb-4">
                                        {order.deliverymode === 'pickup' ? (
                                            <>
                                                <Text className="font-futura font-semibold text-base md:text-lg leading-5 mb-0.5">
                                                    {order.vendorname}
                                                </Text>
                                                <StyledStreetAddress>{storeStreetAddress}</StyledStreetAddress>
                                                <StyledStreetCityStateZip
                                                    style={{
                                                        marginBottom: 'unset',
                                                    }}
                                                >
                                                    {storeCity}, {storeState} {storeZip}
                                                </StyledStreetCityStateZip>
                                                <StyledPhoneNumber>{storePhone}</StyledPhoneNumber>
                                            </>
                                        ) : (
                                            //TODO: NEED TO STYLE USER DELIVERY ADDRESS
                                            order.deliveryaddress
                                        )}{' '}
                                    </StyledStoreDetails>

                                    {order.products.map((product, i) => (
                                        <div className={'mb-4'} key={i}>
                                            <StyledOrderQuantityPriceCtn>
                                                <StyledOrderQuantity>
                                                    <div>
                                                        {product.quantity} x {product.name}
                                                    </div>
                                                </StyledOrderQuantity>
                                                <StyledOrderPrice>
                                                    <div>${product.totalcost}</div>
                                                </StyledOrderPrice>
                                            </StyledOrderQuantityPriceCtn>
                                            <StyledOrderDetailsCtn>
                                                <div className="flex flex-col">
                                                    <StyledOrderDetailsExtras>
                                                        {product.choices.map((choice, i) => (
                                                            <div key={i}>{choice.name}</div>
                                                        ))}
                                                    </StyledOrderDetailsExtras>
                                                </div>
                                            </StyledOrderDetailsCtn>
                                        </div>
                                    ))}
                                    <StyledOrderQuantityPriceCtn className="mb-2">
                                        <StyledOrderTotals>
                                            <div>Subtotal</div>
                                        </StyledOrderTotals>
                                        <StyledOrderTotals>
                                            <div>${order.subtotal}</div>
                                        </StyledOrderTotals>
                                    </StyledOrderQuantityPriceCtn>
                                    <StyledOrderQuantityPriceCtn className="mb-2">
                                        <StyledOrderTotals>
                                            <div>Delivery Fees</div>
                                        </StyledOrderTotals>
                                        <StyledOrderTotals>
                                            <div>${order.totalfees}</div>
                                        </StyledOrderTotals>
                                    </StyledOrderQuantityPriceCtn>
                                    {order.taxes.map((item, i) => {
                                        return (
                                            <StyledOrderQuantityPriceCtn className="mb-2" key={i}>
                                                <StyledOrderTotals>
                                                    <div>{item.label}</div>
                                                </StyledOrderTotals>
                                                <StyledOrderTotals>
                                                    <div>${item.tax}</div>
                                                </StyledOrderTotals>
                                            </StyledOrderQuantityPriceCtn>
                                        );
                                    })}
                                    <StyledOrderQuantityPriceCtn className="mb-2">
                                        <StyledOrderTotals>
                                            <div>Tip</div>
                                        </StyledOrderTotals>
                                        <StyledOrderTotals>
                                            <div>${order.tip}</div>
                                        </StyledOrderTotals>
                                    </StyledOrderQuantityPriceCtn>
                                    <StyledOrderQuantityPriceCtn className="mb-6">
                                        <StyledOrderQuantity>
                                            <div>Total</div>
                                        </StyledOrderQuantity>
                                        <StyledOrderPrice>
                                            <div>${order.total}</div>
                                        </StyledOrderPrice>
                                    </StyledOrderQuantityPriceCtn>
                                    {/* TODO: Need to output PAYMENT METHOD/CC INFO */}
                                </StyledSingleOrderContainer>
                            </StyledPastOrdersCtn>
                        </GenericContainerNarrow>
                    ) : (
                        <NotLoggedIn></NotLoggedIn>
                    )}
                </>
            )}
        </>
    );
};

export default OrderDetails;
