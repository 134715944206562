import styled from 'styled-components';
import tw from 'tailwind.macro';

export const StyledHeroCtn = styled.div`
    ${tw`bg-black h-49 lg:h-56 -mt-1 flex flex-col justify-around lg:items-center lg:py-4`}
`;

export const StyledSubCtn = styled.div`
    ${tw`w-full py-5 px-4 mb-5 lg:m-0 text-white text-4xl font-veneer uppercase flex flex-row justify-between items-center content-center lg:max-w-2xl lg:px-8`}
`;

export const StyledHeroNavCtn = styled.nav`
    ${tw`w-full px-4 flex leading-8 text-white items-center content-center lg:max-w-2xl lg:px-8`}
`;

export const StyledHeroHeading = styled.div`
    ${tw`max-w-56 leading-9`}
`;
