import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Container = styled.div`
    ${tw`
      p-10
      bg-primaryColor
      w-screen
      mx-0
    `}
`;

export const ContainerBeige = styled.div`
    ${tw`
		  p-4
		  bg-beige
      w-full
    `}
`;

export const LoginHeader = styled.div`
    ${tw`
        flex
        flex-col
        items-start
        justify-center
        h-49
        lg:flex-row
        lg:justify-between
        lg:items-center
        lg:px-70
        lg:h-56 
      `}
`;

export const HeaderLinksList = styled.ul`
    ${tw`
        flex
        text-right
        text-white 
        font-futura
        text-sm
        lg:text-lg
        uppercase
      `}
`;
