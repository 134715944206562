import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik, ErrorMessage, getIn } from 'formik';
import * as Yup from 'yup';
import getStyles from '@global/helpers/getStyles';
import { TextInput, Label, Button } from '@components';
// import { setSavedDeliveryAdresses } from '@store/actions';

export const UpdateDeliveryAddressForm = ({ action, close, }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { userSavedDeliveryAddress } = useSelector((state) => state.authData);
    console.log(userSavedDeliveryAddress, 'in form')

    const formik = useFormik({
        initialValues: {
            streetaddress: userSavedDeliveryAddress?.streetaddress || '',
            building: userSavedDeliveryAddress?.building || '',
            city: userSavedDeliveryAddress?.city || '',
            state: userSavedDeliveryAddress?.state || '',
            zipcode: userSavedDeliveryAddress?.zipcode || '',
            // contactnumber: contactnumber ? contactnumber : '',
        },
        validationSchema: Yup.object({
            streetaddress: Yup.string(),
            building: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            zipcode: Yup.string(),
        }),
        onSubmit: (values) => {
            action(
                {
                   values,
                },
                (res) => {
                    //handleResponse
                    console.log(res);
                    if (res.status === 'success') {
                        // dispatch(setSavedDeliveryAdresses(values));
                        close(false);
                    } else {
                    }
                    setLoading(false);
                }
            );
            
        },
    });

    return (
        <>
            <form
                onSubmit={formik.handleSubmit}
                className="w-full flex flex-col content-center justify-center"
                noValidate
            >
                <Label label="Street address" type="primary" className="mt-0 mb-0">
                    <TextInput
                        className="mt-1"
                        type="text"
                        name="streetaddress"
                        withoutValidation
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.streetaddress}
                        style={getStyles(formik, 'streetaddress')}
                        maxLength={36}
                    />
                    {formik.errors.streetaddress && formik.touched.streetaddress ? (
                        <div className="mt-1 text-status-error">{formik.errors.streetaddress}</div>
                    ) : null}
                </Label>
                <Label label="Address line 2" type="primary">
                    <TextInput
                        className="mt-1"
                        type="text"
                        name="building"
                        withoutValidation
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.building}
                        style={getStyles(formik, 'building')}
                        maxLength={36}
                    />
                    {formik.errors.building && formik.touched.building ? (
                        <div className="mt-1 text-status-error">{formik.errors.building}</div>
                    ) : null}
                </Label>
                <Label label="City" type="primary">
                    <TextInput
                        className="mt-1"
                        type="text"
                        name="city"
                        withoutValidation
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.city}
                        style={getStyles(formik, 'city')}
                        maxLength={128}
                    />
                    {formik.errors.city && formik.touched.city ? (
                        <div className="mt-1 text-status-error">{formik.errors.city}</div>
                    ) : null}
                </Label>
                <Label label="State" type="primary">
                    <TextInput
                        className="mt-1"
                        type="text"
                        name="state"
                        withoutValidation
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.state}
                        style={getStyles(formik, 'state')}
                        maxLength={24}
                    />
                    {formik.errors.state && formik.touched.state ? (
                        <div className="mt-1 text-status-error">{formik.errors.state}</div>
                    ) : null}
                </Label>
                <Label label="Zip" type="primary">
                    <TextInput
                        className="mt-1"
                        type="text"
                        name="zipcode"
                        withoutValidation
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.zipcode}
                        style={getStyles(formik, 'zipcode')}
                        maxLength={24}
                    />
                    {formik.errors.zipcode && formik.touched.zipcode ? (
                        <div className="mt-1 text-status-error">{formik.errors.zipcode}</div>
                    ) : null}
                </Label>
                <Button
                    className="w-full flex justify-center content-center items-center m-auto my-12 lg:mb-10 rounded-sm"
                    type="primary"
                    loading={loading}
                    // onClick={(e) => handlePersonalDetails(e)}
                >
                    Save Changes
                </Button>
            </form>
        </>
    );
};
