import React from 'react';
import MainContainer from 'containers/components/MainContainer';
import GenericContainerNarrow from 'components/GlobalComponents/GenericContainerNarrow';
import {
    StyledHeroCtn,
    StyledHeroHeading,
    StyledMessageCtn,
    StyledSmallText,
    StyledSmallRedText,
    StyledBoldWords,
} from './styles/index';

export const TrackingOptOutGuide = () => {
    return (
        <MainContainer>
            <StyledHeroCtn>
                <StyledHeroHeading>CCPA Tracking Opt-Out Guide</StyledHeroHeading>
            </StyledHeroCtn>

            <GenericContainerNarrow className={'px-4 py-7 lg:py-12'}>
                <StyledMessageCtn>
                    <StyledSmallText>
                        We use services provided by third parties that collect device data and online activity data from
                        individuals who visit our website or use the app to analyze website traffic and facilitate
                        advertising, such as Google and Facebook.
                    </StyledSmallText>
                    <StyledSmallText>
                        There are a number of ways to opt-out, which we have summarized below.
                    </StyledSmallText>
                    <StyledSmallText>
                        <StyledBoldWords>Blocking cookies in your browser</StyledBoldWords>. Most browsers let you
                        remove or reject cookies, including cookies used for interest-based advertising. To do this,
                        follow the instructions in your browser settings. Many browsers accept cookies by default until
                        you change your settings. See more at{' '}
                        <StyledSmallRedText
                            href={'http://www.allaboutcookies.org'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            www.allaboutcookies.org
                        </StyledSmallRedText>
                        .
                    </StyledSmallText>
                    <StyledSmallText>
                        <StyledBoldWords>Blocking advertising ID use in your mobile settings</StyledBoldWords>. Your
                        mobile device settings may provide functionality to limit use of the advertising ID associated
                        with your mobile device for interest-based advertising purposes.
                    </StyledSmallText>
                    <StyledSmallText>
                        <StyledBoldWords>Using privacy plug-ins or browsers</StyledBoldWords>. You can block our site
                        from setting cookies used for interest-based ads by using a browser with privacy features, like{' '}
                        <StyledSmallRedText href={'https://brave.com/'} target="_blank" rel="noopener noreferrer">
                            Brave
                        </StyledSmallRedText>
                        , or installing browser plugins, like{' '}
                        <StyledSmallRedText
                            href={'https://privacybadger.org/'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Badger
                        </StyledSmallRedText>
                        ,{' '}
                        <StyledSmallRedText
                            href={'https://www.ghostery.com/'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Ghostery
                        </StyledSmallRedText>{' '}
                        or{' '}
                        <StyledSmallRedText href={'https://ublock.org/'} target="_blank" rel="noopener noreferrer">
                            uBlock Origin
                        </StyledSmallRedText>
                        , and configuring them to block third party cookies/trackers.
                    </StyledSmallText>
                    <StyledSmallText>
                        <StyledBoldWords>Platform opt-outs</StyledBoldWords>. Google and Facebook offer opt-out features
                        that let you opt-out of use of your information for interest-based advertising. We have included
                        the applicable links below to facilitate this election if you choose to do so:
                    </StyledSmallText>
                    <StyledSmallText className="flex flex-col">
                        <p>
                            Google:{' '}
                            <StyledSmallRedText
                                href={'https://adssettings.google.com'}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://adssettings.google.com
                            </StyledSmallRedText>
                        </p>
                        <p>
                            Facebook:{' '}
                            <StyledSmallRedText
                                href={'https://www.facebook.com/about/ads'}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://www.facebook.com/about/ads
                            </StyledSmallRedText>
                        </p>
                    </StyledSmallText>
                    <StyledSmallText>
                        <StyledBoldWords>Advertising industry opt-out tools</StyledBoldWords>. You can also use these
                        opt-out options to limit use of your information for interest-based advertising by participating
                        companies:
                    </StyledSmallText>
                    <StyledSmallText>
                        <p>
                            {' '}
                            Digital Advertising Alliance:{' '}
                            <StyledSmallRedText
                                href={'http://optout.aboutads.info'}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                http://optout.aboutads.info
                            </StyledSmallRedText>
                        </p>
                        <p>
                            {' '}
                            Network Advertising Initiative:{' '}
                            <StyledSmallRedText
                                href={'http://optout.networkadvertising.org/?c=1'}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                http://optout.networkadvertising.org/?c=1
                            </StyledSmallRedText>
                        </p>
                    </StyledSmallText>
                </StyledMessageCtn>
            </GenericContainerNarrow>
        </MainContainer>
    );
};
