import React from 'react';
import NewCreditCardForm from './NewCreditCardForm';
import { CheckoutSimpleHeadline, CheckoutRedHeading, StyledCreditCardIcon } from '../styles';

export const FullPaymentInfo = (props) => {
    return (
        <>
            <CheckoutSimpleHeadline>
                <StyledCreditCardIcon />
                <CheckoutRedHeading>Payment Info</CheckoutRedHeading>
            </CheckoutSimpleHeadline>
            <NewCreditCardForm {...props} />
        </>
    );
};
