import React from 'react';
import { RestaurantList, SubHeading } from '@components';

const RecentRestaurant = ({ restaurants }) => {
    return (
        <>
            <SubHeading type="primary" className="px-4 pt-8 lg:pt-0">
                Recent Restaurants
            </SubHeading>
            <RestaurantList restaurants={restaurants} />
        </>
    );
};

export default RecentRestaurant;
