import styled from 'styled-components';
import tw from 'tailwind.macro';

    // ${tw`flex flex-row border-transparent rounded-sm justify-between py-3 pl-5 pr-2`}
export const StyleTooltipContent = styled.div`
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 12px 14px;
    width: 320px;
    top: 26px;
    transform: translateX(-50%);
`;
