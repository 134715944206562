import React from 'react';
import { ProductImageHero } from '@components';
import { useSelector } from 'react-redux';
import { CategoryImageHero } from '@assets';
import { useWindowSize } from '@hooks';
import { useParams } from 'react-router-dom';
import find from 'lodash/find';

const HeroContainer = ({ displayName, className, children, showLarge }) => {
    const { menu } = useSelector((state) => state.menuData);

    let { categoryId, itemId } = useParams();

    const viewPort = useWindowSize().width;
    const breakpoint = 1024;
    const isMobile = viewPort < breakpoint;

    const filterForViewPort = () => {
        let category = filterForCategory();
        return isMobile ? category.images[0].filename : category.images[1].filename;
    };

    const filterForCategory = () => {
        return menu.categories.filter((category) => category.id == categoryId)[0];
    };

    const formatImageUrl = () => {
        const result = filterForViewPort();
        const imageUrl = result.split('?')[0];
        return `${menu.imagepath}${imageUrl}`;
    };

    const filterForProductImg = () => {
        let category = filterForCategory();
        if (category) {
            let product = category.products.filter((item) => item.id == itemId)[0];
            if (!product?.metadata) {
                return null;
            }
            let image = find(product.metadata, { key: 'img' });
            let value = null;
            if (image?.value) {
                value = image.value;
            }
            return value;
        } else {
            return null;
        }
    };

    const getSrcUrl = () => {
        if (itemId) {
            return filterForProductImg();
        } else {
            return formatImageUrl();
        }
    };

    return (
        <ProductImageHero
            src={displayName === 'Menu' || filterForProductImg() == null ? CategoryImageHero : getSrcUrl()}
            type="category"
            large={showLarge}
            className={className}
        >
            {children}
        </ProductImageHero>
    );
};

export default HeroContainer;
