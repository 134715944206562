import React from 'react';
import MainContainer from 'containers/components/MainContainer';
import GenericContainerNarrow from 'components/GlobalComponents/GenericContainerNarrow';
import {
    StyledHeroCtn,
    StyledHeroHeading,
    StyledDateCtn,
    StyledDate,
    StyledMessageCtn,
    StyledSubHeading,
    StyledSmallText,
} from './styles/index';

export const ContactUs = () => {
    return (
        <>
            <MainContainer>
                <StyledHeroCtn>
                    <StyledHeroHeading>Contact Us</StyledHeroHeading>
                </StyledHeroCtn>

                <GenericContainerNarrow className={'px-4 py-7 lg:py-12'}>
                    <StyledMessageCtn>
                        <StyledDateCtn>
                            <StyledDate>Dated 10 December 2020</StyledDate>
                        </StyledDateCtn>
                        <StyledSubHeading>Online Ordering Customer Support</StyledSubHeading>
                        <StyledSmallText>
                            If you do not see your favorite store today, please check back soon!
                        </StyledSmallText>
                        <StyledSmallText>
                            Click on the “View Menus” tab to search for locations currently online.
                        </StyledSmallText>
                        <StyledSubHeading>Locations</StyledSubHeading>
                        <StyledSmallText>
                            To find the phone number, hours, or address for a specific location, select “View Menus” or
                            view “Participating Locations”.
                        </StyledSmallText>
                        <StyledSubHeading>Online Order Support</StyledSubHeading>
                        <StyledSmallText>
                            If you have any questions on your online order, please contact the specific location for
                            assistance.
                        </StyledSmallText>
                    </StyledMessageCtn>
                </GenericContainerNarrow>
            </MainContainer>
        </>
    );
};
