import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
    Map,
    RestaurantList,
    PickupDeliveryContainer,
    RecentRestaurant,
    SavedAddresses,
    AllRestaurants,
    NearbyRestaurants,
    Button,
} from '@components';
import tw from 'tailwind.macro';
import { setMap } from '@store/actions';
import { ArrowRightIcon } from '@assets';
import uniqBy from 'lodash/uniqBy';
import LandingPageContext from '@containers/LandingPage/landingPageContext';

const StyledCtn = styled.div`
    @media (max-width: 768px) {
        ${tw` mx-auto`}
    }
`;

const StyledIcon = styled(ArrowRightIcon)`
    ${tw`ml-1 inline h-4 w-4`}
    fill: ${(p) => p.theme.colors.primaryColor};
`;

const StyledMapCtn = styled.div`
    @media (min-width: ${(p) => p.theme.screens.lg}) {
        width: 57%;
    }

    > div {
        width: 100% !important;
    }
`;

const DesktopMapContainer = styled.div`
    height: calc(100vh - (75px + 67px));
    display: flex;
    width: 100%;
`;

const RestaurantContainer = ({ inputValue, setInputValue, isPickup, isMobile }) => {
    let { restaurants, userRecentRestaurant } = useSelector((state) => state.rootData);
    userRecentRestaurant = uniqBy(userRecentRestaurant, 'id');

    const [viewLocations, setViewLocations] = useState(false);
    const { nearbyRestaurants } = useSelector((state) => state.restaurantData);
    const dispatch = useDispatch();

    const landingPageContext = useContext(LandingPageContext);
    const [searchValue, setSearchValue] = landingPageContext.searchValue;

    const filterForPickUp = () => {
        return nearbyRestaurants.filter((restaurants) => isPickup && restaurants.canpickup);
    };

    const getRestaurantsList = () => {
        return nearbyRestaurants.length > 0
            ? isPickup
                ? filterForPickUp()
                : nearbyRestaurants
            : Boolean(inputValue)
            ? []
            : restaurants;
    };

    const displayAllLocations = () => {
        isMobile && dispatch(setMap());
        setViewLocations(true);
    };

    return (
        <>
            {isMobile ? (
                <div className="lg:flex flex-col flex-1">
                    <PickupDeliveryContainer
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        isPickup={isPickup}
                        viewLocations={viewLocations}
                        setViewLocations={setViewLocations}
                        isMobile={isMobile}
                    />
                    <Map isMobile={isMobile} />
                    <StyledCtn>
                        {viewLocations ? (
                            <AllRestaurants inputValue={inputValue} restaurants={restaurants} />
                        ) : userRecentRestaurant.length > 0 && !searchValue ? (
                            <>
                                <RecentRestaurant restaurants={userRecentRestaurant} />
                                <Button
                                    className="font-futuraConMD flex mx-auto items-center my-12 text-primaryColor uppercase underline text-sm"
                                    onClick={() => displayAllLocations()}
                                >
                                    View All Locations <StyledIcon />
                                </Button>
                            </>
                        ) : (
                            <RestaurantList restaurants={getRestaurantsList()} isPickup={isPickup} />
                        )}
                        {!isMobile && <SavedAddresses setInputValue={setInputValue} />}
                    </StyledCtn>
                </div>
            ) : (
                <DesktopMapContainer>
                    <div className="flex flex-1 flex-col">
                        <PickupDeliveryContainer
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            isPickup={isPickup}
                            viewLocations={viewLocations}
                            setViewLocations={setViewLocations}
                            isMobile={isMobile}
                        />
                        <div className="overflow-scroll px-8 pt-8">
                            {viewLocations ? (
                                <AllRestaurants inputValue={inputValue} restaurants={restaurants} />
                            ) : userRecentRestaurant.length > 0 && !searchValue ? (
                                <RecentRestaurant restaurants={userRecentRestaurant} />
                            ) : (
                                <NearbyRestaurants
                                    restaurants={getRestaurantsList()}
                                    isPickup={isPickup}
                                    nearbyRestaurants={nearbyRestaurants}
                                />
                            )}
                        </div>
                    </div>
                    <StyledMapCtn className="h-full">
                        <Map isMobile={isMobile} />
                    </StyledMapCtn>
                </DesktopMapContainer>
            )}
        </>
    );
};

export default RestaurantContainer;
