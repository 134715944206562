import styled from 'styled-components';
import tw from 'tailwind.macro';

export const StyledHeroCtn = styled.div`
    ${tw`bg-black h-34 -mt-1 flex lg:justify-center lg:items-center lg:py-4`}
`;

export const StyledHeroHeading = styled.span`
    ${tw`w-full px-4 lg:px-8 py-5 text-white text-5xl font-veneer uppercase flex flex-col lg:flex-row justify-end lg:justify-start content-center lg:max-w-2xl max-w-56 leading-9 lg:items-center`}
`;

export const StyledDateCtn = styled.div`
    ${tw`flex lg:justify-end pb-4 lg:py-2`}
`;

export const StyledDate = styled.span`
    ${tw`font-futuraLT text-base text-black font-normal`}
`;

export const StyledMessageCtn = styled.div`
    ${tw`w-full flex flex-col m-auto py-4`}
`;

export const StyledHeading = styled.div`
    ${tw`text-4xl font-veneer text-black font-normal leading-8 py-2`}
`;

export const StyledSubHeading = styled.div`
    ${tw`font-veneer text-2xl text-black font-normal py-2 leading-8`}
`;

export const StyledSmallText = styled.div`
    ${tw`font-futuraLT text-sm lg:text-base text-black font-normal py-2 leading-6`}
`;

//////////--Privacy Policy--//////////

export const StyledInnerText = styled.div`
    ${tw`font-futuraLT text-sm lg:text-base text-black font-normal leading-6`}
`;

export const StyledBoldWords = styled.div`
    ${tw`inline font-futuraHY text-sm lg:text-base text-black`}
`;

export const StyledSmallRedText = styled.a`
    ${tw`font-futuraLT cursor-pointer text-sm lg:text-base text-primaryColor font-normal leading-6 hover:underline`}
`;

export const StyledListCtn = styled.ul`
    ${tw`font-futuraLT text-sm lg:text-base text-black font-normal py-2 px-2 leading-6`}
`;

export const StyledListItem = styled.li`
    ${tw`list-outside ml-6 list-disc font-futuraLT text-sm lg:text-base text-black font-normal leading-9`}
`;

//////////--User Agreement--//////////

export const StyledOrderedListCtn = styled.ol`
    ${tw`font-futuraLT text-sm lg:text-base text-black font-normal py-2 px-2 leading-6`}
`;

export const StyledNumberedListItem = styled.li`
    ${tw`list-outside ml-6 list-decimal font-futuraLT text-sm lg:text-base text-black font-normal leading-9`}
`;

export const StyledNoDiscListItem = styled.li`
    ${tw`list-outside ml-7 list-none font-futuraLT text-sm lg:text-base text-black font-normal leading-9`}
`;

export const StyledLetteredListItem = styled.li`
    ${tw`list-outside ml-7 list-alpha font-futuraLT text-sm lg:text-base text-black font-normal leading-9`}
`;
