import React from 'react';
import PropTypes from 'prop-types';

import { ContainerBeige } from './style';

const PageHeaderNavBeige = (props) => {
    return <ContainerBeige>{props.children}</ContainerBeige>;
};

export default PageHeaderNavBeige;
