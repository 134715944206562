import React, { useEffect, useState } from 'react';
import { /*useDispatch,*/ useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { Text, Button, GenericContainerNarrow } from '@components';
import { CreditCardInfo } from './subComponents/CreditCardInfo';
import { UserDeliveryAddresses } from './subComponents/UserDeliveryAddresses';
import { UserInfoDetails } from './subComponents/UserInfoDetails';
import { UpdateDeliveryAddressForm } from './forms/UpdateDeliveryAddressForm';
// import { setSavedDeliveryAdresses } from '@store/actions';

import qs from 'qs';
import {
    StyledAccountDetailsCtn,
    StyledDetailsContainer,
    StyledHeadlineIconRow,
    StyledSimpleHeadline,
    StyledRedHeading,
    StyledProfileIcon,
    StyledPasswordIcon,
    StyledCreditCardIcon,
    StyledDeliveryIcon,
    StyledMailIcon,
} from '../styles/profile-index';
import { Modal } from 'components/GlobalComponents/Modal';
import { EditPersonalDetailsForm } from './forms/EditPersonalDetails';
import { ChangePassword } from './forms/ChangePassword';
import { ContactPreferences } from './forms/ContactPreferences';
import { DeleteAccount } from './forms/DeleteAccount';
import API from '@utils/API';
import forEach from 'lodash/forEach';

// NOTE:PageLoading component not merged into master yet - 1/26
// import {PageLoading} from '../../../components/GlobalComponents/Loading/PageLoading';
// import {Transition} from 'react-spring/renderprops';

const AccountProfile = (props) => {
    const authtoken = useSelector((state) => state.authData.authtoken);
    const { firstname, lastname, emailaddress, contactnumber, communicationPreferences } = useSelector(
        (state) => state.authData
    );

    const { userSavedDeliveryAddress } = useSelector((state) => state.authData);

    //TODO: Retrieve user CC info
    const [userCreditCards, setUserCreditCards] = useState([]);
    const [userCommunicationPreferences, setUserCommunicationPreferences] = useState(null);

    //For loading state:
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    let isCancelled = false;

    //Handles modal state
    const [showModal, setShowModal] = useState({
        show: false,
        type: null,
    });
    const openModal = (type = null, header = null) => {
        setShowModal((prev) => ({
            type: type,
            header: header,
            show: !prev.show,
        }));
    };



    const updateUserCommunicationPreferences = async (userUpdatedCommPrefs, callback) => {
        let response = null;
        const { communicationPrefs } = userCommunicationPreferences;
        let updatedCommPrefs = {
            ...userCommunicationPreferences,
            ...userUpdatedCommPrefs,
        };

        forEach(updatedCommPrefs, function (comm, key) {
            updatedCommPrefs[key] = comm.toString();
        });

        try {
            const { data } = await API.put(`/users/${authtoken}/updateCommunicationPreferences`, {
                ...updatedCommPrefs,
            });

            response = { status: 'success', data };
        } catch (e) {
            response = { status: 'error' };
        } finally {
            callback(response);
        }
    };
    const updatePersonalDetails = async (userData, callback) => {
        let response = null;
        const { data } = userData;
        let contactNumber;
        try {
            const userDetails = await API.put(`/users/${authtoken}/updateDetails`, { ...data });
            if(data.contactnumber){
                contactNumber = await API.put(`/users/${authtoken}/contactNumber`, {
                    contactdetails: data.contactnumber,
                });
            }
            const user = {
                firstname: userDetails.data.data.firstname,
                lastname: userDetails.data.data.lastname,
                emailaddress: userDetails.data.data.emailaddress,
                contactnumber: (contactNumber)?contactNumber.data.data.contactdetails:null,
            };
            response = { status: 'success', user };
        } catch (e) {
            response = { status: 'error' };
        } finally {
            callback(response);
        }
    };

    const updateChangePassword = async (data, callback) => {
        const result = await API.post(`/users/${authtoken}/changePassword`, {
            currentpassword: data.oldPassword,
            newpassword: data.newPassword,
        });
        callback(result);
    };

    const removeCreditCard = async (id) => {
        try {
            await API.delete(`/users/${authtoken}/creditcards/${id}`);
        } catch (e) {
            console.log(e);
        } finally {
            getCardsOnFile();
        }
    };

    const setDeleteAccount = async (callback) => {
        try {
            const result = await API.delete(`/users/${authtoken}/deleteUser`);
            callback(result);
        } catch (e) {
            callback({ error: e });
        }
    };
    const setDefaultCreditCard = async (id) => {
        try {
            const result = await API.put(`/users/${authtoken}/creditcards/${id}`);
        } catch (e) {
            console.log(e);
        } finally {
            getCardsOnFile();
        }
    };
    async function getCardsOnFile() {
        const result = await API.get(`/users/${authtoken}/billingAccounts`);
        if (!isCancelled) {
            setUserCreditCards(result.data.data.billingaccounts);
        }
    }

    async function getCommunicationPreferences() {
        //TODO: Update with real endpoint for user cards
        const result = await API.get(`/users/${authtoken}/communicationPreferences`);
        if (!isCancelled) {
            setUserCommunicationPreferences(result.data.data);
        }
    }

    //TODO: Make API call to update default address:


    useEffect(() => {
        getCommunicationPreferences();
        getCardsOnFile();

        return () => {
            isCancelled = true;
        };
    }, [setUserCreditCards]);

    return (
        <>
            <GenericContainerNarrow>
                <StyledAccountDetailsCtn>
                    <StyledDetailsContainer>
                        <StyledHeadlineIconRow>
                            <div className="flex flex-row">
                                <StyledProfileIcon />
                                <StyledRedHeading>Personal Details</StyledRedHeading>
                            </div>
                            <Button
                                className="border rounded-sm"
                                type="quaternary"
                                size="small"
                                onClick={() => openModal('personalDetails', 'Update Personal Details')}
                            >
                                Edit
                            </Button>
                            <Modal
                                showModal={showModal.type}
                                setShowModal={setShowModal}
                                headline={showModal.header}
                                content={({ close }) => {
                                    if (showModal.type === 'personalDetails') {
                                        return (
                                            <EditPersonalDetailsForm
                                                action={updatePersonalDetails}
                                                close={close}
                                                {...{
                                                    firstname,
                                                    lastname,
                                                    emailaddress,
                                                    contactnumber,
                                                }}
                                            />
                                        );
                                    } else if (showModal.type === 'changePassword') {
                                        return <ChangePassword action={updateChangePassword} close={close} />;
                                    } else if (showModal.type === 'communicationPreferences') {
                                        return (
                                            <ContactPreferences
                                                communicationPreferences={communicationPreferences}
                                                action={updateUserCommunicationPreferences}
                                                close={close}
                                            />
                                        );
                                    } else if (showModal.type === 'deleteAccount') {
                                        return <DeleteAccount action={setDeleteAccount} close={close} />;
                                    } else if (showModal.type  === 'updateDeliveryAddress') {
                                        return <UpdateDeliveryAddressForm close={close} />;
                                        // action={setSavedDeliveryAdresses}
                                    }
                                }}
                            />
                        </StyledHeadlineIconRow>
                        <UserInfoDetails
                            userName={`${firstname} ${lastname}`}
                            emailAddress={`${emailaddress}`}
                            //TODO: User phone number is empty
                            phoneNumber={`${contactnumber}`}
                        />
                    </StyledDetailsContainer>

                    <StyledDetailsContainer>
                        <StyledHeadlineIconRow>
                            <div className="flex flex-row content-center">
                                <StyledPasswordIcon />
                                <Text type="lgRed">Password</Text>
                            </div>
                            <Button
                                className="border rounded-sm"
                                type="quaternary"
                                size="small"
                                onClick={() => openModal('changePassword', 'Change Password')}
                            >
                                Change Password
                            </Button>
                        </StyledHeadlineIconRow>
                    </StyledDetailsContainer>

                    {userCreditCards?.length > 0 && (
                        <StyledDetailsContainer>
                            <StyledSimpleHeadline>
                                <StyledCreditCardIcon />
                                <StyledRedHeading>Cards On File</StyledRedHeading>
                            </StyledSimpleHeadline>
                            {/* TODO: Map through userCreditCards when available */}
                            {/* {userCreditCards.map((card) => { */}
                            {/* return ( */}
                            {userCreditCards.map(
                                (card) =>
                                    card.accounttype === 'creditcard' && (
                                        <CreditCardInfo
                                            defaultCard={card.isdefault}
                                            key={card.accountid}
                                            id={card.accountid}
                                            setDefaultCreditCard={setDefaultCreditCard}
                                            removeCreditCard={removeCreditCard}
                                            cardType={card.cardtype}
                                            suffix={card.cardsuffix}
                                        />
                                    )
                            )}
                        </StyledDetailsContainer>
                    )}

                    <StyledDetailsContainer>
                        <StyledHeadlineIconRow>
                            <div className="flex flex-row items-baseline">
                                <StyledMailIcon />
                                <StyledRedHeading>Contact Preferences</StyledRedHeading>
                            </div>
                            <Button
                                className="border rounded-sm"
                                type="quaternary"
                                size="small"
                                onClick={() => openModal('communicationPreferences', 'Update Contact Preferences')}
                            >
                                Edit
                            </Button>
                        </StyledHeadlineIconRow>
                    </StyledDetailsContainer>
                    <div className="m-auto pt-8 lg:pt-12 pb-24">
                        <Button
                            className="border rounded-sm"
                            type="quaternary"
                            size="small"
                            onClick={() => openModal('deleteAccount', 'Delete Account')}
                        >
                            Delete Your Account
                        </Button>
                    </div>
                </StyledAccountDetailsCtn>
            </GenericContainerNarrow>
        </>
    );
};

export default AccountProfile;
