import styled from 'styled-components';
import tw from 'tailwind.macro';
import { HeartOutlineIcon } from '@assets';

//////////---General Past Orders---//////////

export const StyledPastOrdersCtn = styled.div`
    ${tw`w-full flex flex-col lg:pt-12 mb-24 lg:mb-32`}
`;
export const StyledSingleOrderContainer = styled.div`
    ${tw`py-6 px-4 border-b-2 border-gray lg:border-beige`}
`;
export const StyledOrderInfoRow = styled.div`
    ${tw`w-full flex flex-row justify-between items-center pb-5`}
`;
export const StyledDateCtn = styled.div`
    ${tw`text-primaryColor font-futuraLT text-sm leading-5 lg:text-base leading-6 mr-2`}
`;
export const StyledOrderedFrom = styled.div`
    ${tw`text-primaryColor font-bold font-futuraHY text-sm leading-5 lg:text-base leading-6 mr-2`}
`;
export const StyledOrderStatus = styled.div`
    ${tw`text-black font-bold font-futuraHY text-sm leading-5 lg:text-base leading-6 mr-2`}
`;
export const StyledOrderQuantityPriceCtn = styled.div`
    ${tw`flex flex-row justify-between items-center content-center`}
`;
export const StyledOrderQuantity = styled.div`
    ${tw`font-futuraHY text-base lg:text-lg leading-5 lg:leading-6`}
`;
export const StyledOrderTotals = styled.div`
    font-weight: 600;
    ${tw`font-futura text-base lg:text-lg leading-5 lg:leading-6`}
`;
export const StyledOrderPrice = styled.div`
    ${tw`font-futuraHY text-base lg:text-lg leading-5 lg:leading-6`}
`;
export const StyledOrderDetailsCtn = styled.div`
    ${tw`flex flex-row justify-between items-center content-center pt-3`}
`;
export const StyledOrderDetails = styled.div`
    ${tw`font-futura text-xs lg:text-sm leading-4 lg:leading-5 opacity-75`}
`;
export const StyledOrderDetailsExtras = styled.div`
    ${tw`font-futura text-xs lg:text-sm leading-4 lg:leading-5 opacity-75`}
`;
export const StyledOrderCalories = styled.div`
    ${tw`font-futura text-xs lg:text-sm leading-4 lg:leading-5 opacity-75`}
`;
export const StyledOrderTotalsCtn = styled.div`
    ${tw`flex flex-row content-center items-center py-2`}
`;
export const StyledOrderTotalCost = styled.div`
    ${tw`font-futuraHY text-sm lg:text-sm`}
`;
export const StyledOrderTotalCalories = styled.div`
    ${tw`font-futura text-sm lg:text-sm opacity-75`}
`;

//////////---Icons---//////////

export const StyledHeartOutlineIcon = styled(HeartOutlineIcon)`
    height: 30px;
    width: 30px;
    ${tw`mr-4`}
`;
