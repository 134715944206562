import React, { useEffect, useState } from 'react';
import { TextInput, Label, Button } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { loginUser } from '@store/actions/authActions';

export const LoginForm = (props) => {
    const dispatch = useDispatch();
    let { path } = useRouteMatch();
    let history = useHistory();
    const apiError = useSelector((state) => state.authData.error);
    const apiErrorMessage = apiError?.message;

    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [emailaddress, setEmailaddress] = useState('');
    const [password, setPassword] = useState('');

    const getInputState = (attr) => (formSubmitted && !attr ? 'error' : 'default');

    const handleInputOnChange = (setInputCallback, value) => {
        setInputCallback(value);
    };

    const handleForgotPasswordClick = (e) => {
        e.preventDefault();
        history.push(`/auth/forgot-password`);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (Boolean(emailaddress && password)) {
            setValid(true);
        } else {
            setValid(false);
        }

        setFormSubmitted(true);
    };

    // Check for API errors
    useEffect(() => {
        if (apiError && apiErrorMessage) {
            setFormSubmitted(false);
            setLoading(false);
        }
    }, [apiError, apiErrorMessage]);

    // Finally submit the form when everything checks out
    useEffect(() => {
        if (valid && formSubmitted) {
            setLoading(true);
            dispatch(
                loginUser({
                    login: emailaddress,
                    password,
                    history,
                })
            );
        }
    }, [valid, formSubmitted, loginUser, emailaddress, password]);

    return (
        <>
            <form className="w-full flex flex-col content-center justify-center" noValidate onSubmit={handleFormSubmit}>
                {apiErrorMessage && (
                    <>
                        <div className="text-status-error mb-4">{apiErrorMessage}</div>
                        <hr className="mb-4 border-gray" />
                    </>
                )}
                <Label label="Username" type="primary">
                    <TextInput
                        type="email"
                        name="login"
                        required
                        errorMessage="Please supply a valid email address"
                        value={emailaddress}
                        state={getInputState(emailaddress)}
                        onChange={(e) => handleInputOnChange(setEmailaddress, e.target.value)}
                        maxLength={128}
                    />
                </Label>
                <Label label="Password" type="primary">
                    <TextInput
                        type="password"
                        name="password"
                        required
                        requiredMessage="Please supply a password (min 8 chars)"
                        errorMessage="Passwords must be at least 8 characters long"
                        value={password}
                        state={getInputState(password)}
                        onChange={(e) => handleInputOnChange(setPassword, e.target.value)}
                        minLength={8}
                        maxLength={1024}
                    />
                </Label>

                <Button className="mt-6 rounded-sm" type="primary" loading={loading}>
                    Log In
                </Button>

                <Button className="m-auto" type="quinternary" onClick={handleForgotPasswordClick}>
                    Forgot Password?
                </Button>
            </form>
        </>
    );
};
