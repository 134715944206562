import styled from 'styled-components';
import tw from 'tailwind.macro';
import { EmptyCircleIcon, BlackCircleCheckIcon, CarouselArrowIcon, EditIcon, RemoveIcon } from '@assets';

export const StyledMainContainer = styled.div`
    ${tw`w-full border-extraLightGray mt-5`};
`;

export const StyledHeadingRow = styled.div`
    ${tw`w-full flex flex-row justify-between items-center`}
`;

export const StyledHeading = styled.span`
    ${tw`font-futuraConMD text-lg lg:text-xl leading-6 uppercase tracking-wide text-primaryColor`}
`;

export const StyledOptional = styled.span`
    color: rgba(0, 0, 0, 0.7);
    ${tw`font-futura text-xs lg:text-sm lowercase`}
`;

//////////--Carousel--//////////

export const CarouselCtn = styled.div`
    ${tw`w-full flex`}
`;

export const StyledBlackCircleCheckedIcon = styled(BlackCircleCheckIcon)`
    height: 100%;
    width: auto;
    right: 18px;
`;
export const StyledEmptyCircleIcon = styled(EmptyCircleIcon)`
    right: 18px;
    height: 100%;
    width: 25px;
`;
export const StyledAddItemBox = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    height: 70px;
    ${tw`w-full pl-1 pr-2 cursor-pointer py-2 flex flex-row content-center items-center rounded-sm justify-between mb-2`}
`;
export const StyledCarouselItemBoxDesk = styled.div`
    border: 1px solid transparent;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    height: 150px;
    width: 135px;
    ${tw`px-2 py-2 flex cursor-pointer flex-col content-center items-center rounded-sm justify-between mb-2`}
`;
export const CarouselDesktopRow = styled.div`
    ${tw`w-full flex flex-row justify-between items-center flex-wrap`}
`;
export const AddItemRow = styled.div`
    gap: 5px;
    ${tw`w-full flex flex-row justify-between items-center flex-nowrap`}
`;
export const CarouselItemInfoDesk = styled.div`
    ${tw`flex flex-col`}
`;
export const AddItemInfoCtn = styled.div`
    ${tw`flex w-full flex-row justify-center content-center items-center`}
`;
export const AddItemPhotoMobile = styled.span`
    display: flex;
    object-fit: scale-down;
    max-width: 65px;
    max-height: 65px;
    ${tw`p-1`}
`;
export const AddItemPhotoDesk = styled.span`
    object-fit: scale-down;
    width: auto;
    max-height: 55px;
`;
export const AddItemName = styled.p`
    ${tw`w-full flex font-futuraHY text-sm lg:text-xs leading-5`}
`;
export const AddItemPrice = styled.span`
    font-size: 10px;
    ${tw`font-futuraHY font-bold text-darkGray leading-3 pr-1`}
`;
export const AddItemCals = styled.span`
    font-size: 10px;
    ${tw`text-center font-futuraLT font-normal text-darkGray opacity-75`}
`;
export const StyledArrowRight = styled(CarouselArrowIcon)`
    width: 9px;
    height: 15px;
`;
export const StyledMobileEditIcon = styled(EditIcon)`
    ${tw`py-1 w-5 h-auto`}
`;
export const StyledMobileRemoveIcon = styled(RemoveIcon)`
    ${tw`py-1 w-5 h-auto`}
`;
