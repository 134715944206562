import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextInput, Label, Button, Text } from '@components';
import { userForgotPassword, userForgotPasswordReset } from '@store/actions/authActions';

export const ForgotPasswordForm = () => {
    const dispatch = useDispatch();
    const { forgotPasswordRequested } = useSelector((state) => state.authData);

    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailaddress, setEmailaddress] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);

    const getInputState = (attr) => (formSubmitted && !attr ? 'error' : 'default');

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (Boolean(emailaddress)) {
            setValid(true);
        } else {
            setValid(false);
        }

        setFormSubmitted(true);
    };

    // Finally submit the form when everything checks out
    useEffect(() => {
        if (valid && formSubmitted) {
            setLoading(true);
            dispatch(
                userForgotPassword({
                    emailaddress,
                })
            );
        }
    }, [valid, formSubmitted, userForgotPassword, emailaddress]);

    useEffect(() => {
        if (forgotPasswordRequested) {
            // Reset the password request prop after 3 seconds
            setTimeout(() => {
                setLoading(false);
                setFormSubmitted(false);
                dispatch(userForgotPasswordReset());
            }, 5000);
        }
    }, [forgotPasswordRequested]);

    return (
        <form className="w-full flex flex-col content-center justify-center" noValidate onSubmit={handleFormSubmit}>
            {forgotPasswordRequested ? (
                <>
                    <div className="text-status-success mb-4">
                        Thank you. You'll receive an email shortly to reset your password. If you do not see the mail
                        please be sure to check your junk mail folder.
                    </div>
                    <hr className="mb-4 border-gray" />
                </>
            ) : (
                <>
                    <Text className="mb-6" type="smLT">
                        Enter your email address and we will send you an email.
                    </Text>
                    <Text type="smLT">
                        If your email address matches an account, it will contain a link to reset your password.
                    </Text>
                    <Text className="mb-10" type="smLT">
                        If your email address does not match an account, the email will let you know.
                    </Text>
                </>
            )}
            <Label label="Email Address" type="primary">
                <TextInput
                    type="email"
                    name="login"
                    required
                    errorMessage="Please supply a valid email address"
                    value={emailaddress}
                    state={getInputState(emailaddress)}
                    onChange={(e) => setEmailaddress(e.target.value)}
                    maxLength={128}
                />
            </Label>

            <Button className="mt-2 mb-12 rounded-sm" type="primary" loading={loading}>
                Reset Password
            </Button>

            <Text className="leading-5 lg:leading-4" type="xs">
                For your added security, any credit card details stored with your account will be cleared. You will be
                asked to re-enter your card details on your next purchase.
            </Text>
        </form>
    );
};
