//logos
import { ReactComponent as TmBuffaoLogo } from './logos/tmBuffalo.svg';
import { ReactComponent as TmCombindedLogo } from './logos/tmCombined.svg';
import { ReactComponent as TmWordLogo } from './logos/tmWordLogo.svg';

//icons
import { ReactComponent as MapIcon } from './icons/map.svg';
import { ReactComponent as BagIcon } from './icons/bag.svg';
import { ReactComponent as LocationIcon } from './icons/location.svg';
import { ReactComponent as LocationBlackIcon } from './icons/location-black.svg';
import { ReactComponent as ArrowIcon } from './icons/arrow.svg';
import { ReactComponent as CloseRedIcon } from './icons/close-red.svg';
import { ReactComponent as MapPinIcon } from './icons/map-pin.svg';
import { ReactComponent as CurrentLocationIcon } from './icons/current-location.svg';
import { ReactComponent as ListIcon } from './icons/list.svg';
import { ReactComponent as ArrowRightIcon } from './icons/arrow-right.svg';
import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as CloseWhiteIcon } from './icons/close-white.svg';
import { ReactComponent as RadioIcon } from './icons/radio.svg';
import { ReactComponent as RadioCheckedIcon } from './icons/radio_checked.svg';
import { ReactComponent as RadioCheckedGreenIcon } from './icons/radio_checked_green.svg';
import { ReactComponent as Asterisk } from './icons/asterisk.svg';
import { ReactComponent as AvatarIcon } from './icons/profile.svg';
import { ReactComponent as Loader } from './icons/three-dots.svg';
import { ReactComponent as LoaderRed } from './icons/three-dots-red.svg';
import { ReactComponent as CheckboxUncheckedIcon } from './icons/checkbox_unchecked.svg';
import { ReactComponent as CheckboxCheckedIcon } from './icons/checkbox_checked.svg';
import { ReactComponent as PasswordIcon } from './icons/passwordIcon.svg';
import { ReactComponent as CreditCardIcon } from './icons/creditCardIcon.svg';
import { ReactComponent as DeliveryAddressIcon } from './icons/deliveryAddressPin.svg';
import { ReactComponent as MailIcon } from './icons/mailIcon.svg';
import { ReactComponent as AllergensIcon } from './icons/allergen.svg';
import { ReactComponent as HeartOutlineIcon } from './icons/heartOutlineIcon.svg';

import { ReactComponent as HeartFavoritesIcon } from './icons/heartFavorites.svg';
import { ReactComponent as DownArrowWhiteIcon } from './icons/whiteArrowDown.svg';
import { ReactComponent as RedQuestionIcon } from './icons/redQuestion.svg';
import { ReactComponent as EmptyCircleIcon } from './icons/emptyCircle.svg';
import { ReactComponent as BlackCircleCheckIcon } from './icons/blackCircleCheck.svg';
import { ReactComponent as RedClockIcon } from './icons/redClock.svg';
import { ReactComponent as ForwardSlashIcon } from './icons/forwardSlashIcon.svg';
import { ReactComponent as LocationTrueBlackIcon } from './icons/locationIconTrueBlack.svg';
import { ReactComponent as HeartOutlineRedIcon } from './icons/heartOutlineRed.svg';
import { ReactComponent as CarouselArrowIcon } from './icons/arrowCarousel.svg';
import { ReactComponent as MobileSwipeIcon } from './icons/mobileSwipeIcon.svg';
import { ReactComponent as EditIcon } from './icons/editPencilIcon.svg';
import { ReactComponent as RemoveIcon } from './icons/trashIcon.svg';
import { ReactComponent as FilterIcon } from './icons/filterIcon.svg';
import { ReactComponent as RedCheckedIcon } from './icons/redCheckedIcon.svg';
import { ReactComponent as ThinArrowRightIcon } from './icons/thinRightArrow.svg';

//logos
export { TmBuffaoLogo, TmCombindedLogo, TmWordLogo };

//icons
export {
    MapIcon,
    BagIcon,
    LocationIcon,
    LocationBlackIcon,
    ArrowIcon,
    CloseRedIcon,
    MapPinIcon,
    CurrentLocationIcon,
    ListIcon,
    ArrowRightIcon,
    SearchIcon,
    CloseWhiteIcon,
    RadioCheckedIcon,
    RadioIcon,
    Asterisk,
    RadioCheckedGreenIcon,
    AvatarIcon,
    Loader,
    LoaderRed,
    CheckboxCheckedIcon,
    CheckboxUncheckedIcon,
    PasswordIcon,
    CreditCardIcon,
    DeliveryAddressIcon,
    MailIcon,
    AllergensIcon,
    HeartOutlineIcon,
    HeartFavoritesIcon,
    DownArrowWhiteIcon,
    RedQuestionIcon,
    EmptyCircleIcon,
    BlackCircleCheckIcon,
    RedClockIcon,
    ForwardSlashIcon,
    LocationTrueBlackIcon,
    HeartOutlineRedIcon,
    CarouselArrowIcon,
    MobileSwipeIcon,
    EditIcon,
    RemoveIcon,
    FilterIcon,
    RedCheckedIcon,
    ThinArrowRightIcon,
};

//image heros
const LandingPageImageHero = `https://olo-images-sandbox.imgix.net/83/833145dc5b7c4759863deac2f4a1df3e.jpg`;
const CategoryImageHero = `https://olo-images-sandbox.imgix.net/69/696325d5821046e79db765b5e9661a41.jpg`;

export { LandingPageImageHero, CategoryImageHero };
