import * as types from '../types';

export const getNearbyRestaurants = (lat, lng, radius, limit) => {
    return { type: types.GET_NEARBY_RESTAURANTS, lat, lng, radius, limit };
};

export const getRestaurantOperatingHours = (restaurantId, range) => {
    return { type: types.GET_RESTAURANTS_OPERATING_HOURS, restaurantId, range };
};

export const setPickupOrDelivery = () => {
    return { type: types.TOGGLE_PICKUP };
};
