import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { useWindowSize } from '@hooks';
import { ThinArrowRightIcon } from '@assets';
import { AllergensIcon } from '@assets';

export const CardContainer = styled.div`
    ${tw`flex w-full md:h-auto md:w-1/4 border-whiteSmoke border-b-2 md:border-none`}
`;
export const CardContentOverflow = styled.div`
    @media (min-width: 768px) {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        transition: all 0.1s ease-in-out;

        &::before {
            content: '';
            display: flex;
            padding-bottom: 100%;
        }

        &:hover {
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
            transform: scale(1.02);
        }
    }
    ${tw`
        flex
        relative
        md:p-6
        h-20
        md:h-auto
    `}
`;
export const CardContent = styled.div`
    ${tw`w-full flex-row justify-between
        flex
        pl-3
        pr-7
        md:p-6
        md:absolute
        md:inset-0
        md:justify-center
        items-center
    `}
`;
export const AllergenContent = styled.div`
    ${tw`
        absolute
        text-primaryColor
        text-xs
        px-4
        py-1
        bg-beige
        hidden
        md:flex
        items-center
    `}
    border-radius: 30px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    top: 3.5rem;
    left: 5rem;
    @media (min-width: 768px) {
        top: 1rem;
        left: 1rem;
    }
`;
export const AllergenContentMobile = styled.div`
    ${tw`
        block
        md:hidden
        text-primaryColor
        px-4
        py-1
        bg-beige
        flex
        items-center
        ml-3
    `}
    border-radius: 30px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    top: 3.5rem;
    font-size: 11px;
    left: 5rem;
    @media (min-width: 768px) {
        top: 1rem;
        left: 1rem;
    }
`;

export const StyledArrowRightIcon = styled(ThinArrowRightIcon)`
    ${tw`h-auto`}
`;

const PhotoCard = ({ target, item, image, withUrl = false, allergens }) => {
    const viewPort = useWindowSize().width;
    const breakpoint = 768;
    const isMobile = viewPort < breakpoint;
    const imageUrl = process.env.REACT_APP_IMAGE_PATH || 'https://olo-images-live.imgix.net/';
    if (!withUrl) {
        image = `${imageUrl}${image}`;
    }
    return (
        <CardContainer>
            <Link to={target} className="w-full md:p-3">
                <CardContentOverflow>
                    <CardContent>
                        {allergens && allergens.length > 0 && (
                            <AllergenContent>
                                <AllergensIcon className="mr-1" />
                                contains&nbsp;
                                {allergens.map((a, i, arr) => (
                                    <span key={i}>
                                        <span>{a}</span>
                                        {arr.length - 1 != i && <span>,&nbsp;</span>}
                                    </span>
                                ))}
                            </AllergenContent>
                        )}
                        <div className="w-full flex flex-row md:flex-col justify-start md:justify-center items-center content-center">
                            {image && (
                                <img
                                    src={image}
                                    alt={item.name}
                                    className="w-20 h-auto px-2 md:w-full md:block md:mx-auto"
                                />
                            )}
                            <div
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '100%',
                                }}
                                className={classNames('font-veneer', 'font-bold', 'text-xl', 'text-center', {
                                    'md:mt-4': image,
                                })}
                            >
                                {item.name}
                            </div>
                            {allergens && allergens.length > 0 && (
                                <AllergenContentMobile>
                                    <AllergensIcon className="mr-1" />
                                    {allergens.map((a, i, arr) => (
                                        <span key={i}>
                                            <span>{a}</span>
                                            {arr.length - 1 != i && <span>,&nbsp;</span>}
                                        </span>
                                    ))}
                                </AllergenContentMobile>
                            )}
                            {/* <div>{item.id}</div> */}
                        </div>

                        {isMobile && <StyledArrowRightIcon />}
                    </CardContent>
                </CardContentOverflow>
            </Link>
        </CardContainer>
    );
};

export default PhotoCard;
