import React from 'react';
import { StyledPageLoadingCtn } from './styles/index';
import loaderAnimation from '../../../assets/gif/whiteLoading.gif';

export const PageLoading = ({ inner }) => {
    return (
        <StyledPageLoadingCtn className={`${inner ? 'py-8 lg:py-16' : 'h-screen'}`}>
            <img src={loaderAnimation} alt={'page loading animation'} />
        </StyledPageLoadingCtn>
    );
};
