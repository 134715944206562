import React from 'react';
import PropTypes from 'prop-types';
import { Text, Button } from '@components';
import { useHistory, Link } from 'react-router-dom';

const NotLoggedIn = (props) => {
    const history = useHistory();

    return (
        <div className="inline mx-auto mt-7 mb-25 lg:mb-90 lg:mt-16 px-4">
            <div className="inline">
                <Text type="smLT" className="lg:text-lg">
                    <Link
                        onClick={() => history.push('/auth/login')}
                        className="text-primaryColor font-bold underline"
                        size="small"
                        type="quinternaryLowerCase"
                    >
                        Login
                    </Link>{' '}
                    or{' '}
                    <Link
                        onClick={() => history.push('/auth/create-account')}
                        className="text-primaryColor font-bold underline"
                        size="small"
                        type="quinternaryLowerCase"
                    >
                        sign up
                    </Link>{' '}
                    to access your favorites and save new ones.
                </Text>
            </div>
            <Button
                onClick={() => history.push('/auth/login')}
                className="w-full m-auto mt-7 rounded-sm"
                type="primary"
            >
                Log In
            </Button>
        </div>
    );
};

export default NotLoggedIn;
