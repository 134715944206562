import React from 'react';
import MainContainer from '../components/MainContainer';

import { BrowserRouter as Router, Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import CreateAccount from './pages/createAccount';
import Login from './pages/login';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';

const Auth = () => {
    let { path } = useRouteMatch();

    return (
        <MainContainer>
            <Switch>
                <Route path={`${path}/login`} component={Login} />
                <Route path={`${path}/forgot-password`} component={ForgotPassword} />
                <Route path={`${path}/create-account`} component={CreateAccount} />
                <Route path={`${path}/reset-password`} component={ResetPassword} />
                <Route path={`${path}/*`}>
                    <Redirect to="/" />
                </Route>
            </Switch>
        </MainContainer>
    );
};

export default Auth;
