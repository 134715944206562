import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'tailwind.macro';

import { validationSchema } from './schema';

const StyledInputCtn = styled.div`
    ${tw`flex items-center w-full bg-white`}
    border-radius: 5px;
    height: 52px;
    &.validate-error {
        ${tw`border-status-error`}
    }
    &.validate-success {
        ${tw`border-status-success`}
    }
`;

const StyledInput = styled.input`
    ${tw`text-black text-base font-futuraLT outline-none h-full w-full`}
    border-radius: 5px;
    text-indent: 10px;
    line-height: 22px;
    &:placeholder {
        color: rgba(0, 0, 0, 0.5);
        opacity: 0.7;
    }
`;

const TextInput = ({
    Icon,
    autoFocus,
    className,
    errorMessage,
    id,
    maxLength,
    minLength,
    name,
    onBlur,
    onChange,
    onClick,
    onFocus,
    onKeyPress,
    onKeyUp,
    pattern,
    placeholder,
    required,
    requiredMessage,
    state,
    type,
    value,
    children,
    style,
    withoutValidation,
    autocomplete
}) => {
    const [valid, setValid] = useState(true);

    const password = type === 'password' || type === 'confirmPassword';
    const error = Boolean(state === 'error' || !valid);
    const getType = () => (password ? 'password' : type);
    const getId = () => (id ? id : name);
    const isValid = (type, value) => validationSchema.isValidSync({ [type]: value });
    const isConfirmPasswordType = () =>
        type === 'confirmPassword' && typeof errorMessage === 'string' && errorMessage.length > 0;
    const validatedErrorClass = () => {
        if (required) return error;

        return Boolean(error && value);
    };
    const InputCtnClasses = classnames(className, 'border border-lightGray', {
        'validate-error': validatedErrorClass(),
        'validate-success': Boolean(valid && value),
    });

    const handleOnBlur = (e) => {
        if (onBlur) onBlur(e);
        if (!value && required) {
            return setValid(false);
        } else if (!value) {
            return;
        }
        if (isConfirmPasswordType()) {
            return setValid(false);
        }
        // Sets the type and value to check
        setValid(isValid(type, value));
    };

    const handleOnChange = (e) => {
        if (onChange) onChange(e);
        let valid = isValid(type, e.target.value);
        if (isConfirmPasswordType()) {
            valid = false;
        } else if (type === 'confirmPassword' && !errorMessage) {
            valid = true;
        }

        setValid(valid);
    };

    const getErrorMessage = () => {
        if (required && requiredMessage && !value) {
            return requiredMessage;
        } else if (value && !valid) {
            return errorMessage;
        }
    };

    return (
        <>
            {withoutValidation ? (
                <div>
                    <StyledInputCtn className="border border-lightGray" style={style}>
                        <StyledInput
                            autoComplete="false"
                            id={getId()}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder={placeholder}
                            value={value}
                            type={getType()}
                        />
                    </StyledInputCtn>
                    {error && <div className="mt-2 text-status-error">{getErrorMessage()}</div>}
                </div>
            ) : (
                <div>
                    <StyledInputCtn className={InputCtnClasses} style={style}>
                        <StyledInput
                            autoComplete="false"
                            autoFocus={autoFocus}
                            id={getId()}
                            maxLength={maxLength}
                            minLength={minLength}
                            name={name}
                            onBlur={handleOnBlur}
                            onChange={handleOnChange}
                            onFocus={onFocus}
                            onKeyPress={onKeyPress}
                            onKeyUp={onKeyUp}
                            pattern={pattern}
                            placeholder={placeholder}
                            required={required}
                            type={getType()}
                            value={value}
                            autocomplete={autocomplete}
                        />
                        {children && (
                            <div className="mx-3 cursor-pointer" onClick={onClick}>
                                {children}
                            </div>
                        )}
                    </StyledInputCtn>
                    {error && <div className="mt-2 text-status-error">{getErrorMessage()}</div>}
                </div>
            )}
        </>
    );
};

TextInput.propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
    pattern: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    state: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.any,
};

export default TextInput;
