import React from 'react';
import { StyledUserInfoDetails, StyledUserName, StyledUserEmail, StyledUserPhone } from '../../styles/profile-index';

export const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
};

export const UserInfoDetails = ({ userName, emailAddress, phoneNumber }) => {
    return (
        <>
            <StyledUserInfoDetails>
                <StyledUserName>{userName}</StyledUserName>
                <StyledUserEmail>{emailAddress}</StyledUserEmail>
                <StyledUserPhone>{formatPhoneNumber(phoneNumber)}</StyledUserPhone>
            </StyledUserInfoDetails>
        </>
    );
};
