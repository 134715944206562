import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { LandingPage, MenuContainer as Menu, Auth, Checkout, Account, NotFound } from '@containers';
import { ContactUs } from 'components/GlobalComponents/Footer/components/ContactUs';
import { PrivacyPolicy } from 'components/GlobalComponents/Footer/components/PrivacyPolicy';
import { TrackingOptOutGuide } from 'components/GlobalComponents/Footer/components/TrackingOptOut';
import { UserAgreement } from 'components/GlobalComponents/Footer/components/UserAgreement';
import PrivateRoute from '@utils/PrivateRoute';
import { createBrowserHistory } from 'history';

const Routes = () => {
    const history = createBrowserHistory();
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route path="/order/:location/menu" component={Menu} />
                <Route path="/auth" component={Auth} />
                <Route path="/checkout" component={Checkout} />
                <PrivateRoute path="/account">
                    <Account></Account>
                </PrivateRoute>
                <Route path="/contactus" component={ContactUs} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/user-agreement" component={UserAgreement} />
                <Route path="/online-tracking-opt-out-guide" component={TrackingOptOutGuide} />
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
};

export default Routes;
