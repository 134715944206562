import React from 'react';
import PropTypes from 'prop-types';
import { Text, Button, GenericContainerNarrow } from '@components';
import { Link, useParams } from 'react-router-dom';
import MainContainer from '../components/MainContainer';

const NotFound = (props) => {
    return (
        <MainContainer>
            <GenericContainerNarrow>
                <div className="text-center font-veneer text-4xl w-full mt-12">Page not found</div>
                <div className="text-center font-veneer text-lg w-full text-primaryColor underline cursor-pointer">
                    <Link to="/">Return home</Link>
                </div>
            </GenericContainerNarrow>
        </MainContainer>
    );
};

export default NotFound;
