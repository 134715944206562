import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@components';
import { CheckoutHeadlineIconRow, CheckoutRedHeading, StyledCreditCardIcon } from '../styles/index';
import { CardToggle } from './CardToggle';

const StyledCardElement = styled.div`
    height: 0px;
    visibility: hidden;
`;

export const UserPaymentInfo = ({ cards, onClick }) => {
    const defaultCardIndex = cards.findIndex((card) => card.isdefault);
    const [active, setActive] = useState(defaultCardIndex);

    return (
        <div className="flex flex-col">
            <CheckoutHeadlineIconRow>
                <div className="flex flex-row content-center items-center self-center">
                    <StyledCreditCardIcon />
                    <CheckoutRedHeading>Payment Info</CheckoutRedHeading>
                </div>
            </CheckoutHeadlineIconRow>
            {/* the CC iframe elements are still needed but we hide them from the view */}
            <StyledCardElement id="paymentCC" className="paymentCC" />
            <StyledCardElement id="paymentCVV" className="paymentCVV" />
            {cards.length
                ? cards.map((card, index) => (
                      <CardToggle {...{ active, setActive, card, index }} onClick={onClick} key={index} />
                  ))
                : null}
        </div>
    );
};
