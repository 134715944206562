import React from 'react';

import getStyles from '@global/helpers/getStyles';
import { TextInput, Label, Tooltip } from '@components';
import { RedQuestionIcon as Question } from '@assets';

const GuestContactForm = ({ formik }) => {
    return (
        <div className="w-full mt-5 lg:mt-7 flex flex-col content-center justify-center">
            <Label label="First Name" type="primary">
                <TextInput
                    className="mt-1"
                    type="text"
                    name="contactFirstName"
                    withoutValidation
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contactFirstName}
                    style={getStyles(formik, 'contactFirstName')}
                    maxLength={36}
                />
                {formik.errors.contactFirstName && formik.touched.contactFirstName ? (
                    <div className="mt-1 text-status-error">
                        {formik.errors.contactFirstName}
                    </div>
                ) : null}
            </Label>
            <Label label="Last Name" type="primary">
                <TextInput
                    className="mt-1"
                    type="text"
                    name="contactLastName"
                    withoutValidation
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contactLastName}
                    style={getStyles(formik, 'contactLastName')}
                    maxLength={36}
                />
                {formik.errors.contactLastName && formik.touched.contactLastName ? (
                    <div className="mt-1 text-status-error">
                        {formik.errors.contactLastName}
                    </div>
                ) : null}
            </Label>
            <Label label="Email Address" type="primary">
                <TextInput
                    className="mt-1"
                    type="email"
                    name="contactEmail"
                    withoutValidation
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contactEmail}
                    style={getStyles(formik, 'contactEmail')}
                    maxLength={128}
                />
                {formik.errors.contactEmail && formik.touched.contactEmail ? (
                    <div className="mt-1 text-status-error">
                        {formik.errors.contactEmail}
                    </div>
                ) : null}
            </Label>
            <Label label="Phone Number" type="primary">
                <Tooltip content="Contact number is required only in case of issues regarding your order (e.g. items out of stock)." delay={250}>
                    <Question className="cursor-pointer ml-2" />
                </Tooltip>
                <TextInput
                    className="mt-1"
                    type="text"
                    name="contactPhone"
                    withoutValidation
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contactPhone || ''}
                    style={getStyles(formik, 'contactPhone')}
                    maxLength={24}
                />
                {formik.errors.contactPhone && formik.touched.contactPhone ? (
                    <div className="mt-1 text-status-error">
                        {formik.errors.contactPhone}
                    </div>
                ) : null}
            </Label>
        </div>
    );
};

export default GuestContactForm;
