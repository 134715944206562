import React from 'react';
// import PropTypes from 'prop-types';
import { Text } from '@components';
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from '@assets';

export const ToggleCommPrefsCheckboxes = ({ upsell, setUpsell, optin, setOptin, formik }) => {
    const toggleUpsell = () => {
        if(typeof upsell == 'boolean'){
            setUpsell(!upsell);
        }else{
            if(upsell == 'false'){
                setUpsell(true);
            }else{
                setUpsell(false);
            }
        }
    };

    const toggleEmails = () => {
        if(typeof optin == 'boolean'){
            setOptin(!optin);
        }else{
            if(optin == 'false'){
                setOptin(true);
            }else{
                setOptin(false);
            }
        }
    };

    return (
        <>
            <div className="w-full flex flex-col">
                <div
                    className="flex flex-row items-center py-2 cursor-pointer"
                    onClick={toggleEmails}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.optin}
                >

                    {(optin && optin != 'false') ? (
                        <span>
                            <CheckboxCheckedIcon></CheckboxCheckedIcon>
                        </span>
                    ) : (
                        <span>
                            <CheckboxUncheckedIcon></CheckboxUncheckedIcon>
                        </span>
                    )}
                    <Text className="font-futura leading-4 text-xs px-2 text-black text-opacity-75">
                        Send me emails with special deals and rewards
                    </Text>
                </div>
                {/* <div
                    className="flex flex-row items-center py-0.5 cursor-pointer"
                    onClick={toggleUpsell}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.upsell}
                >
                    {upsell ? (
                        <span>
                            <CheckboxCheckedIcon></CheckboxCheckedIcon>
                        </span>
                    ) : (
                        <span>
                            <CheckboxUncheckedIcon></CheckboxUncheckedIcon>
                        </span>
                    )}
                    <Text className="font-futura flex flex-row flex-wrap leading-4 text-xs pl-2">
                        Show suggestions when ordering
                    </Text>
                </div> */}
            </div>
        </>
    );
};

// ToggleCommPrefsCheckboxes.propTypes = {
//     upsell: PropTypes.bool,
//     setUpsell: PropTypes.func,
//     optin: PropTypes.bool,
//     setOptin: PropTypes.func,
//     error: PropTypes.bool,
// };
