import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { HeartFavoritesIcon, HeartOutlineRedIcon } from '@assets';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

// import PropTypes from 'prop-types';

export const StyledFaveIcon = styled(HeartFavoritesIcon)`
    width: 15.75px;
    height: 15px;
    ${tw`mr-1 z-10 cursor-pointer`}
`;
export const StyledEmptyFaveIcon = styled(HeartOutlineRedIcon)`
    width: 15.75px;
    height: 15px;
    ${tw`mr-1 z-10 cursor-pointer`}
`;

const ToggleAddFavorite = ({ isFavorite, setFavorite, children }) => {
    const {loggedIn } = useSelector((state) => state.authData);
    return (
        <>
        <ReactTooltip />
        {loggedIn ?
            <div onClick={setFavorite} className="flex items-center cursor-pointer">
                {isFavorite ? (
                    <span>
                        <StyledFaveIcon />
                    </span>
                ) : (
                    <span>
                        <StyledEmptyFaveIcon />
                    </span>
                )}
                {children}
            </div>
        :
            <div data-tip="You must be logged-in to use this feature" className="flex items-center cursor-pointer">
                {isFavorite ? (
                    <span>
                        <StyledFaveIcon />
                    </span>
                ) : (
                    <span>
                        <StyledEmptyFaveIcon />
                    </span>
                )}
                {children}
            </div>
        }
        </>
    );
};

export default ToggleAddFavorite;
