import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import tw from 'twin.macro';
import classNames from 'classnames';
import { RadioIcon, RadioCheckedIcon } from '@assets';
import find from 'lodash/find';

export const CardContainer = styled.div`
    ${tw`
        col-span-2
    `}
    @media (max-width: 767px) {
        ${tw`
	        col-span-6
	    `}
    }
`;
export const CardImageContainer = styled.div`
    flex: 1;
    ${tw`
        col-span-1
        h-full
        relative
    `}
    @media (max-width: 767px) {
        ${tw`
	        col-span-6
	    `}
    }
`;
export const CardImageOverflow = styled.div`
    &::before {
        content: '';
        display: block;
        padding-bottom: 61%;
        background-color: #f0f0f0;
    }
    ${tw`
        relative
    `}
    @media (max-width: 767px) {
        ${tw`
	        hidden
	    `}
    }
`;
export const CardContentImageContainer = styled.div`
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    cursor: pointer;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: all 0.1s ease-in-out;
    @media (max-width: 767px) {
        box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px rgba(0, 0, 0, 0.1);
    }
    &:hover {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
        transform: scale(1.02);
    }
    ${tw`

    `}
    ${({ selected }) =>
        selected &&
        `
        &:hover{
            box-shadow:  0px 2px 7px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px rgba(0,0,0,1);
            transform: scale(1.02);
        }
		box-shadow:  0px 2px 7px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px rgba(0,0,0,1);
	`}
`;
export const CardContentImage = styled.div`
    ${tw`
        p-4
        font-bold
        relative
        flex
        justify-between
        content-center
        items-center
        flex-1

    `}
`;
export const CardImageIcon = styled.div`
    width: 45px;
    height: 45px;
    border-radius: 45px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${(props) => `url('${props.imgSrc}')`};
    ${tw`
        mr-2
        hidden
    `}
    @media (max-width: 767px) {
        ${tw`
	        inline-block
	    `}
    }
`;
export const ImageContainer = styled.div`
    ${tw`
        absolute
        top-0
    `}
`;

export const CardContentOverflow = styled.div`
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    &:hover {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
        transform: scale(1.02);
    }
    @media (max-width: 767px) {
        box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px rgba(0, 0, 0, 0.1);
        ${({ selected }) =>
            selected &&
            `
    		box-shadow:  0px 2px 7px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px rgba(0,0,0,1);
    	`}
        ${tw`
            text-sm
        `}
    }
    ${tw`
        p-4
        font-bold
        relative
        flex
        justify-between

    `}
    ${({ selected }) =>
        selected &&
        `
        &:hover{
            box-shadow:  0px 2px 7px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px rgba(0,0,0,1);
            transform: scale(1.02);
        }
		box-shadow:  0px 2px 7px rgba(0, 0, 0, 0.15), 0px 0px 0px 2px rgba(0,0,0,1);
	`}
`;

const MenuItemCard = ({ onClick, option, selected, hasImages, isDefault, preSelectOption }) => {
    let ImageRender = null;
    let imageData = null;
    let [isInitialValueRendered, setIsInitialValueRendered] = useState(false);
    let [selectedStatus, setSelectedStatus] = useState(false);

    if (hasImages) {
        imageData = find(option.metadata, { key: 'img' });
        //Account for capitalized key
        if (!imageData) {
            imageData = find(option.metadata, { key: 'IMG' });
        }
    }

    useEffect(()=>{
        if(!isInitialValueRendered && isDefault){
            preSelectOption();
        }
        setIsInitialValueRendered(true);
    },[])

    return (
        <>
            {hasImages ? (
                <CardImageContainer>
                    <CardContentImageContainer selected={selected} onClick={onClick}>
                        <CardImageOverflow>
                            {/* Account for cards without images. We only check to see if it's an image card based off first option. Can break if first option doesn't have an image but subsuquent ones do */}
                            {imageData && (
                                <ImageContainer>
                                    <img src={imageData.value} alt="" />
                                </ImageContainer>
                            )}
                        </CardImageOverflow>
                        <CardContentImage
                            className={`

            					`}
                        >
                            <div className={`pr-3 items-center flex`}>
                                <CardImageIcon imgSrc={imageData ? imageData.value : ''} />
                                <div>
                                    <span className={'block'}>{option.name}</span>
                                    {(option.basecal || (option.cost != 0 && option.cost != null)) && (
                                        <span className={'text-sm text-darkGray font-normal mr-3'}>
                                            {option.basecal && <span>{option.basecal}</span>}
                                            {option.basecal && option.cost != 0 && option.cost != null && (
                                                <span>/</span>
                                            )}
                                            {option.cost != 0 && option.cost != null && (
                                                <span>${Number(option.cost).toFixed(2)}</span>
                                            )}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div style={{ width: '25px' }}>
                                {selected ? (
                                    <RadioCheckedIcon
                                        height={25}
                                        width={25}
                                        className={'inline-block'}
                                    ></RadioCheckedIcon>
                                ) : (
                                    <RadioIcon height={25} width={25} className={'inline-block'}></RadioIcon>
                                )}
                            </div>
                        </CardContentImage>
                    </CardContentImageContainer>
                </CardImageContainer>
            ) : (
                <CardContainer>
                    <CardContentOverflow
                        onClick={onClick}
                        selected={selected}
                        className={`

        					`}
                    >
                        <div>{option.name}</div>
                        <div>
                            {(option.basecal || (option.cost != 0 && option.cost != null)) && (
                                <span className={'text-sm text-darkGray font-normal mr-3'}>
                                    {option.basecal && <span>{option.basecal}</span>}
                                    {option.basecal && option.cost != 0 && option.cost != null && <span>/</span>}
                                    {option.cost != 0 && option.cost != null && (
                                        <span>${Number(option.cost).toFixed(2)}</span>
                                    )}
                                </span>
                            )}
                            {selected ? (
                                <RadioCheckedIcon className={'inline-block'}></RadioCheckedIcon>
                            ) : (
                                <RadioIcon className={'inline-block'}></RadioIcon>
                            )}
                        </div>
                    </CardContentOverflow>
                </CardContainer>
            )}
        </>
    );
};

export default MenuItemCard;
