import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import locationIcon from '@assets/icons/location-black.svg';
import { useSelector, useDispatch } from 'react-redux';

const PickUpCtn = styled.div`
    ${tw`text-darkGray border-beige border-l pl-4 text-sm font-futura leading-3`}
`;

const StyledLocation = styled.div`
    ${tw`w-full uppercase font-veneer text-black text-sm lg:text-base leading-4`}
`;

const PickupLocationSelected = ({ selectedLocation }) => {
    const { isPickup } = useSelector((state) => state.restaurantData);

    return (

    <PickUpCtn>
        <span className="text-xs lg:text-sm">{isPickup ? 'Pickup at' : 'Delivery from'}</span>
        <div className="flex items-center lg:py-0.5">
            <img className="h-5 w-4" src={locationIcon} />
            <StyledLocation>{selectedLocation}</StyledLocation>
        </div>
    </PickUpCtn>
)};

export default PickupLocationSelected;
