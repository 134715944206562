import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const StyledToggleCtn = styled.div`
    ${tw`bg-white w-full`}
    border-radius: 28px;

    max-width: 444px;
    box-shadow: 1px 3px 4px -1px rgba(0, 0, 0, 0.15);
    .btn {
        ${tw`
            inline-block
            uppercase
            font-veneer
            p-2
            text-center
            text-2xl
            leading-8
        `}
    }

    input[type='radio'].toggle {
        display: none;
        & + label {
            ${tw`cursor-pointer w-1/2 text-lightGray`}
        }
        &.toggle-left + label {
            &:after {
                left: 100%;
            }
        }

    &.toggle-right + label {
        &:after {
            left: -100%;
        }
    }

    &:checked + label {
        ${tw`
            bg-black
            text-white
            w-1/2
            cursor-pointer
        `}
        border-radius: 28px;
        border: 1px solid white;
        &:after {
            left: 0;
        }
    }
`;

const PickUpDeliveryToggle = ({ dispatch, className, setPickupOrDelivery, isPickup }) => (
    <StyledToggleCtn className={className}>
        <input
            id="toggle-on"
            className="toggle toggle-left"
            name="toggle"
            value="false"
            type="radio"
            defaultChecked={isPickup}
            onClick={() => {
                if(!isPickup){
                    dispatch(setPickupOrDelivery())
                }
            }}
        />
        <label htmlFor="toggle-on" className="btn">
            Pickup
        </label>
        <input
            id="toggle-off"
            className="toggle toggle-right"
            name="toggle"
            value="true"
            type="radio"
            defaultChecked={!isPickup}
            onClick={() => {
                if(isPickup){
                    dispatch(setPickupOrDelivery())
                }
            }}
        />
        <label htmlFor="toggle-off" className="btn">
            Delivery
        </label>
    </StyledToggleCtn>
);

export default PickUpDeliveryToggle;
