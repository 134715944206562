import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Theme from '../../global/theme/Theme';
import GlobalStyles from '../../global/theme/GlobalStyles';
import { NavBar, Footer } from '@components';
import Cart from '@components/Cart/cart';
import { getRestaurants } from '@store/actions';
import styled from 'styled-components';
import { PageLoading } from '../../components/GlobalComponents/Loading/PageLoading';
import { Transition } from 'react-spring/renderprops';

const StyledCtn = styled.div`
    min-height: calc(100vh - (75px + 67px));
`;

const MainContainer = ({ children }) => {
    //NOTE: Creating state for PageLoading Transition
    const [show, setShow] = useState(false);

    const { restaurants, loading, error } = useSelector((state) => state.rootData);
    const dispatch = useDispatch();

    useEffect(() => {
        if (restaurants.length === 0) {
            dispatch(getRestaurants());
        }
    }, [restaurants]);

    return (
        <Theme>
            <GlobalStyles />
            <NavBar />
            {loading || (!restaurants && error === false) ? (
                <Transition
                    items={show}
                    from={{
                        opacity: 1,
                    }}
                    enter={{
                        opacity: 1,
                    }}
                    leave={{
                        opacity: 0,
                    }}
                    config={{
                        duration: 300,
                    }}
                >
                    {(show) =>
                        !show &&
                        ((props) => (
                            <div style={props}>
                                <PageLoading />
                            </div>
                        ))
                    }
                </Transition>
            ) : (
                <>
                    {error ? (
                        <div>Error</div>
                    ) : (
                        <>
                            <StyledCtn>{children}</StyledCtn>
                        </>
                    )}
                </>
            )}
            <Footer />
            <Cart></Cart>
        </Theme>
    );
};

export default MainContainer;
