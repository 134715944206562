import MainContainer from 'containers/components/MainContainer';
import React from 'react';
import GenericContainerNarrow from '../../GenericContainerNarrow';
import {
    StyledHeroCtn,
    StyledHeroHeading,
    StyledSubHeading,
    StyledDateCtn,
    StyledDate,
    StyledMessageCtn,
    StyledSmallText,
    StyledInnerText,
    StyledBoldWords,
    StyledSmallRedText,
    StyledListCtn,
    StyledListItem,
} from './styles/index';

export const PrivacyPolicy = () => {
    //href for the StyledSmallRedText links throughout document, added target="_blank" & rel="noopener noreferrer"
    const href = '';
    return (
        <>
            <MainContainer>
                <StyledHeroCtn>
                    <StyledHeroHeading>Privacy Policy</StyledHeroHeading>
                </StyledHeroCtn>
                <GenericContainerNarrow className={'px-4 py-7 lg:py-12'}>
                    <StyledMessageCtn>
                        <StyledDateCtn>
                            <StyledDate>Last updated: 10 December 2020</StyledDate>
                        </StyledDateCtn>
                        <StyledSmallText>
                            Olo Inc. ("<StyledBoldWords>Olo</StyledBoldWords>") and the company whose branding is
                            displayed on the website ("<StyledBoldWords>Site</StyledBoldWords>") and/or mobile
                            application ("
                            <StyledBoldWords>Application</StyledBoldWords>") that links to this Privacy Policy (the "
                            <StyledBoldWords>Branded Company</StyledBoldWords>") respect the privacy of Site and
                            Application (collectively, "<StyledBoldWords>Services</StyledBoldWords>") users.
                            <div className="py-2">
                                This Privacy Policy is intended to inform you of the policies and practices regarding
                                the collection, use and disclosure of any personal information that Olo collects from or
                                about users in connection with the Services. Olo provides the Services to users on
                                behalf of the Branded Company, as a service provider and data processor. This Privacy
                                Policy does not apply to any websites, mobile applications or other online services that
                                do not link to this policy, including other services provided by the Branded Company. To
                                the extent Olo shares personal information with the Branded Company as this Privacy
                                Policy describes, this Privacy Policy does not apply to the Branded Company's subsequent
                                use of that information.
                            </div>
                        </StyledSmallText>
                        <StyledSubHeading>INTERNATIONAL DATA TRANSFERS AND PRIVACY SHIELD</StyledSubHeading>
                        <StyledSmallText>
                            If you are a non-U.S. user of the Services, by using the Services and providing us with
                            data, you acknowledge and agree that your personal information may be processed outside the
                            country in which it was collected, including the United States, where laws regarding
                            processing of personal information may be less stringent than the laws in your country. By
                            providing your data, you acknowledge such transfer.
                        </StyledSmallText>
                        <StyledSmallText>
                            Olo complies with the EU-US and Swiss-US Privacy Shield Frameworks as set forth by the US
                            Department of Commerce regarding the collection, use, and retention of personal information
                            from the EU and/or Switzerland. Please visit our{' '}
                            <StyledSmallRedText
                                href={'https://www.olo.com/privacy-shield/'}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Shield Statement{' '}
                            </StyledSmallRedText>
                            for more information on our Privacy Shield certification.
                        </StyledSmallText>
                        <StyledSubHeading>THE TYPES OF INFORMATION OLO COLLECTS</StyledSubHeading>
                        <StyledSmallText>
                            Olo collects information from you in various ways when you access or use the Services. Olo
                            collect personal information from you in the following ways:
                        </StyledSmallText>
                        <StyledListCtn>
                            (a) Personal Information You Provide to Us. For example:
                            <StyledListItem>
                                Olo may collect personal information from you, such as your first and last name,
                                address, e-mail, telephone number and when you create an account ("
                                <StyledBoldWords>Account</StyledBoldWords>").
                            </StyledListItem>
                            <StyledListItem>
                                The Services allow you to store preferences such as your location, recent orders and
                                favorite menu items in order to make it more convenient for you to place additional
                                orders in the future.
                            </StyledListItem>
                            <StyledListItem>
                                When you order menu items from the Branded Company on our Services, Olo will collect all
                                information necessary to complete the transaction, including your name and payment
                                information. Olo may store this information on our servers, in order to make it easier
                                for you to place orders for the Branded Company's menu items in the future. Olo does not
                                store your payment information on our servers, but stores a secure "token" that helps
                                facilitate a secure payment transaction on the Branded Company Services.
                            </StyledListItem>
                            <StyledListItem>
                                If you provide feedback or contact Olo via email, Olo will collect your name and email
                                address, as well as any other content included in the email, in order to send you a
                                reply.
                            </StyledListItem>
                            <StyledListItem>
                                Olo also collects other types of personal information that you provide voluntarily, such
                                as any information requested by Olo if you contact Olo via email regarding support for
                                the Services.
                            </StyledListItem>
                        </StyledListCtn>
                        <StyledListCtn>
                            (b) Personal Information Collected Via Technology. Olo collects certain information from you
                            automatically when you access and use the Services as described below:
                            <StyledListItem>
                                Information Collected by our Servers. To make the Services more useful to you, Olo
                                servers (which may be hosted by a third party service provider) collect information from
                                you, including the referring/exit pages, your browser type, domain name, date/time stamp
                                for your visit and your Internet Protocol (IP) address (a number that is automatically
                                assigned to your computer or device when you use the Internet). Olo uses this
                                information to analyze trends, administer the Services, monitor for fraudulent
                                activities using fraud management tools, and better tailor the Services to users' needs.
                            </StyledListItem>
                            <StyledListItem>
                                Cookies. Like many online services, Olo uses cookies to collect information on the
                                Sites. "Cookies" are small pieces of information that a web site sends to your
                                computer's hard drive while you are viewing a web site. Olo may use both session cookies
                                (which expire once you close your web browser) and persistent cookies (which stay on
                                your computer until you delete them) to provide you with a more personal and interactive
                                experience on the Sites. This type of information is collected to make the Services more
                                useful to you and to tailor your experience with the Services to meet your special
                                interests and needs.
                            </StyledListItem>
                            <StyledListItem>
                                Mobile Services. We may also collect information from your mobile device if you have
                                downloaded a Branded Company's Application. This information is generally used to help
                                deliver the most relevant information to you. Examples of information that may be
                                collected and used include your general geographic location, how you use the
                                Application(s), and information about the type of device you use. In addition, in the
                                event the Application crashes your mobile device, Olo will receive information about
                                your mobile device model, software version and device carrier, to identify and fix bugs
                                and otherwise improve the performance of the Application. This information is sent to
                                Olo as aggregated information. The aggregated information is not used to identify an
                                individual.
                            </StyledListItem>
                            <StyledListItem>
                                Third Party Analytics. Olo uses third party service providers such as Google Analytics
                                to help analyze how users use the Services ("Analytics Companies"). These Analytics
                                Companies use Cookies and other tracking technologies to collect information such as how
                                often users visit the Site, what pages they visit and for how long, what other sites
                                they used prior to coming to the Site, and other engagement data. Olo uses the
                                information we get from these Analytics Companies to evaluate and improve the Services.
                                For more information about Google Analytics or to prevent the storage and processing of
                                this data (including your IP address) by Google, you can download and install the
                                browser plug-in available at the following link:
                                <StyledSmallRedText
                                    target="_blank"
                                    href={'https://tools.google.com/dlpage/gaoptout?hl=en'}
                                    rel="noopener noreferrer"
                                >
                                    {' '}
                                    https://tools.google.com/dlpage/gaoptout?hl=en
                                </StyledSmallRedText>
                                . You can also obtain additional information on Google Analytics' data privacy and
                                security at the following links:
                                <StyledListCtn>
                                    <StyledListItem>
                                        <StyledSmallRedText
                                            target="_blank"
                                            href={'https://policies.google.com/technologies/partner-sites'}
                                            rel="noopener noreferrer"
                                        >
                                            https://policies.google.com/technologies/partner-sites
                                        </StyledSmallRedText>
                                    </StyledListItem>
                                    <StyledListItem>
                                        <StyledSmallRedText
                                            target="_blank"
                                            href={'https://support.google.com/analytics/topic/2919631'}
                                            rel="noopener noreferrer"
                                        >
                                            https://support.google.com/analytics/topic/2919631{' '}
                                        </StyledSmallRedText>
                                    </StyledListItem>
                                </StyledListCtn>
                            </StyledListItem>
                        </StyledListCtn>
                        <StyledListCtn>
                            (c) Facebook and other Social Networking Services. The Services may allow users to access
                            Facebook and other social networking sites (each, an "SNS") to interact with and share their
                            experiences with the Branded Company's products and services with their friends. If you are
                            not a registered user of the Services, you can create an Account by clicking on "Sign In"
                            using Facebook or another SNS that we support, and then entering your SNS login credentials.
                            In this case, Olo may receive information from Facebook or another SNS to make it easier for
                            you to create an Account on the Services. Once you register on the Services and connect with
                            Facebook or another SNS, you will be able to automatically post recent activity back to
                            Facebook or the other SNS. The information that your Facebook or other SNS account provides
                            to Olo may depend on the privacy settings you have with that SNS, so please consult the
                            SNS's privacy and data practices.
                        </StyledListCtn>
                        <StyledListCtn>
                            (d) Location Information. If you have enabled location services on your phone and agree to
                            the collection of your location when prompted by the Services, Olo will collect your
                            location information when you use the Services; for example, to locate those restaurants and
                            locations of the Branded Company that are near you or when you "check in" via the Services.
                            If you do not want Olo to collect this information, you may decline the collection of your
                            location when prompted, or adjust the location services settings on your device.
                        </StyledListCtn>
                        <StyledSubHeading>HOW OLO USES YOUR INFORMATION</StyledSubHeading>
                        <StyledListCtn>
                            (a) General Use. In general, Olo uses your personal information to respond to your requests
                            as submitted through the Services, to provide you the Services you request, or to help serve
                            you better. Olo uses your personal information in the following ways:
                            <StyledListItem>
                                facilitate the creation of, and secure and maintain your Account;
                            </StyledListItem>
                            <StyledListItem>identify you as a legitimate user in Olo's system;</StyledListItem>
                            <StyledListItem>
                                provide improved administration of the Site, Application and Services; provide the
                                Services you request;
                            </StyledListItem>
                            <StyledListItem>
                                improve the quality of experience when you interact with the Site, Application and
                                Services;
                            </StyledListItem>
                            <StyledListItem>
                                send you a welcome e-mail to verify ownership of the e-mail address provided when your
                                Account was created;
                            </StyledListItem>
                            <StyledListItem>
                                send you administrative e-mail notifications, such as security or support and
                                maintenance advisories;
                            </StyledListItem>
                            <StyledListItem>
                                call or send you an SMS message about your order status or any issues with your order
                                (e.g., food being prepared); and,
                            </StyledListItem>
                            <StyledListItem>
                                send surveys, offers, and other promotional materials related to the Services at the
                                Branded Company's request or direction.
                            </StyledListItem>
                        </StyledListCtn>
                        <StyledListCtn>
                            (b) Creation of Non-Identifiable Data. Olo may create de-identified information records from
                            personal information by excluding certain information (such as your name) that makes the
                            information personally identifiable to you. Olo may use this information, in a form that
                            does not personally identify you to analyze request patterns and usage patterns to enhance
                            Olo's products and services. Olo reserves the right to use and disclose non-identifiable
                            information to third parties in its discretion.
                        </StyledListCtn>
                        <StyledSubHeading>DISCLOSURE OF YOUR PERSONAL INFORMATION</StyledSubHeading>
                        <StyledSmallText>
                            Olo discloses your personal information as described below and as described elsewhere in
                            this Privacy Policy.
                        </StyledSmallText>
                        <StyledListCtn>
                            (a) At the Direction of the Branded Company. Olo may share your information with the Branded
                            Company or with third parties at the direction of the Branded Company. For example, when you
                            use the Services to place orders, Olo will transmit your information to the Branded Company
                            location you designate, or to the Branded Company's third party services providers or
                            partners, as directed by the Branded Company, to facilitate your order. Other than as
                            described in this Privacy Policy in connection with the Services, this Privacy Policy does
                            not apply to the processing of your information by the Branded Company or third parties with
                            whom Olo shares information at the Branded Company's direction.
                        </StyledListCtn>
                        <StyledSmallText>
                            BECAUSE OLO DOES NOT CONTROL THE PRIVACY PRACTICES OF THE BRANDED COMPANY, YOU SHOULD READ
                            AND UNDERSTAND THE BRANDED COMPANY'S PRIVACY POLICIES.
                        </StyledSmallText>
                        <StyledListCtn>
                            (b) Third Party Service Providers. Olo may share your personal information with third party
                            service providers that perform services for or on behalf of Olo, for the purposes described
                            in this Privacy Policy, including: to provide you with the Services; to conduct quality
                            assurance testing; to facilitate the creation of Accounts; to optimize the performance of
                            the Services; to provide technical support; and/or to provide other services to Olo. Our
                            service providers may be located in the U.S., Canada or other foreign jurisdictions.
                        </StyledListCtn>
                        <StyledListCtn>
                            (c) Affiliates. Olo may share some or all of your personal information with its parent
                            company, subsidiaries, joint ventures, or other companies under common control
                            ("Affiliates"), in which case Olo will require its Affiliates to honor this Privacy Policy.
                        </StyledListCtn>
                        <StyledListCtn>
                            (d) Corporate Restructuring. Olo may share some or all of your personal information in
                            connection with or during negotiation of any merger, financing, acquisition or dissolution
                            transaction involving the sale, transfer, divestiture, or disclosure of all or a portion of
                            Olo's business or assets. In the event of an insolvency, bankruptcy, or receivership,
                            personal information may also be transferred as a business asset. If Olo's company, business
                            or assets is acquired by another company, that company will possess the personal information
                            collected by Olo and the company will assume the rights and obligations regarding your
                            personal information as described in this Privacy Policy.
                        </StyledListCtn>
                        <StyledListCtn>
                            (e) Social Networking Sites ("SNS"). Olo's Application and Services may enable you to post
                            content to SNSs (e.g. Facebook and Twitter). If you choose to do this, Olo will provide
                            information to such SNSs in accordance with your elections. You acknowledge and agree that
                            you are solely responsible for your use of those websites and that it is your responsibility
                            to review the terms of use and privacy policy of the third party provider of such SNSs. Olo
                            will not be liable for: (i) the availability or accuracy of such SNSs; (ii) the content,
                            products or services on or availability of such SNSs; or (iii) your use of any such SNSs.
                        </StyledListCtn>
                        <StyledListCtn>
                            (f) Other Disclosures. Regardless of any choices you make regarding your personal
                            information, Olo and its U.S. and other foreign service providers may disclose your personal
                            information if Olo believes in good faith that such disclosure is necessary (a) to comply
                            with relevant laws or to respond to subpoenas, warrants, or other legally valid inquiries or
                            orders served on Olo; (b) to protect and defend the rights or property of Olo, the Branded
                            Company or users of the Services; (c) in connection with a legal investigation; and/or (d)
                            to investigate or assist in preventing any violation or potential violation of the law, this
                            Privacy Policy, or Olo's Terms of Use, or as otherwise may be required or permitted by
                            applicable Canadian, U.S. or other law or legal process, which may include lawful access by
                            U.S. or foreign courts, law enforcement or other government authorities.
                        </StyledListCtn>
                        <StyledSubHeading>SAFEGUARDS AND RETENTION</StyledSubHeading>
                        <StyledSmallText>
                            We have implemented reasonable administrative, technical and physical measures in an effort
                            to safeguard the personal information in our custody and control against theft, loss and
                            unauthorized access, use, modification and disclosure. We restrict access to personal
                            information on a need-to-know basis to employees and authorized service providers who
                            require access to fulfil their job requirements.
                        </StyledSmallText>
                        <StyledSmallText>
                            We have record retention processes designed to retain personal information for no longer
                            than necessary for the purposes set out herein or as otherwise required to meet legal or
                            business requirements.
                        </StyledSmallText>
                        <StyledSubHeading>A NOTE ABOUT CHILDREN</StyledSubHeading>
                        <StyledSmallText>
                            The Services are not directed towards individuals under the age of 13, and Olo does not
                            intentionally gather personal information about visitors who are under the age of 13.
                            Furthermore, Olo does not intentionally allow visitors under the age of 18 to register for
                            the Services. If a child under 13 submits personal information to Olo and Olo learns that
                            the personal information is the information of a child under 13, Olo will attempt to delete
                            the information as soon as possible. If you believe that Olo might have any personal
                            information from a child under 13, please contact Olo at: One World Trade Center, 82nd
                            Floor, New York, NY 10007.
                        </StyledSmallText>
                        <StyledSubHeading>LINKS TO OTHER SITES</StyledSubHeading>
                        <StyledSmallText>
                            The Site may contain links to third party websites. When you click on a link to any other
                            website or location, you will leave the Site and go to another site and another entity may
                            collect personal and/or anonymous information from you. Olo's provision of a link to any
                            other website or location is for your convenience and does not signify Olo's endorsement of
                            such other website or location or its contents. Olo has no control over, does not review,
                            and cannot be responsible for, these outside websites or their content. Please be aware that
                            the terms of this Privacy Policy do not apply to these outside websites. We encourage you to
                            read the privacy policy of every website you visit.
                        </StyledSmallText>
                        <StyledSubHeading>YOUR CHOICES REGARDING YOUR INFORMATION</StyledSubHeading>
                        <StyledSmallText>
                            You have several choices regarding use of information on the Services
                        </StyledSmallText>
                        <StyledListCtn>
                            (a) Cookies. If you decide at any time that you no longer wish to accept cookies from the
                            Services for any of the purposes described above, then you can instruct your browser, by
                            changing its settings, to stop accepting cookies or to prompt you before accepting a cookie
                            from the websites you visit. Consult your browser's technical information. If you do not
                            accept cookies, however, you may not be able to use all or portions of the Services or all
                            functionality of the Services. If you have any questions about how to disable or modify
                            cookies, please let Olo know at the contact information provided below.
                        </StyledListCtn>
                        <StyledListCtn>
                            (b) De-Linking SNS. If you decide at any time that you no longer wish to have your SNS
                            account (e.g., Facebook or Twitter) linked to your Account, then you may close your account
                            with the Branded Company. Please refer to the privacy settings of the SNS to determine how
                            you may adjust our permissions and manage the interactivity between the Services and your
                            social media account or mobile device.
                        </StyledListCtn>
                        <StyledListCtn>
                            (c) Application. You can stop all collection of information by the Application by
                            uninstalling the Application. You may use the standard uninstall processes as may be
                            available as part of your mobile device or via the mobile application marketplace or
                            network.
                        </StyledListCtn>
                        <StyledListCtn>
                            (d) How We Respond to Do Not Track Signals. Some web browsers transmit "do not track"
                            signals to the websites and other online services with which your web browser
                            communications. There is currently no standard that governs what, if anything, websites
                            should do when they receive these signals. Olo currently does not take action in response to
                            these signals. If and when a standard is established, Olo may revise its policy on
                            responding to these signals.
                        </StyledListCtn>
                        <StyledListCtn>
                            (e) Access, Update, or Correct Your Information. Subject to limited exceptions under
                            applicable law, you may request to review, correct inaccuracies, and/or update your personal
                            information Olo's custody and control by submitting a request to
                            <StyledSmallRedText
                                target="_blank"
                                //TODO: Add correct link?
                                href="mailto:privacy@olo.com"
                                rel="noopener noreferrer"
                            >
                                {' '}
                                privacy@olo.com.{' '}
                            </StyledSmallRedText>
                            or by writing to us at the contact information set out below. We may request certain
                            personal information for the purpose of verifying the identity of the individual seeking
                            access to his or her personal information records.
                        </StyledListCtn>
                        <StyledListCtn>
                            (f) Opting Out of Email and SMS Communications. If you have signed-up to receive our email
                            marketing communications, you can unsubscribe any time by clicking the “unsubscribe” link
                            included at the bottom of the email or other electronic communication. Alternatively, you
                            can opt out of receiving our marketing communications by contacting us at the contact
                            information under “Contact Us” below. If you provide your phone number through the Services,
                            Olo may send you notifications by SMS, such as to alert you of a problem with your order or
                            to notify you that your delivery is on its way. You may opt out of SMS communications by
                            unlinking your mobile phone number through the Site.
                        </StyledListCtn>
                        <StyledListCtn>
                            (g) Opting Out of Location Tracking. If you initially consented to the collection of
                            geo-location information through the Services, you can subsequently stop the collection of
                            this information at any time by changing the preferences on your mobile device. Please note,
                            however, that if you withdraw consent to Olo's collection of location information, you may
                            no longer be able to use some features of the Services.
                        </StyledListCtn>
                        <StyledSubHeading>YOUR CALIFORNIA PRIVACY RIGHTS</StyledSubHeading>
                        <StyledSmallText>(AS PROVIDED BY CALIFORNIA CIVIL CODE SECTION 1798.83)</StyledSmallText>
                        <StyledSmallText>
                            A California resident who has provided personal data to a business with whom he/she has
                            established a business relationship for personal, family, or household purposes (a
                            "California customer") may request information about whether the business has disclosed
                            personal data to any third parties for the third parties' direct marketing purposes. In
                            general, if the business has made such a disclosure of personal data, upon receipt of a
                            request by a California customer, the business is required to provide a list of all third
                            parties to whom personal data was disclosed in the preceding calendar year, as well as a
                            list of the categories of personal data that were disclosed. California customers should
                            visit the Branded Company's website for further information on how to submit a request or,
                            if applicable, opt out of the Branded Company's sharing of the customers' personal data with
                            third parties for those parties' direct marketing purposes.
                        </StyledSmallText>
                        <StyledSubHeading>PRIVACY POLICY UPDATES</StyledSubHeading>
                        <StyledSmallText>
                            This Privacy Policy is subject to occasional revision. Olo will notify you of any material
                            changes in its collection, use, or disclosure of your personal information by posting a
                            notice on the Site and within the Application. Any material changes to this Privacy Policy
                            will be effective thirty (30) calendar days following notice of the changes on the Site and
                            within the Application. These changes will be effective immediately for new users of the
                            Services. If you object to any such changes, you must notify Olo prior to the effective date
                            of such changes that you wish to deactivate your Account. Continued use of the Services
                            following notice of any such changes shall indicate your acknowledgement of such changes.
                        </StyledSmallText>
                        <StyledSubHeading>CONTACT OLO</StyledSubHeading>
                        <StyledSmallText>
                            If you have any questions or complaints about this Privacy Policy or Olo's data collection
                            or processing practices, or if you want to report any security violations to Olo, please
                            contact Olo by email at: privacy@olo.com; or by mail at:
                        </StyledSmallText>
                        <StyledInnerText>[Insert contact title/position]</StyledInnerText>
                        <StyledInnerText>Olo</StyledInnerText>
                        <StyledInnerText>One World Trade Center, 82nd Floor</StyledInnerText>
                        <StyledInnerText>New York, NY 10007 USA</StyledInnerText>
                        <StyledSubHeading>NOTICE TO CALIFORNIA USERS</StyledSubHeading>
                        <StyledSmallText>
                            We are providing this supplemental privacy notice to California users on behalf of the
                            Branded Company, pursuant to the California Consumer Privacy Act of 2018 ("CCPA"). Please
                            note that as a service provider handling personal information on behalf of the Branded
                            Company, Olo is not required to provide a CCPA notice. This notice does not apply to
                            individuals with whom Olo engages in the individuals' capacity as representatives of
                            businesses that may provide services to Olo, or to which Olo may provide its services
                            including any user who visits{' '}
                            <StyledSmallRedText target="_blank" href={'https://www.olo.com'} rel="noopener noreferrer">
                                www.olo.com
                            </StyledSmallRedText>
                            , which is designed only for businesses and not for individuals' use in their personal or
                            household capacity.
                        </StyledSmallText>
                        <StyledSmallText>
                            The Branded Company is prohibited from selling personal information through the Olo
                            Services. As set out in this Privacy Policy, we, our service providers and partners use
                            cookies and other tracking technologies to analyze website traffic and facilitate
                            advertising. Please visit the Branded Company's homepage for further details about its
                            privacy practices.
                        </StyledSmallText>
                        <StyledSmallText>The CCPA grants California residents the following rights.</StyledSmallText>
                        <StyledListCtn>
                            <StyledListItem>
                                <StyledBoldWords>Information</StyledBoldWords>. You can request information about how
                                the Branded Company has collected, used and shared your personal information through the
                                Olo Services during the past 12 months.
                            </StyledListItem>
                            <StyledListItem>
                                <StyledBoldWords>Access</StyledBoldWords>. You can request a copy of the personal
                                information that Olo maintains about you on behalf of the Branded Company.
                            </StyledListItem>
                            <StyledListItem>
                                <StyledBoldWords>Deletion</StyledBoldWords>. You can ask to delete the personal
                                information that Olo maintains about you on behalf of the Branded Company
                            </StyledListItem>
                            <StyledListItem>
                                <StyledBoldWords>Opt-out of sale of your personal information</StyledBoldWords>. While
                                Olo does not allow the Branded Company to sell personal information through the Olo
                                Services, we offer{' '}
                                <StyledSmallRedText
                                    target="_blank"
                                    href={'https://www.olo.com/on-line-tracking-opt-out-guide/'}
                                    rel="noopener noreferrer"
                                >
                                    instructions on how to limit online tracking
                                </StyledSmallRedText>
                                .
                            </StyledListItem>
                        </StyledListCtn>
                        <StyledSmallText>
                            Please note that the CCPA limits these rights by, for example, prohibiting businesses from
                            providing certain sensitive information in response to an access request and limiting the
                            circumstances in which they must comply with a deletion request.
                        </StyledSmallText>
                        <StyledSmallText>
                            You are entitled to exercise the rights described above free from discrimination.
                        </StyledSmallText>
                        <StyledSmallText>Here is how you can submit requests:</StyledSmallText>
                        <StyledListCtn>
                            <StyledListItem>
                                <StyledBoldWords>
                                    To request access to or deletion of personal information
                                </StyledBoldWords>
                                , visit the Branded Company's website.
                            </StyledListItem>
                            <StyledListItem>
                                <StyledBoldWords>To learn how to limit tracking</StyledBoldWords>, visit{' '}
                                <StyledSmallRedText
                                    target="_blank"
                                    href={'/online-tracking-opt-out-guide'}
                                    rel="noopener noreferrer"
                                >
                                    Online Tracking Opt-out Guide
                                </StyledSmallRedText>
                                .
                            </StyledListItem>
                            <StyledListItem>
                                <StyledBoldWords>Identity verification</StyledBoldWords>. CCPA requires verification of
                                the identity of the individual submitting the request. For identity verification
                                requirements, visit the Branded Company's website.
                            </StyledListItem>
                            <StyledListItem>
                                <StyledBoldWords>Authorized agents</StyledBoldWords>. California residents can empower
                                an "authorized agent" to submit requests on their behalf.
                            </StyledListItem>
                        </StyledListCtn>
                    </StyledMessageCtn>
                </GenericContainerNarrow>
            </MainContainer>
        </>
    );
};
