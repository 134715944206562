import React from 'react';
import { StyledLoadingCtn } from './styles/index';
import loaderAnimation from '../../../assets/gif/loading1.gif';

export const LoadingPage = () => {
    return (
        <StyledLoadingCtn>
            <img src={loaderAnimation} alt={'page loading animation'} />
        </StyledLoadingCtn>
    );
};
