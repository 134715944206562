import React from 'react';
import { BagIcon } from '@assets';
import { Text } from '@components';
import styled from 'styled-components';

const StyledIcon = styled(BagIcon)`
    height: 30px;
    width: 27px;
`;

const ShoppingBag = ({ onClick, isMobile }) => (
    <span onClick={onClick} className="cursor flex items-center lg:my-6 lg:mr-16 lg:ml-10 cursor-pointer">
        <StyledIcon />
        {!isMobile && (
            <Text className="text-primaryColor text-lg font-veneer font-normal leading-8 lg:ml-2 lg:mt-1">bag</Text>
        )}
    </span>
);

export default ShoppingBag;
