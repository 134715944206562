import React from 'react';

import { BrowserRouter as Router, Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import CheckoutForm from './pages';
import Confirmation from './pages/confirmation';

import MainContainer from '../components/MainContainer';
import OrderDetails from './pages/subcomponents/OrderDetails';

const Checkout = (props) => {
    let { path } = useRouteMatch();

    return (
        <MainContainer>
            <Switch>
                <Route exact path={`${path}/:cartId`} component={CheckoutForm} />
                <Route exact path={`${path}/:orderId/confirmation`} component={Confirmation} />
                <Route exact path={`${path}/:orderId/order-details`} component={OrderDetails} />
                <Route path="*">
                    <Redirect to="/menu" />
                </Route>
            </Switch>
        </MainContainer>
    );
};

export default Checkout;
