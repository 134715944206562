module.exports = {
    purge: [],
    theme: {
        extend: {
            fontFamily: {
                futura: ['Futura'],
                futuraConMD: ['FuturaBT-CondMedium'],
                futuraLT: ['FuturaBT-Light'],
                futuraHY: ['FuturaBT-Heavy'],
                veneer: ['Veneer'],
            },

            background: (theme) => ({
                'btn-red-swatch': "url('/assets/icons/bg-btn-red.svg')",
            }),
            outline: ['responsive', 'hover', 'focus', 'active'],
        },
        listStyleType: {
            none: 'none',
            disc: 'disc',
            decimal: 'decimal',
            alpha: 'lower-alpha',
        },
        screens: {
            xxs: '270px',
            xs: '375px',
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
        },
        colors: {
            transparent: 'transparent',
            current: 'currentColor',
            black: '#000',
            white: '#fff',
            primaryColor: '#C62032',
            darkGray: '#585858',
            beige: '#F6EFE8',
            lightGray: '#C4C4C4',
            extraLightGray: '#F5F5F5',
            whiteSmoke: '#F5F5F5',
            gray: '#E5E5E5',
            status: {
                error: '#C62032',
                success: '#000000',
            },
        },
        spacing: {
            px: '1px',
            0: '0',
            0.5: '0.125rem',
            1: '0.25rem',
            2: '0.5rem',
            3: '0.75rem',
            4: '1rem',
            5: '1.25rem',
            6: '1.5rem',
            7: '1.75rem',
            8: '2rem',
            9: '2.25rem',
            10: '2.5rem',
            11: '2.75rem',
            12: '3rem',
            13: '3.5rem',
            15: '3.75rem',
            16: '4rem',
            20: '5rem',
            22: '5.5rem',
            24: '6rem',
            25: '7rem',
            32: '8rem',
            34: '9rem',
            40: '10rem',
            48: '12rem',
            49: '12.5rem',
            56: '14rem',
            64: '16rem',
            70: '20rem',
            71: '20.25rem',
            90: '42rem',
        },
        boxShadow: {
            xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
            sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
            default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
            md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
            xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
            xlAlt: '0px -4px 19px -5px rgba(0, 0, 0, 0.1)',
            '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
            inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
            outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
            none: 'none',
        },
        fontSize: {
            xs: '0.75rem',
            sm: '0.875rem',
            base: '1rem',
            lg: '1.125rem',
            xl: '1.25rem',
            '2xl': '1.5rem',
            '3xl': '1.875rem',
            '4xl': '2.25rem',
            '5xl': '3rem',
            '6xl': '4rem',
        },
        fontWeight: {
            hairline: '100',
            thin: '200',
            light: '300',
            normal: '400',
            medium: '500',
            semibold: '600',
            bold: '700',
            extrabold: '800',
            black: '900',
        },
        height: (theme) => ({
            auto: 'auto',
            ...theme('spacing'),
            full: '100%',
            screen: '100vh',
        }),
        margin: (theme, { negative }) => ({
            auto: 'auto',
            ...theme('spacing'),
            ...negative(theme('spacing')),
        }),
        maxHeight: {
            full: '100%',
            screen: '100vh',
        },
        maxWidth: (theme, { breakpoints }) => ({
            none: 'none',
            xs: '20rem',
            sm: '24rem',
            md: '28rem',
            lg: '32rem',
            xl: '36rem',
            '2xl': '42rem',
            '3xl': '48rem',
            '4xl': '56rem',
            '5xl': '64rem',
            '6xl': '72rem',
            full: '100%',
            ...breakpoints(theme('screens')),
        }),
        minHeight: {
            0: '0',
            full: '100%',
            screen: '100vh',
        },
        minWidth: {
            0: '0',
            full: '100%',
        },
    },
    variants: {
        backgroundImage: ['responsive', 'hover', 'focus', 'active'],
        opacity: ['responsive', 'hover', 'focus', 'active'],
    },
    plugins: [],
};
