import React, { useState } from 'react';

import { TextInput, Label } from '@components';
import { useDispatch } from 'react-redux';
import { setCouponCode } from '@store/actions/cartActions';

const CouponCodeForm = () => {
    const dispatch = useDispatch();
    const [code, setCode] = useState(null);

    return (
        <div className="w-full mt-4 lg:mt-5 flex flex-col content-center justify-center">
            <Label label="Coupon Code" type="primary">
                <div className="relative">
                    <TextInput
                        className="mt-1"
                        type="text"
                        name="couponCode"
                        withoutValidation
                        onChange={(e) => setCode(e.target.value)}
                    />
                    <a className="absolute inset-y-0 right-0 px-3 flex items-center uppercase tracking-wide font-bold text opacity-50" onClick={() => {
                        dispatch(setCouponCode({ code }));
                    }}>
                        Apply
                    </a>
                </div>
            </Label>
        </div>
    );
};

export default CouponCodeForm;
