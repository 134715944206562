import React, { useState, useEffect } from 'react';
import { Text, Button, GenericContainerNarrow } from '@components';
import { SingleFavoriteOrder } from './subComponents/SingleFavorite';
import NotLoggedIn from './subComponents/NotLoggedIn';
import timeout from '@global/helpers/timeout';
import API from '@utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { addFavorite } from '@store/actions';
import { PageLoading } from '@components/GlobalComponents/Loading/PageLoading';
import { useHistory, useLocation, Link } from 'react-router-dom';

const FavoritesAccount = (props) => {
    const { authtoken } = useSelector((state) => state.authData);
    const cartLoading = useSelector((state) => state.cartData.loading);

    const [favorites, setFavorites] = useState([]);
    const [activeRequest, setActiveRequest] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState([]);
    const history = useHistory();
    const location = useLocation();

    const deleteFavorite = (id) => {
        if (!activeRequest) {
            handleDeleteFavorite(id);
        }
        return;
    };
    const addFavoriteToBag = (favorite) => {
        if (!cartLoading) {
            handleAddFavoriteToBag(favorite);
        }
        return;
    };
    const handleAddFavoriteToBag = async (favorite) => {
        const loc = location.pathname.includes('menu') ? 'menu' : 'account';
        dispatch(addFavorite({ data: { ...favorite, from: loc }, history }));
    };
    const handleDeleteFavorite = async (id) => {
        setActiveRequest(true);
        try {
            await API.delete(`/users/${authtoken}/deleteFave/${id}`);
            const result = await API.get(`/users/${authtoken}/faves/`);
            setFavorites(result.data.data.faves);
        } catch (e) {
            //error
            console.log('delete error', e);
        } finally {
            setActiveRequest(false);
        }
    };

    useEffect(() => {
        let isCancelled = false;
        async function getFavorites() {
            setLoading(true);
            if (authtoken) {
                const result = await API.get(`/users/${authtoken}/faves/`);
                if (!isCancelled) {
                    setFavorites(result.data.data.faves);
                    await timeout(1000);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }
        getFavorites();
        return () => {
            isCancelled = true;
        };
    }, [setFavorites]);

    return (
        <>
            <GenericContainerNarrow>
                {loading ? (
                    <PageLoading inner={true}></PageLoading>
                ) : (
                    <>
                        {authtoken ? (
                            <div className="w-full px-4">
                                {!favorites.length ? (
                                    <div className="inline mt-7 mb-25 lg:mb-90 lg:mt-16 px-4">
                                        <div className="inline">
                                            <Text type="smLT" className="lg:text-lg">
                                                You don’t have any favorites yet. You can favorite items
                                                {/* from the
                                                <Button
                                                    onClick={()=>history.push('/account/past-orders')}
                                                    className="inline-flex p-0 m-0 lg:text-lg"
                                                    size="small"
                                                    type="quinternaryLowerCase"
                                                >
                                                    Past Orders
                                                </Button>
                                                screen or  */}
                                                at checkout.
                                            </Text>
                                        </div>
                                        <Button
                                            onClick={() => history.push('/account/past-orders')}
                                            className="w-full m-auto mt-7 rounded-sm"
                                            type="primary"
                                        >
                                            Start An Order
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="w-full flex flex-col pb-24">
                                        {favorites.map((favorite, i) => (
                                            <SingleFavoriteOrder
                                                key={i}
                                                favorite={favorite}
                                                deleteFavorite={deleteFavorite}
                                                addFavoriteToBag={addFavoriteToBag}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <NotLoggedIn></NotLoggedIn>
                        )}
                    </>
                )}
            </GenericContainerNarrow>
        </>
    );
};

export default FavoritesAccount;
