import React, {useRef, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik, ErrorMessage, getIn} from 'formik';
import * as Yup from 'yup';
import getStyles from '@global/helpers/getStyles';
import {TextInput, Button} from '@components';
import API from '@utils/API';

export const DeliveryAddress = ({setDeliveryAddress}) => {
	const inputStyles = {
		backgroundColor: '#F6EFE8 !important',
		height: '40px'
	};

	const apiError = useSelector((state) => state.authData.error);
	const cartData = useSelector((state) => state.cartData.data);

	const apiErrorMessage = apiError
		?.message;

	const [loading, setLoading] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);

	const formik = useFormik({
		initialValues: {
			streetaddress: '',
			building: '',
			city: '',
			zipcode: '',
			phonenumber: '',
			specialinstructions: ''
		},
		validationSchema: Yup.object({
			streetaddress: Yup.string().required(),
			building: Yup.string(),
			city: Yup.string().required(),
			zipcode: Yup.string().required(),
			phonenumber: Yup.string().required('Please enter your phone number').typeError('Please enter a valid phone number; Must no include parenthesis or dashes').length(10, "Phone number must be 10 digits"),
			specialinstructions: Yup.string()
		}),
		onSubmit: (values) => {
			setDeliveryAddress(values);
		}
	});

	return (<div>
		<form className="w-full flex flex-col" onSubmit={formik.handleSubmit} noValidate="noValidate">
			<label className="mt-3 font-futura text-sm text-darkGray text-opacity-70">
				Street Address
				<TextInput placeholder={'required'} style={inputStyles} type="text" name="streetaddress" withoutValidation="withoutValidation" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.streetaddress} autocomplete="shipping address-line1"/> {
					formik.errors.streetaddress && formik.touched.streetaddress
						? (<div className="mt-1 text-status-error">{formik.errors.streetaddress}</div>)
						: null
				}
			</label>
			<label className="mt-3 font-futura text-sm text-darkGray text-opacity-70">
				Apt, Floor, Suite, Building
				<TextInput placeholder={'optional'} style={inputStyles} type="text" name="building" withoutValidation="withoutValidation" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.building} autocomplete="shipping address-line2"/>
			</label>
			<label className="mt-3 font-futura text-sm text-darkGray text-opacity-70">
				City
				<TextInput placeholder={'required'} style={inputStyles} type="text" name="city" withoutValidation="withoutValidation" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.city} autocomplete="shipping locality"/> {
					formik.errors.city && formik.touched.city
						? (<div className="mt-1 text-status-error">{formik.errors.city}</div>)
						: null
				}
			</label>

			<label className="mt-3 font-futura text-sm text-darkGray text-opacity-70">
				Zip Code
				<TextInput placeholder={'required'} style={inputStyles} type="text" name="zipcode" withoutValidation="withoutValidation" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.zipcode} autocomplete="shipping postal-code"/> {
					formik.errors.zipcode && formik.touched.zipcode
						? (<div className="mt-1 text-status-error">{formik.errors.zipcode}</div>)
						: null
				}
			</label>

			<label className="mt-3 font-futura text-sm text-darkGray text-opacity-70">
				Phone Number (Must be 10 digits, no special characters)
				<TextInput placeholder={'required'} style={inputStyles} type="tel" name="phonenumber" withoutValidation="withoutValidation" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phonenumber} autocomplete="tel"/> {
					formik.errors.phonenumber && formik.touched.phonenumber
						? (<div className="mt-1 text-status-error">{formik.errors.phonenumber}</div>)
						: null
				}
			</label>
			<label className="mt-3 font-futura text-sm text-darkGray text-opacity-70">
				Delivery notes
				<TextInput placeholder={'optional notes for your carrier'} style={inputStyles} type="text" name="specialinstructions" withoutValidation="withoutValidation" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.specialinstructions}/>
			</label>

			<Button className="w-full flex justify-center content-center items-center m-auto my-12 lg:mb-10 rounded-sm" type="primary">
				Save
			</Button>
		</form>
	</div>);
};
