import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import styled from 'styled-components';

export const Generic = styled.div`
    ${(props) => tw`
        flex
        -mx-2
        flex-wrap
        max-w-screen-xl
        mx-auto
        py-6
     `}
`;

const GenericContainer = (props) => {
    return <Generic className={props.className}>{props.children}</Generic>;
};

export default GenericContainer;
