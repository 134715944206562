import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components';
import { AllergensIcon } from '@assets';
import tw from 'tailwind.macro';

const StyleCtn = styled.nav`
    ${tw`flex justify-center`}
    &.navbar {
        width: 100%;
    }

    &.navbar--shrink {
        height: auto;
    }
`;

const StyledAllergens = styled.div`
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 60px;
    span {
        display: flex;
        align-items: center;
    }
`;

const SubHero = ({ price, category, description, calories, isMobile, allergens, className }) => {
    const shrinkNav = className === 'navbar';
    return (
        <div className="top-0 z-10 bg-black w-full">
            <StyleCtn className={className}>
                <div className="flex justify-between lg:py-5 lg:w-4/5">
                    <div className={shrinkNav ? `px-4 py-6 lg:max-w-xl xl:max-w-2xl` : `flex items-center py-6 z-10`}>
                        <Text className="pb-2" type="lg">
                            {category}
                        </Text>
                        {!shrinkNav && (
                            <Text type="xsWhite" className="ml-4">
                                {calories} cal
                            </Text>
                        )}
                        {shrinkNav && <Text type="xsWhite">{description}</Text>}
                        <div className="flex items-center mt-5">
                            {shrinkNav && (
                                <Text type="xsWhiteFutura" className="lg:mr-2 font-futura">
                                    ${price}+
                                </Text>
                            )}
                            {!isMobile && shrinkNav && <Text type="xsWhite">/</Text>}
                            {shrinkNav && (
                                <Text type="xsWhite" className="mx-2">
                                    {calories} cal
                                </Text>
                            )}
                            {!isMobile && allergens && shrinkNav && (
                                <StyledAllergens className="bg-beige ml-2 text-primaryColor px-4 py-2 flex items-center">
                                    <AllergensIcon />
                                    <span className="ml-2">contains {allergens.value}</span>
                                </StyledAllergens>
                            )}
                            {isMobile && allergens && shrinkNav && (
                                <StyledAllergens className="bg-beige ml-2 text-primaryColor px-4 py-2">
                                    <AllergensIcon />
                                    <span className="ml-2">contains {allergens.value}</span>
                                </StyledAllergens>
                            )}
                        </div>
                    </div>
                </div>
            </StyleCtn>
        </div>
    );
};

export default SubHero;
