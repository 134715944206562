// In case you need to use a selector
// import also select from redux-saga/effects
// and then simplie yield select(yourSelector())

import { put, call, takeLatest, all, delay, select } from 'redux-saga/effects';
import API from '@utils/API';

import {
    GET_RESTAURANTS,
    START_SESSION,
    GET_USER_SAVED_ADDRESSES,
    GET_USER_RECENT_RESTAURANTS,
    LOGIN_USER_SUCCESS,
    CLEAR_AUTH_DATA,
    CLEAR_CART,
} from '../types';

function* handleStartSession() {
    const authentication = yield call(handleGetAuthentication);
    const cart = yield call(handleValidateBasket);
}

function* handleGetAuthentication() {
    const { authData } = yield select();
    const { authtoken } = authData;

    if (authtoken) {
        try {
            let { data } = yield call(API.get, `users/${authtoken}`);
            yield put({ type: LOGIN_USER_SUCCESS, userData: data.data });
            yield call(handleSavedDeliveryAddresses);
            return { status: true, data: data.data };
        } catch (e) {
            yield put({ type: CLEAR_AUTH_DATA });
            return { status: false, e };
        }
    } else {
        return { status: false };
    }
}

function* handleValidateBasket() {
    const { cartData } = yield select();
    const { id } = cartData;
    if (id) {
        try {
            let { data } = yield call(API.post, `baskets/${id}/validate/`);
            return { status: true };
        } catch (e) {
            let message = (e?.message)?e.message:"There's been an error with your request";
            yield put({ type: 'SET_GENERIC_MODAL_MESSAGE', data: message });

            yield put({ type: CLEAR_CART });

            return { status: false };
        }
    }
}

function* handleGetRestaurants() {
    yield put({ type: 'SET_LOADING', status: true });
    yield delay(2000);
    try {
        const { data } = yield call(API.get, 'restaurants/');
        yield put({ type: 'SET_RESTAURANTS', data: data.data.restaurants });
        // return { status: true, data };
    } catch (e) {
        let message = (e?.message)?e.message:"There's been an error with your request";
        yield put({ type: 'SET_GENERIC_MODAL_MESSAGE', data: message });
        // return { status: false, e };
    }

    yield put({ type: 'SET_CART_LOADING', status: false });
    yield put({ type: 'SET_LOADING', status: false });
    yield put({ type: 'SET_SESSION', data: { initialized: true } });
}

function* handleSavedDeliveryAddresses() {
    const { authData } = yield select();
    const { authtoken } = authData;
    try {
        const { data } = yield call(API.get, `/users/${authtoken}/deliveryAddress`);
        yield put({
            type: 'SET_USER_SAVED_ADDRESSES',
            data: (Array.isArray(data.data) && data.data.length == 0) ? [] : data.data.deliveryaddresses,
        });

        // return {status: true, data}
    } catch (e) {
        console.log(e);
        // return {'status': false, e};
    }
}

function* retrieveUserRecentRestaurants() {
    const { authData } = yield select();
    const { authtoken } = authData;
    try {
        const { data } = yield call(API.get, `/users/${authtoken}/recentRestaurants`);
        yield put({ type: 'SET_USER_RECENT_RESTAURANTS', data: data.data });
        // return {status: true, data}
    } catch (e) {
        console.log(e);
        // return {'status': false, e};
    }
}

function* watchRootSagas() {
    yield all([
        takeLatest(START_SESSION, handleStartSession),
        takeLatest(GET_RESTAURANTS, handleGetRestaurants),
        takeLatest(GET_USER_SAVED_ADDRESSES, handleSavedDeliveryAddresses),
        takeLatest(GET_USER_RECENT_RESTAURANTS, retrieveUserRecentRestaurants),
    ]);
}

export default watchRootSagas;
