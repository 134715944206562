import React, { useCallback, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { Button } from '@components';
import {
    StyledBackground,
    StyledModalWrapper,
    StyledModalSubCtn,
    StyledModalHeadline,
    StyledCloseWhiteIcon,
    StyledModalContent,
    StyleCloseModalCtn,
} from './styles/index';

export const Modal = ({ showModal, setShowModal, className, type, onClick, cta, headline, loading, content, hideCancel }) => {
    //to close modal by pressing 'esc' on keyboard
    const keyPress = useCallback(
        (e) => {
            if (e.key === 'Escape' && showModal) {
                setShowModal(false);
            }
        },
        [setShowModal, showModal]
    );

    //listening for 'esc' keypress
    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    return (
        <>
            {showModal && (
                <StyledBackground>
                    <StyledModalWrapper className={className}>
                        <StyledModalSubCtn>
                            <StyleCloseModalCtn>
                                <StyledCloseWhiteIcon
                                    aria-label="Close modal"
                                    onClick={() => setShowModal((prev) => !prev)}
                                ></StyledCloseWhiteIcon>
                            </StyleCloseModalCtn>
                            <StyledModalHeadline>{headline}</StyledModalHeadline>
                        </StyledModalSubCtn>
                        <StyledModalContent>
                            {typeof content === 'function' ? content({ close: setShowModal }) : content}
                            {cta && onclick && (
                                <Button
                                    className="w-full flex justify-center content-center items-center m-auto mt-12 mb-5 rounded-sm"
                                    type="primary"
                                    loading={loading}
                                    onClick={onClick}
                                >
                                    {cta}
                                </Button>
                            )}
                            {!hideCancel &&
                                <Button
                                    type={'tertiary'}
                                    className={'flex underline m-auto justify-center -mt-8 mb-11 '}
                                    onClick={() => setShowModal((prev) => !prev)}
                                >
                                    Cancel
                                </Button>
                            }
                        </StyledModalContent>
                    </StyledModalWrapper>
                </StyledBackground>
            )}
        </>
    );
};
