import React from 'react';
import PropTypes from 'prop-types';
import MainContainer from 'containers/components/MainContainer';
import { PhotoCard, GenericContainer } from '@components';
import HeroContainer from '../components/heroContainer';
import { MenuHero } from '@components/GlobalComponents/Heros/MenuHero/index';
import PastOrders from '../../Account/components/PastOrders';

const Recents = (props) => {
    return (
        <MainContainer>
            <HeroContainer />
            <MenuHero></MenuHero>
            <div>
                <PastOrders />
            </div>
        </MainContainer>
    );
};

export default Recents;
