import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Link, useParams } from 'react-router-dom';
import { ArrowIcon, CloseRedIcon } from '@assets';
import { useSelector } from 'react-redux';

const StyledBackToMenuCtn = styled.div`
    ${tw`px-6`}
`;

const StyledText = styled.div`
    ${tw`
        font-veneer
        leading-4
        uppercase
    `}
`;

const BackToMenu = () => {
    const location = useSelector((state) => state.menuData.location.slug);

    return (
        <StyledBackToMenuCtn>
            <div>
                <Link to={`/order/${location}/menu`}>
                    <StyledText>
                        <span>&lsaquo;</span> Back To Menu
                    </StyledText>
                </Link>
            </div>
        </StyledBackToMenuCtn>
    );
};

export default BackToMenu;
