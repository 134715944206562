import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { DeliveryAddress } from './subcomponents/DeliveryAddress';
import { setDispatchAddress } from 'store/actions';
import { useScript } from '@hooks';
import API from '@utils/API';
import { setCartVisible, setTimeWanted, setOrderSuccess, setGenericModalMessage, clearCart, getRestaurantOperatingHours } from '@store/actions';
import { Modal } from 'components/GlobalComponents/Modal';
import uniqBy from 'lodash/uniqBy';
import { BodyContainer, Button, GenericContainerNarrow, PageHeaderNavBeige, Text } from '@components';
import {
    CheckoutDetailsContainer,
    CheckoutHeader,
    CheckoutHeaderLabel,
    CheckoutOrderDetailsCtn,
    CheckoutOrderDetailsHeading,
    CheckoutOrderDetailsRow,
    CheckoutOrderDetailsSubTotal,
    CheckoutOrderDetailsTax,
    CheckoutOrderTotal,
    CheckoutOrderTotalsCtn,
    CheckoutRedHeading,
    CheckoutSimpleHeadline,
    StyledDeliveryTimeSelectBar,
    StyledProfileIcon,
    StyledRedClockIcon,
} from './styles';
import GuestContactForm from './guest/GuestContactForm';
import UserContactForm from './user/UserContactForm';
import { UserPaymentInfo } from './user/UserPaymentInfo';
import { FullPaymentInfo } from './subcomponents/FullPaymentInfo';
import { AsapLaterToggle } from './subcomponents/AsapLaterToggle';
import { TipForm } from './subcomponents/TipForm';
import Select from 'react-select';
import { DateTime } from 'luxon';

import {
    baseIframeInputStyles,
    errorIframeInputStyles, getDayOptions,
    getHoursOptions,
    getTipAmount,
    getWantedTime
} from "./utils";
import { UpdateDeliveryAddressForm } from 'containers/Account/components/forms/UpdateDeliveryAddressForm';
import CouponCodeForm from './subcomponents/CouponCodeForm';

const CheckoutForm = () => {
    useScript(process.env.REACT_APP_CCSF_IFRAME_URL);

    const history = useHistory();
    const { cartId } = useParams();
    const dispatch = useDispatch();
    const tipRanges = [15, 20, 25];

    const deliveryAddress = useSelector((state) => state.cartData.deliveryAddress);
    const { initialized } = useSelector((state) => state.rootData);
    const cartData = useSelector((state) => state.cartData.data);
    const defaultDeliveryAddressSet = useSelector((state) => state.cartData.defaultDeliveryAddressSet);
    const cartLoading = useSelector((state) => state.cartData.loading);
    const selectedLocation = useSelector((state) => state.menuData.location);
    const { isVisible } = useSelector((state) => state.rootData.cart);
    const { isPickup } = useSelector((state) => state.restaurantData);

    const date = DateTime.local().setZone('America/New_York');

    const {
        authtoken,
        emailaddress,
        firstname,
        lastname,
        loggedIn,
        userSavedDeliveryAddress,
    } = useSelector((state) => state.authData);

    const { restaurantOperatingHours } = useSelector((state) => state.restaurantData);

    const selectWantedTimeStyles = classnames('w-full mt-3', {
        'opacity-50 pointer-events-none': cartLoading,
    });

    //AddressForm
    const [showAddressForm, setShowAddressForm] = useState(true);

    const [showDeliveryAddressModal, setShowDeliveryAddressModal] = useState(false);

    // Generic States
    const userLoggedIn = loggedIn;
    const [checkoutFrame, setCheckoutFrame] = useState(null);
    const [checkoutFrameInitialized, setCheckoutFrameInitialized] = useState(false);
    const [accessToken, setAccessToken] = useState(null);
    const [deliveryTimeToggle, setDeliveryTimeToggle] = useState('asap');
    const [tip] = useState(tipRanges[0]);
    const [tipType] = useState('percent');
    const [saveOnFile, setSaveOnFile] = useState(false);
    const [successfulFormSubmitted, setSuccessfulFormSubmitted] = useState(false);

    // Contact Info States
    const [contactFirstName] = useState(firstname);
    const [contactLastName] = useState(lastname);
    const [contactEmail] = useState(emailaddress);
    const [contactPhone] = useState('');

    // Payment Info States
    const [userCreditCards, setUserCreditCards] = useState();
    const [selectedCreditCard, setSelectedCreditCard] = useState();
    const [paymentExpMonth] = useState('');
    const [paymentExpYear] = useState('');
    const [paymentAddress] = useState('');
    const [paymentAddress2] = useState('');
    const [paymentCity] = useState('');
    const [paymentState] = useState('');
    const [paymentZip] = useState('');

    let paymentCC = document.getElementById('paymentCC');
    let paymentCVV = document.getElementById('paymentCVV');

    const getCardsOnFile = async () => {
        const { data } = await API.get(`/users/${authtoken}/billingAccounts`);
        const schemesResult =  await API.get(`/baskets/${cartId}/billingOptions`);

        const billingSchemes = schemesResult?.data?.data.billingschemes;
        let billingAccounts = data?.data?.billingaccounts;
        billingAccounts.map(account => {
            if (billingSchemes) {
                account.scheme = billingSchemes.filter(scheme => scheme.type === account.accounttype)[0];
            }

            return account;
        });

        setUserCreditCards(data?.data?.billingaccounts);
    }

    const getValidationScheme = () => {
        if (userLoggedIn && userCreditCards?.length) {
            return {
                contactPhone: Yup.number()
                    .required('Please enter your phone number')
                    .typeError('Please enter a valid phone number; Must no include parenthesis or dashes'),
            }
        }

        return {
            contactFirstName: Yup.string().required('Please enter your first name'),
            contactLastName: Yup.string().required('Please enter your last name'),
            contactEmail: Yup.string()
                .required('Please enter a valid email')
                .email('Please enter a valid email'),
            contactPhone: Yup.number()
                .required('Please enter your phone number')
                .typeError('Please enter a valid phone number; Must no include parenthesis or dashes'),
            paymentExpMonth: Yup.string().required('Please select an expiration month'),
            paymentExpYear: Yup.string().required('Please select an expiration year'),
            paymentAddress: Yup.string().required('Please enter your address'),
            paymentAddress2: Yup.string(),
            paymentCity: Yup.string().required('Please enter your city'),
            paymentState: Yup.string().required('Please enter your state'),
            paymentZip: Yup.string().required('Please enter your zip code'),
        }
    }

    const getBillingAccount = (values) => {
        if (userLoggedIn && userCreditCards?.length) {
            return {
                billingMethod: 'creditcardonfile',
                billingaccountid: selectedCreditCard.accountid
            }
        }

        return {
            billingMethod: 'creditcard',
            expiryMonth: values.paymentExpMonth,
            expiryYear: values.paymentExpYear,
            streetAddress: values.paymentAddress,
            streetAddress2: values.paymentAddress2,
            city: values.paymentCity,
            state: values.paymentState,
            zip: values.paymentZip,
            saveOnFile: saveOnFile,
        }
    }

    const [selectedDeliveryDay, setSelectedDeliveryDay] = useState(date.toObject());
    const [selectedDeliveryTime, setSelectedDeliveryTime] = useState('');
    const dayOptions = useCallback(() => getDayOptions(selectedLocation), [selectedLocation])
    const hourOptions = useCallback(() => getHoursOptions(restaurantOperatingHours, selectedDeliveryDay, isPickup), [restaurantOperatingHours, selectedDeliveryDay, isPickup]);

    const setTipAmount = async (amount) => {
        const result = await API.put(`baskets/${cartId}/setTipAmount`, {
            amount,
        }).catch((e) => {
            // @todo: Do something if there's an error
            console.info(e);
        });
        return result.data;
    };

    //Need to update delivery address in modal
    // Go back to the landing page
    const handleLocationEditOnClick = () => {
        history.push('/');
    };

    /**
     * @devNote: Error validation only shows after validation because Olo
     * is weird
     * @param errors
     */
    const handleIframeErrors = (errors) => {
        setSuccessfulFormSubmitted(false);
        // Reset to base styles in case they only fix one error
        checkoutFrame.setStyles({
            cardNumber: baseIframeInputStyles,
            cvv: baseIframeInputStyles,
        });
        let errorString = '';
        errors.forEach((error) => {
            const { description, code } = error;
            if (description) {
                errorString = errorString + description + '<br />';
            }
            if (+code === 213) {
                // @todo: How do we handle this error? "Your order could not be placed. Please call this location directly to place your order." Do we display a modal with the restaurant info?
            }

            if (description.toLowerCase().includes('credit card')) {
                checkoutFrame.setStyles({
                    cardNumber: errorIframeInputStyles,
                });
            }

            if (description.toLowerCase().includes('cvv')) {
                checkoutFrame.setStyles({
                    cvv: errorIframeInputStyles,
                });
            }
        });
        if (errorString) {
            dispatch(setGenericModalMessage(errorString));
        }
    };

    const handleLoginOnClick = () => {
        history.push('/auth/login');
    }

    const handleWantedDay = e => {
        setSelectedDeliveryDay(JSON.parse(e.target.value));
        setSelectedDeliveryTime('');
    }

    const handleWantedTime = (e) => {
        setSelectedDeliveryTime(e.target.value);
    }

    const ccElementsExists = () => {
        return (
                (typeof(paymentCC) !== 'undefined' && paymentCC !== null) &&
                (typeof(paymentCVV) !== 'undefined' && paymentCVV !== null) &&
                paymentCC.dataset.initialized === true &&
                paymentCVV.dataset.initialized === true
            )
    }

    const initializeCheckoutForm = (iframe) => {
        iframe.initialize({
            brandAccessId: process.env.REACT_APP_CCSF_IFRAME_TOKEN,
            styles: {
                cardNumber: baseIframeInputStyles,
                cvv: baseIframeInputStyles,
            },
            cardNumber: {
                placeholder: '0000 0000 0000 0000',
            },
            cvv: {
                placeholder: 'cvv',
            },
        });

        if (!ccElementsExists()) {
            paymentCC = document.getElementById('paymentCC');
            paymentCVV = document.getElementById('paymentCVV');

            paymentCC.dataset.initialized = true;
            paymentCVV.dataset.initialized = true;
        }
    }

    /* Form Validation */
    const formik = useFormik({
        initialValues: {
            contactFirstName,
            contactLastName,
            contactEmail,
            contactPhone,
            paymentExpMonth,
            paymentExpYear,
            paymentAddress,
            paymentAddress2,
            paymentCity,
            paymentState,
            paymentZip
        },
        validationSchema: Yup.object(getValidationScheme()),
        onSubmit: async (values) => {
            const handleValidFormSubmission = async (iframeRequest) => {
                let deliveryId = (cartData.deliveryaddress?.id)?cartData.deliveryaddress?.id:userSavedDeliveryAddress[0].id;
                let payload = { id: deliveryId, isdefault: 'false' };
                try {
                    await API.put(`baskets/${cartData.id}/setDispatchAddress`, payload);
                    checkoutFrame.submit(iframeRequest);
                } catch (err) {
                    dispatch(setGenericModalMessage(err.message));
                }
            };

            if (accessToken && checkoutFrame) {
                // Set tip amount
                // const tipPortion = isPickup ? 0 : getTipAmount(tip, tipType, cartData.total);
                const tipPortion = 0;
                const { data } = await setTipAmount(0);
                const amount = data ? data.total : (cartData.total + tipPortion);
                const frameTimeout = ccElementsExists() ? 0 : 500;

                setSuccessfulFormSubmitted(true);

                const iframeRequest = {
                    id: cartId,
                    accessToken,
                    billingAccounts: [
                        {
                            amount,
                            tipPortion,
                            ...getBillingAccount(values)
                        }
                    ],
                    firstName: values.contactFirstName,
                    lastName: values.contactLastName,
                    contactNumber: values.contactPhone,
                    emailAddress: values.contactEmail,
                    guestOptIn: false,
                    userType: userLoggedIn ? 'user' : 'guest'
                }

                const paymentCC = document.getElementById('paymentCC');
                const paymentCVV = document.getElementById('paymentCVV');

                if (!ccElementsExists() && !paymentCC.dataset?.initialized && !paymentCVV.dataset?.initialized) {
                    initializeCheckoutForm(checkoutFrame);
                }

                // Submit the order
                let deliveryStatus = true;
                if (!isPickup) {
                    deliveryStatus = cartData.deliveryaddress?.id ? true : false;
                    if(deliveryStatus == false){
                        //check for defaults
                        if(userSavedDeliveryAddress.length){
                            deliveryStatus = true;
                        }
                    }
                }
                if (deliveryStatus) {
                    if (isPickup) {
                        setTimeout(() => {
                            checkoutFrame.submit(iframeRequest);
                        }, frameTimeout)
                    } else {
                        handleValidFormSubmission(iframeRequest);
                    }
                } else {
                    setSuccessfulFormSubmitted(false);
                    dispatch(setGenericModalMessage('Please select a delivery address'));
                }
            }
        }
    });

    /**
     * Redirect, the user shouldn't be here yet
     */
    useEffect(() => {
        // Redirect back to landing page if no location selected
        if (!selectedLocation) {
            history.push('/');
        }
    });

    /**
     * Build Olo's Checkout Iframe
     */
    useEffect(() => {
        if (
            !checkoutFrame &&
            typeof window.Olo !== 'undefined' &&
            Boolean(window.Olo) &&
            typeof window.Olo.CheckoutFrame !== 'undefined' &&
            Boolean(window.Olo.CheckoutFrame)
        ) {
            const iframe = new window.Olo.CheckoutFrame({
                cardElement: 'paymentCC',
                cvvElement: 'paymentCVV',
            });

            initializeCheckoutForm(iframe);

            setCheckoutFrame(iframe);
        }
    });

    /**
     * Add Checkout Frame events
     */
    useEffect(() => {
        if (checkoutFrame) {
            // Fire iframe events: `ready, focus, validate, success`
            // checkoutFrame.on('EVENT', function(data) {});

            checkoutFrame.on('success', (data) => {
                dispatch(
                    setOrderSuccess({
                        data,
                        history,
                        location: data.id,
                    })
                );
            });

            checkoutFrame.on('error', handleIframeErrors);
        }
    }, [checkoutFrame]);

    /**
     * Clean up the page
     */
    useEffect(() => {
        if (isVisible) {
            dispatch(setCartVisible(false));
        }
        // `isVisible` not passed as a dep so this can only fire once on page load
    }, []);

    /**
     * Get User cards on file
     */
    useEffect(() => {
        if (!initialized) {
            return;
        }

        if (userLoggedIn) {
            getCardsOnFile();
        }
    }, [initialized]);

    /**
     * Set default credit card if any
     */
    useEffect(() => {
        if (!initialized) {
            return;
        }

        if (userCreditCards && userCreditCards.length) {
            setSelectedCreditCard(userCreditCards.filter(card => card.isdefault)[0]);
        }

    }, [userCreditCards]);

    /**
     * Fetch CCSF Payment Token
     */
    useEffect(() => {
        if (!initialized) {
            return;
        }
        async function fetchToken() {
            const result = await API.post(`baskets/${cartId}/checkout`, {
                authtoken,
            });

            const { accesstoken } = result.data.data;

            if (accesstoken) {
                setAccessToken(accesstoken);
            }
        }

        fetchToken();
    }, [initialized]);

    const [deliveryDefaultValue, setDeliveryDefaultValue] = useState({
        value: null,
        label: null,
    });
    useEffect(() => {
        if (cartData?.deliveryaddress?.id) {
            setDeliveryDefaultValue({
                value: cartData.deliveryaddress?.id,
                label: cartData.deliveryaddress?.streetaddress,
            });
        } else if (userSavedDeliveryAddress.length) {
            setDeliveryDefaultValue({
                value: userSavedDeliveryAddress[0].id,
                label: userSavedDeliveryAddress[0].streetaddress,
            });
        }
    }, [cartData.deliveryaddress]);

    // Update wanted day/time
    useEffect(() => {
        if (selectedDeliveryDay && selectedDeliveryTime) {
            dispatch(setTimeWanted(getWantedTime(selectedDeliveryDay, selectedDeliveryTime)));
        }
    }, [selectedDeliveryDay, selectedDeliveryTime])

    const handleSetDeliveryAddress = (values, close = false) => {
        const payload = { ...values, id: 0, isdefault: 'false' };
        dispatch(setDispatchAddress(payload));
        setShowDeliveryAddressModal(false);
    };

    const setSelectedDeliveryAddress = (e) => {
        const payload = { id: e.value, isdefault: 'false' };
        dispatch(setDispatchAddress(payload));
    };

    const customStyles = {
        control: (provided) => ({
            // none of react-select's styles are passed to <Control />
            ...provided,
            width: 275,
        }),
    };

    useEffect(() => {
        if (!cartData?.products?.length) {
            history.push('/');
        }
    }, []);

    useEffect(() => {
        dispatch(getRestaurantOperatingHours(selectedLocation.id, {
            from: date.toFormat('yyyyLLdd'),
            to: date.plus({ days: 6 }).toFormat('yyyyLLdd')
        }));
    }, [selectedLocation.id]);

    return (
        <>
            <PageHeaderNavBeige>
                <GenericContainerNarrow>
                    <div className="flex w-full items-center">
                        {(selectedLocation && restaurantOperatingHours.length > 0) && (
                            <div className="w-full">
                                <div>
                                    {isPickup && (
                                        <>
                                            <span className={'text-sm text-darkGray leading-none'}>Pickup at:</span>
                                            <div className="flex flex-col mt-1">
                                                <span className="font-veneer text-lg font-bold leading-5 tracking-wider">
                                                    {selectedLocation.name}
                                                </span>
                                                <span className="font-futura text-sm leading-5">
                                                    {selectedLocation.streetaddress}, {selectedLocation.city}
                                                </span>
                                                <span className="font-futura text-sm leading-5">
                                                    {selectedLocation.telephone}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                                {!isPickup && (
                                    <>
                                        <div className="text-xl font-veneer text-black font-normal leading-8 mb-2">
                                            Delivery
                                        </div>
                                        {deliveryDefaultValue.value ? (
                                            <div className="w-full">
                                                <div className="w-full flex items-center">
                                                    <div>
                                                        <span className={'text-sm text-darkGray leading-none'}>
                                                            Select Delivery Address:
                                                        </span>

                                                        <Select
                                                            onChange={setSelectedDeliveryAddress}
                                                            defaultValue={deliveryDefaultValue}
                                                            styles={customStyles}
                                                            options={uniqBy(
                                                                userSavedDeliveryAddress,
                                                                'streetaddress'
                                                            ).map((address) => {
                                                                return {
                                                                    value: address.id,
                                                                    label: address.streetaddress,
                                                                };
                                                            })}
                                                        />
                                                    </div>
                                                </div>

                                                {authtoken && (
                                                    <button
                                                        className={
                                                            'mt-2 text-sm text-primaryColor underline leading-none'
                                                        }
                                                        onClick={() => setShowDeliveryAddressModal(true)}
                                                    >
                                                        Add New Delivery Address
                                                    </button>
                                                )}
                                            </div>
                                        ) : (
                                            <Button type={'primary'} onClick={() => setShowDeliveryAddressModal(true)}>
                                                Add Delivery Address
                                            </Button>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </GenericContainerNarrow>
            </PageHeaderNavBeige>
            <form noValidate onSubmit={formik.handleSubmit}>
                <GenericContainerNarrow>
                    <BodyContainer className="flex flex-col">
                        <CheckoutHeader>
                            <div className="w-full px-4 lg:px-0">
                                {!userLoggedIn ? (
                                    <div className="w-full flex flex-row justify-between items-center content-center">
                                        <CheckoutHeaderLabel>Checkout as Guest</CheckoutHeaderLabel>
                                        <Button
                                            className="flex content-center border rounded-sm mb-5"
                                            type="quaternary"
                                            size="small"
                                            onClick={handleLoginOnClick}
                                        >
                                            Log In
                                        </Button>
                                    </div>
                                ) : (
                                    <CheckoutHeaderLabel>Checkout</CheckoutHeaderLabel>
                                )}
                            </div>
                        </CheckoutHeader>
                        <CheckoutDetailsContainer>
                            <div className="w-full px-4 lg:px-0">
                                <CheckoutSimpleHeadline>
                                    <StyledRedClockIcon />
                                    <CheckoutRedHeading>
                                        Select A {isPickup ? 'Pickup' : 'Delivery'} Time
                                    </CheckoutRedHeading>
                                </CheckoutSimpleHeadline>
                                {/* @todo: Do all locations need this toggle? */}
                                <AsapLaterToggle
                                    {...{
                                        deliveryTimeToggle,
                                        setDeliveryTimeToggle,
                                        loading: cartLoading,
                                    }}
                                />
                                {deliveryTimeToggle === 'asap' ? (
                                    null
                                ) : (
                                    <div className={selectWantedTimeStyles}>
                                        <StyledDeliveryTimeSelectBar required onChange={handleWantedTime} value={selectedDeliveryTime}>
                                            <>
                                                <option value="" disabled>
                                                    Please select a {isPickup ? 'pickup' : 'delivery'} time
                                                </option>
                                                {hourOptions()}
                                            </>
                                        </StyledDeliveryTimeSelectBar>
                                        <br /><br />
                                        <StyledDeliveryTimeSelectBar onChange={handleWantedDay} defaultValue="">
                                            {dayOptions()}
                                        </StyledDeliveryTimeSelectBar>
                                    </div>
                                )}
                            </div>
                        </CheckoutDetailsContainer>
                        <CheckoutDetailsContainer>
                            <div className="w-full px-4 lg:px-0">
                                <div className="flex flex-col">
                                    <CouponCodeForm formik={formik}/>
                                </div>
                            </div>
                        </CheckoutDetailsContainer>
                        <CheckoutDetailsContainer>
                            <div className="w-full px-4 lg:px-0">
                                <CheckoutOrderDetailsCtn>
                                    <CheckoutOrderDetailsRow>
                                        <CheckoutOrderDetailsHeading>Order Details</CheckoutOrderDetailsHeading>
                                        <Button
                                            className="flex content-center self-baseline border rounded-sm mb-5"
                                            type="quaternary"
                                            size="small"
                                            buttonType="button"
                                            onClick={() => {
                                                dispatch(setCartVisible(true));
                                            }}
                                        >
                                            Edit Cart
                                        </Button>
                                    </CheckoutOrderDetailsRow>
                                    {cartData?.discount !== 0 && (
                                      <CheckoutOrderDetailsRow>
                                          <CheckoutOrderDetailsTax>Discount</CheckoutOrderDetailsTax>
                                          <CheckoutOrderDetailsSubTotal>
                                              <NumberFormat
                                                  value={cartData?.discount}
                                                  fixedDecimalScale={true}
                                                  decimalScale={2}
                                                  displayType={'text'}
                                                  thousandSeparator={true}
                                                  prefix={'$'}
                                              />
                                          </CheckoutOrderDetailsSubTotal>
                                      </CheckoutOrderDetailsRow>
                                    )}
                                    <CheckoutOrderDetailsRow>
                                        <CheckoutOrderDetailsTax>Subtotal</CheckoutOrderDetailsTax>
                                        <CheckoutOrderDetailsSubTotal>
                                            <NumberFormat
                                                value={cartData?.subtotal}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                            />
                                        </CheckoutOrderDetailsSubTotal>
                                    </CheckoutOrderDetailsRow>
                                    {!isPickup && (
                                        <CheckoutOrderDetailsRow>
                                            <CheckoutOrderDetailsTax>Delivery Fees</CheckoutOrderDetailsTax>
                                            <CheckoutOrderDetailsSubTotal>
                                                <NumberFormat
                                                    value={cartData?.customerhandoffcharge}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                />
                                            </CheckoutOrderDetailsSubTotal>
                                        </CheckoutOrderDetailsRow>
                                    )}
                                    <CheckoutOrderDetailsRow>
                                        <CheckoutOrderDetailsTax>Tax</CheckoutOrderDetailsTax>
                                        <CheckoutOrderDetailsTax>
                                            <NumberFormat
                                                value={cartData?.salestax}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                            />
                                        </CheckoutOrderDetailsTax>
                                    </CheckoutOrderDetailsRow>
                                    <CheckoutOrderTotalsCtn>
                                        <CheckoutOrderTotal>Total</CheckoutOrderTotal>
                                        <CheckoutOrderTotal>
                                            <NumberFormat
                                                value={cartData?.total}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                            />
                                        </CheckoutOrderTotal>
                                    </CheckoutOrderTotalsCtn>
                                </CheckoutOrderDetailsCtn>
                            </div>
                        </CheckoutDetailsContainer>
                        <CheckoutDetailsContainer>
                            <div className="w-full px-4 lg:px-0">
                                <CheckoutSimpleHeadline>
                                    <StyledProfileIcon />
                                    <CheckoutRedHeading>Contact Info</CheckoutRedHeading>
                                </CheckoutSimpleHeadline>
                                {!userLoggedIn ? (
                                    <GuestContactForm formik={formik}/>
                                ) : (
                                    <UserContactForm formik={formik} />
                                )}
                            </div>
                        </CheckoutDetailsContainer>
                        <CheckoutDetailsContainer>
                            <div className="w-full px-4 lg:px-0">
                                {!userLoggedIn || !userCreditCards?.length ? (
                                    <FullPaymentInfo
                                        formik={formik}
                                        saveOnFile={saveOnFile}
                                        setSaveOnFile={setSaveOnFile}
                                    />
                                ) : (
                                    <UserPaymentInfo cards={userCreditCards} onClick={setSelectedCreditCard}/>
                                )}
                            </div>
                        </CheckoutDetailsContainer>
                        {/* {!isPickup && (
                            <CheckoutDetailsContainer>
                                <CheckoutRedHeading className="tracking-wider">Add Tip?</CheckoutRedHeading>
                                <TipForm {...{ tipRanges, setTip, setTipType }} />
                            </CheckoutDetailsContainer>
                        )} */}
                        <div className="w-full px-4 lg:px-0 mt-4 flex justify-center items-center content-center border-3 border-beige">
                            <Button
                                className="w-full cursor flex m-auto content-center items-center justify-center rounded-sm"
                                type="primary"
                                buttonType="submit"
                                loading={successfulFormSubmitted}
                            >
                                Place Order
                            </Button>
                        </div>
                    </BodyContainer>
                </GenericContainerNarrow>
            </form>
            <Modal
                showModal={showDeliveryAddressModal}
                setShowModal={setShowDeliveryAddressModal}
                headline={'Delivery Address'}
                hideCancel={true}
                content={({ close }) => {
                    return <DeliveryAddress setDeliveryAddress={handleSetDeliveryAddress} close={close} />;
                }}
            />
        </>
    );
};

export default CheckoutForm;
