import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logOut } from '@store/actions';

import { Button } from '@components';

export const DeleteAccount = ({ action, close }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const deleteAccount = () => {
        action((res) => {
            //handleResponse with error
            setLoading(false);
            close(false);
            dispatch(logOut(history));
        });
    };
    return (
        <>
            <div className="w-full flex flex-col content-center justify-center" noValidate>
                <span className="text-xl mr-2  text-black font-normal leading-6">
                    Are you sure you'd like to delete your account?
                </span>
                <Button
                    className="w-full flex justify-center content-center items-center m-auto mt-8 lg:mt-12 rounded-sm"
                    type="primaryAlt"
                    onClick={() => close(false)}
                >
                    Cancel
                </Button>
                <Button
                    className="w-full flex justify-center content-center items-center m-auto mb-12 mt-4 lg:mb-10 rounded-sm"
                    type="primary"
                    onClick={deleteAccount}
                >
                    Delete
                </Button>
            </div>
        </>
    );
};
