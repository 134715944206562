import * as types from '../types';

export const startSession = () => {
    return { type: types.START_SESSION };
};

export const setLoading = (status) => {
    return { type: types.SET_LOADING, status };
};

export const getRestaurants = () => {
    return { type: types.GET_RESTAURANTS };
};

export const setCartVisible = (status) => {
    return { type: types.SET_CART_VISIBLE, status };
};

export const getUserSavedDeliveryAddress = () => {
    return { type: types.GET_USER_SAVED_ADDRESSES };
};

export const retrieveUserRecentRestaurant = () => {
    return { type: types.GET_USER_RECENT_RESTAURANTS };
};

export const setUserAddressQuery = (data) => {
    return { type: types.SET_USER_ADDRESS_QUERY, data };
};

export const setGenericModalMessage = (data) => {
    return { type: types.SET_GENERIC_MODAL_MESSAGE, data };
}
