import React from 'react';
import { StyledMobileEditIcon, StyledMobileRemoveIcon } from './style';
import { removeItem } from '@store/actions';
import { useDispatch } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { Text } from '@components';

const styles = {
    root: {
        height: 'auto',
    },
    slideContainer: {},
    slide: {
        padding: 15,
        minHeight: '100',
        color: '#fff',
    },
    slide1: {
        backgroundColor: 'transparent',
    },
    slide2: {
        backgroundColor: 'primaryColor',
    },
};
export const MobileSwiper = ({ beforeSwipe, product }) => {
    const dispatch = useDispatch();
    return (
        <SwipeableViews style={styles.root} slideStyle={styles.slideContainer}>
            <div
                style={Object.assign({}, styles.slide, styles.slide1)}
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                {beforeSwipe}
            </div>
            <div
                style={Object.assign({}, styles.slide, styles.slide2)}
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                {/* NOTE: Commenting out EDIT
                 <div
                    className="w-full cursor-pointer mx-px bg-primaryColor p-6 flex flex-col justify-center items-center"
                    onClick={() => {}}
                >
                    <StyledMobileEditIcon></StyledMobileEditIcon>
                    <Text className='uppercase py-1 font-futuraConMD text-sm text-white tracking-tight'> edit</Text>
                </div> */}
                <div
                    className="w-full cursor-pointer mx-px bg-primaryColor p-6 flex flex-col justify-center items-center"
                    onClick={() => {}}
                >
                    <StyledMobileRemoveIcon
                        onClick={() => {
                            dispatch(removeItem(product));
                        }}
                    ></StyledMobileRemoveIcon>
                    <Text className="uppercase py-1 font-futuraConMD text-sm text-white tracking-tight">remove</Text>
                </div>
            </div>
        </SwipeableViews>
    );
};

export default MobileSwiper;
