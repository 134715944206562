import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import API from '@utils/API';

import {
    AUTH_REQUEST_FAILED,
    CREATE_USER_ACCOUNT,
    SET_NEW_USER_ACCOUNT_SUCCESS,
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    UPDATE_COMMUNICATION_PREFERENCES,
    UPDATE_COMMUNICATION_PREFERENCES_SUCCESS,
    USER_FORGOT_PASSWORD,
    USER_FORGOT_PASSWORD_SUCCESS,
    GET_LOG_OUT,
    CLEAR_AUTH_DATA,
    CLEAR_AUTH_ERROR,
    GET_USER_SAVED_ADDRESSES,
} from '../types';

function* createUserAccount({ firstname, lastname, emailaddress, contactnumber, password }) {
    yield put({ type: CLEAR_AUTH_ERROR });
    try {
        const response = yield call(API.post, 'users/create', {
            firstname,
            lastname,
            emailaddress,
            contactnumber,
            password,
        });
        yield put({
            type: SET_NEW_USER_ACCOUNT_SUCCESS,
            user: response.data.data,
        });
    } catch (e) {
        yield put({
            type: AUTH_REQUEST_FAILED,
            message: `Error: ${e.response.data.data.message}`,
        });
    }
}

function* loginUser({ login, password, history }) {
    yield put({ type: CLEAR_AUTH_ERROR });
    try {
        const response = yield call(API.post, 'users/authenticate', {
            login,
            password,
        });
        yield put({ type: LOGIN_USER_SUCCESS, userData: response.data.data });
        yield put({ type: GET_USER_SAVED_ADDRESSES });

        history.push(`/account`);
    } catch (e) {
        yield put({
            type: AUTH_REQUEST_FAILED,
            message: `Error: ${e.response.data.data.message}`,
        });
    }
}

function* usersForgotPassword({ emailaddress }) {
    yield put({ type: CLEAR_AUTH_ERROR });
    try {
        const response = yield call(API.post, 'users/forgotPasswordEmail', {
            emailaddress,
        });
        yield put({
            type: USER_FORGOT_PASSWORD_SUCCESS,
            user: response.data.data,
        });
    } catch (e) {
        yield put({
            type: AUTH_REQUEST_FAILED,
            message: `Error: ${e.response.data.data.message}`,
        });
    }
}

function* updateCommunicationPreferences(data) {
    yield put({ type: CLEAR_AUTH_ERROR });
    try {
        const { commPrefs, authtoken } = data.payload;
        // Per Olo's API values, must be a string
        const commData = Object.assign(
            {},
            {
                emailreceipts: 'true',
                followups: 'true',
                marketingsms: 'false',
                optin: 'false',
                upsell: 'true',
            },
            commPrefs
        );

        const response = yield call(API.put, `users/${authtoken}/updateCommunicationPreferences`, commData);
        yield put({
            type: UPDATE_COMMUNICATION_PREFERENCES_SUCCESS,
            commPrefs: response.data.data,
        });
    } catch (e) {
        yield put({
            type: AUTH_REQUEST_FAILED,
            message: `Error: ${e.response.data.data.message}`,
        });
    }
}

function* logOut(history) {
    const { authData } = yield select();
    const { authtoken } = authData;
    const redirectTo = () => {
        history.push(`/`);
    };
    if (authtoken) {
        try {
            const response = yield call(API.delete, `users/${authtoken}`);
            yield put({ type: CLEAR_AUTH_DATA });
            yield call(redirectTo);
        } catch (e) {
            yield put({
                type: AUTH_REQUEST_FAILED,
                message: `Error: ${e.response}`,
            });
        }
    } else {
        yield call(redirectTo);
    }
}

function* watchRootSagas() {
    yield all([
        takeLatest(CREATE_USER_ACCOUNT, createUserAccount),
        takeLatest(LOGIN_USER, loginUser),
        takeLatest(GET_LOG_OUT, logOut),
        takeLatest(USER_FORGOT_PASSWORD, usersForgotPassword),
        takeLatest(UPDATE_COMMUNICATION_PREFERENCES, updateCommunicationPreferences),
    ]);
}

export default watchRootSagas;
