import { all, fork } from 'redux-saga/effects';
import rootSagas from './rootSagas';
import menuSagas from './menuSagas';
import cartSagas from './cartSagas';
import restaurantSaga from './restaurantSagas';
import authSaga from './authSaga';

export default function* rootSaga() {
    yield all([fork(rootSagas), fork(menuSagas), fork(cartSagas), fork(restaurantSaga), fork(authSaga)]);
}
