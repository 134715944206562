import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { TextInput, Label, Button, Text } from '@components';
import { useWindowSize } from '@hooks';

import ToggleCheckboxes from 'components/GlobalComponents/Toggles/TermsEmailCheckboxes';
import { createUserAccount, updateCommunicationPreferences } from '@store/actions/authActions';

export const CreateAccountForm = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.authData.loggedIn);
    const authtoken = useSelector((state) => state.authData.authtoken);
    const apiError = useSelector((state) => state.authData.error);
    const apiErrorMessage = apiError?.message;
    const formRef = useRef(null);
    const scrollToErrorRef = () =>
        window.scrollTo({
            left: 0,
            top: formRef.current.offsetTop - 50,
            behavior: 'smooth',
        });

    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [emailaddress, setEmailaddress] = useState('');
    const [contactnumber, setContactnumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [terms, setTerms] = useState(false);
    const [optin, setOptin] = useState(false);

    const viewPort = useWindowSize().width;
    const breakpoint = 1024;
    const isMobile = viewPort < breakpoint;

    const getPasswordError = () => {
        if (password !== confirmPassword) {
            return 'The passwords you supplied do not match';
        }
    };

    const getInputState = (attr) => (formSubmitted && !attr ? 'error' : 'default');

    const handleInputOnChange = (setInputCallback, value) => {
        setInputCallback(value);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (Boolean(firstname && lastname && emailaddress && contactnumber && password && confirmPassword && terms)) {
            setValid(true);
        } else {
            setValid(false);
        }

        setFormSubmitted(true);
    };

    // Check for API errors
    useEffect(() => {
        if (apiError && apiErrorMessage) {
            setFormSubmitted(false);
            setLoading(false);
            scrollToErrorRef();
        }
    }, [apiError, apiErrorMessage, scrollToErrorRef]);

    // Check if user is created and logged in
    useEffect(() => {
        if (userLoggedIn && Boolean(authtoken) && optin) {
            // Value must be a string
            dispatch(updateCommunicationPreferences(authtoken, { optin: 'true' }));
            history.push('/account');
        } else if (userLoggedIn && Boolean(authtoken)) {
            history.push('/account');
        }
    }, [userLoggedIn, authtoken, optin, history, dispatch]);

    // Finally submit the form when everything checks out
    useEffect(() => {
        if (valid && formSubmitted) {
            setLoading(true);
            dispatch(
                createUserAccount({
                    firstname,
                    lastname,
                    emailaddress,
                    contactnumber,
                    password,
                })
            );
        }
    }, [
        valid,
        formSubmitted,
        setLoading,
        dispatch,
        createUserAccount,
        firstname,
        lastname,
        emailaddress,
        contactnumber,
        password,
    ]);

    return (
        <>
            <form
                className="w-full px-4 mt-7 lg:mt-16 flex flex-col content-center justify-center"
                noValidate
                onSubmit={handleFormSubmit}
                ref={formRef}
            >
                {apiErrorMessage && (
                    <>
                        <div className="text-status-error mb-4">{apiErrorMessage}</div>
                        <hr className="mb-4 border-gray" />
                    </>
                )}
                <Label label="First Name" type="primary">
                    <TextInput
                        className="mt-1"
                        type="text"
                        name="firstname"
                        required
                        requiredMessage="Please supply a first name"
                        errorMessage="First Name cannot be longer than 36 characters"
                        value={firstname}
                        state={getInputState(firstname)}
                        onChange={(e) => handleInputOnChange(setFirstname, e.target.value)}
                        maxLength={36}
                    />
                </Label>
                <Label label="Last Name" type="primary">
                    <TextInput
                        className="mt-1"
                        type="text"
                        name="lastname"
                        required
                        requiredMessage="Please supply a last name"
                        errorMessage="Last Name cannot be longer than 36 characters"
                        value={lastname}
                        state={getInputState(lastname)}
                        onChange={(e) => handleInputOnChange(setLastname, e.target.value)}
                        maxLength={36}
                    />
                </Label>
                <Label label="Email Address" type="primary">
                    <TextInput
                        className="mt-1"
                        type="email"
                        name="emailaddress"
                        required
                        errorMessage="Please supply a valid email address"
                        value={emailaddress}
                        state={getInputState(emailaddress)}
                        onChange={(e) => handleInputOnChange(setEmailaddress, e.target.value)}
                        maxLength={128}
                    />
                </Label>
                <Label label="Phone Number" type="primary">
                    <TextInput
                        className="mt-1"
                        type="tel"
                        name="contactnumber"
                        required
                        requiredMessage="Please supply a phone number"
                        errorMessage="Please enter a valid phone number"
                        value={contactnumber}
                        state={getInputState(contactnumber)}
                        onChange={(e) => handleInputOnChange(setContactnumber, e.target.value)}
                        maxLength={24}
                        placeholder="(XXX) XXX-XXXX"
                    />
                </Label>
                <Label label="Password (min 8 characters)" type="primary">
                    <TextInput
                        className="mt-1"
                        type="password"
                        name="password"
                        required
                        requiredMessage="Please supply a password (min 8 chars)"
                        errorMessage="Passwords must be at least 8 characters long"
                        value={password}
                        state={getInputState(password)}
                        onChange={(e) => handleInputOnChange(setPassword, e.target.value)}
                        minLength={8}
                        maxLength={1024}
                    />
                </Label>
                <Label label="Confirm Password" type="primary">
                    <TextInput
                        className="mt-1"
                        type="confirmPassword"
                        name="confirmPassword"
                        required
                        requiredMessage="Please confirm your password"
                        errorMessage={getPasswordError()}
                        value={confirmPassword}
                        state={getInputState(confirmPassword)}
                        onChange={(e) => handleInputOnChange(setConfirmPassword, e.target.value)}
                    />
                </Label>

                <div className="mt-7 mb-16 lg:mb-11 lg:mt-9">
                    <ToggleCheckboxes
                        {...{
                            optin,
                            setOptin,
                            terms,
                            setTerms,
                            error: formSubmitted && !valid,
                        }}
                    />
                </div>

                <Button className="mb-12 lg:mb-10 h-13 lg:h-15 rounded-sm" type="primary" loading={loading}>
                    Create Account
                </Button>

                {!isMobile && (
                    <div className="pb-34">
                        <Text type="xs" className="leading-4">
                            By creating your Taco Mac account, you agree to the Terms of Use and Privacy Policy. Taco
                            Mac may send email communications about your account activity and other updates from time to
                            time. These email communications may be disabled on your My Account page.
                        </Text>
                    </div>
                )}
            </form>
        </>
    );
};
