import * as types from '../types';

let initialState = {
    nearbyRestaurants: [],
    restaurantOperatingHours: [],
    isPickup: true,
    error: false,
};

const setNearbyRestaurants = (state, action) => {
    return Object.assign({}, state, { nearbyRestaurants: action.data });
};

const setRestaurantsOperatingHours = (state, action) => {
    return Object.assign({}, state, { restaurantOperatingHours: action.data });
};

const setOrderType = (state) => {
    return Object.assign({}, state, { isPickup: !state.isPickup });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_NEARBY_RESTAURANTS:
            return setNearbyRestaurants(state, action);
        case types.SET_RESTAURANTS_OPERATING_HOURS:
            return setRestaurantsOperatingHours(state, action);
        case types.TOGGLE_PICKUP:
            return setOrderType(state, action);
        default:
            return state;
    }
    return state;
};

export default reducer;
