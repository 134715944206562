import React, { useState } from 'react';
import { StyledArrowRight } from './style';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './css/PureReactSlider.css';
import { AddItem } from './AddItem';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

export const Carousel = ({ items, setUpsellItem }) => {
    const flatMenu = useSelector((state) => state.menuData.menu.flatMenu);
    items.sort(() => Math.random() - 0.5);

    items = items.map((item) => {
        const additionalData = find(flatMenu, (o) => {
            return item.id == o.id;
        });
        const mergedItem = { ...additionalData, ...item };
        return mergedItem;
    });

    return (
        <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            playDirection={['forward' | 'backward']}
            visibleSlides={3}
            step={1}
            totalSlides={items.length}
            currentSlide={0}
        >
            <ButtonBack className="arrow arrow-rotate">
                <StyledArrowRight />
            </ButtonBack>
            <Slider className="slider-container" classNameTray="slider-container__trayUl">
                {items?.map((item, i) => {
                    return (
                        <Slide className="flex slider-slide" index={i} key={i} onClick={() => setUpsellItem(item)}>
                            <AddItem
                                key={item.id}
                                onClick={setUpsellItem}
                                itemPhoto={item.imagefilename}
                                itemName={item.name}
                                price={item.cost}
                                calories={item.basecalories !== null ? `/ ${item.basecalories} cal` : ''}
                            />
                        </Slide>
                    );
                })}
            </Slider>
            <ButtonNext className="arrow">
                <StyledArrowRight />
            </ButtonNext>
        </CarouselProvider>
    );
};
