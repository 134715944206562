import React from 'react';
import cx from 'classnames';

const TextType = {
    xs: 'text-sm font-futuraLT text-darkGray',
    xsWhite: 'text-sm font-futuraLT text-white leading-4 lg:text-lg leading-6',
    xsWhiteFutura: 'text-sm font-futura text-white leading-4 lg:text-lg lg:leading-6',
    sm: 'font-futuraConMD text-sm text-black font-normal',
    smBlk: 'font-futura text-sm text-black font-normal',
    smLT: 'font-futuraLT text-base text-black font-normal',
    smRed: 'text-sm lg:text-base font-futuraConMD text-primaryColor font-medium uppercase tracking-normal leading-6',
    md: 'text-2xl font-veneer text-black font-normal leading-8',
    lg: 'text-4xl font-veneer text-white font-normal leading-8',
    lgBlk: 'text-lg font-futura text-black font-normal leading-8',
    lgBlkHY: 'text-sm lg:text-lg font-futuraHY text-black font-normal leading-5',
    lgRed: 'text-lg lg:text-2xl uppercase self-end font-futuraConMD text-primaryColor font-medium leading-8',
};
const Text = ({ children, type, className }) => {
    const classNames = TextType[type];
    return <div className={cx(classNames, className)}>{children}</div>;
};

export default Text;
