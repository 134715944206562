import * as types from '../types';

export const setCart = (data) => {
    return { type: types.SET_CART, ...data };
};
export const resetCart = (data) => {
    return { type: types.RESET_CART, ...data };
};
export const clearCart = () => {
    return { type: types.CLEAR_CART };
};
export const createCartFromFav = (data) => {
    return { type: types.CREATE_CART_FROM_FAV, ...data };
};
export const addItem = (data, redirect, history, location) => {
    return { type: types.ADD_ITEM, data, redirect, history, location };
};
export const updateItem = (data) => {
    return { type: types.UPDATE_ITEM, ...data };
};
export const removeItem = (data) => {
    return { type: types.REMOVE_ITEM, ...data };
};
export const incrementItem = (data) => {
    return { type: types.INCREMENT_ITEM, ...data };
};
export const decrementItem = (data) => {
    return { type: types.DECREMENT_ITEM, ...data };
};
export const getUpsell = (data) => {
    return { type: types.GET_UPSELL, ...data };
};
export const setUpsell = (data) => {
    return { type: types.SET_UPSELL, ...data };
};
export const getBillingOptions = (data) => {
    return { type: types.GET_BILLING_OPTIONS, ...data };
};
export const setBillingOptions = (data) => {
    return { type: types.SET_BILLING_OPTIONS, ...data };
};
export const transferBasket = (data) => {
    return { type: types.TRANSFER_BASKET, ...data };
};
export const setCartLoading = (data) => {
    return { type: types.SET_CART_LOADING, ...data };
};
export const addUpsellItem = (data) => {
    return { type: types.ADD_UPSELL, ...data };
};
export const submitOrder = (data) => {
    return { type: types.SUBMIT_ORDER, ...data };
};
export const addFave = (data) => {
    return { type: types.ADD_FROM_FAVE, ...data };
};
export const setBasketTimeAsap = (data) => {
    return { type: types.SET_BASKET_TIME_ASAP, ...data };
};
export const setTip = (data) => {
    return { type: types.SET_TIP, ...data };
};
export const setDispatchAddress = (data) => {
    return { type: types.SET_DISPATCH_ADDRESS, ...data };
};
export const setDeliveryAddress = (data) => {
    return { type: types.SET_DELIVERY_ADDRESS, ...data };
};
export const setTimeWanted = (data) => {
    return { type: types.SET_TIME_WANTED, ...data };
};
export const setTimeWantedAsap = (data) => {
    return { type: types.SET_TIME_WANTED_ASAP };
};
export const addPrevious = (data) => {
    return { type: types.ADD_PREVIOUS, ...data };
};
export const addFavorite = (data) => {
    return { type: types.ADD_FAVORITE, ...data };
};
export const addUpsell = (data) => {
    return { type: types.ADD_UPSELL, ...data };
};
export const setDefaultDeliveryAddressSet = (data) => {
    return { type: types.SET_DEFAULT_DELIVERY_ADDRESS_SET, ...data };
};
export const setOrderSuccess = ({ data, history, location }) => {
    return { type: types.SET_ORDER_SUCCESS, data, history, location };
};
export const setCouponCode = (data) => {
    return { type: types.SET_COUPON_CODE, ...data };
}

// export const  = (data) => {
//     return { type: types., ...data };
// };
