//Root
export const START_SESSION = 'START_SESSION';
export const SET_SESSION = 'SET_SESSION';
export const SET_LOADING = 'SET_LOADING';
export const SET_CART_VISIBLE = 'SET_CART_VISIBLE';
export const GET_USER_SAVED_ADDRESSES = 'GET_USER_SAVED_ADDRESSES';
export const SET_USER_SAVED_ADDRESSES = 'SET_USER_SAVED_ADDRESSES';
export const GET_USER_RECENT_RESTAURANTS = 'GET_USER_RECENT_RESTAURANTS';
export const SET_USER_RECENT_RESTAURANTS = 'SET_USER_RECENT_RESTAURANTS';
export const SET_USER_ADDRESS_QUERY = 'SET_USER_ADDRESS_QUERY';
export const SET_GENERIC_MODAL_MESSAGE = 'SET_GENERIC_MODAL_MESSAGE';

//Menu
export const SET_MENU = 'SET_MENU';
export const SELECT_LOCATION = 'SELECT_LOCATION';
export const SET_LOCATION = 'SET_LOCATION';

//Cart
export const SET_CART = 'SET_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const ADD_ITEM = 'ADD_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const CREATE_CART = 'CREATE_CART';
export const CREATE_CART_FROM_FAV = 'CREATE_CART_FROM_FAV';
export const GET_CART = 'GET_CART';
export const RESET_CART = 'RESET_CART';
export const GET_UPSELL = 'GET_UPSELL';
export const SET_UPSELL = 'SET_UPSELL';
export const GET_BILLING_OPTIONS = 'GET_BILLING_OPTIONS';
export const SET_BILLING_OPTIONS = 'SET_BILLING_OPTIONS';
export const TRANSFER_BASKET = 'TRANSFER_BASKET';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const INCREMENT_ITEM = 'INCREMENT_ITEM';
export const DECREMENT_ITEM = 'DECREMENT_ITEM';
export const SET_CART_LOADING = 'SET_CART_LOADING';
export const ADD_UPSELL_ITEM = 'ADD_UPSELL_ITEM';
export const SUBMIT_ORDER = 'SUBMIT_ORDER';
export const ADD_FROM_FAVE = 'ADD_FROM_FAVE';
export const SET_BASKET_TIME_ASAP = 'SET_BASKET_TIME_ASAP';
export const SET_TIP = 'SET_TIP';
export const SET_DISPATCH_ADDRESS = 'SET_DISPATCH_ADDRESS';
export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS';
export const SET_TIME_WANTED = 'SET_TIME_WANTED';
export const SET_TIME_WANTED_ASAP = 'SET_TIME_WANTED_ASAP';
export const ADD_PREVIOUS = 'ADD_PREVIOUS';
export const ADD_FAVORITE = 'ADD_FAVORITE';
export const ADD_UPSELL = 'ADD_UPSELL';
export const SET_ORDER_SUCCESS = 'SET_ORDER_SUCCESS';
export const SET_DEFAULT_DELIVERY_ADDRESS_SET = 'SET_DELIVERY_ADDRESS_SET';
export const SET_COUPON_CODE = 'SET_COUPON_CODE';

//Restaurant
export const SET_RESTAURANTS = 'SET_RESTAURANTS';
export const GET_RESTAURANTS = 'GET_RESTAURANTS';
export const SET_NEARBY_RESTAURANTS = 'SET_NEARBY_RESTAURANTS';
export const GET_NEARBY_RESTAURANTS = 'GET_NEARBY_RESTAURANTS';
export const SET_RESTAURANTS_OPERATING_HOURS = 'SET_RESTAURANTS_OPERATING_HOURS';
export const GET_RESTAURANTS_OPERATING_HOURS = 'GET_RESTAURANTS_OPERATING_HOURS';
export const TOGGLE_PICKUP = 'TOGGLE_PICKUP';
export const TOGGLE_MAP = 'TOGGLE_MAP';

//Auth
export const CREATE_USER_ACCOUNT = 'CREATE_USER_ACCOUNT';
export const SET_NEW_USER_ACCOUNT_SUCCESS = 'SET_NEW_USER_ACCOUNT_SUCCESS';
export const UPDATE_COMMUNICATION_PREFERENCES = 'UPDATE_COMMUNICATION_PREFERENCES';
export const UPDATE_COMMUNICATION_PREFERENCES_SUCCESS = 'UPDATE_COMMUNICATION_PREFERENCES_SUCCESS';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_FORGOT_PASSWORD_RESET = 'USER_FORGOT_PASSWORD_RESET';
export const AUTH_REQUEST_FAILED = 'AUTH_REQUEST_FAILED';
export const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA';
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';
export const GET_LOG_OUT = 'GET_LOG_OUT';
export const SET_USER_DATA = 'SET_USER_DATA';
