import React, { useState } from "react";
import { StyleTooltipContent } from './style';

const Tooltip = ({
    initialState,
    delay,
    children,
    direction,
    content
}) => {
  let timeout;
  const [active, setActive] = useState(initialState || false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper relative inline-block"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && (
        <StyleTooltipContent className="absolute bg-white left-1/2">
          {content}
        </StyleTooltipContent>
      )}
    </div>
  );
};

export default Tooltip;
