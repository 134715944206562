// In case you need to use a selector
// import also select from redux-saga/effects
// and then simplie yield select(yourSelector())

import { put, call, takeLatest, all } from 'redux-saga/effects';
import API from '@utils/API';

import { GET_RESTAURANTS_OPERATING_HOURS, GET_NEARBY_RESTAURANTS, GET_RESTAURANT_MENU } from '../types';

function* handleGetNearbyRestaurants(action) {
    try {
        const { data } = yield call(API.get, 'restaurants/near/', {
            params: {
                lat: action.lat,
                long: action.lng,
                limit: action.limit,
                radius: action.radius,
            },
        });
        yield put({
            type: 'SET_NEARBY_RESTAURANTS',
            data: data.data.restaurants,
        });
        // return {status: true, data}
    } catch (e) {
        // return {'status': false, e};
    }
}

function* handleRestaurantOperatingHours(action) {
    const restaurantId = action.restaurantId;
    const from = action.range.from;
    const to = action.range.to;
    try {
        const { data } = yield call(API.get, `restaurants/${restaurantId}/hours`, { params: { from: from, to: to } });
        yield put({
            type: 'SET_RESTAURANTS_OPERATING_HOURS',
            data: data.data.calendar,
        });
        // return {status: true, data}
    } catch (e) {
        // @todo: set error getting restaurant hours
        // return {'status': false, e};
    }
}

function* watchRootSagas() {
    yield all([
        takeLatest(GET_NEARBY_RESTAURANTS, handleGetNearbyRestaurants),
        takeLatest(GET_RESTAURANTS_OPERATING_HOURS, handleRestaurantOperatingHours),
    ]);
}

export default watchRootSagas;
