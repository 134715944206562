import React from 'react';
import {
    StyledCardBox,
    StyledCardType,
    StyledEndingIn,
    StyledBlackCircleCheckedIcon,
    StyledEmptyCircleIcon,
} from '../styles/index';

export const CardToggle = ({ active, card, index, onClick, setActive}) => {
    const toggleCard = () => {
        setActive(index);
        onClick(card);
    };
    const selected = (active === index);
    const selectedClasses = selected ? 'flex flex-row' : 'flex flex-row justify-around';
    const selectedStyles = selected ? {
        border: '1px solid black',
        borderRadius: '4px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.16)',
    } : {};

    return (
        <div className="mt-7">
            <StyledCardBox
                onClick={toggleCard}
                style={selectedStyles}
            >
                <div className={selectedClasses}>
                    <StyledCardType>{card.cardtype}</StyledCardType>
                    <StyledEndingIn>ending in {card.cardsuffix}</StyledEndingIn>
                </div>
                {selected ? (
                    <StyledBlackCircleCheckedIcon />
                ) : (
                    <StyledEmptyCircleIcon />
                )}
            </StyledCardBox>
        </div>
    );
};
