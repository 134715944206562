// In case you need to use a selector
// import also select from redux-saga/effects
// and then simplie yield select(yourSelector())

import { put, call, takeLatest, all, delay } from 'redux-saga/effects';
import flatten from 'lodash/flatten';
// import axios from 'axios';
import API from '@utils/API';

import { SELECT_LOCATION, SET_LOADING } from '../types';

function* fetchLocation(location) {
    try {
        const { data } = yield call(API.get, `restaurants/byslug/${location}`);
        yield put({ type: 'SET_LOCATION', data: data.data });
        return data;
    } catch (e) {
        let message = (e?.message)?e.message:"There's been an error with your request";
        yield put({ type: 'SET_GENERIC_MODAL_MESSAGE', data: message });
        return { status: false, e };
    }
}

function* fetchMenu(locationId) {
    try {
        const { data } = yield call(API.get, `restaurants/${locationId}/menu`);
        data.data.flatMenu = flatten(
            data.data.categories.map((category) => {
                return category.products.map((product) => {
                    return product;
                });
            })
        );
        yield put({ type: 'SET_MENU', data: data.data });
        return { status: true };
    } catch (e) {
        let message = (e?.message)?e.message:"There's been an error with your request";
        yield put({ type: 'SET_GENERIC_MODAL_MESSAGE', data: message });
        return { status: false, e };
    }
}

function* handleSelectLocation({ location }) {
    yield put({ type: 'SET_LOADING', status: true });
    yield delay(1000);
    let locationRequest = yield call(fetchLocation, location);
    let menuRequest = yield call(fetchMenu, locationRequest.data.id);
    yield put({ type: 'SET_LOADING', status: false });

    if (locationRequest && menuRequest) {
    } else {
        //Handle fetch error
    }
}

function* watchRootSagas() {
    yield all([takeLatest(SELECT_LOCATION, handleSelectLocation)]);
}

export default watchRootSagas;
