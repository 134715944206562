import React from 'react';
import { Button } from '@components';
import { StyledHeroCtn, StyledSubCtn, StyledHeroNavCtn, StyledHeroHeading } from './styles/index';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch,
    Redirect,
    useHistory,
    useLocation,
} from 'react-router-dom';

const AccountPageHero = ({ className, heading, cta, onClick, type }) => {
    const history = useHistory();
    const location = useLocation();
    return (
        <StyledHeroCtn>
            <StyledSubCtn className={className}>
                <StyledHeroHeading>{heading}</StyledHeroHeading>
                <Button onClick={onClick} type={type}>
                    {cta}
                </Button>
            </StyledSubCtn>
            <StyledHeroNavCtn>
                <Button
                    onClick={() => history.push('/account')}
                    size="xsmall"
                    className={`mr-5 lg:mr-12 focus:opacity-100 ${
                        location.pathname == '/account' ? 'text-white opacity-100' : ''
                    }`}
                    type={'sexternary'}
                >
                    {'Profile'}
                </Button>
                <Button
                    onClick={() => history.push('/account/past-orders')}
                    size="xsmall"
                    className={`mr-5 lg:mr-12 focus:opacity-100 ${
                        location.pathname == '/account/past-orders' ? 'text-white opacity-100' : ''
                    }`}
                    type={'sexternary'}
                >
                    {'Past Orders'}
                </Button>
                <Button
                    onClick={() => history.push('/account/favorites')}
                    size="xsmall"
                    className={`mr-5 lg:mr-12 focus:opacity-100 ${
                        location.pathname == '/account/favorites' ? 'text-white opacity-100' : ''
                    }`}
                    type={'sexternary'}
                >
                    {'Favorites'}
                </Button>
            </StyledHeroNavCtn>
        </StyledHeroCtn>
    );
};

export default AccountPageHero;
