// In case you need to use a selector
// import also select from redux-saga/effects
// and then simplie yield select(yourSelector())

import { put, call, takeLatest, all, select, delay } from 'redux-saga/effects';
import axios from 'axios';
import API from '@utils/API';
import find from 'lodash/find';
import {
    ADD_ITEM,
    CREATE_CART,
    RESET_CART,
    REMOVE_ITEM,
    INCREMENT_ITEM,
    DECREMENT_ITEM,
    SET_CART_LOADING,
    SET_CART_VISIBLE,
    ADD_FAVORITE,
    ADD_PREVIOUS,
    ADD_UPSELL,
    SET_TIME_WANTED,
    SET_TIME_WANTED_ASAP,
    SET_ORDER_SUCCESS,
    SET_DISPATCH_ADDRESS,
    SET_COUPON_CODE,
} from '../types';

function* createCart(vendorid, authtoken = '') {
    try {
        let { data } = yield call(API.post, `baskets/create`, {
            vendorid,
            authtoken,
        });
        data = data.data;
        data.vendorid = vendorid;
        yield put({ type: 'SET_CART', data: data });
        return { status: true, data };
    } catch (e) {
        return { status: false, e };
        //Test code
    }
}
function* resetCart() {}

function* addCartItem({ data, redirect, history, location }, upsell = false) {
    let { cartData, menuData, authData } = yield select();

    const redirectTo = () => {
        history.push(`/order/${location}/menu`);
    };
    yield put({ type: 'SET_CART_LOADING', status: true });
    yield delay(500);
    //currently an active cart
    if (!cartData.id || (menuData.location.id != cartData.vendorid && !upsell)) {
        // TODO: Validate the cart here...
        let createdCart = yield call(createCart, menuData.location.id, authData.authtoken);
        cartData = createdCart.data;
    }
    try {
        const addItem = yield call(API.post, `baskets/${cartData.id}/addSingleProduct`, {
            ...data,
            options: data?.options ? data.options : '',
        });
        yield put({ type: 'SET_CART', data: addItem.data });
        if (!upsell) {
            yield call(redirectTo);
        }
        yield put({ type: 'SET_CART_VISIBLE', status: true });
        // return {status: true, data}
    } catch (e) {
        let message = (e?.message)?e.message:"There's been an error with your request";
        yield put({ type: 'SET_GENERIC_MODAL_MESSAGE', data: message });
        console.log(e);
        // return {'status': false, e};
    }
    yield put({ type: 'SET_CART_LOADING', status: false });
}
function* addPrevious({ data, history }) {
    yield put({ type: 'SET_CART_LOADING', status: true });
    let { cartData, menuData, authData, rootData } = yield select();
    const { authtoken } = authData;
    const selectedLocationId = menuData.location.id;
    try {
        const addPrevious = yield call(API.post, `baskets/createFromPreviousOrder?authToken=${authtoken}`, {
            id: data.id,
            ignoreunavailableproducts: 'true',
        });
        yield put({ type: 'SET_CART', data: addPrevious.data });
        if (selectedLocationId != data.vendorid) {
            let newRestaurant = find(rootData.restaurants, (r) => {
                return r.id == data.vendorid;
            });
            if (data.from == 'menu') {
                history.push(`/order/${newRestaurant.slug}/menu/recents`);
            }

            yield put({
                type: 'SELECT_LOCATION',
                location: newRestaurant.slug,
            });
            yield delay(500);
        }
        yield put({ type: 'SET_CART_VISIBLE', status: true });
    } catch (e) {
        console.log(e);
        let message = (e?.message)?e.message:"There's been an error with your request";
        yield put({ type: 'SET_GENERIC_MODAL_MESSAGE', data: message });
    } finally {
        yield put({ type: 'SET_CART_LOADING', status: false });
    }
}
function* addFavorite({ data, history }) {
    yield put({ type: 'SET_CART_LOADING', status: true });
    let { cartData, menuData, authData, rootData } = yield select();
    const { authtoken } = authData;
    const selectedLocationId = menuData.location.id;
    try {
        const addFave = yield call(API.post, `baskets/createFromFave?authToken=${authtoken}`, {
            faveid: data.id,
            ignoreunavailableproducts: 'true',
        });
        yield put({ type: 'SET_CART', data: addFave.data });
        if (selectedLocationId != data.vendorid) {
            let newRestaurant = find(rootData.restaurants, (r) => {
                return r.id == data.vendorid;
            });
            if (data.from == 'menu') {
                history.push(`/order/${newRestaurant.slug}/menu/favorites`);
            }

            yield put({
                type: 'SELECT_LOCATION',
                location: newRestaurant.slug,
            });
            yield delay(500);
        }
        yield put({ type: 'SET_CART_VISIBLE', status: true });
    } catch (e) {
        console.log(e);
        let message = (e?.message)?e.message:"There's been an error with your request";
        yield put({ type: 'SET_GENERIC_MODAL_MESSAGE', data: message });
    } finally {
        yield put({ type: 'SET_CART_LOADING', status: false });
    }
}
function* addUpsell(data) {
    yield call(addCartItem, { data: { quantity: 1, productid: data.id } }, true);
}
function* removeCartItem(data) {
    let { cartData } = yield select();
    yield put({ type: 'SET_CART_LOADING', status: true });
    yield delay(500);
    try {
        const removeItem = yield call(API.delete, `baskets/${cartData.id}/products/${data.id}`);
        yield put({ type: 'SET_CART', data: removeItem.data });
    } catch (e) {
        console.log(e);
        let message = (e?.message)?e.message:"There's been an error with your request";
        yield put({ type: 'SET_GENERIC_MODAL_MESSAGE', data: message });
    }
    yield put({ type: 'SET_CART_LOADING', status: false });
}
function* setTimeWanted(data) {
    let { cartData } = yield select();

    yield put({ type: 'SET_CART_LOADING', status: true });

    try {
        const response = yield call(API.put, `baskets/${cartData.id}/setTimeWanted`, data);
        yield put({ type: 'SET_CART', data: response.data });
    } catch (e) {
        console.log(e);
        let message = (e?.message)?e.message:"There's been an error with your request";
        yield put({ type: 'SET_GENERIC_MODAL_MESSAGE', data: message });
        // return {'status': false, e};
    }

    yield put({ type: 'SET_CART_LOADING', status: false });
}
function* setTimeWantedAsap() {
    let { cartData } = yield select();

    yield put({ type: 'SET_CART_LOADING', status: true });

    try {
        const response = yield call(API.delete, `baskets/${cartData.id}/setTimeWanted`);
        yield put({ type: 'SET_CART', data: response.data });
    } catch (e) {
        console.log(e);
        let message = (e?.message)?e.message:"There's been an error with your request";
        yield put({ type: 'SET_GENERIC_MODAL_MESSAGE', data: message });
        // return {'status': false, e};
    }

    yield put({ type: 'SET_CART_LOADING', status: false });
}
function* setDispatchAddress(data) {
    let { cartData } = yield select();
    let payload = { id: data.id, isdefault: data.isdefault ,};

    yield put({ type: 'SET_CART_LOADING', status: true });


    if (payload.id === 0) {
        let { streetaddress, building, city, zipcode, phonenumber, specialinstructions } = data;
        payload = {...payload, streetaddress, building, city, zipcode, phonenumber, specialinstructions }
    }

    try {
        const response = yield call(API.put, `baskets/${cartData.id}/setDispatchAddress`, payload);
        yield put({ type: 'SET_CART', data: response.data });
    } catch (e) {
        console.log(e);
        let message = (e?.message)?e.message:"There's been an error with your request";
        yield put({ type: 'SET_GENERIC_MODAL_MESSAGE', data: message });
        // return {'status': false, e};
    }

    yield put({ type: 'SET_CART_LOADING', status: false });
}

function* setOrderSuccess({ data, history, location }) {
    const redirectTo = () => {
        history.push(`/checkout/${data.id}/confirmation`);
    };
    yield put({ type: 'SET_CART_LOADING', status: true });
    yield delay(500);
    yield put({ type: 'CLEAR_CART' });
    yield call(redirectTo);
    yield put({ type: 'SET_CART_LOADING', status: false });
}

function* setCouponCode({ code }) {
    let { cartData } = yield select();

    yield put({ type: 'SET_CART_LOADING', status: true });

    try {
        const response = yield call(API.put, `baskets/${cartData.id}/applyCoupon`, { code});

        yield put({ type: 'SET_CART', data: response.data });
    } catch (e) {
        yield put({ type: 'SET_GENERIC_MODAL_MESSAGE', data: e.message ?? 'There was an error applying your coupon code.' });
    }

    yield put({ type: 'SET_CART_LOADING', status: false });
}

function* watchRootSagas() {
    yield all([
        takeLatest(CREATE_CART, createCart),
        takeLatest(RESET_CART, resetCart),
        takeLatest(ADD_ITEM, addCartItem),
        takeLatest(REMOVE_ITEM, removeCartItem),
        takeLatest(ADD_PREVIOUS, addPrevious),
        takeLatest(ADD_FAVORITE, addFavorite),
        takeLatest(ADD_UPSELL, addUpsell),
        takeLatest(SET_TIME_WANTED, setTimeWanted),
        takeLatest(SET_TIME_WANTED_ASAP, setTimeWantedAsap),
        takeLatest(SET_ORDER_SUCCESS, setOrderSuccess),
        takeLatest(SET_DISPATCH_ADDRESS, setDispatchAddress),
        takeLatest(SET_COUPON_CODE, setCouponCode),
    ]);
}

export default watchRootSagas;
