import React from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import { StyledAsap, StyledText, StyledBlackCircleCheckedIcon, StyledEmptyCircleIcon } from '../styles/index';
import { setTimeWantedAsap } from '@store/actions';

export const AsapLaterToggle = ({ deliveryTimeToggle, setDeliveryTimeToggle, loading }) => {
    const dispatch = useDispatch();

    const asap = deliveryTimeToggle === 'asap';
    const containerStyles = classnames('w-full grid grid-cols-2 gap-1 lg:gap-4 pt-5', {
        'opacity-50 pointer-events-none': loading,
    });
    const selectedStyles = {
        border: '1px solid black',
        borderRadius: '4px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.16)',
    };

    const handleOnClick = (time) => {
        setDeliveryTimeToggle(time);
        if (time === 'asap') {
            dispatch(setTimeWantedAsap());
        }
    };

    const renderDeliveryTimeSelection = (text, styles) => {
        const handle = text.toLowerCase();

        return (
            <StyledAsap style={styles} onClick={() => handleOnClick(handle)}>
                <StyledText>{text}</StyledText>
                {styles ? <StyledBlackCircleCheckedIcon /> : <StyledEmptyCircleIcon />}
            </StyledAsap>
        );
    };

    return (
        <div className={containerStyles}>
            {!asap ? (
                <>
                    {renderDeliveryTimeSelection('ASAP')}
                    {renderDeliveryTimeSelection('Later', selectedStyles)}
                </>
            ) : (
                <>
                    {renderDeliveryTimeSelection('ASAP', selectedStyles)}
                    {renderDeliveryTimeSelection('Later')}
                </>
            )}
        </div>
    );
};
