import {
    AUTH_REQUEST_FAILED,
    LOGIN_USER_SUCCESS,
    SET_NEW_USER_ACCOUNT_SUCCESS,
    UPDATE_COMMUNICATION_PREFERENCES_SUCCESS,
    USER_FORGOT_PASSWORD_RESET,
    USER_FORGOT_PASSWORD_SUCCESS,
    CLEAR_AUTH_DATA,
    SET_USER_DATA,
    CLEAR_AUTH_ERROR,
    SET_USER_SAVED_ADDRESSES,
} from '../types';

const initialState = {
    authtoken: '',
    basketid: null,
    cardsuffix: null,
    contactnumber: '',
    emailaddress: '',
    firstname: '',
    lastname: '',
    loggedIn: false,
    reference: null,
    error: null,
    forgotPasswordRequested: false,
    userSavedDeliveryAddress: [],
    communicationPreferences: {
        marketingsms: false,
        optin: false,
        upsell: false,
        emailreceipts: false,
        followups: false,
    },
};
const setNewUserAccountSuccess = (state, action) => {
    const { user } = action;
    // Password comes as null from action, we should still ensure we don't
    // keep a reference to it
    delete user.password;

    return Object.assign({}, state, user, {
        error: null,
        loggedIn: true,
    });
};

const setUserData = (state, { firstname, lastname, emailaddress, contactnumber }) => {
    return Object.assign({}, state, {
        firstname,
        lastname,
        emailaddress,
        contactnumber,
    });
};

const loginUserSuccess = (state, action) => {
    const { userData } = action;

    return Object.assign({}, state, userData.user, {
        error: null,
        loggedIn: true,
    });
};

const userForgotPasswordSuccess = (state) => {
    return Object.assign({}, state, {
        error: null,
        forgotPasswordRequested: true,
    });
};

const userForgotPasswordReset = (state) => {
    return Object.assign({}, state, {
        error: null,
        forgotPasswordRequested: false,
    });
};

const updateCommunicationPreferencesSuccess = (state, action) => {
    const { commPrefs } = action;

    return Object.assign({}, state, {
        error: null,
        communicationPreferences: Object.assign({}, state.communicationPreferences, commPrefs),
    });
};

const authActionFailed = (state, action) => {
    return Object.assign({}, state, {
        error: {
            message: action.message,
        },
    });
};
const clearAuthError = (state, action) => {
    return Object.assign({}, state, {
        error: null,
    });
};
const clearAuthData = (state, action) => {
    return initialState;
};
const setSavedDeliveryAdresses = (state, action) => {
    return Object.assign({}, state, { userSavedDeliveryAddress: action.data });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NEW_USER_ACCOUNT_SUCCESS:
            return setNewUserAccountSuccess(state, action);
        case SET_USER_DATA:
            return setUserData(state, action);
        case LOGIN_USER_SUCCESS:
            return loginUserSuccess(state, action);
        case USER_FORGOT_PASSWORD_SUCCESS:
            return userForgotPasswordSuccess(state);
        case USER_FORGOT_PASSWORD_RESET:
            return userForgotPasswordReset(state);
        case UPDATE_COMMUNICATION_PREFERENCES_SUCCESS:
            return updateCommunicationPreferencesSuccess(state, action);
        case AUTH_REQUEST_FAILED:
            return authActionFailed(state, action);
        case CLEAR_AUTH_DATA:
            return clearAuthData(state, action);
        case CLEAR_AUTH_ERROR:
            return clearAuthError(state, action);
        case SET_USER_SAVED_ADDRESSES:
            return setSavedDeliveryAdresses(state, action);
        default:
            return state;
    }
};

export default reducer;
