import React from 'react';
import { AccountPageHero } from '@components';
import MainContainer from 'containers/components/MainContainer';
import PastOrders from './components/PastOrders';
import AccountProfile from './components/AccountProfile';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useRouteMatch, Redirect, useHistory, Link } from 'react-router-dom';

import { logOut } from '@store/actions';
import FavoritesAccount from './components/Favorites';

const Account = ({ onClick }) => {
    const { firstname } = useSelector((state) => state.authData);
    const dispatch = useDispatch();
    const history = useHistory();
    let { path, url } = useRouteMatch();

    const logOutUser = () => {
        dispatch(logOut(history));
    };

    return (
        <MainContainer>
            <AccountPageHero
                heading={`Hey, ${firstname}!`}
                onClick={logOutUser}
                type={'tertiaryWhite'}
                cta={'Sign Out'}
            />
            <Switch>
                <Route exact path={path} component={AccountProfile} />
                <Route path={`${path}/past-orders`} component={PastOrders} />
                <Route path={`${path}/favorites`} component={FavoritesAccount} />
                <Route path={`${path}/*`}>
                    <Redirect to="/" />
                </Route>
            </Switch>
            {/* <AccountProfile /> */}
            {/* <PastOrders /> */}
        </MainContainer>
    );
};

export default Account;
