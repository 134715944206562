import * as types from '../types';

let initialState = {
    location: null,
    menu: null,
    error: false,
};

const setLocation = (state, action) => {
    // return state.set('location', action.data);
    return Object.assign({}, state, { location: action.data });
};
const setMenu = (state, action) => {
    return Object.assign({}, state, { menu: action.data });
    // return state.set('menu', action.data);
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_LOCATION:
            return setLocation(state, action);
        case types.SET_MENU:
            return setMenu(state, action);
        default:
            return state;
    }
    return state;
};

export default reducer;
