import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useWindowSize } from '@hooks';
import { useSelector, useDispatch } from 'react-redux';
import { setCartVisible } from '@store/actions';

import { Logo, PickupLocationSelected, Avatar, ShoppingBag, BackToMenu } from '@components';

const StyledNav = styled.nav`
    ${tw`w-full text-primaryColor flex justify-between h-16 items-center`}
    @media (min-width: ${(p) => p.theme.screens.lg}) {
        height: 75px;
    }
`;

const CheckoutAndAuthCtn = styled.div`
    ${tw`w-1/2 lg:w-full flex items-center justify-end`}
`;

const PickupAndLogoCtn = styled.div`
    ${tw`w-1/2 lg:w-full flex items-center `}
`;

const CartItemCount = styled.span`
    ${tw`absolute text-white font-bold font-futura text-base`}
    top: 5px;
`;

const NavBar = () => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    const isRoot = location.pathname === '/';

    const viewPort = useWindowSize().width;
    const breakpoint = 1024;
    const isMobile = viewPort < breakpoint;
    const dispatch = useDispatch();

    const selectedLocation = useSelector((state) => state.menuData.location?.name);

    const showBackToMenu =
        selectedLocation && location.pathname != `/order/${params.location}/menu` && location.pathname != `/`;

    const cartData = useSelector((state) => state.cartData);
    return (
        <StyledNav>
            <PickupAndLogoCtn>
                <Logo
                    className="flex h-16 w-48"
                    isMobile={isMobile}
                    isRoot={isRoot}
                    selectedLocation={selectedLocation}
                    onClick={() => {
                        history.push('/');
                    }}
                />
                {showBackToMenu && <BackToMenu></BackToMenu>}
                {!isRoot && selectedLocation && <PickupLocationSelected selectedLocation={selectedLocation} />}
            </PickupAndLogoCtn>
            <CheckoutAndAuthCtn>
                <Avatar isMobile={isMobile} />
                <div className="flex items-center justify-center relative mr-4 lg:mx-4 cursor-pointer">
                    {selectedLocation ? (
                        <>
                            <ShoppingBag
                                onClick={() => {
                                    dispatch(setCartVisible(true));
                                }}
                                isMobile={isMobile}
                            />
                            <CartItemCount>
                                {cartData?.products && cartData?.products.length !== 0 && cartData.products.length}
                            </CartItemCount>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </CheckoutAndAuthCtn>
        </StyledNav>
    );
};

export default NavBar;
