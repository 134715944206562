import React, { useEffect, useContext } from 'react';
import { PickUpDeliveryToggle, Button, ProductImageHero } from '@components';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { MapIcon, ListIcon, ArrowRightIcon, SearchIcon, CloseRedIcon, LandingPageImageHero } from '@assets';
import { useSelector, useDispatch } from 'react-redux';
import LandingPageContext from '@containers/LandingPage/landingPageContext';
import { setPickupOrDelivery, setMap, getNearbyRestaurants } from '@store/actions';
import MapboxAutocomplete from 'react-mapbox-autocomplete';

const StyledCtn = styled.div`
    @media (min-width: ${(p) => p.theme.screens.lg}) {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
    }
`;

const StyledIcon = styled(ArrowRightIcon)`
    ${tw`ml-1`}
    @media (min-width: ${(p) => p.theme.screens.lg}) {
        fill: ${(p) => p.theme.colors.primaryColor};
    }
`;

const StyledAutoCompleteCtn = styled.div`
    ${tw`w-full`}
    &.mapbox-auto > div:first-child {
        height: 100%;
    }
    border: 1px solid ${(p) => p.theme.colors.lightGray};
    border-radius: 5px;
    height: 52px;

    input {
        ${tw`text-black text-base font-futuraLT outline-none h-full w-full`}
        text-indent: 10px;
        line-height: 22px;
        border-radius: 5px;
        &:placeholder {
            color: rgba(0, 0, 0, 0.5);
            opacity: 0.7;
        }
    }

    .react-mapbox-ac-suggestion {
        ${tw`bg-white border-none h-auto`}
    }
`;

const PickupDeliveryContainer = ({
    inputValue,
    setInputValue,
    isPickup,
    viewLocations,
    setViewLocations,
    isMobile,
}) => {
    const dispatch = useDispatch();
    const { showMap } = useSelector((state) => state.mapData);
    const { userSavedDeliveryAddress } = useSelector((state) => state.authData);
    const allLocationsHeroImg = 'https://olo-images-sandbox.imgix.net/25/25b641930ae6410d9ea1798c7392e88d.jpg';
    const landingPageContext = useContext(LandingPageContext);

    const [searchValue, setSearchValue] = landingPageContext.searchValue;

    const checkForDefaultDeliveryAddress = () => {
        // @todo: This needs to be refactored as neither checkForDefaultDeliveryAddress
        //  nor getStreetAddress are returning a value
        userSavedDeliveryAddress.filter((address) => getStreetAddress(address));
    };

    const getStreetAddress = (address) => {
        if (address.isdefault) {
            const { streetaddress, city, state, zipcode } = address;
            setInputValue(`${streetaddress}, ${city}, ${state}, ${zipcode}`);
        }
    };

    const displayAllLocations = () => {
        isMobile && dispatch(setMap());
        setViewLocations(true);
    };

    useEffect(() => {
        !isPickup &&
            userSavedDeliveryAddress &&
            userSavedDeliveryAddress.length > 0 &&
            checkForDefaultDeliveryAddress();
    }, [isPickup, userSavedDeliveryAddress]);

    const suggestionSelect = (result, lat, lng) => {
        let radius = 10;
        let limit = 10;
        setSearchValue(result);
        setInputValue(result);
        dispatch(getNearbyRestaurants(lat, lng, radius, limit));
    };

    return (
        <StyledCtn>
            <ProductImageHero src={viewLocations ? allLocationsHeroImg : LandingPageImageHero} />
            {!viewLocations && (
                <div className="bg-black flex flex-col items-center p-5 lg:bg-white lg:px-16 lg:py-10">
                    <PickUpDeliveryToggle
                        dispatch={dispatch}
                        setPickupOrDelivery={setPickupOrDelivery}
                        isPickup={isPickup}
                    />
                    <div className="w-full flex flex-row mt-5">
                        <StyledAutoCompleteCtn className="mapbox-auto">
                            <MapboxAutocomplete
                                publicKey={process.env.REACT_APP_MAPBOX_TOKEN}
                                onSuggestionSelect={suggestionSelect}
                                country="us"
                                query={inputValue}
                                resetSearch={false}
                                placeholder={isPickup ? 'Search Zip or City, State' : 'Add delivery address'}
                            />
                        </StyledAutoCompleteCtn>
                        <button
                            onClick={() => dispatch(setMap())}
                            className="ml-4 flex flex-row cursor items-center font-futuraLT text-white uppercase text-sm"
                        >
                            {showMap ? (
                                <>
                                    <ListIcon className="lg:hidden" />
                                    <p className="ml-1 lg:hidden">List</p>
                                </>
                            ) : (
                                <>
                                    <MapIcon className="lg:hidden" />
                                    <p className="ml-1 lg:hidden">Map</p>
                                </>
                            )}
                        </button>
                    </div>
                    <Button onClick={() => displayAllLocations()}>
                        <span className="font-futuraConMD text-white flex flex-row uppercase underline text-sm mt-5 items-center lg:text-primaryColor">
                            View All Locations <StyledIcon />
                        </span>
                    </Button>
                </div>
            )}
        </StyledCtn>
    );
};

export default PickupDeliveryContainer;
