import React from 'react';
import cx from 'classnames';

const SubHeadingType = {
    primary: 'text-primaryColor text-xl font-futuraConMD uppercase tracking-wider',
};

const SubHeading = ({ children, type, className }) => {
    const classNames = SubHeadingType[type];
    return <h3 className={cx(className, classNames)}>{children}</h3>;
};

export default SubHeading;
