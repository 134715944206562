/*global fbq*/
import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GenericContainerNarrow, Text, Button } from '@components';
import {
    StyledConfirmationHeader,
    StyledHeroCtn,
    StyledSubCtn,
    StyledOrderStatus,
    StyledOrderStatusDetail,
    StyledCreateAccountSection,
} from './styles/index';
import { OCDeliveryPickupDetails } from './subcomponents/DeliveryPickupDetails';
import find from 'lodash/find';
import { OrderSummary } from './subcomponents/OrderSummary';
import API from '@utils/API';
import _ from 'lodash';

const Confirmation = (props) => {
    const { orderId } = useParams();
    const { authtoken, loggedIn } = useSelector((state) => state.authData);
    const selectedLocation = useSelector((state) => state.menuData);
    const cartData = useSelector((state) => state.cartData.data);
    const [pastOrders, setPastOrders] = useState([]);
    const [loading, setLoading] = useState([]);
    const [orderConfirmation, setOrderConfirmation] = useState([]);

    //Restaurant Info:
    const [restaurantId, setRestaurantId] = useState('');
    const [storeStreetAddress, setStoreStreetAddress] = useState('');
    const [storeCity, setStoreCity] = useState('');
    const [storeState, setStoreState] = useState('');
    const [storeZip, setStoreZip] = useState('');
    const [storePhone, setStorePhone] = useState('');

    //TODO: SETTING LOCAL STATE TO TOGGLE ORDER STATUS DETAILS FOR NOW:
    const [orderReceived, setOrderReceived] = useState(true);
    const [orderPickUp, setOrderPickUp] = useState(false);
    const [orderDelivery, setOrderDelivery] = useState(false);

    let { path } = useRouteMatch();
    let history = useHistory();

    const handleCreateAccountBtn = (e) => {
        e.preventDefault();
        history.push(`/auth/create-account`);
    };

    const sendToGtm = (order, restaurant) => {
        const dataLayerData = {
            transactionId: order?.oloid,
            transactionAffiliation: restaurant?.name,
            transactionTotal: order?.total,
            transactionShipping: order?.totalfees,
            transactionTax: order?.salestax,
            transactionProducts: [],
        }

        order.products.map((product) => {
            const menuProduct = _.find(selectedLocation.menu.flatMenu, (menuItem) => {
                return (menuItem.name === product.name);
            });

            const menuCategory = _.find(selectedLocation.menu.categories, (menuItem) => {
                const productFound = _.find(menuItem.products, (productItem) => {
                    return (productItem.name === product.name);
                });

                return (productFound);
            });

            dataLayerData.transactionProducts.push({
                name: product?.name,
                sku: menuProduct?.id,
                category: menuCategory?.name,
                price: product?.totalcost,
                quantity: product?.quantity,
            });
        });

        window.dataLayer.push(dataLayerData);
    }

    useEffect(() => {
        orderConfirmation.map((order, i) => {
            if(typeof fbq !== 'undefined'){
                fbq('track', 'Purchase', {value: order.total, currency: 'USD'});
            }
        });
    })

    useEffect(() => {
        async function getOrderDetails() {

            const statusResult = await API.get(`/orders/${orderId}/status`);
            setRestaurantId(statusResult.data.data.vendorid);
            setOrderConfirmation([statusResult.data.data]);

            const restaurantResult = await API.get(`restaurants/`);

            const restaurantData = find(restaurantResult.data.data.restaurants, function(r) { return r.id === statusResult.data.data.vendorid; })
            setStoreStreetAddress(restaurantData.streetaddress);
            setStoreCity(restaurantData.city);
            setStoreState(restaurantData.state);
            setStoreZip(restaurantData.zip);
            setStorePhone(restaurantData.telephone);

            sendToGtm(statusResult.data.data, restaurantData);
        };

        getOrderDetails();

    }, [orderId]);

    return (
        <>
            <StyledConfirmationHeader className="mt-5 md:mt-0 px-4 md:px-0">
                <Text className={'font-veneer uppercase text-2xl md:text-5xl'}>Thanks for your order!</Text>
                <div>
                    <a
                        href={'/'}
                        target={'_blank'}
                        style={{ marginTop: '0' }}
                        className="inline font-futura flex-none text-primaryColor underline text-base md:text-lg"
                    >
                        Visit our website
                    </a>
                    <Text className="inline font-futura text-base md:text-lg">
                        {'  '} to give us a shout and let us know what you think!
                    </Text>
                </div>
            </StyledConfirmationHeader>
            <StyledHeroCtn>
                <StyledSubCtn>
                    <div className="w-1/3 flex flex-col justify-around md:items-center">
                        <StyledOrderStatus className="flex flex-col">
                            order received
                            {orderReceived && (
                                <StyledOrderStatusDetail>We have your order in our system</StyledOrderStatusDetail>
                            )}
                        </StyledOrderStatus>
                    </div>
                    <div className="w-1/3 flex flex-col justify-around md:items-center">
                        <StyledOrderStatus className="flex flex-col">
                            order pickup
                            {/* {orderReceived && (
							<StyledOrderStatusDetail>
								TODO: Add order status
                            </StyledOrderStatusDetail>
                        	)} */}
                        </StyledOrderStatus>
                    </div>

                    <div className="w-1/3 flex flex-col justify-around md:items-center">
                        <StyledOrderStatus className="flex flex-col">
                            out for delivery
                            {/* {orderReceived && (
                             <StyledOrderStatusDetail>
                                 TODO: Add order status
                             </StyledOrderStatusDetail>
                            )} */}
                        </StyledOrderStatus>
                    </div>
                </StyledSubCtn>
            </StyledHeroCtn>
            <GenericContainerNarrow>
                {!loggedIn && (
                    <StyledCreateAccountSection>
                        <div className="py-1">
                            <Text className="font-futuraHY leading-5 md:leading-6 text-base md:text-lg">
                                Create an Account
                            </Text>
                        </div>
                        <div className="pb-4 md:pb-5">
                            <Text className="font-futura leading-5 md:leading-6 text-base md:text-lg">
                                Create an account to save favorites, payment details, and view your full order history.
                            </Text>
                        </div>
                        <Button
                            type="invertedPrimary"
                            className="rounded-sm"
                            onClick={(e) => handleCreateAccountBtn(e)}
                        >
                            Create An Account
                        </Button>
                    </StyledCreateAccountSection>
                )}
                {orderConfirmation.map((order, i) => {
                    return (
                        <OCDeliveryPickupDetails
                            key={i}
                            deliveryMode={order.deliverymode}
                            deliveryAddress={order.deliveryaddress}
                            orderDeliveryPickupTime={order.readytime}
                            storeName={order.vendorname}
                            storeStreetAddress={storeStreetAddress}
                            storeCity={storeCity}
                            storeState={storeState}
                            storeZip={storeZip}
                            storePhone={storePhone}
                        />
                    );
                })}
                {orderConfirmation.map((order, i) => {
                    return (
                        <OrderSummary
                            key={i}
                            orderNumber={order.oloid}
                            orderTotal={`$${order.total}`}
                            //TODO: Missing Billing info
                            // card={'Amex'}
                            // cardSuffix={'0989'}
                        />
                    );
                })}
            </GenericContainerNarrow>
        </>
    );
};

export default Confirmation;
