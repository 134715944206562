import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import { useSelector } from 'react-redux';
import { PhotoCard, GenericContainer } from '@components';
import { useParams, useRouteMatch, Link } from 'react-router-dom';
import HeroContainer from '../components/heroContainer';
import FilterContext from '../components/filterContext';

import ItemContext from './context';

const Category = (props) => {
    let { categoryId } = useParams();
    let { url } = useRouteMatch();
    const childFilterContext = useContext(FilterContext);
    const menu = useSelector((state) => state.menuData.menu);
    let category = find(menu.categories, (c) => c.id == categoryId);

    return (
        <>
            <HeroContainer />
            <GenericContainer className="p-4 lg:p-8">
                {category &&
                    category.products.map((item, index) => {
                        let allergens = find(item.metadata, (metadata) => metadata.key == 'allergens');
                        if (allergens) {
                            allergens = allergens.value.split(',');
                        }
                        let selectedAllergens = flatMap(childFilterContext.filters[0], (o) => {
                            return o.key;
                        });
                        let intersectionSelectedAllergens = null;
                        if(allergens){
                            intersectionSelectedAllergens = allergens.filter((value) =>
                                selectedAllergens.includes(value)
                            );
                        }

                        let image = item?.images ? item?.images[1].filename : null;
                        return (
                            <PhotoCard
                                key={index}
                                allergens={intersectionSelectedAllergens}
                                image={image}
                                target={`${url}/${item.id}`}
                                item={item}
                            ></PhotoCard>
                        );
                    })}
            </GenericContainer>
        </>
    );
};

export default Category;
