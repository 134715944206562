import styled from 'styled-components';
import tw from 'tailwind.macro';
import { AvatarIcon, PasswordIcon, CreditCardIcon, DeliveryAddressIcon, MailIcon } from '@assets';

//////////---General Account Details---//////////

export const StyledAccountDetailsCtn = styled.div`
    ${tw`w-full flex flex-col lg:pt-12`}
`;
export const StyledDetailsContainer = styled.div`
    ${tw`py-6 px-4 border-b-2 border-gray lg:border-beige`}
`;
export const StyledHeadlineIconRow = styled.div`
    ${tw`w-full flex flex-row justify-between items-center`}
`;
export const StyledSimpleHeadline = styled.div`
    ${tw`flex flex-row items-center content-center pb-6`}
`;
export const StyledRedHeading = styled.div`
    ${tw`text-lg lg:text-2xl uppercase self-end font-futuraConMD text-primaryColor font-medium leading-8`}
`;

//////////---Icons---//////////

export const StyledProfileIcon = styled(AvatarIcon)`
    height: 30px;
    width: 30px;
    ${tw`mr-4`}
`;
export const StyledPasswordIcon = styled(PasswordIcon)`
    height: 30px;
    width: 30px;
    ${tw`mr-4`}
`;
export const StyledCreditCardIcon = styled(CreditCardIcon)`
    height: 30px;
    width: 30px;
    ${tw`mr-4`}
`;
export const StyledDeliveryIcon = styled(DeliveryAddressIcon)`
    height: 27px;
    width: 27px;
    ${tw`mr-4`}
`;
export const StyledMailIcon = styled(MailIcon)`
    ${tw`mt-2 mr-4`}
`;

//////////---User Info---//////////

export const StyledUserInfoDetails = styled.div`
    ${tw`flex flex-col pt-6 pb-9`}
`;
export const StyledUserName = styled.div`
    ${tw`text-2xl mr-2 font-veneer text-black font-normal leading-8`}
`;
export const StyledUserEmail = styled.div`
    ${tw`font-futuraLT text-base text-black font-normal`}
`;
export const StyledUserPhone = styled.div`
    ${tw`font-futuraLT text-base text-black font-normal`}
`;

//////////---Credit Cards---//////////

export const StyledCreditCardInfoContainer = styled.div`
    ${tw`flex flex-row w-full justify-between items-center py-3`}
`;
export const StyledCreditCardDetails = styled.div`
    ${tw`flex flex-row justify-center`}
`;
export const StyledCreditCardType = styled.div`
    ${tw`text-2xl mr-2 font-veneer text-black font-normal leading-8`}
`;
export const StyledEndingIn = styled.div`
    ${tw`font-futuraLT text-base text-black font-normal self-center`}
`;

//////////---Delivery Addresses---//////////

export const StyledAddressesContainer = styled.div`
    ${tw`flex flex-col w-full justify-between py-3`}
`;
export const StyledAddressRemoveRow = styled.div`
    ${tw`flex flex-row justify-between items-center`}
`;
export const StyledStreetAddress = styled.div`
    ${tw`text-lg mr-2 font-veneer text-black font-normal leading-8`}
`;
export const StyledAddressDetails = styled.div`
    ${tw`flex font-futuraLT text-base text-black font-normal`}
`;
export const StyledAddressExtras = styled.div`
    ${tw`flex font-futuraLT text-base text-black font-normal`}
`;
