import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    StyledSingleOrderContainer,
    StyledOrderInfoRow,
    StyledDateCtn,
    StyledOrderedFrom,
    StyledOrderQuantityPriceCtn,
    StyledOrderQuantity,
    StyledOrderPrice,
    StyledOrderDetailsCtn,
    StyledOrderDetails,
    StyledOrderCalories,
    StyledOrderDetailsExtras,
    StyledOrderTotalsCtn,
    StyledOrderTotalCost,
} from '../../styles/pastOrders-index';
import { Button } from '@components';

export const SingleOrder = ({
    order,
    cartOrderID,
    date,
    location,
    quantity,
    item,
    price,
    orderDetails,
    orderExtras,
    calories,
    totalCost,
    totalCalories,
    pastOrders,
    singleItems,
    itemChoices,
    addToBag,
}) => {
    const history = useHistory();

    const formatDate = (str) => {
        if (str) {
            let year = str.slice(0, 4);
            let month = str.slice(4, 6);
            let day = str.slice(6, 8);
            let newDate = month.concat('/', day).concat('/', year);
            return newDate;
        }
    };

    return (
        <>
            <StyledSingleOrderContainer>
                <StyledOrderInfoRow>
                    <div className="flex flex-row">
                        <StyledDateCtn>{formatDate(order.timeplaced)}</StyledDateCtn>
                        <StyledOrderedFrom>
                            <span className={'capitalize'}>{order.deliverymode}</span>
                            {` from ${order.vendorname}`}
                        </StyledOrderedFrom>
                    </div>
                </StyledOrderInfoRow>

                {order.products.map((product, i) => (
                    <div className={'mb-4'} key={i}>
                        <StyledOrderQuantityPriceCtn>
                            <StyledOrderQuantity>
                                <div>
                                    {product.quantity} x {product.name}
                                </div>
                            </StyledOrderQuantity>
                            <StyledOrderPrice>
                                <div>${product.totalcost}</div>
                            </StyledOrderPrice>
                        </StyledOrderQuantityPriceCtn>
                        <StyledOrderDetailsCtn>
                            <div className="flex flex-col">
                                <StyledOrderDetails></StyledOrderDetails>
                                <StyledOrderDetailsExtras>
                                    {product.choices.map((choice, i) => (
                                        <div key={i}>{choice.name}</div>
                                    ))}
                                </StyledOrderDetailsExtras>
                            </div>
                        </StyledOrderDetailsCtn>
                    </div>
                ))}
                <StyledOrderQuantityPriceCtn className="mb-6">
                    <StyledOrderQuantity>
                        <div>Total</div>
                    </StyledOrderQuantity>
                    <StyledOrderPrice>
                        <div>${order.total}</div>
                    </StyledOrderPrice>
                </StyledOrderQuantityPriceCtn>

                <Button type={'tertiary'} onClick={() => history.push(`/checkout/${cartOrderID}/order-details`)}>
                    + View Order Details
                </Button>

                <Button
                    className="w-full flex justify-center content-center items-center ml-auto mr-auto mt-4 mb-5 rounded-sm"
                    type="primary"
                    onClick={addToBag}
                >
                    Add to bag
                </Button>
            </StyledSingleOrderContainer>
        </>
    );
};
