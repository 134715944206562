import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, Button } from '@components';
import { useFormik, ErrorMessage, getIn } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import getStyles from '@global/helpers/getStyles';
import { ToggleCommPrefsCheckboxes } from 'components/GlobalComponents/Toggles/CommPrefs';
import { updateCommunicationPreferences } from '@store/actions';
import { UPDATE_COMMUNICATION_PREFERENCES_SUCCESS } from '@store/types';

export const ContactPreferences = ({ action, close, communicationPreferences }) => {
    const [loading, setLoading] = useState(false);
    const [upsell, setUpsell] = useState(communicationPreferences.upsell);
    const [optin, setOptin] = useState(communicationPreferences.optin);

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            optin: optin,
            upsell: upsell,
        },

        validationSchema: Yup.object({
            optin: Yup.string(),
            upsell: Yup.string(),
        }),
        onSubmit: (values) => {
            action(
                {
                    optin,
                    upsell,
                },
                (res) => {
                    //handleResponse
                    if (res.status === 'success') {
                        console.log(res);
                        dispatch({
                            type: UPDATE_COMMUNICATION_PREFERENCES_SUCCESS,
                            commPrefs: res.data.data,
                        });
                        close(false);
                    } else {
                        console.log(ErrorMessage);
                    }
                    setLoading(false);
                }
            );
        },
    });

    //NOTE: Not sure where or if we should have errors here for these checkboxes
    //since we're just opting in and out, still adding below

    return (
        <form onSubmit={formik.handleSubmit} className="w-full flex flex-col content-center justify-center" noValidate>
            <Text type="smLT">
                From time to time we send out details of promotions, discount specials and news of new locations.
            </Text>
            <div className="mt-7 lg:mt-9">
                <ToggleCommPrefsCheckboxes
                    {...{
                        optin,
                        setOptin,
                        upsell,
                        setUpsell,
                        formik,
                    }}
                />
                {/* {formik.errors.optin && formik.touched.optin ? (
                    <div className="mt-1 text-status-error">
                        {formik.errors.optin}
                    </div>
                ) : null}
                {formik.errors.upsell && formik.touched.upsell ? (
                    <div className="mt-1 text-status-error">
                        {formik.errors.upsell}
                    </div>
                ) : null} */}
            </div>
            <Button
                className="w-full flex justify-center content-center items-center m-auto my-12 lg:mb-10 rounded-sm"
                type="primary"
                loading={loading}
                // onClick={(e)=>handleContactPreferences(e)}
            >
                Save Changes
            </Button>
        </form>
    );
};
