import React from 'react';

import { Text, Button } from '@components';

import { StyledGenericContainer } from './styles';

export const AddToCartBar = ({ isDisabled, isMobile, calories, loading, name, onClick, price }) => {
    return (
        <div className="fixed bottom-0 z-10 bg-white w-full shadow-xlAlt">
            <StyledGenericContainer>
                <div className="flex justify-between items-center w-full">
                    {!isMobile && (
                        <div className="flex w-1/2">
                            <Text className="pr-2" type="md">
                                {name}
                            </Text>
                            <Text className="text-lg" type="smLT">
                                {calories} cal
                            </Text>
                        </div>
                    )}
                    <div className="flex items-center w-full justify-end lg:w-1/2">
                        <Button
                            type="primary"
                            className="w-full text-2xl lg:max-w-md"
                            loading={loading}
                            isDisabled={isDisabled}
                            onClick={onClick}
                        >
                            Add to bag
                            {Number(price) > 0 && <span>- ${Number(price).toFixed(2)}</span>}
                        </Button>
                    </div>
                </div>
            </StyledGenericContainer>
        </div>
    );
};
