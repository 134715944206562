import React from 'react';
import {
    StyledDeliveryPickupSection,
    StyledHeader,
    StyledDeliveryPickupTime,
    StyledStreetAddress,
    StyledStreetCityStateZip,
    StyledStoreInfo,
    StyledStoreDetails,
    StyledPhoneNumber,
} from '../styles/index';
import { Text } from '@components';

export const OCDeliveryPickupDetails = ({
    deliveryMode,
    deliveryAddress,
    orderDeliveryPickupTime,
    storeName,
    storeStreetAddress,
    storeCity,
    storeState,
    storeZip,
    storePhone,
}) => {
    //TODO: Format user delivery address, currently shows up NULL so can't tell from JSON
    const location = { storeStreetAddress, storeCity, storeState, storeZip };
    const formatDate = (str) => {
        if (str) {
            let year = str.slice(0, 4);
            let month = str.slice(4, 6);
            let day = str.slice(6, 8);
            let time = str.slice(9);
            time = time.split(':'); // convert to array

            // fetch
            var hours = Number(time[0]);
            var minutes = Number(time[1]);

            // calculate
            var timeValue;

            if (hours > 0 && hours <= 12) {
                timeValue = '' + hours;
            } else if (hours > 12) {
                timeValue = '' + (hours - 12);
            } else if (hours == 0) {
                timeValue = '12';
            }

            timeValue += minutes < 10 ? ':0' + minutes : ':' + minutes; // get minutes
            timeValue += hours >= 12 ? ' PM' : ' AM'; // get AM/PM

            let newDate = timeValue.concat(', ', month).concat('/', day).concat('/', year);
            return newDate;
        }
    };

    //Function to get Google Maps link with directions to recent order store:
    const getGoogleDirections = ({ storeStreetAddress, storeCity, storeState, storeZip }) => {
        const formattedAddress = `${storeStreetAddress} ${storeCity} ${storeState} ${storeZip}`;
        return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(formattedAddress)}`;
    };

    return (
        <>
            <StyledDeliveryPickupSection>
                {deliveryMode === 'pickup' ? (
                    <>
                        <StyledHeader>Pick Up Details</StyledHeader>
                        <StyledDeliveryPickupTime>
                            Ready at {formatDate(orderDeliveryPickupTime)}
                        </StyledDeliveryPickupTime>
                    </>
                ) : (
                    <>
                        <StyledHeader>Delivery Details</StyledHeader>
                        <StyledDeliveryPickupTime>
                            Delivery at {formatDate(orderDeliveryPickupTime)}
                        </StyledDeliveryPickupTime>
                    </>
                )}
                {deliveryMode === 'delivery' ? (
                    <>
                        <StyledStreetAddress>{deliveryAddress}</StyledStreetAddress>
                        <StyledStreetCityStateZip>{deliveryAddress}</StyledStreetCityStateZip>
                    </>
                ) : (
                    <div className="mb-4">
                        {' '}
                        <StyledStoreDetails className="py-2">
                            <Text className="font-futura font-semibold text-base md:text-lg leading-5 mb-0.5">
                                {storeName}
                            </Text>
                            <StyledStreetAddress>{storeStreetAddress}</StyledStreetAddress>
                            <StyledStreetCityStateZip style={{ marginBottom: 'unset' }}>
                                {storeCity}, {storeState} {storeZip}
                            </StyledStreetCityStateZip>
                        </StyledStoreDetails>
                    </div>
                )}

                <StyledStoreInfo>
                    <Text className="font-futuraHY leading-5 md:leading-6 text-base md:text-lg mb-4">Your Store</Text>
                    <a
                        href={getGoogleDirections(location)}
                        target={'_blank'}
                        className="flex h-5 items-center px-2 p-0 font-futuraConMD text-primaryColor uppercase text-sm border border-current rounded-sm border-solid"
                    >
                        <span className="leading-0">Get Directions</span>
                    </a>
                </StyledStoreInfo>
                <StyledStoreDetails>
                    <Text className="font-futura font-semibold text-base md:text-lg leading-5 mb-0.5">{storeName}</Text>
                    <StyledStreetAddress>{storeStreetAddress}</StyledStreetAddress>
                    <StyledStreetCityStateZip style={{ marginBottom: 'unset' }}>
                        {storeCity}, {storeState} {storeZip}
                    </StyledStreetCityStateZip>
                    <StyledPhoneNumber>{storePhone}</StyledPhoneNumber>
                </StyledStoreDetails>
            </StyledDeliveryPickupSection>
        </>
    );
};
