import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Generic = styled.div`
    ${tw`
        flex
        -mx-2
        flex-wrap
        max-w-screen-sm
        mx-auto
    `}
`;

const GenericContainerNarrow = (props) => {
    return <Generic className={props.className}>{props.children}</Generic>;
};

export default GenericContainerNarrow;
