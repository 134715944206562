import React, { useEffect, useCallback, useState } from 'react';
import { Button } from '@components';
import { useSpring, animated } from 'react-spring';
import { useHistory, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
    StyledHeroCtn,
    StyledSubCtn,
    StyledHeroNavCtn,
    StyledToggleCtn,
    StyledFilterIcon,
    StyledToggleText,
    StyledCloseWhiteIcon,
} from './styles/index';
import { useWindowSize } from '@hooks';
import { MenuFilter } from 'containers/Menu/components/menuFilter';

export const MenuHero = ({ onClick, children }) => {
    const [showFilter, setShowFilter] = useState(false);
    const history = useHistory();
    const selectedLocation = useSelector((state) => state.menuData.location);

    //for animated display of filter drop-down
    const animation = useSpring({
        config: {
            duration: 300,
        },
    });

    //toggling filter show/hide
    const openFilter = () => {
        setShowFilter((prev) => !prev);
    };

    //mobile view control
    const viewPort = useWindowSize().width;
    const breakpoint = 1024;
    const isMobile = viewPort < breakpoint;

    //to close filter by pressing 'esc' on keyboard
    const keyPress = useCallback(
        (e) => {
            if (e.key === 'Escape' && showFilter) {
                setShowFilter(false);
            }
        },
        [setShowFilter, showFilter]
    );
    const checkActive = (match, location) => {
        //some additional logic to verify you are in the home URI
        if (!location) return false;
        const { pathname } = location;
        return pathname === `/order/${selectedLocation.slug}/menu`;
    };

    //listening for 'esc' keypress
    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    return (
        <>
            <StyledHeroCtn>
                <StyledSubCtn>
                    <StyledHeroNavCtn>
                        <NavLink
                            to={`/order/${selectedLocation.slug}/menu`}
                            isActive={checkActive}
                            activeStyle={{
                                opacity: 1,
                            }}
                            className="mr-3 leading-8 sm:mr-5 text-2xl xs:text-3xl sm:text-4xl lg:text-5xl lg:mr-13 opacity-50 focus:opacity-100"
                        >
                            {'Menu'}
                        </NavLink>
                        <NavLink
                            to={`/order/${selectedLocation.slug}/menu/favorites`}
                            activeStyle={{
                                opacity: 1,
                            }}
                            className="mr-3 sm:mr-5 text-2xl xs:text-3xl sm:text-4xl lg:text-5xl lg:mr-15 opacity-50 focus:opacity-100"
                        >
                            {'Favorites'}
                        </NavLink>
                        <NavLink
                            to={`/order/${selectedLocation.slug}/menu/recents`}
                            activeStyle={{
                                opacity: 1,
                            }}
                            className="w-min mr-2 sm:mr-5 text-2xl xs:text-3xl sm:text-4xl lg:text-5xl lg:text-5xl opacity-50 focus:opacity-100"
                        >
                            {'Recents'}
                        </NavLink>
                    </StyledHeroNavCtn>
                    {children && (
                        <>
                            {!showFilter ? (
                                <StyledToggleCtn aria-label="Close filter" onClick={openFilter}>
                                    {!isMobile && <StyledToggleText>{'Filter'}</StyledToggleText>}
                                    <StyledFilterIcon></StyledFilterIcon>
                                </StyledToggleCtn>
                            ) : (
                                <StyledToggleCtn aria-label="Close filter" onClick={openFilter}>
                                    {!isMobile && <StyledToggleText>{'Close'}</StyledToggleText>}
                                    <StyledCloseWhiteIcon></StyledCloseWhiteIcon>
                                </StyledToggleCtn>
                            )}
                        </>
                    )}
                </StyledSubCtn>
            </StyledHeroCtn>
            {showFilter && children}
        </>
    );
};
