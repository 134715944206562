import React from 'react';
import styled from 'styled-components';

import getStyles from '@global/helpers/getStyles';
import { TextInput, Label } from '@components';
import { ForwardSlashIcon, CheckboxCheckedIcon, CheckboxUncheckedIcon } from '@assets';
import { Text } from '@components';

const StyledCardElement = styled.div`
    height: 52px;
`;

const StyledSelect = styled.select`
    height: 52px;
    bordercolor: rgba(196, 196, 196, 1);
    color: #a1aec0;
`;

const NewCreditCardForm = ({ formik, saveOnFile, setSaveOnFile }) => {
    const getCardMonths = () => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        return [...Array(12).keys()].map((m) => {
            const month = months[m];
            let value = m + 1;
            const label = value < 10 ? `0${value}` : value;
            return (
                <option value={value} key={m}>
                    {label} - {month}
                </option>
            );
        });
    };

    const getCardYears = () => {
        const today = new Date();
        const thisYear = +today.getFullYear().toString().substr(-2);

        // Return the next 10 years from today
        return [...Array(10).keys()].map((y) => (
            <option value={`20${thisYear + y}`} key={y}>
                {thisYear + y}
            </option>
        ));
    };

    const getUSStates = () => {
        return [
            'Alabama',
            'Alaska',
            'American Samoa',
            'Arizona',
            'Arkansas',
            'California',
            'Colorado',
            'Connecticut',
            'Delaware',
            'District of Columbia',
            'Federated States of Micronesia',
            'Florida',
            'Georgia',
            'Guam',
            'Hawaii',
            'Idaho',
            'Illinois',
            'Indiana',
            'Iowa',
            'Kansas',
            'Kentucky',
            'Louisiana',
            'Maine',
            'Marshall Islands',
            'Maryland',
            'Massachusetts',
            'Michigan',
            'Minnesota',
            'Mississippi',
            'Missouri',
            'Montana',
            'Nebraska',
            'Nevada',
            'New Hampshire',
            'New Jersey',
            'New Mexico',
            'New York',
            'North Carolina',
            'North Dakota',
            'Northern Mariana Islands',
            'Ohio',
            'Oklahoma',
            'Oregon',
            'Palau',
            'Pennsylvania',
            'Puerto Rico',
            'Rhode Island',
            'South Carolina',
            'South Dakota',
            'Tennessee',
            'Texas',
            'Utah',
            'Vermont',
            'Virgin Island',
            'Virginia',
            'Washington',
            'West Virginia',
            'Wisconsin',
            'Wyoming',
        ].map((s, i) => (
            <option value={s} key={i}>
                {s}
            </option>
        ));
    };

    return (
        <div className="w-full mt-5 lg:mt-7 flex flex-col content-center justify-center">
            <Label label="Card Number*" type="primary">
                <StyledCardElement id="paymentCC" className="paymentCC mt-1" />
            </Label>
            <Label label="Expiration*" type="primary">
                <div className="w-full flex flex-row items-center content-center justify-between">
                    <StyledSelect
                        className="mt-1 outline-none leading-5 pl-2 text-black text-base font-futuraLT border rounded-md w-full"
                        type="text"
                        name="paymentExpMonth"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.paymentExpMonth}
                    >
                        <option value="" disabled>
                            MM
                        </option>
                        {getCardMonths()}
                    </StyledSelect>
                    <span>
                        <ForwardSlashIcon className="m-2" />
                    </span>
                    <StyledSelect
                        className="mt-1 outline-none leading-5 pl-2 text-black text-base font-futuraLT border rounded-md w-full"
                        type="text"
                        name="paymentExpYear"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.paymentExpYear}
                    >
                        <option value="" disabled>
                            YY
                        </option>
                        {getCardYears()}
                    </StyledSelect>
                </div>
                {formik.errors.paymentExpMonth && formik.touched.paymentExpMonth ? (
                    <div className="mt-1 text-status-error">{formik.errors.paymentExpMonth}</div>
                ) : null}
                {formik.errors.paymentExpYear && formik.touched.paymentExpYear ? (
                    <div className="mt-1 text-status-error">{formik.errors.paymentExpYear}</div>
                ) : null}
            </Label>
            <Label label="CVV*" type="primary">
                <StyledCardElement id="paymentCVV" className="paymentCVV mt-1" />
            </Label>
            <Label label="Street Address*" type="primary">
                <TextInput
                    className="mt-1"
                    type="text"
                    name="paymentAddress"
                    withoutValidation
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.paymentAddress}
                    style={getStyles(formik, 'paymentAddress')}
					autocomplete="shipping address-line1"
                />
                {formik.errors.paymentAddress && formik.touched.paymentAddress ? (
                    <div className="mt-1 text-status-error">{formik.errors.paymentAddress}</div>
                ) : null}
            </Label>
            <Label label="Apt, Floor, Suite, Building" type="primary">
                <TextInput
                    className="mt-1"
                    type="text"
                    name="paymentAddress2"
                    withoutValidation
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.paymentAddress2}
					autocomplete="shipping address-line2"
                />
            </Label>
            <Label label="City*" type="primary">
                <TextInput
                    className="mt-1"
                    type="text"
                    name="paymentCity"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.paymentCity}
                    style={getStyles(formik, 'paymentCity')}
					autocomplete="shipping address-locality"
                />
                {formik.errors.paymentCity && formik.touched.paymentCity ? (
                    <div className="mt-1 text-status-error">{formik.errors.paymentCity}</div>
                ) : null}
            </Label>
            <div className="w-full grid grid-cols-2 gap-3 lg:gap-4">
                <Label label="State*" type="primary">
                    <StyledSelect
                        className="mt-1 outline-none leading-5 pl-2 text-black text-base font-futuraLT border rounded-md w-full"
                        type="text"
                        name="paymentState"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.paymentState}
						autocomplete="shipping address-region"
                    >
                        <option value="" disabled>
                            Select State
                        </option>
                        {getUSStates()}
                    </StyledSelect>
                    {formik.errors.paymentState && formik.touched.paymentState ? (
                        <div className="mt-1 text-status-error">{formik.errors.paymentState}</div>
                    ) : null}
                </Label>
                <Label label="Zip*" type="primary">
                    <TextInput
                        className="mt-1"
                        type="text"
                        name="paymentZip"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.paymentZip}
						autocomplete="shipping postal-code"
                    />
                    {formik.errors.paymentZip && formik.touched.paymentZip ? (
                        <div className="mt-1 text-status-error">{formik.errors.paymentZip}</div>
                    ) : null}
                </Label>
            </div>
            <div
                className="flex flex-row items-center py-0.5 cursor-pointer my-2"
                onClick={() => setSaveOnFile(!saveOnFile)}
            >
                <span>{saveOnFile ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}</span>
                <Text className="font-futura leading-4 text-xs px-2 text-black text-opacity-75">
                    Save card info for future orders
                </Text>
            </div>
        </div>
    );
};

export default NewCreditCardForm;
