import React, { useEffect, useRef, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { useFormik, ErrorMessage, getIn } from 'formik';
import * as Yup from 'yup';
import getStyles from '@global/helpers/getStyles';
import { TextInput, Label, Button } from '@components';
import { useDispatch } from 'react-redux';
import { setUserData } from '@store/actions';

export const EditPersonalDetailsForm = ({ action, close, firstname, lastname, emailaddress, contactnumber }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    //Passed down initialValues as props from modal
    const formik = useFormik({
        initialValues: {
            firstname: firstname,
            lastname: lastname,
            emailaddress: emailaddress,
            contactnumber: contactnumber ? contactnumber : '',
        },
        validationSchema: Yup.object({
            firstname: Yup.string(),
            lastname: Yup.string(),
            emailaddress: Yup.string().email('Please enter a vaild email'),
            contactnumber: Yup.number().typeError('Phone number must not include parenthesis or dashes'),
        }),
        onSubmit: (values) => {
            action(
                {
                    data: values,
                },
                (res) => {
                    //handleResponse
                    console.log(res);
                    if (res.status === 'success') {
                        dispatch(setUserData(res.user));
                        close(false);
                    } else {
                    }
                    setLoading(false);
                }
            );
        },
    });

    return (
        <>
            <form
                onSubmit={formik.handleSubmit}
                className="w-full flex flex-col content-center justify-center"
                noValidate
            >
                <Label label="First Name" type="primary" className="mt-0 mb-0">
                    <TextInput
                        className="mt-1"
                        type="text"
                        name="firstname"
                        withoutValidation
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstname}
                        style={getStyles(formik, 'firstname')}
                        maxLength={36}
                    />
                    {formik.errors.firstname && formik.touched.firstname ? (
                        <div className="mt-1 text-status-error">{formik.errors.firstname}</div>
                    ) : null}
                </Label>
                <Label label="Last Name" type="primary">
                    <TextInput
                        className="mt-1"
                        type="text"
                        name="lastname"
                        withoutValidation
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastname}
                        style={getStyles(formik, 'lastname')}
                        maxLength={36}
                    />
                    {formik.errors.lastname && formik.touched.lastname ? (
                        <div className="mt-1 text-status-error">{formik.errors.lastname}</div>
                    ) : null}
                </Label>
                <Label label="Email Address" type="primary">
                    <TextInput
                        className="mt-1"
                        type="email"
                        name="emailaddress"
                        withoutValidation
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.emailaddress}
                        style={getStyles(formik, 'emailaddress')}
                        maxLength={128}
                    />
                    {formik.errors.emailaddress && formik.touched.emailaddress ? (
                        <div className="mt-1 text-status-error">{formik.errors.emailaddress}</div>
                    ) : null}
                </Label>
                <Label label="Phone Number" type="primary">
                    <TextInput
                        className="mt-1"
                        type="text"
                        name="contactnumber"
                        withoutValidation
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.contactnumber}
                        style={getStyles(formik, 'contactnumber')}
                        maxLength={24}
                    />
                    {formik.errors.contactnumber && formik.touched.contactnumber ? (
                        <div className="mt-1 text-status-error">{formik.errors.contactnumber}</div>
                    ) : null}
                </Label>
                <Button
                    className="w-full flex justify-center content-center items-center m-auto my-12 lg:mb-10 rounded-sm"
                    type="primary"
                    loading={loading}
                    // onClick={(e) => handlePersonalDetails(e)}
                >
                    Save Changes
                </Button>
            </form>
        </>
    );
};
