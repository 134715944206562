import React from 'react';

import getStyles from '@global/helpers/getStyles';
import { TextInput, Label, Tooltip } from '@components';
import { RedQuestionIcon as Question } from '@assets';

const UserContactForm = ({ formik }) => {
    return (
        <div className="w-full mt-4 lg:mt-5 flex flex-col content-center justify-center">
            <Label label="Phone Number*" type="primary">
                <Tooltip content="Contact number is required only in case of issues regarding your order (e.g. items out of stock)." delay={250}>
                    <Question className="cursor-pointer ml-2" />
                </Tooltip>
                <TextInput
                    className="mt-1"
                    type="text"
                    name="contactPhone"
                    withoutValidation
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contactPhone || ''}
                    style={getStyles(formik, 'contactPhone')}
                    maxLength={24}
                    autocomplete="tel"
                />
                {formik.errors.contactPhone && formik.touched.contactPhone ? (
                    <div className="mt-1 text-status-error">
                        {formik.errors.contactPhone}
                    </div>
                ) : null}
            </Label>
        </div>
    );
};

export default UserContactForm;
