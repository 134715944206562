import React, { useState, useEffect } from 'react';
import { Text } from '@components';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { useSelector, useDispatch } from 'react-redux';
import { getRestaurantOperatingHours } from '@store/actions';
import { DateTime } from 'luxon';

const StyledRestaurantSubCtn = styled.div`
    ${tw`p-5`}
    border-bottom: 2px solid ${(p) => p.theme.colors.whiteSmoke};
`;

const StyledImg = styled.img`
    height: 50px;
    width: 50px;
    @media (min-width: 375px) {
        height: 86px;
        width: 86px;
    }
    border-radius: 50%;
`;

const RestaurantDetails = ({ restaurants }) => {
    const { restaurantOperatingHours } = useSelector((state) => state.restaurantData);

    const [openingHours, setOpeningTime] = useState('');
    const [closingTime, setClosingTime] = useState('');
    const [deliveryTime, setDeliveryTime] = useState('');

    const dispatch = useDispatch();
    const date = DateTime.local();

    const mapOverOperatingHours = () => {
        if (restaurantOperatingHours.length) {
            return restaurantOperatingHours.forEach((calendarType) => {
                return calendarType.ranges.forEach((operating) => {
                    if (calendarType.type === 'business') {
                        setOperatingHours(operating);
                    }
                    if (calendarType.type === 'dispatch') {
                        setDeliveryHours(operating);
                    }
                });
            });
        }
    };

    const setOperatingHours = (operating) => {
        const tomorrow = getTomorrowsDate();

        const startDate = operating.start.slice(0, 7);

        const convertedTime = setStartAndEndTime(operating);

        setOpeningTime(`${convertedTime[0]} - ${convertedTime[1]}`);
        setClosingTime(tomorrow === startDate && `tomorrow at ${convertedTime[0]}`);
    };

    const setDeliveryHours = (operating) => {
        const convertedTime = setStartAndEndTime(operating);
        setDeliveryTime(`${convertedTime[0]} - ${convertedTime[1]}`);
    };

    const getTodaysDate = () => {
        return date.toFormat('yyyyLLdd');
    };

    const getSixDaysInAdvance = () => {
        return date.plus({ days: 6 }).toFormat('yyyyLLdd');
    };

    const getTomorrowsDate = () => {
        return date.plus({ days: 1 }).toFormat('yyyyLLdd');
    };

    const getRangeOperatingDays = () => {
        const today = getTodaysDate();
        const aWeekInAdvance = getSixDaysInAdvance();
        return {
            from: today,
            to: aWeekInAdvance,
        };
    };

    const formatTime = (timeType) => {
        return timeType.slice(8, 14);
    };

    const setStartAndEndTime = (operating) => {
        const startTime = formatTime(operating.start);
        const endTime = formatTime(operating.end);
        return [convertToStandardTime(startTime), convertToStandardTime(endTime)];
    };

    const convertToStandardTime = (operatingTime) => {
        const time = operatingTime.split(':');
        const hours = time[0];
        const minutes = time[1];

        let timeValue;

        if (hours > 0 && hours <= 12) {
            timeValue = '' + hours;
        } else if (hours > 12) {
            timeValue = '' + (hours - 12);
        } else if (hours == 0) {
            timeValue = '12';
        }

        timeValue += minutes < 10 ? ':' + minutes : ':' + minutes;
        timeValue += hours >= 12 ? 'pm' : 'am';
        return timeValue;
    };

    useEffect(() => {
        const restaurantId = restaurants.id;
        const range = getRangeOperatingDays();
        dispatch(getRestaurantOperatingHours(restaurantId, range));
        mapOverOperatingHours();
    }, [restaurants.id]);

    return (
        <StyledRestaurantSubCtn className="flex justify-between">
            <div className="flex-1">
                <Text type="md">{restaurants.name}</Text>
                <Text type="smLT" className="xs:w-40 lg:w-full">
                    <span>{restaurants.streetaddress}</span> <span>{restaurants.city}</span>{' '}
                    <span>{restaurants.state}</span> <span>{restaurants.zip}</span>{' '}
                </Text>
                <a className="font-futuraLT underline" href={`tel:${restaurants.telephone}`}>
                    {restaurants.telephone.replace(' ', '-').replace(/[()]/g, '')}
                </a>
                {restaurants.iscurrentlyopen ? (
                    <Text type="xs" className="mt-3">
                        Open today {openingHours}
                    </Text>
                ) : (
                    <Text type="xs">Closed Today. Opens {closingTime}</Text>
                )}
                <Text type="xs">Delivery hours: {deliveryTime}</Text>
            </div>
            <div>
                <StyledImg src={restaurants.image} alt={restaurants.city} />
            </div>
        </StyledRestaurantSubCtn>
    );
};

export default RestaurantDetails;
