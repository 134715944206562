import styled from 'styled-components';
import tw from 'tailwind.macro';
import { FilterIcon, CloseWhiteIcon } from '@assets';

export const StyledHeroCtn = styled.div`
    ${tw`bg-black m-auto h-16 lg:h-32 -mt-1 flex flex-col justify-around lg:items-center lg:py-4`}
`;
export const StyledSubCtn = styled.div`
    margin: auto;
    ${tw`w-full py-5 px-4 lg:m-0 text-white font-veneer uppercase flex flex-row justify-between items-center content-center lg:max-w-screen-xl lg:px-8`}
`;
export const StyledHeroNavCtn = styled.nav`
    ${tw`w-full lg:px-4 flex leading-8 text-white justify-start items-center content-center lg:max-w-2xl lg:px-4`}
`;
export const StyledToggleCtn = styled.div`
    ${tw`w-min flex content-center items-center justify-center lg:px-4`}
`;
export const StyledToggleText = styled.p`
    ${tw`text-white py-0 px-2 text-2xl font-veneer uppercase flex cursor-pointer`}
`;
export const StyledFilterIcon = styled(FilterIcon)`
    ${tw``}
`;
export const StyledCloseWhiteIcon = styled(CloseWhiteIcon)`
    ${tw`z-10 h-7 w-7 lg:h-5 lg:w-5 cursor-pointer`}
`;
