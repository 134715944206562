import React from 'react';
import styled from 'styled-components';

const StyledImg = styled.img`
    height: ${(p) => {
        if (p.large) {
            return '300px';
        } else {
            return p.type === 'category' ? '250px' : '143px';
        }
    }};

    @media (min-width: ${(p) => p.theme.screens.lg}) {
        height: ${(p) => {
            if (p.large) {
                return '600px';
            } else {
                return p.type === 'category' ? '445px' : '233px';
            }
        }};
    }
    object-fit: cover;
    width: 100%;
`;

const ProductImageHero = ({ src, type, className, children, large }) => {
    return (
        <div>
            {children}
            <StyledImg type={type} large={large} className={className} src={src} alt="product-img" />
        </div>
    );
};

export default ProductImageHero;
