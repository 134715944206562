import styled from 'styled-components';
import tw from 'tailwind.macro';

export const StyledLoadingCtn = styled.div`
    ${tw`w-screen h-screen bg-primaryColor flex justify-center content-center items-center relative`}
    z-index: 30;
`;

export const StyledPageLoadingCtn = styled.div`
    ${tw`w-screen bg-white flex justify-center content-center items-center relative`}
    z-index: 20;
`;
