import React from 'react';

const LabelTypes = {
    primary: 'text-black text-sm font-futuraLT my-2',
};

const Label = ({ children, type, label }) => {
    const classNames = LabelTypes[type];
    return (
        <label className={classNames}>
            {label}
            {children}
        </label>
    );
};

export default Label;
