import React, { useEffect } from 'react';

import history from './global/services/history';
import Routes from './global/routes/index';
import { Router } from 'react-router-dom';
import { Transition, animated } from 'react-spring/renderprops';
import { Modal } from 'components/GlobalComponents/Modal';
import { Button } from '@components';
import { useSelector, useDispatch } from 'react-redux';

import { startSession, setGenericModalMessage } from '@store/actions';
import { LoadingPage } from 'components/GlobalComponents/Loading';

import LogRocket from 'logrocket';

if (process.env.NODE_ENV === 'production') {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
}

const App = () => {
    let { initialized } = useSelector((state) => state.rootData);
    const genericModalMessage = useSelector((state) => state.rootData.genericModalMessage);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startSession());
    }, [dispatch]);

    const handleModalClose = () => {
        dispatch(setGenericModalMessage(null));
    }

    return (
        <>
            <Transition
                items={initialized}
                from={{
                    opacity: 1,
                }}
                enter={{
                    opacity: 1,
                }}
                leave={{
                    opacity: 0,
                }}
                config={{
                    duration: 300,
                }}
            >
                {(initialized) =>
                    !initialized &&
                    ((props) => (
                        <div style={props} className="z-50 fixed">
                            <LoadingPage />
                        </div>
                    ))
                }
            </Transition>
            <Modal
                showModal={genericModalMessage}
                setShowModal={handleModalClose}
                hideCancel={true}
                headline={'Attention'}
                content={({ close }) => {
                    return(
                        <div>
                            <div className="text-xl">
                                {(typeof genericModalMessage === 'string' || genericModalMessage instanceof String) &&
                                    <span dangerouslySetInnerHTML={{ __html: genericModalMessage }}></span>
                                }
                            </div>
                            <Button
                                className="w-full flex justify-center content-center items-center m-auto my-12 lg:mb-10 rounded-sm"
                                type="primary"
                                onClick={handleModalClose}
                            >
                                Got It
                            </Button>
                        </div>
                    )

                }}
            ></Modal>
            <Router history={history}>
                <Routes />
            </Router>
        </>
    );
};

export default App;
