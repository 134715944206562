import React from 'react';
import {
    StyledFavoritesMainCtn,
    StyledFavorite,
    StyledHeadlineIconRow,
    StyledSimpleHeadline,
    StyledHeartFavoritesIcon,
    StyledOrderQuantity,
    StyledOrderDetailsCtn,
    StyledOrderDetails,
    StyledOrderDetailsExtras,
} from '../../styles/favorites-index';
import { StyledOrderedFrom } from '../../styles/pastOrders-index';

import { Button } from '@components';

export const SingleFavoriteOrder = ({
    favoriteItem,
    favorite,
    quantity,
    item,
    orderDetails,
    orderExtras,
    deleteFavorite,
    addFavoriteToBag,
}) => {
    return (
        <StyledFavoritesMainCtn>
            <StyledFavorite>
                <div>
                    <StyledHeadlineIconRow>
                        <div className="flex flex-row items-center">
                            <StyledHeartFavoritesIcon />
                            <StyledSimpleHeadline>{favorite.name}</StyledSimpleHeadline>
                        </div>
                        <Button
                            className="border rounded-sm"
                            type="quaternary"
                            size="small"
                            onClick={() => deleteFavorite(favorite.id)}
                        >
                            Delete
                        </Button>
                    </StyledHeadlineIconRow>
                    <div className={`pb-4 font-bold font-futuraLT text-sm leading-5 lg:text-base leading-6 mr-2`}>
                        Order From: <span className="font-futuraHY text-primaryColor">{favorite.vendorname}</span>
                    </div>
                    {favorite.products.map((fave, i) => (
                        <div key={i}>
                            <StyledOrderQuantity>{`${fave.quantity} x ${fave.name}`}</StyledOrderQuantity>
                            <StyledOrderDetailsCtn>
                                <div className="flex flex-col">
                                    <StyledOrderDetails>
                                        {fave.choices.map((choice, i) => (
                                            <div key={i}>{choice.name}</div>
                                        ))}
                                    </StyledOrderDetails>
                                </div>
                            </StyledOrderDetailsCtn>
                        </div>
                    ))}
                </div>

                <Button
                    className="w-full flex justify-center content-center items-center ml-auto mr-auto mt-8 mb-5 rounded-sm"
                    type="primary"
                    onClick={() => addFavoriteToBag(favorite)}
                >
                    Add To Bag
                </Button>
            </StyledFavorite>
        </StyledFavoritesMainCtn>
    );
};
