import styled from 'styled-components';
import tw from 'tailwind.macro';
import { EmptyCircleIcon, RedCheckedIcon } from '@assets';

export const StyledFilterCtn = styled.div`
    ${tw`w-full m-auto text-black flex flex-col items-center content-center lg:max-w-screen-xl lg:py-4 lg:px-8`}
`;
export const StyledTextCtn = styled.div`
    ${tw`w-full flex m-auto flex-col justify-start content-start items-start`}
`;
export const StyledHeaderText = styled.span`
    align-self: flex-start;
    ${tw`w-full m-auto py-1 lg:py-2 flex text-lg lg:text-3xl uppercase font-futuraConMD text-primaryColor font-medium leading-8`}
`;
export const StyledToggleItemsCtn = styled.div`
    ${tw`w-full lg:max-w-screen-xl`}
`;
export const StyledEmptyCircleIcon = styled(EmptyCircleIcon)`
    right: 18px;
    height: 25px;
    width: 25px;
`;
export const StyledRedCheckedIcon = styled(RedCheckedIcon)`
    right: 18px;
    height: 25px;
    width: 25px;
`;
export const StyledFilterItemBox = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    ${tw`h-12 md:h-13 lg:h-15 cursor-pointer w-full lg:max-w-66 px-4 lg:px-5 flex flex-row content-center items-center rounded-sm bg-white justify-between`}
`;
