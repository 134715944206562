import { compose, createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware, { END } from 'redux-saga';
import logger from 'redux-logger';

import rootReducer from './store/reducers';
import LogRocket from 'logrocket';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['rootData'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer());

const configureStore = (initialState = {}) => {
    const composeEnhancers =
        process.env.NODE_ENV !== 'production' &&
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  shouldHotReload: false,
              })
            : compose;

    const middlewares = [sagaMiddleware];
    if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_LOGGER) {
        middlewares.push(logger);
    }
    middlewares.push(LogRocket.reduxMiddleware());

    const enhancers = [applyMiddleware(...middlewares)];

    const store = createStore(persistedReducer, initialState, composeEnhancers(...enhancers));

    let persistor = persistStore(store);

    store.runSaga = sagaMiddleware.run;
    store.asyncReducers = {};
    store.close = () => store.dispatch(END);

    if (module.hot) {
        // Enable webpack hot module replacement for reducers
        module.hot.accept('./store/reducers', () => {
            import('./store/reducers').then((reducerModule) => {
                const createReducers = reducerModule.default;
                const nextReducers = createReducers(store.asyncReducers);
                store.replaceReducer(nextReducers);
            });
        });
    }

    return { store, persistor };
};

export default configureStore;
