import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PhotoCard, GenericContainer } from '@components';
import { useHistory, Link, useRouteMatch, useParams } from 'react-router-dom';
import HeroContainer from '../components/heroContainer';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { MenuHero } from '../../../components/GlobalComponents/Heros/MenuHero/index';
import { MenuFilter } from 'containers/Menu/components/menuFilter';
import FilterContext from '../components/filterContext';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';

const Menu = (props) => {
    const menu = useSelector((state) => state.menuData.menu);
    let history = useHistory();
    let { url } = useRouteMatch();
    const { location } = useParams();

    useEffect(() => {
        if (location === 'taco-mac-alpharetta-old-milton') {
            window.location.href = 'https://tacomac.olo.com/menu/taco-mac-alpharetta-old-milton?stay=y';
        }
    }, []);

    const childFilterContext = useContext(FilterContext);
    return (
        <>
            <HeroContainer displayName="Menu" />
            <MenuHero>
                <MenuFilter filters={childFilterContext.filters} />
            </MenuHero>
            <div className="md:p-4 lg:p-10 mx-auto">
                <GenericContainer className="max-w-xl">
                    {menu.categories.map((category, index) => {
                        let categoryAllergens = uniq(
                            flatMap(
                                filter(
                                    category.products,
                                    (product) =>
                                        product?.metadata &&
                                        find(product.metadata, (metadata) => metadata.key == 'allergens')
                                ),
                                (product) => {
                                    const allergens = find(product.metadata, (metadata) => metadata.key == 'allergens');
                                    if (allergens) {
                                        return allergens.value.split(',');
                                    }
                                }
                            )
                        );

                        let selectedAllergens = flatMap(childFilterContext.filters[0], (o) => {
                            return o.key;
                        });
                        let intersectionCategorySelectedAllergens = categoryAllergens.filter((value) =>
                            selectedAllergens.includes(value)
                        );
                        let image = find(category.metadata, { key: 'img' })
                            ? find(category.metadata, { key: 'img' }).value
                            : null;
                        return (

                            <PhotoCard
                                key={index}
                                allergens={intersectionCategorySelectedAllergens}
                                withUrl={true}
                                image={image}
                                target={`${url}/${category.id}`}
                                item={category}
                            ></PhotoCard>
                        );
                    })}
                </GenericContainer>
            </div>
        </>
    );
};

export default Menu;
