import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Button, Text, RestaurantDetails } from '@components';
import { LocationIcon } from '@assets';
import { useHistory } from 'react-router-dom';

const StyledCtn = styled.div`
    justify-content: ${(p) => (p.distance === 0 ? 'flex-end' : 'space-between')};
`;

const StyledRestaurantCtn = styled.div`
    ${tw`flex flex-col rounded-lg`}
    border: 2px solid ${(p) => p.theme.colors.whiteSmoke};
    margin: 11px 18px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
`;

const RestaurantContainerDetails = ({ restaurants }) => {
    const history = useHistory();
    return (
        <StyledRestaurantCtn key={restaurants.city}>
            <RestaurantDetails restaurants={restaurants} />
            <StyledCtn distance={restaurants.distance} className="flex flex-row mx-5 my-4 items-center">
                {restaurants.distance !== 0 && (
                    <div distance={restaurants.distance} className="flex items-center w-2/5 sm:w-auto">
                        <LocationIcon className="w-4 h-6 mr-1" />
                        <Text className="mr-6" type="sm">
                            {restaurants.distance} mi
                        </Text>
                    </div>
                )}
                <Button onClick={() => {
                    if (restaurants.slug === 'taco-mac-alpharetta-old-milton') {
                        window.location.href = 'https://tacomac.olo.com/menu/taco-mac-alpharetta-old-milton?stay=y';
                    } else {
                        history.push(`/order/${restaurants.slug}/menu`);
                    }
                }} type="primary">
                    Order From This Location
                </Button>
            </StyledCtn>
        </StyledRestaurantCtn>
    );
};

export default RestaurantContainerDetails;
