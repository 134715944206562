import React, { useEffect, useMemo, useState } from 'react';
import MapGL, { Marker } from 'react-map-gl';
import { useSelector } from 'react-redux';
import { MapPinIcon } from '@assets';
import { useHistory } from 'react-router-dom';

const Map = ({ isMobile }) => {
    const { restaurants } = useSelector((state) => state.rootData);
    const { showMap } = useSelector((state) => state.mapData);
    const history = useHistory();

    const [viewport, setViewport] = useState({
        latitude: 40.7055092,
        longitude: -74.0131178,
        width: '100%',
        height: isMobile ? '232px' : '100%',
        zoom: 10,
    });

    useEffect(() => {
        setTimeout(setUserLocation, 500);
    }, []);

    const setUserLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            let newViewport = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                zoom: 10,
            };
            setViewport(newViewport);
        });
    };

    // generate the markers with useMemo
    const markers = useMemo(() => restaurants.map(restaurant =>
        <Marker
            latitude={restaurant.latitude}
            longitude={restaurant.longitude}
            key={restaurant.streetaddress}
            anchor="bottom"
        >
            <MapPinIcon
                onClick={() => history.push(`/order/${restaurant.slug}/menu`)}
                className="cursor-pointer h-12"
            />
        </Marker>
    ), [restaurants]);

    return (
        <>
            {showMap && (
                <MapGL
                    {...viewport}
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                    onViewportChange={(viewport) => setViewport(viewport)}
                    onZoom={({ viewState }) => setViewport(viewState)}
                    onDrag={({ viewState }) => setViewport(viewState)}
                    mapStyle="mapbox://styles/mapbox/light-v8"
                    style={{ width: '100%', height: isMobile ? '232px' : '100%' }}
                >
                    {markers}
                </MapGL>
            )}
        </>
    );
};

export default Map;
