import React from 'react';
import { useHistory } from 'react-router-dom';

import MainContainer from 'containers/components/MainContainer';
import { GenericContainerNarrow, TextHero } from '@components';
import { CreateAccountForm } from '../components/CreateAccountForm';

const CreateAccount = () => {
    let history = useHistory();

    const handleHeroOnClick = (e) => {
        e.preventDefault();

        history.push('/auth');
    };

    return (
        <>
            <TextHero
                heading={'Create Account'}
                subHeading={'Already Have One?'}
                to={'/auth/login'}
                type={'tertiaryWhite'}
                cta={'Sign In'}
            />
            <GenericContainerNarrow>
                <CreateAccountForm />
            </GenericContainerNarrow>
        </>
    );
};

export default CreateAccount;
