import React, { useState } from 'react';
import {
    StyledAddItemBox,
    AddItemRow,
    AddItemInfoCtn,
    AddItemName,
    AddItemPrice,
    AddItemCals,
    StyledEmptyCircleIcon,
    StyledBlackCircleCheckedIcon,
    StyledCarouselItemBoxDesk,
    CarouselDesktopRow,
    CarouselItemInfoDesk,
    CarouselCtn,
    AddItemPhotoMobile,
    AddItemPhotoDesk,
} from './style';
import { useWindowSize } from '@hooks';

export const AddItem = ({ itemPhoto, itemName, price, calories }) => {
    const viewPort = useWindowSize().width;
    const breakpoint = 1024;
    const isMobile = viewPort < breakpoint;

    const [added, setAdded] = useState(false);

    const selectedStyles = {
        border: '1px solid black',
        borderRadius: '4px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.16)',
    };
    const styles = added ? selectedStyles : {};

    const ToggleAddItem = () => {
        setAdded((prev) => !prev);
    };

    // NOTE: to remove quotes from string
    itemPhoto = itemPhoto && itemPhoto.split(' ').join();

    return (
        <div className="w-full flex lg:w-auto" onClick={ToggleAddItem}>
            {isMobile ? (
                <div className="w-full flex flex-col">
                    <StyledAddItemBox style={styles}>
                        <AddItemRow>
                            <div className="flex overflow-hidden w-1/2 items-center content-center">
                                <AddItemPhotoMobile>
                                    <img src={`https://olo-images-sandbox.imgix.net/${itemPhoto}`} alt={itemName} />
                                </AddItemPhotoMobile>
                                <AddItemName
                                    style={{
                                        display: 'block',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        maxWidth: '180px',
                                    }}
                                >
                                    {itemName}
                                </AddItemName>
                            </div>

                            <div className="flex w-1/2 justify-end absolute right-0 mr-6 items-center content-end">
                                <AddItemPrice>+{price}</AddItemPrice>
                                <AddItemCals>{calories}</AddItemCals>
                                <span className="px-1">
                                    {!added ? <StyledEmptyCircleIcon /> : <StyledBlackCircleCheckedIcon />}
                                </span>
                            </div>
                        </AddItemRow>
                    </StyledAddItemBox>
                </div>
            ) : (
                <CarouselCtn>
                    <StyledCarouselItemBoxDesk style={styles}>
                        <AddItemPhotoDesk>
                            <img src={`https://olo-images-sandbox.imgix.net/${itemPhoto}`} alt={itemName} />
                        </AddItemPhotoDesk>

                        <CarouselDesktopRow>
                            <div className="w-full flex flex-col flex-1 justify-around items-start">
                                <AddItemName
                                    style={{
                                        display: 'block',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        maxWidth: '90px',
                                    }}
                                >
                                    {itemName}
                                </AddItemName>
                                <div className="w-full flex flex-1 flex-row bottom-0">
                                    <AddItemPrice>+{price}</AddItemPrice>
                                    <AddItemCals>{calories}</AddItemCals>
                                </div>
                            </div>
                            <span className="pl-2 mr-1 absolute ml-22">
                                {!added ? <StyledEmptyCircleIcon /> : <StyledBlackCircleCheckedIcon />}
                            </span>
                        </CarouselDesktopRow>
                    </StyledCarouselItemBoxDesk>
                </CarouselCtn>
            )}
        </div>
    );
};
