import * as types from '../types';

let initialState = {
    loading: true,
    initialized: false,
    error: false,
    cart: {
        isVisible: false,
    },
    genericModalMessage:null,
    restaurants: [],
    userAddressQuery: '',
    // userSavedDeliveryAddress: [],
    userRecentRestaurant: [],
};

const setInitialData = (state, action) => {
    return Object.assign({}, state, { ...action.data });
};
const setLoading = (state, action) => {
    return Object.assign({}, state, { loading: action.status });
};
const setGenericModalMessage = (state, action) => {
    return Object.assign({}, state, { genericModalMessage: action.data });
};
const setCartVisible = (state, action) => {
    document.body.style.overflow = (action.status)?'hidden':'auto';
    return Object.assign({}, state, {
        cart: { ...state.cart, isVisible: action.status },
    });
};
const setRestaurants = (state, action) => {
    return Object.assign({}, state, { restaurants: action.data });
};

const setUserRecentRestaurants = (state, action) => {
    return Object.assign({}, state, { userRecentRestaurant: action.data });
};
const setUserAddressQuery = (state, action) => {
    return Object.assign({}, state, { userAddressQuery: action.data });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_SESSION:
            return setInitialData(state, action);
        case types.SET_LOADING:
            return setLoading(state, action);
        case types.SET_CART_VISIBLE:
            return setCartVisible(state, action);
        case types.SET_RESTAURANTS:
            return setRestaurants(state, action);
        case types.SET_USER_RECENT_RESTAURANTS:
            return setUserRecentRestaurants(state, action);
        case types.SET_USER_ADDRESS_QUERY:
            return setUserAddressQuery(state, action);
        case types.SET_GENERIC_MODAL_MESSAGE:
            return setGenericModalMessage(state, action);
        default:
            return state;
    }
    return state;
};

export default reducer;
