import React, { useState, useEffect } from 'react';
import { Text } from '@components';
import find from 'lodash/find';
import reject from 'lodash/reject';
import {
    StyledFilterItemBox,
    StyledEmptyCircleIcon,
    StyledRedCheckedIcon,
} from '../../../../containers/Menu/components/styles/index';

export const MenuFilterToggle = ({ content, action, value, appliedFilters }) => {
    const [active, setActive] = useState(false);
    const setActiveLogic = () => {
        action((o) => {
            if (
                find(o, function (a) {
                    return a.key == value;
                })
            ) {
                return reject(o, function (a) {
                    return a.key == value;
                });
            } else {
                return [...o, { key: value, title: content }];
            }
        });
    };
    useEffect(() => {
        setActive(
            !!find(appliedFilters, function (a) {
                return a.key == value;
            })
        );
    }, [appliedFilters]);

    const selectedStyles = {
        border: '2px solid #C62032',
        borderRadius: '4px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.16)',
    };
    const styles = active ? selectedStyles : {};

    return (
        <div className={'w-full'}>
            <StyledFilterItemBox onClick={setActiveLogic} style={styles}>
                <Text type={'lgBlkHY'}>{content}</Text>
                {!active ? <StyledEmptyCircleIcon /> : <StyledRedCheckedIcon />}
            </StyledFilterItemBox>
        </div>
    );
};
