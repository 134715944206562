import React from 'react';
import { ThemeProvider } from 'styled-components';

import tailwindcss from '../../tailwind.config';

const theme = { ...tailwindcss.theme };

const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default Theme;
