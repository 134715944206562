import React, { useState, useEffect, useCallback } from 'react';
import { setCartVisible, removeItem, createFavorite, addUpsellItem } from '@store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { CloseRedIcon, LoaderRed } from '@assets';
import NumberFormat from 'react-number-format';
import { Button, Text, NavBar } from '@components';
import { MobileSwiper } from './subcomponents/MobileSwipe';
import Favorite from './subcomponents/Favorite';
import { Transition } from 'react-spring/renderprops';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Modal } from 'components/GlobalComponents/Modal';
import { Link, useParams } from 'react-router-dom';
import API from '@utils/API';
import cloneDeep from 'lodash/cloneDeep';

import {
    CartOverlay,
    CartContainer,
    CartHeader,
    CloseContainer,
    PickupContainer,
    BagContainer,
    BagInfo,
    BagHeader,
    BagAddMore,
    BagItems,
    BagItem,
    ProductName,
    ProductChoices,
    AddedExtras,
    ProductPrice,
    EditRemoveBtn,
    CartItemCount,
    PriceRow,
    PriceRowText,
    PriceRowTotal,
    PriceContainer,
    CartContentContainer,
    CheckoutContainer,
    CartLoading,
    PickUpDeliveryHeadline,
    PickUpDeliveryAddressCtn,
    PickUpDeliveryAddressRow,
    PickUpDeliveryAddressBold,
    PickUpDeliveryStreet,
    StyledLocationIcon,
    StyledMobileSwipeIcon,
    StyledAddMoreLink,
} from './style';
import ToggleAddFavorite from 'components/GlobalComponents/Toggles/Favorites/AddFavorite';
import { CompleteYourMeal } from './subcomponents/CompleteYourMeal';
import { useWindowSize } from '@hooks';

const Cart = (props) => {
    // adding state to create addFave toggle
    // TODO: replace with redux state *addFave below:
    let isCancelled = false;
    const addFavorite = useSelector((state) => state.cartData.addFave);
    const [isFavorite, setIsFavorite] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [upsellItems, setUpsellItems] = useState([]);

    const dispatch = useDispatch();
    const cartLoading = useSelector((state) => state.cartData.loading);
    const { isPickup } = useSelector((state) => state.restaurantData);

    let history = useHistory();
    const isVisible = useSelector((state) => state.rootData.cart.isVisible);
    const cartData = useSelector((state) => state.cartData.data);
    const selectedLocation = useSelector((state) => state.menuData.location);
    const { location } = useParams();

    const authtoken = useSelector((state) => state.authData.authtoken);

    const viewPort = useWindowSize().width;
    const breakpoint = 1024;
    const isMobile = viewPort < breakpoint;

    const handleToggle = () => {
        dispatch(setCartVisible(false));
    };
    const setFavorite = () => {
        if (isFavorite) {
            return;
        }
        setShowModal(true);
    };

    const fetchSetFavorite = async (values, callback) => {
        let response = null;
        try {
            const { data } = await API.post(`/users/${authtoken}/createFave`, {
                basketid: cartData.id,
                description: values.description,
                isdefault: false,
            });
            response = { status: 'success', data };
            setIsFavorite((prev) => !prev);
        } catch (e) {
            response = { status: 'error' };
        } finally {
            callback(response);
        }
    };

    const setUpsellItem = (item) => {
        dispatch(addUpsellItem(item));
    };

    async function getUpsellItems() {
        const result = await API.get(`/baskets/${cartData.id}/upsell`);
        if (!isCancelled) {
            if (result?.data?.data) {
                //Break out of groups and put into flat array

                let newItems = cloneDeep(result.data.data.groups).map((item) => {
                    return item.items.flat();
                });
                setUpsellItems(newItems.flat());
            }
        }
    }

    useEffect(() => {
        if (cartData?.products && cartData.products.length > 0) {
            getUpsellItems();
        }
        return () => {
            isCancelled = true;
        };
    }, [cartData]);
    return (
        <>
            <Transition
                items={isVisible}
                from={{
                    opacity: 0,
                }}
                enter={{
                    opacity: 1,
                }}
                leave={{
                    opacity: 0,
                }}
                config={{
                    duration: 100,
                }}
            >
                {(isVisible) =>
                    isVisible &&
                    ((props) => (
                        <div style={props}>
                            <CartOverlay onClick={handleToggle}>
                                <CartContainer onClick={(e) => e.stopPropagation()}>
                                    <CartLoading isLoading={cartLoading} onClick={(e) => e.stopPropagation()}>
                                        <LoaderRed width={80} className={'inline-block'}></LoaderRed>
                                    </CartLoading>
                                    <CartContentContainer isLoading={cartLoading}>
                                        {isMobile && <NavBar />}
                                        <CartHeader>
                                            <CloseContainer>
                                                <CloseRedIcon
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    width={23}
                                                    height={23}
                                                    onClick={handleToggle}
                                                ></CloseRedIcon>
                                            </CloseContainer>

                                            {selectedLocation && (
                                                <PickupContainer>
                                                    <div className="w-full flex flex-col">
                                                        <div className="flex pb-4">
                                                            {isPickup && (
                                                                <PickUpDeliveryHeadline>
                                                                    Pickup at
                                                                </PickUpDeliveryHeadline>
                                                            )}
                                                        </div>
                                                        {isPickup ? (
                                                            <PickUpDeliveryAddressCtn>
                                                                <PickUpDeliveryAddressRow>
                                                                    <div className="flex flex-row content-center items-center">
                                                                        <StyledLocationIcon />
                                                                        <PickUpDeliveryAddressBold>
                                                                            {selectedLocation.name}
                                                                        </PickUpDeliveryAddressBold>
                                                                    </div>
                                                                </PickUpDeliveryAddressRow>
                                                                <PickUpDeliveryStreet>
                                                                    <PickUpDeliveryStreet>
                                                                        {selectedLocation.streetaddress},{' '}
                                                                        {selectedLocation.city},{' '}
                                                                        {selectedLocation.state} {selectedLocation.zip}
                                                                    </PickUpDeliveryStreet>
                                                                </PickUpDeliveryStreet>
                                                            </PickUpDeliveryAddressCtn>
                                                        ) : (
                                                            <div className="text-xl font-veneer text-black font-normal leading-8 mb-2">
                                                                Delivery
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* <div className="flex justify-end">
                                                        {' '}
                                                        <Button
                                                            className="border rounded-sm"
                                                            type="quaternary"
                                                            size="small"
                                                            onClick={() => {
                                                                history.push(`/`);
                                                                handleToggle();
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </div> */}
                                                </PickupContainer>
                                            )}
                                        </CartHeader>

                                        <BagContainer>
                                            <div className="flex-1 flex flex-col h-full">
                                                {cartData?.products && cartData?.products.length > 0 && (
                                                    <BagInfo>
                                                        <div className="flex flex-col content-center">
                                                            <div className="flex flex-row">
                                                                <BagHeader>Your Bag</BagHeader>
                                                                <CartItemCount>
                                                                    (
                                                                    {cartData?.products &&
                                                                        cartData?.products.length !== 0 &&
                                                                        cartData.products.length}
                                                                    )
                                                                </CartItemCount>
                                                            </div>
                                                            <div className="w-full flex flex-row items-center">
                                                                <ToggleAddFavorite
                                                                    {...{
                                                                        isFavorite,
                                                                    }}
                                                                    setFavorite={setFavorite}
                                                                >
                                                                    <Text type="smRed">Save To Your Favorites</Text>
                                                                </ToggleAddFavorite>
                                                            </div>
                                                        </div>
                                                        <BagAddMore>
                                                            {location ? (
                                                                <StyledAddMoreLink
                                                                    className="border rounded-sm px-2"
                                                                    type="quaternary"
                                                                    size="small"
                                                                    to={`/order/${location}/menu`}
                                                                    onClick={handleToggle}
                                                                >
                                                                    Add More Items
                                                                </StyledAddMoreLink>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </BagAddMore>
                                                    </BagInfo>
                                                )}

                                                <BagItems>
                                                    <div className="flex flex-col absolute overflow-y-scroll w-full pb-6 px-4 lg:px-8">
                                                        {cartData?.products?.map((product, i) => (
                                                            //TODO: need to accomodate the possibility that the items list is overflowed and needs to scroll
                                                            <MobileSwiper
                                                                key={i}
                                                                product={product}
                                                                beforeSwipe={
                                                                    <BagItem key={i}>
                                                                        <div className="w-full">
                                                                            <ProductName>{product.name}</ProductName>
                                                                            <div className="w-full flex flex-col">
                                                                                <ProductChoices>
                                                                                    {product.choices.map(
                                                                                        (choice, i) => (
                                                                                            <div key={i}>
                                                                                                {choice.name}
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </ProductChoices>
                                                                            </div>
                                                                            {!isMobile && (
                                                                                <div className="pt-4">
                                                                                    <EditRemoveBtn
                                                                                        //NOTE: Removed edit button
                                                                                        // // onClick={() => {
                                                                                        // //     history.push(
                                                                                        // //         `/order/${selectedLocation.slug}/edit/${i}`
                                                                                        // //     );
                                                                                        // // }}
                                                                                        // >
                                                                                        //     Edit
                                                                                        // </EditRemoveBtn>

                                                                                        //<EditRemoveBtn

                                                                                        onClick={() => {
                                                                                            dispatch(
                                                                                                removeItem(product)
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Remove
                                                                                    </EditRemoveBtn>
                                                                                </div>
                                                                            )}
                                                                        </div>

                                                                        <ProductPrice>
                                                                            <NumberFormat
                                                                                value={product.totalcost}
                                                                                displayType={'text'}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}
                                                                                thousandSeparator={true}
                                                                                prefix={'$'}
                                                                            />
                                                                        </ProductPrice>
                                                                        {isMobile && (
                                                                            <span className="flex justify-end">
                                                                                <StyledMobileSwipeIcon />
                                                                            </span>
                                                                        )}
                                                                    </BagItem>
                                                                }
                                                            ></MobileSwiper>
                                                        ))}
                                                        {upsellItems.length ? (
                                                            <CompleteYourMeal
                                                                items={upsellItems}
                                                                handleToggle={handleToggle}
                                                                setUpsellItem={setUpsellItem}
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    {!cartData?.products && <div>NO PRODUCTS</div>}
                                                </BagItems>
                                            </div>
                                        </BagContainer>

                                        <PriceContainer>
                                            <div className="py-6 px-4 lg:px-8">
                                                <PriceRow>
                                                    <PriceRowText>Subtotal</PriceRowText>
                                                    <PriceRowText>
                                                        <NumberFormat
                                                            value={cartData.subtotal}
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                        />
                                                    </PriceRowText>
                                                </PriceRow>
                                                <PriceRow>
                                                    <PriceRowText>Tax</PriceRowText>
                                                    <PriceRowText>
                                                        <NumberFormat
                                                            value={cartData.salestax}
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                        />
                                                    </PriceRowText>
                                                </PriceRow>
                                                {!isPickup && (
                                                    <PriceRow>
                                                        <PriceRowText>Delivery Fees</PriceRowText>
                                                        <PriceRowText>
                                                            <NumberFormat
                                                                value={cartData.customerhandoffcharge}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                prefix={'$'}
                                                            />
                                                        </PriceRowText>
                                                    </PriceRow>
                                                )}
                                                <PriceRow bold={true}>
                                                    <PriceRowTotal>Total</PriceRowTotal>
                                                    <PriceRowTotal>
                                                        <NumberFormat
                                                            value={cartData.total}
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                        />
                                                    </PriceRowTotal>
                                                </PriceRow>
                                            </div>
                                            <CheckoutContainer>
                                                <Button
                                                    type="primary"
                                                    isDisabled={cartData?.products && cartData.products.length < 1}
                                                    loading={cartLoading}
                                                    className={
                                                        'w-full rounded-sm font-medium text-2xl uppercase leading-7'
                                                    }
                                                    onClick={() => history.push(`/checkout/${cartData.id}`)}
                                                >
                                                    Checkout -{' '}
                                                    <NumberFormat
                                                        value={cartData.total}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                    />
                                                </Button>
                                            </CheckoutContainer>
                                        </PriceContainer>
                                    </CartContentContainer>
                                </CartContainer>
                            </CartOverlay>
                        </div>
                    ))
                }
            </Transition>
            <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                headline={'Save this order as a favorite'}
                content={({ close }) => {
                    return <Favorite {...{ fetchSetFavorite }} close={close}></Favorite>;
                }}
            ></Modal>
        </>
    );
};

export default Cart;
