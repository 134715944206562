import React from 'react';
import PropTypes from 'prop-types';
import MainContainer from 'containers/components/MainContainer';
import { GenericContainerNarrow } from '@components';
import { ResetPasswordForm } from '../components/ResetPasswordForm';

const resetPassword = (props) => {
    return (
        <>
            <GenericContainerNarrow className={'px-4 py-7 lg:py-16 lg:mb-24'}>
                <ResetPasswordForm />
            </GenericContainerNarrow>
        </>
    );
};

export default resetPassword;
