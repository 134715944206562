import React from 'react';
import {
    StyledCreditCardInfoContainer,
    StyledCreditCardDetails,
    StyledCreditCardType,
    StyledEndingIn,
} from '../../styles/profile-index';

import { Button } from '@components';

export const CreditCardInfo = ({ id, cardType, suffix, defaultCard, setDefaultCreditCard, removeCreditCard }) => {
    return (
        <>
            <StyledCreditCardInfoContainer>
                <StyledCreditCardDetails>
                    <StyledCreditCardType>{cardType}</StyledCreditCardType>
                    <StyledEndingIn>
                        {`ending in ${suffix}`}
                        {defaultCard && <span> (default)</span>}
                    </StyledEndingIn>
                </StyledCreditCardDetails>
                <div>
                    {!defaultCard && (
                        <Button
                            className="border rounded-sm mr-2"
                            type="quaternary"
                            size="small"
                            onClick={() => setDefaultCreditCard(id)}
                        >
                            Make Default
                        </Button>
                    )}

                    <Button
                        className="border rounded-sm"
                        type="quaternary"
                        size="small"
                        onClick={() => removeCreditCard(id)}
                    >
                        Delete
                    </Button>
                </div>
            </StyledCreditCardInfoContainer>
        </>
    );
};
