import React from 'react';
import { SubHeading, SavedAddressDetails } from '@components';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

const StyledCtn = styled.div`
    border-radius: 15px 15px 0px 0px;
    background-color: white;
`;

const StyledSubHeading = styled(SubHeading)`
    border-bottom: 2px solid ${(p) => p.theme.colors.extraLightGray};
`;
const SavedAddresses = ({ setInputValue }) => {
    const { userSavedDeliveryAddress } = useSelector((state) => state.authData);
    const handleOnClick = (streetAddress, building, city, state, zipcode) => {
        let address;
        if (building) {
            address = `${streetAddress}, ${building}, ${city}, ${state},${zipcode}`;
        } else {
            address = `${streetAddress}, ${city}, ${state},${zipcode}`;
        }

        setInputValue(address);
    };

    return (
        <>
            {userSavedDeliveryAddress?.length > 0 && (
                <StyledCtn>
                    <StyledSubHeading type="primary" className="px-4 pt-8 flex justify-between items-center">
                        Saved Addresses
                    </StyledSubHeading>
                    {userSavedDeliveryAddress.map((deliveryAddress) => {
                        return (
                            <SavedAddressDetails
                                key={deliveryAddress.id}
                                streetAddress={deliveryAddress.streetaddress}
                                city={deliveryAddress.city}
                                zipcode={deliveryAddress.zipcode}
                                building={deliveryAddress.building}
                                state={deliveryAddress.state}
                                handleOnClick={handleOnClick}
                            />
                        );
                    })}
                </StyledCtn>
            )}
        </>
    );
};

export default SavedAddresses;
