import React from 'react';
import cx from 'classnames';
import { Loader } from '@assets';

const ButtonType = {
    primary:
        'bg-primaryColor cursor text-white font-futuraConMD uppercase text-lg font-medium tracking-wider py-2 px-2 lg:px-5 lg:py-3',
    primaryAlt:
        'bg-black cursor text-white font-futuraConMD uppercase text-lg font-medium tracking-wider py-2 px-2 lg:px-5 lg:py-3',
    secondary:
        'text-2xl rounded-sm w-full py-3 bg-primaryColor cursor text-white font-futuraConMD uppercase font-medium tracking-wider',
    tertiary: 'font-futuraConMD text-primaryColor uppercase text-sm border-current rounded-sm border-solid',
    tertiaryWhite:
        'font-futuraConMD text-white uppercase text-sm border-white rounded-sm tracking-wider px-2 border-solid border',
    quaternary: 'font-futuraConMD text-primaryColor uppercase text-sm border-current',
    quinternary:
        'font-futuraConMD text-primaryColor flex flex-row uppercase underline text-sm mt-5 items-center lg:text-primaryColor',
    quinternaryLowerCase:
        'font-futuraHY text-primaryColor px-0 flex flex-row underline text-xs items-center lg:text-primaryColor',
    sexternary:
        'font-veneer content-center text-white opacity-50 uppercase tracking-normal xxs:tracking-tight xxs:text-lg xs:text-2xl lg:tracking-normal lg:text-3xl focus:opacity-100',
    invertedPrimary:
        'bg-white cursor text-primaryColor font-futuraConMD uppercase text-lg font-medium tracking-wider border border-primaryColor borderRadius-3 py-2 px-2 lg:px-10 xl:px-16 lg:py-3',
};

const Size = {
    small: 'p-0 px-2',
    standard: 'p-1 px-2',
    noPadding: 'p-0 px-0',
};

const Button = ({ buttonType, children, onClick, type, className, isDisabled, loading, size }) => {
    if (!size) {
        size = 'standard';
    }
    const classNames = ButtonType[type];
    const buttonSize = Size[size];
    const disabled = () => {
        if (isDisabled && !loading) {
            return 'disabled bg-darkGray opacity-25 cursor-not-allowed';
        } else {
            return '';
        }
    };
    return (
        <button
            className={cx(classNames, buttonSize, className, disabled())}
            disabled={isDisabled || loading}
            onClick={onClick}
            type={buttonType}
        >
            {loading ? <Loader width={120} className={'inline-block'}></Loader> : <span>{children}</span>}
        </button>
    );
};

export default Button;
