import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children, ...rest }) => {
    const { authtoken } = useSelector((state) => state.authData);
    let { path } = useRouteMatch();
    return (
        <Route
            {...rest}
            render={() => {
                return authtoken ? children : <Redirect to={`/auth/login`} />;
            }}
        ></Route>
    );
};

export default PrivateRoute;
