import React from 'react';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';

import { StyledOrderSummarySection, StyledHeader, StyledStoreDetails, StyledStoreInfo } from '../styles/index';
import { Text, Button } from '@components';

export const OrderSummary = ({ orderNumber, orderTotal, card, cardSuffix }) => {
    const history = useHistory();
    const { orderId } = useParams();

    return (
        <>
            <StyledOrderSummarySection>
                <StyledStoreInfo>
                    <StyledHeader>Order Summary</StyledHeader>

                    <Button
                        onClick={() => history.push(`/checkout/${orderId}/order-details`)}
                        type="tertiary"
                        size="small"
                        className="flex items-center text-center h-5 border border-primaryColor rounded-sm"
                    >
                        View Order Details
                    </Button>
                </StyledStoreInfo>

                <StyledStoreDetails>
                    <Text className="flex font-futura font-semibold text-base md:text-lg leading-5 mb-3">
                        Order #:{' '}
                        <Text type="smLT" className="px-1">
                            {orderNumber}
                        </Text>
                    </Text>
                    <Text className="flex font-futura font-semibold text-base md:text-lg leading-5 mb-3">
                        Order Total:{' '}
                        <Text type="smLT" className="px-1">
                            {orderTotal}
                        </Text>
                    </Text>

                    {/* NOTE: Commenting out until we can get user CC info from recent orders
                    <Text className="flex font-futura font-semibold text-base md:text-lg leading-5 mb-3">
                        Payment Method:
                        <Text type="smLT" className="px-1">
                            {card} ending in {cardSuffix}
                        </Text>
                    </Text> */}
                </StyledStoreDetails>
            </StyledOrderSummarySection>
        </>
    );
};
